import React, { Component } from "react";
import { Button, Icon, Form, Sidebar, Menu, Segment } from "semantic-ui-react";
import ToggleIcon from "../ToggleIcon";
import SearchSchema from "./AdminSearchSchema";
import { BasicSchemaDisplay, DetailedSchemaDisplay } from "./SchemaDisplay";
import { ViewAction, CloneAction } from "../../utils/ModalActions";
// TODO add validation:
/*
 * Marker must have a name and description
 * Marker must not have a name that already exists
 * Schema must have a name ad description
 */
// TODO likely need to improve performance of this component - maybe virtualize groups?

export default class SchemaOverlay extends Component {
  state = {
    showOptions: false,
    activeIndex: 0,
  };

  addSchemaPane = {
    menuItem: (
      <Menu.Item key="upload" className="mediate-form-tabs-item">
        <Button
          fluid
          onClick={(evt) => this.toggleOptions(evt, false)}
          icon
          className="mediate-form-tabs-item-button"
          labelPosition="right"
        >
          <Icon name="plus" />
          Add
        </Button>
      </Menu.Item>
    ),
    render: () => {
      const { onSave, currentSchema, user } = this.props;
      return (
        <DetailedSchemaDisplay
          onSave={onSave}
          currentSchema={currentSchema}
          user={user}
        />
      );
    },
  };

  searchSchemaPane = {
    menuItem: (
      <Menu.Item key="search" className="mediate-form-tabs-item">
        <Button
          onClick={(evt) => this.toggleOptions(evt, false)}
          fluid
          icon
          className="mediate-form-tabs-item-button"
          labelPosition="right"
        >
          <Icon name="search" />
          Search
        </Button>
      </Menu.Item>
    ),
    render: () => (
      <SearchSchema
        user={this.props.user}
        actions={
          this.props.actions
            ? this.props.actions
            : [
                <ViewAction
                  onClick={(schema) =>
                    window.open(`/admin/schema/${schema.id}`)
                  }
                />,
                <CloneAction onClick={this.props.onClone} />,
              ]
        }
      />
    ),
  };

  mySchemaPane = {
    menuItem: (
      <Menu.Item key="my-schema" className="mediate-form-tabs-item">
        <Button
          onClick={(evt) => this.toggleOptions(evt, false)}
          fluid
          icon
          className="mediate-form-tabs-item-button"
          labelPosition="right"
        >
          <Icon name="tags" />
          My Schema
        </Button>
      </Menu.Item>
    ),
    render: () => {
      const { userSchema, user, actions } = this.props;
      return (
        <Form
          inverted
          as={Segment}
          size="big"
          className="mediate-admin-project-user-asset-display"
        >
          <BasicSchemaDisplay
            inverted
            columns={3}
            schema={userSchema}
            user={user}
            actions={actions}
          />
        </Form>
      );
    },
  };

  renderPanes = (...panes) => {
    const { activeIndex } = this.state;
    return [
      panes.map((pane, idx) =>
        React.cloneElement(pane.menuItem, {
          ...pane.menuItem.props,
          ...{
            onClick: () => this.setState({ activeIndex: idx }),
            active: activeIndex === idx,
            index: idx,
          },
        })
      ),
      () => panes[this.state.activeIndex].render(),
    ];
  };

  toggleOptions = (evt, val) => {
    this.setState({ showOptions: val });
  };

  render() {
    const {
      currentSchema,
      backButton,
      showOptionsToggle,
      readOnly,
      showSchemaOverviewOnly,
    } = this.props;
    const { showOptions } = this.state;
    const activePanes = readOnly
      ? [this.mySchemaPane, this.searchSchemaPane]
      : [this.addSchemaPane, this.searchSchemaPane];
    const [panes, renderPane] = this.renderPanes(...activePanes);
    if (showSchemaOverviewOnly) {
      return this.addSchemaPane.render();
    }
    return (
      <div className="mediate-schema-admin-container">
        <div className="mediate-form-tabs-header mediate-schema-admin-header">
          {backButton}
          <h2 className="mediate-form-tabs-header-title">{`${
            currentSchema ? "Edit" : "Add"
          } Schema`}</h2>
          {showOptionsToggle ? (
            <ToggleIcon
              onIcon="options"
              offIcon="options"
              onColor="blue"
              offColor="grey"
              onLabel="Hide Options"
              offLabel="Show Options"
              val={showOptions}
              onClick={this.toggleOptions}
            />
          ) : (
            <div className="mediate-form-tabs-header-placeholder"></div>
          )}
        </div>
        <div className="mediate-form-tabs-menu-container">
          <Sidebar
            animation="overlay"
            visible={showOptions}
            as={Menu}
            className="mediate-form-tabs-menu"
            inverted={true}
            tabular={false}
            vertical={true}
            fluid={false}
            secondary={true}
          >
            {panes}
          </Sidebar>
          <div
            onClick={(evt) => this.toggleOptions(evt, false)}
            className={`mediate-form-tabs-active-tab-dimmer ${
              showOptions ? "visible" : "hidden"
            }`}
          ></div>
          <Sidebar.Pusher
            dimmed={showOptions}
            className="mediate-form-tabs-active-tab mediate-form-add-schema-active-tab"
          >
            {renderPane()}
          </Sidebar.Pusher>
        </div>
      </div>
    );
  }
}
