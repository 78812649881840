import { call, put, takeEvery, select, fork, all } from "redux-saga/effects";
import { delay } from "redux-saga";
import {
  loginUser,
  logoutUser,
  getUserSession,
  fetchDashboardData,
  authenticate,
} from "../api/User";

import adminSaga from "./AdminSagas";
import wsSaga from "./WebSocketSagas";
import searchSagas from "./SearchSagas";
import publicPageSagas from "./PublicPageSagas";
import notesSaga from "./NotesSaga";

import {
  fetchMediateData,
  fetchMediateMarkers,
  fetchMediatePresentationData,
  fetchAddMarker,
  fetchDeleteMarker,
  fetchUpdateMarker,
  fetchQueryOptions,
  fetchGetMarkersByMarkerType,
  fetchGetQueryResultsForPlayer,
  fetchSchemaByResearchGroup,
  queryMarkers,
} from "../api/MediateFetch";

// Constants

import { MEDIATE_ACTIONS, AUTH_ACTIONS } from "../constants/Actions";
import {
  SHORTCUT_KEY,
  CURRENT_SCHEMA_KEY,
  MARKER_STATUS_SAVED,
  MARKER_STATUS_FAILED,
  MARKER_STATUS_DELETED,
  MARKER_STATUS_PENDING,
} from "../constants/Application";

// localStorage
import LocalStorageAdapter from "../api/LocalStorageAdapter";

export function* authenticateUserSaga() {
  try {
    const authentication = yield authenticate();
    if (authentication.isAuthenticated) {
      yield put({
        type: AUTH_ACTIONS.USER_AUTHENTICATED,
        loggedIn: true,
        session: authentication.session,
      });
    }
    yield put({ type: AUTH_ACTIONS.AUTHENTICATE_ATTEMPTED });
  } catch (error) {
    console.log(error);
  }
}
/**
 * Saga to retrieve a user
 * @param login
 */
export function* login(loginAction) {
  try {
    // check if there's a csrf token set, if not, do a GET
    /*const csrftoken = Cookies.get("csrftoken");
    if (csrftoken === undefined) {
      const res = yield call(fetchGetCSRFToken);
      Cookies.set("csrftoken", res.token);
    }*/
    const responseData = yield call(loginUser, loginAction.login);
    if (responseData.session !== undefined) {
      if (responseData.session.userId !== undefined) {
        yield put({ type: AUTH_ACTIONS.USER_AUTHENTICATED, loggedIn: true });
        // Bootstrapping Dashboard data on login
        yield put({
          type: AUTH_ACTIONS.LOAD_DASHBOARD,
          dashboardData: responseData.dashboard,
          dashboardLoaded: true,
        });
        yield getSessionData();
      }
    } else {
      yield put({
        type: AUTH_ACTIONS.LOGIN_ERROR,
        reason: responseData.reason,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: AUTH_ACTIONS.LOGIN_ERROR,
    });
  }
}

export function* logoutUserSaga() {
  const response = yield logoutUser();
  yield put({ type: AUTH_ACTIONS.USER_LOGGED_OUT });
}

export function* getDashboardData() {
  try {
    yield put({
      type: AUTH_ACTIONS.LOAD_DASHBOARD,
      dashboardData: {},
      dashboardLoaded: false,
    });
    const dashboardData = yield call(fetchDashboardData);
    yield put({
      type: AUTH_ACTIONS.LOAD_DASHBOARD,
      dashboardData: dashboardData.dashboard,
      dashboardLoaded: true,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* getMediateData(getMediateDataAction) {
  const { researchGroupFilmId } = getMediateDataAction;
  try {
    const researchGroupFilm = yield fetchMediateData(researchGroupFilmId);
    if (researchGroupFilm) {
      const schema = yield fetchSchemaByResearchGroup(
        researchGroupFilm.researchGroup
      );
      yield put({
        type: MEDIATE_ACTIONS.MEDIATE_DATA_LOADED,
        researchGroupFilm: researchGroupFilm,
        mediateLoaded: true,
      });
      if (schema) {
        yield put({
          type: MEDIATE_ACTIONS.RESEARCH_GROUP_SCHEMA_LOADED,
          schema: schema,
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getMediatePresentationData(getMediatePresentationDataAction) {
  try {
    const data = yield fetchMediatePresentationData(
      getMediatePresentationDataAction.researchGroupFilmQuerySet
    );
    yield put({
      type: MEDIATE_ACTIONS.MEDIATE_DATA_LOADED,
      researchGroupFilmData: data,
      mediateLoaded: true,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* getSessionData() {
  try {
    const sessionData = yield call(getUserSession);
    yield put({
      type: AUTH_ACTIONS.LOAD_SESSION,
      session: sessionData.session,
    });
  } catch (error) {
    console.log(error);
  }
}

function* flushMarkerStatus(ms) {
  yield call(delay, ms);
  yield put({ type: MEDIATE_ACTIONS.MARKER_SAVE_PENDING, result: {} });
}

export function* loadMarkersSaga(loadMarkersAction) {
  const { type, ...rest } = loadMarkersAction;
  try {
    const results = yield fetchMediateMarkers(rest);
    yield put({
      type: MEDIATE_ACTIONS.MARKERS_LOADED,
      researchGroupFilmMarkers: results.researchGroupFilmMarkers,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* addMarkerSaga(addMarkerAction) {
  try {
    const addMarkerResponse = yield call(
      fetchAddMarker,
      addMarkerAction.researchGroupFilmMarker
    );
    if (addMarkerResponse.error) {
      if (addMarkerResponse.responseStatus === 400) {
        yield put({
          type: MEDIATE_ACTIONS.MARKER_SAVE_FAILED,
          result: {
            verboseError: addMarkerResponse.error.message,
            displayError: `Warning: cannot add duplicate marker at frame ${addMarkerAction.researchGroupFilmMarker.frameNo}`,
          },
        });
        yield flushMarkerStatus(4000);
      }
    } else {
      yield put({
        type: MEDIATE_ACTIONS.MARKER_SAVED,
        researchGroupFilmMarker: {
          ...addMarkerResponse,
          ...{ note: null },
        },
      });
      yield put({
        type: MEDIATE_ACTIONS.MARKER_SAVE_SUCCESSFUL,
        result: addMarkerResponse,
      });
      yield flushMarkerStatus(4000);
    }
  } catch (error) {
    yield put({
      type: MEDIATE_ACTIONS.MARKER_SAVE_FAILED,
      result: {
        verboseError: error,
        displayError: "Error: The marker could not be saved!",
      },
    });
    yield flushMarkerStatus(4000);
  }
}

export function* deleteMarkerSaga(deleteMarkerAction) {
  try {
    const deleteMarkerResponse = yield call(
      fetchDeleteMarker,
      deleteMarkerAction.researchGroupFilmMarkerId
    );
    if (deleteMarkerResponse.responseStatus === 204) {
      yield put({
        type: MEDIATE_ACTIONS.MARKER_DELETED,
        researchGroupFilmMarkerId: deleteMarkerAction.researchGroupFilmMarkerId,
      });
      yield flushMarkerStatus(4000);
    } else {
      throw (
        "Marker" + deleteMarkerAction.researchGroupFilmMarkerId + "not deleted"
      );
    }
  } catch (error) {
    console.log(error);
  }
}

// This is a kluge - it's really only for updating the 'note' field because I don't have the time to write an update method in DRF
// TODO format response with objects rather than IDs
export function* updateMarkerSaga(updateMarkerAction) {
  try {
    const updatedMarkerResponse = yield call(
      fetchUpdateMarker,
      updateMarkerAction.researchGroupFilmMarker
    );
    yield put({
      type: MEDIATE_ACTIONS.MARKER_UPDATED,
      researchGroupFilmMarker: updatedMarkerResponse,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* saveCurrentSchemaSaga(saveSchemaAction) {
  const { currentSchema, groupId } = saveSchemaAction;
  try {
    if (currentSchema) {
      const { id } = currentSchema;
      yield LocalStorageAdapter.set(`${CURRENT_SCHEMA_KEY}_${groupId}`, id);
    }
  } catch (error) {
    console.log(error);
  }
}

const getSchemaFromState = (id) => {
  return (state) => {
    const { schema } = state.mediate.researchGroupFilmData;
    if (schema) {
      // TODO figure out what s.id and id aren't the same type
      return schema.find((s) => s.id == id);
    }
  };
};

export function* loadCurrentSchemaSaga(saveSchemaAction) {
  const { groupId } = saveSchemaAction;
  try {
    // TODO FINISH THIS
    const result = yield LocalStorageAdapter.get(
      `${CURRENT_SCHEMA_KEY}_${groupId}`
    );
    // Get the actual schema from the state
    const schema = yield select(getSchemaFromState(result));
    if (schema) {
      yield put({ type: MEDIATE_ACTIONS.SCHEMA_LOADED, currentSchema: schema });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* saveShortcutSaga(action) {
  const { schemaId, markerTypeId, keystrokes } = action;
  const shortcutsKey = `${SHORTCUT_KEY}-schema-${schemaId}`;
  try {
    // see if shortcuts exist for schema
    let shortcuts;
    try {
      shortcuts = yield LocalStorageAdapter.get(shortcutsKey);
    } catch (error) {
      // not found
      shortcuts = null;
    }
    const markerKey = markerTypeId.toString();
    if (!shortcuts) {
      shortcuts = keystrokes ? { [markerKey]: keystrokes } : {};
    } else {
      shortcuts = JSON.parse(shortcuts);
      if (shortcuts[markerKey] && !keystrokes) {
        let removed;
        ({ [markerKey]: removed, ...shortcuts } = shortcuts);
      } else {
        if (keystrokes) {
          shortcuts[markerKey] = keystrokes;
        }
      }
    }
    const result = yield LocalStorageAdapter.set(
      shortcutsKey,
      JSON.stringify(shortcuts)
    );
    if (result.success) {
      yield put({
        type: MEDIATE_ACTIONS.SHORTCUTS_UPDATED,
        shortcuts: shortcuts,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* loadShortcutsSaga(loadShortcutsAction) {
  try {
    const { schemaId } = loadShortcutsAction;
    const shortcutsKey = `${SHORTCUT_KEY}-schema-${schemaId}`;
    const result = yield LocalStorageAdapter.get(shortcutsKey);
    yield put({
      type: MEDIATE_ACTIONS.SHORTCUTS_UPDATED,
      shortcuts: JSON.parse(result),
    });
  } catch (error) {
    yield put({
      type: MEDIATE_ACTIONS.SHORTCUTS_UPDATED,
      shortcuts: null,
    });
  }
}

export function* getQueryOptions(getQueryOptionsAction) {
  try {
    const queryOptionsResponse = yield call(fetchQueryOptions);
    yield put({
      type: MEDIATE_ACTIONS.QUERY_OPTIONS_LOADED,
      queryOptions: queryOptionsResponse,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* getMarkersByMarkerType(getMarkersByMarkerTypeAction) {
  try {
    yield put({
      type: MEDIATE_ACTIONS.QUERY_RESULTS_PENDING,
      queryPending: true,
    });
    const getMarkersResponse = yield call(
      fetchGetMarkersByMarkerType,
      getMarkersByMarkerTypeAction.markerTypeIds
    );
    yield put({
      type: MEDIATE_ACTIONS.QUERY_RESULTS_LOADED,
      queryResults: getMarkersResponse.queryResults,
    });
    yield put({
      type: MEDIATE_ACTIONS.QUERY_RESULTS_PENDING,
      queryPending: false,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* queryMarkersSaga(queryAction) {
  const { query } = queryAction;
  yield put({
    type: MEDIATE_ACTIONS.QUERY_RESULTS_PENDING,
    queryPending: true,
  });
  const data = yield queryMarkers(query);
  yield put({
    type: MEDIATE_ACTIONS.QUERY_RESULTS_LOADED,
    queryResults: data.results,
  });
  yield put({
    type: MEDIATE_ACTIONS.QUERY_RESULTS_PENDING,
    queryPending: false,
  });
}

export function* getQueryResultsForPlayerSaga(getQueryResultsForPlayerAction) {
  try {
    yield put({ type: MEDIATE_ACTIONS.PLAYER_QUERY_RESULTS_PENDING });
    const queryResults = yield call(
      fetchGetQueryResultsForPlayer,
      getQueryResultsForPlayerAction.markerTypeObj
    );
    yield put({
      type: MEDIATE_ACTIONS.PLAYER_QUERY_RESULTS_LOADED,
      playerQueryResults: queryResults,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* watchLoadQueryOptions() {
  yield takeEvery(MEDIATE_ACTIONS.LOAD_QUERY_OPTIONS, getQueryOptions);
}

export function* watchGetMarkersByMarkerType() {
  yield takeEvery(
    MEDIATE_ACTIONS.GET_MARKERS_BY_MARKER_TYPE,
    getMarkersByMarkerType
  );
}

export function* watchQueryMarkers() {
  yield takeEvery(MEDIATE_ACTIONS.QUERY_MARKERS, queryMarkersSaga);
}

export function* watchAuthenticateUser() {
  yield takeEvery(AUTH_ACTIONS.AUTHENTICATE_USER, authenticateUserSaga);
}

export function* watchUserLogin() {
  yield takeEvery(AUTH_ACTIONS.LOGIN_USER, login);
}

export function* watchUserLogout() {
  yield takeEvery(AUTH_ACTIONS.LOGOUT_USER, logoutUserSaga);
}

export function* watchGetSession() {
  yield takeEvery(AUTH_ACTIONS.GET_SESSION, getSessionData);
}

export function* watchGetDashboardData() {
  yield takeEvery(AUTH_ACTIONS.GET_DASHBOARD, getDashboardData);
}

export function* watchGetMediateData() {
  yield takeEvery(MEDIATE_ACTIONS.LOAD_MEDIATE_DATA, getMediateData);
}

export function* watchForLoadMediateMarkers() {
  yield takeEvery(MEDIATE_ACTIONS.LOAD_MARKERS, loadMarkersSaga);
}

export function* watchForSaveCurrentSchema() {
  yield takeEvery(MEDIATE_ACTIONS.SCHEMA_SELECTED, saveCurrentSchemaSaga);
}

export function* watchForLoadCurrentSchema() {
  yield takeEvery(MEDIATE_ACTIONS.LOAD_CURRENT_SCHEMA, loadCurrentSchemaSaga);
}

export function* watchGetMediatePresentationData() {
  yield takeEvery(
    MEDIATE_ACTIONS.LOAD_MEDIATE_PRESENTATION_DATA,
    getMediatePresentationData
  );
}

export function* watchAddMarkerSaga() {
  yield takeEvery(MEDIATE_ACTIONS.ADD_MARKER, addMarkerSaga);
}

export function* watchDeleteMarkerSaga() {
  yield takeEvery(MEDIATE_ACTIONS.DELETE_MARKER, deleteMarkerSaga);
}

export function* watchUpdateMarkerSaga() {
  yield takeEvery(MEDIATE_ACTIONS.UPDATE_MARKER, updateMarkerSaga);
}

export function* watchGetQueryResultsForPlayer() {
  yield takeEvery(
    MEDIATE_ACTIONS.GET_QUERY_RESULT_PLAYER,
    getQueryResultsForPlayerSaga
  );
}

export function* watchSaveShortcutSaga() {
  yield takeEvery(MEDIATE_ACTIONS.SAVE_SHORTCUT, saveShortcutSaga);
}

export function* watchLoadShortcutsSaga() {
  yield takeEvery(MEDIATE_ACTIONS.LOAD_SHORTCUTS, loadShortcutsSaga);
}

/**
 * Root Saga to hold all other sagas
 */
export default function* rootSaga() {
  yield all([
    watchUserLogin(),
    watchUserLogout(),
    watchAuthenticateUser(),
    watchGetSession(),
    watchGetDashboardData(),
    watchGetMediateData(),
    watchForLoadMediateMarkers(),
    watchForSaveCurrentSchema(),
    watchForLoadCurrentSchema(),
    watchGetMediatePresentationData(),
    watchAddMarkerSaga(),
    watchDeleteMarkerSaga(),
    watchUpdateMarkerSaga(),
    watchLoadQueryOptions(),
    watchGetMarkersByMarkerType(),
    watchQueryMarkers(),
    watchGetQueryResultsForPlayer(),
    watchSaveShortcutSaga(),
    watchLoadShortcutsSaga(),
    fork(adminSaga),
    fork(wsSaga),
    fork(searchSagas),
    fork(publicPageSagas),
    fork(notesSaga),
  ]);
}
