import React, { useState } from "react";
import LiveSearch from "./LiveSearch";
import { SEARCH_KEYS } from "../../constants/Application";
import { Icon, Modal, Card, Image, Button } from "semantic-ui-react";
import DynamicGrid from "../DynamicGrid";
import { Link } from "react-router-dom";
import { BasicVideoPlayer } from "../VideoPlayer";
import { playerOptions } from "../MediateInterface";
import backendUrl from "../../constants/Backend";
import { getLanguageFromCaptionsUrl } from "../../utils/Captions";

export default function SearchMedia(props) {
  const { onSelectResult } = props;
  return (
    <LiveSearch
      searchLabel="Search By Title"
      searchKey={SEARCH_KEYS.MEDIA}
      resultsDisplay={MediaResultsDisplay}
      onSelectResult={onSelectResult}
    />
  );
}

export function MediaResultsDisplay(props) {
  const { results, onSelectResult } = props;
  return (
    <DynamicGrid
      divided={true}
      columns={3}
      className="mediate-search-results-display"
      data={results}
      renderCell={MediaResultDisplay}
      onSelectCell={onSelectResult}
    />
  );
}

function derivativeReady(derivative) {
  if (derivative) {
    const { mp4_url, mp4Url, webmUrl } = derivative;
    const mp4 = mp4_url ? mp4_url : mp4Url;
    if (mp4 && webmUrl) return true;
  }
  return false;
}

export function MediaResultDisplay(props) {
  const {
    title,
    overviewImage,
    owner,
    onSelect,
    id,
    onSelectButton,
    forwardRef,
    open,
    onToggleModal,
  } = props;
  const mediaUpload = props.mediaUpload[0];
  const { mediaType, derivativeDuration } = mediaUpload;
  const derivative = mediaUpload.derivative[0];
  const [playMedia, setPlayMedia] = useState(false);
  const [showModal, setShowModal] = useState(false);
  if (!derivativeReady(derivative)) {
    return (
      <div className="mediate-media-search-result">
        <Icon
          className="mediate-search-media-result-label"
          inverted
          color="grey"
          name="sync"
        />
        <div className="mediate-search-media-result-info">
          <span className="mediate-search-media-result-title">{title}</span>
          <span className="mediate-search-media-result-owner">Processing</span>
        </div>
      </div>
    );
  }

  // TODO fix this bug on Django end - camelcase regex is wrong
  const { mp4_url, mp4Url, webmUrl, captionsUrls } = derivative;
  let mp4 = mp4_url ? mp4_url : mp4Url;
  const src = [
    { src: mp4, type: "video/mp4" },
    { src: webmUrl, type: "video/webm" },
  ];
  let textTracks = [];
  if (captionsUrls.length > 0) {
    textTracks = captionsUrls.map((captions) => ({
      kind: "captions",
      label: getLanguageFromCaptionsUrl(captions),
      src: `${backendUrl}/${captions}`,
    }));
  }
  const icon =
    mediaType === "video" ? "file video outline" : "file audio outline";
  const color = mediaType === "video" ? "blue" : "teal";
  const captionsIcon = captionsUrls.length > 0 ? <Icon name="closed captioning" /> : null;
  return (
    <Modal
      className="mediate-search-result-detail-popup"
      ref={forwardRef}
      trigger={
        <div
          className="mediate-media-search-result"
          onClick={() => {
            setShowModal(true);
            if (onToggleModal) onToggleModal(id, true);
          }}
        >
          <Icon
            size="large"
            className="mediate-search-media-result-label"
            color={color}
            name={icon}
          />
          <div className="mediate-search-media-result-info">
            <span className="mediate-search-media-result-title">{title}</span>
            <span className="mediate-search-media-result-owner">
              @{owner.username}
            </span>
          </div>
        </div>
      }
      open={open !== undefined ? open : showModal}
      onClose={() => {
        setShowModal(false);
        if (onToggleModal) onToggleModal(id, false);
      }}
      dimmer="blurring"
      basic
    >
      <Modal.Content>
        {playMedia ? (
          <div className="mediate-search-result-video-container">
            <Button
              size="medium"
              inverted
              icon
              onClick={() => setPlayMedia(false)}
              className="mediate-search-result-back-button"
              labelPosition="left"
            >
              <Icon name="backward" />
              {title}
            </Button>
            <BasicVideoPlayer
              src={src}
              playerOptions={playerOptions}
              textTracks={textTracks}
            />
          </div>
        ) : (
          <Card fluid className="mediate-search-result-detail-card">
            <Image
              className="mediate-search-result-overview-image"
              src={overviewImage}
              fluid
            />
            <Card.Content>
              <Card.Header>{title}</Card.Header>
              <Card.Meta>
                {`${mediaType} | ${derivativeDuration}`} {captionsIcon}
              </Card.Meta>
              <Card.Description>
                <div className="mediate-search-media-result-info">
                  <span className="mediate-search-media-created-info">
                    {"Uploaded by "}
                    <Link
                      className="mediate-admin-link mediate-search-media-result-owner-link"
                      to={`/admin/users/${owner.id}`}
                    >
                      {owner.username}
                    </Link>
                    {" on "}
                    <span className="mediate-search-media-result-date">
                      {new Date(props.createdDate).toLocaleString()}
                    </span>
                  </span>
                </div>
              </Card.Description>
            </Card.Content>
            <Card.Content extra className="mediate-search-media-result-options">
              <Modal.Actions>
                <Button
                  compact
                  icon
                  labelPosition="right"
                  onClick={() => setPlayMedia(true)}
                  size="tiny"
                  color="teal"
                >
                  <Icon name="play" />
                  Play
                </Button>
                {onSelectButton ? (
                  onSelectButton
                ) : (
                  <Button
                    compact
                    size="tiny"
                    icon
                    labelPosition="right"
                    onClick={() => {
                      onSelect(id);
                      setShowModal(false);
                      if (onToggleModal) onToggleModal(false);
                    }}
                    color="blue"
                  >
                    <Icon name="plus" />
                    Add
                  </Button>
                )}
              </Modal.Actions>
            </Card.Content>
          </Card>
        )}
      </Modal.Content>
    </Modal>
  );
}
