import React, { Component, useCallback } from "react";
import SimpleBar from "simplebar-react";
import { Button, Icon } from "semantic-ui-react";
import { BasicAdminMarkerLabel } from "./AdminMarkerLabel";
import DynamicGrid from "../DynamicGrid";
export default class MarkerOverlay extends Component {
  state = { updatedMarkers: {} };
  renderCell = (props) => {
    const { user, actions } = this.props;
    const p = {
      ...props,
      actions: actions,
      user: user,
      onUpdate: this.handleUpdate,
    };
    return <BasicAdminMarkerLabel {...p} />;
  };
  handleUpdate = (marker) => {
    const { updatedMarkers } = this.state;
    const { id, ...rest } = marker;
    const cloned = { ...updatedMarkers };
    cloned[id] = rest;
    this.setState({
      updatedMarkers: cloned,
    });
  };
  handleSave = () => {
    const { onSave } = this.props;
    const { updatedMarkers } = this.state;
    if (onSave) {
      onSave(Object.values(updatedMarkers));
    }
  };
  render() {
    const { markers, backButton, columns } = this.props;
    return (
      <div className="mediate-marker-admin-container">
        <div className="mediate-form-tabs-header mediate-marker-admin-header">
          {backButton}
          <h2 className="mediate-form-tabs-header-title">My Markers</h2>
        </div>

        <div className="mediate-marker-admin">
          <div className="mediate-marker-options">
            <div>Search Markers ...</div>
            <Button
              compact
              className="mediate-schema-save-button"
              size="medium"
              icon
              labelPosition="right"
              color="blue"
            >
              <Icon name="save" />
              Save
            </Button>
          </div>
          <SimpleBar
            style={{ overflowX: "hidden" }}
            className="mediate-basic-marker-list"
          >
            <div className="mediate-marker-labels-display-container">
              <DynamicGrid
                columns={columns !== undefined ? columns : 2}
                className="mediate-marker-labels-display"
                data={markers}
                renderCell={this.renderCell}
              />
            </div>
          </SimpleBar>
        </div>
      </div>
    );
  }
}
