// React
import React, { Component } from "react";

// Stardust
import { Loader, Modal } from "semantic-ui-react";

// React Transitions
import { CSSTransitionGroup } from "react-transition-group";

export default function PageLoader(props) {
  const { text } = props;
  return (
    <div className="mediate-loading-modal">
      <Modal basic dimmer size="small" open={true}>
        <Modal.Content>
          <Loader size="massive">{text}</Loader>
        </Modal.Content>
      </Modal>
    </div>
  );
}
