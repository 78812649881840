// React
import React, { Component } from "react";

// Stardust
import { Loader, Icon } from "semantic-ui-react";

// lodash
import lodash from "lodash";
import { object } from "prop-types";

// node-uuid
const uuidV1 = require("uuid/v1");

export default class DataDownloader extends Component {
  state = { activeDownloadURL: null };
  constructor(props) {
    super(props);
    this.toJSON = this.toJSON.bind(this);
    this.toCSV = this.toCSV.bind(this);
    this.createURL = this.createURL.bind(this);
  }

  toJSON(data) {
    return JSON.stringify(data);
  }

  toCSV(data) {
    let keys = Object.keys(data[0]);
    let csv = "";
    let header = keys.join(",") + "\n";
    csv += header;
    data.forEach((dataObject) => {
      keys.forEach((key, index) => {
        let value = dataObject[key];
        let escaped;
        if (value != null) {
          let value =
            typeof dataObject[key] === "object"
              ? JSON.stringify(dataObject[key])
              : dataObject[key].toString();
          escaped = value.replace(/"/g, '""');
        } else {
          escaped = "none";
        }
        let csvString = '"' + escaped + '"';
        if (index === keys.length - 1) {
          csv += csvString + "\n";
        } else {
          csv += csvString + ",";
        }
      });
    });
    return csv;
  }

  createURL(formattedData, filename, dataType) {
    let blob = new Blob([formattedData], { type: dataType });
    let url = window.URL.createObjectURL(blob);
    return url;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!lodash.isEqual(this.props, nextProps)) {
      return true;
    }
    return false;
  }

  render() {
    const { fileFormat, data, csvData, queryPending } = this.props;
    if (data.length > 0 && queryPending === false) {
      // Actual download here
      let filename = "marker_data_" + uuidV1() + "." + fileFormat;
      if (fileFormat === "csv") {
        return (
          <div className="data-download-link">
            <a
              href={this.createURL(this.toCSV(csvData || data), filename)}
              download={filename}
            >
              <Icon name="cloud download" />
              <h4>Download {filename}</h4>
            </a>
          </div>
        );
      } else if (fileFormat === "json") {
        return (
          <div className="data-download-link">
            <a
              href={this.createURL(this.toJSON(data), filename)}
              download={filename}
            >
              <Icon name="cloud download" />
              <h4>Download {filename}</h4>
            </a>
          </div>
        );
      } else {
        return (
          <div className="data-download-link">
            <h4>Please select an output format</h4>
          </div>
        );
      }
    } else if (queryPending === true) {
      return (
        <div className="data-download-link">
          <Loader active inline>
            <h4 className="data-fetching-loader-message">Fetching Data ...</h4>
          </Loader>
        </div>
      );
    } else {
      return <h4>No Queries to Fetch</h4>;
    }
  }
}
