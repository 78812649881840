import React, { Component } from "react";

import ProjectView from "../../componets/admin/ProjectView";

// Redux
import { connect } from "react-redux";

import {
  loadResearchGroupDetail,
  loadUserMedia,
  addProjectMedia,
  deleteProjectMedia,
  addSchemaToProject,
  cloneProjectSchema,
  removeSchemaFromProject,
  addResearchGroupUser,
  updateResearchGroupUser,
  deleteResearchGroupUser,
  clearAdminInfo,
} from "../../actions/AdminActions";

import {
  subscribeToChannel,
  unsubscribeFromChannel,
} from "../../actions/WebSocketActions";

import PageLoader from "../../componets/Loading";

import AdminNotification from "../../componets/admin/AdminNotification";
import Search from "../Search";
import AdminContextProvider from "./AdminContext";

class ProjectContainer extends Component {
  componentDidMount() {
    const { researchGroupId, user } = this.props;
    if (user.isAuthenticated) {
      this.props.loadUserMedia(user.userId);
      this.props.loadResearchGroupDetail(researchGroupId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { researchGroup } = this.props;
    if (prevProps.researchGroup) {
      if (researchGroup.name !== prevProps.researchGroup.name) {
        this.props.subscribeToChannel(researchGroup.name);
      }
    } else {
      if (researchGroup) {
        this.props.subscribeToChannel(researchGroup.name);
      }
    }
  }

  componentWillUnmount() {
    this.props.unsubscribeFromChannel();
  }

  render() {
    const {
      researchGroup,
      media,
      addProjectMedia,
      addSchemaToProject,
      cloneProjectSchema,
      removeSchemaFromProject,
      message,
      deleteProjectMedia,
      addResearchGroupUser,
      updateResearchGroupUser,
      deleteResearchGroupUser,
      userMedia,
      clearAdminInfo,
      user,
    } = this.props;
    if (researchGroup) {
      return (
        <div className="mediate-admin-container">
          <AdminNotification
            timeout={10000}
            message={message}
            onTimeout={clearAdminInfo}
          >
            <AdminContextProvider>
              <Search>
                <ProjectView
                  {...researchGroup}
                  onSelectMedia={addProjectMedia}
                  onSelectSchema={addSchemaToProject}
                  onCloneSchema={cloneProjectSchema}
                  onDeleteSchema={removeSchemaFromProject}
                  onDeleteMedia={deleteProjectMedia}
                  onAddCollaborator={addResearchGroupUser}
                  onUpdateCollaborator={updateResearchGroupUser}
                  onDeleteCollaborator={deleteResearchGroupUser}
                  media={media}
                  userMedia={userMedia}
                  user={user}
                />
              </Search>
            </AdminContextProvider>
          </AdminNotification>
        </div>
      );
    } else {
      return <PageLoader text="Loading Project ..." />;
    }
  }
}

function mapStateToProps(state, ownProps) {
  let { researchGroupId } = ownProps.match.params;
  const { researchGroups, message, projectMedia } = state.admin;
  researchGroupId = parseInt(researchGroupId, 10);
  let group;
  if (researchGroups && researchGroups.length > 0) {
    group = researchGroups.find(
      (rg) => rg.id === parseInt(researchGroupId, 10) && rg.researchGroupMarkerSets
    );
  }
  
  return {
    researchGroup: group,
    researchGroupId: researchGroupId,
    user: state.user.session,
    message: message,
    media: projectMedia.filter((rgf) => rgf.researchGroup === researchGroupId),
    userMedia: state.admin.media,
  };
}

export default connect(mapStateToProps, {
  loadResearchGroupDetail,
  loadUserMedia,
  addProjectMedia,
  addSchemaToProject,
  cloneProjectSchema,
  removeSchemaFromProject,
  subscribeToChannel,
  unsubscribeFromChannel,
  deleteProjectMedia,
  addResearchGroupUser,
  updateResearchGroupUser,
  deleteResearchGroupUser,
  clearAdminInfo,
})(ProjectContainer);
