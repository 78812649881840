import React, { Component } from "react";
import { Form, Segment } from "semantic-ui-react";
import sleep from "../../utils/sleep";
import { SearchContext } from "../../containers/Search";
import { Label, Icon } from "semantic-ui-react";
import SimpleBar from "simplebar-react";

// TODO when this works turn it into a generic Live Search component
export default class LiveSearch extends Component {
  state = { query: "", timeInactive: 0, currentSearchTerm: "" };
  defaultSearchThrottle = 500;
  sleepDuration = 250;
  _resultsElement = React.createElement;
  componentDidMount() {
    this.initWatchTimeInactive();
  }

  initWatchTimeInactive = async () => {
    await this.watchTimeInactive();
  };

  clearQuery = () => {
    this.setState({
      query: "",
    });
  };

  handleQueryChange = (event, { value }) => {
    this.setState(
      {
        query: value,
      },
      this.resetTimeInactive
    );
  };

  shouldSearchRun = () => {
    const { throttleVal } = this.props;
    const throttle =
      throttleVal !== undefined ? throttleVal : this.defaultSearchThrottle;
    const { query, timeInactive, currentSearchTerm } = this.state;
    return (
      query !== "" && query !== currentSearchTerm && timeInactive > throttle
    );
  };

  watchTimeInactive = async () => {
    while (true) {
      await sleep(this.sleepDuration);
      const { query, timeInactive } = this.state;
      if (this.shouldSearchRun()) {
        this.runSearch();
      } else {
        this.setState((prevState) => {
          return {
            timeInactive: prevState.timeInactive + this.sleepDuration,
          };
        });
      }
    }
  };

  resetTimeInactive = () => {
    this.setState({
      timeInactive: 0,
    });
  };

  runSearch = () => {
    const { searchKey } = this.props;
    const query = { key: searchKey, searchText: this.state.query };
    this.context.runSearch(query);
    this.setState({
      currentSearchTerm: this.state.query,
    });
    this.resetTimeInactive();
  };

  shouldComponentUpdate(prevProps, prevState) {
    const { query } = this.state;
    if (prevState.query !== query) return true;
    return false;
  }

  render() {
    const {
      className,
      searchLabel,
      searchLabelAsPlaceholder,
      resultsDisplay,
      searchKey,
      onSelectResult,
    } = this.props;
    const { resultsPending } = this.context;
    const results = this.context.results[searchKey];
    return (
      <Form
        loading={resultsPending}
        inverted
        as={Segment}
        className="mediate-admin-search-form"
        size="huge"
      >
        {searchLabel && !searchLabelAsPlaceholder ? (
          <div className="mediate-project-overview-header-container">
            <h1 className="mediate-project-overview-header">{searchLabel}</h1>
          </div>
        ) : null}
        <Form.Field className="mediate-live-search-input">
          <Form.Input
            className="mediate-live-search-text-input"
            transparent
            type="text"
            onChange={this.handleQueryChange}
            placeholder={
              searchLabelAsPlaceholder && searchLabel ? searchLabel : "..."
            }
            value={this.state.query}
          />
        </Form.Field>
        <Form.Field className="mediate-live-search-results">
        <SimpleBar className="mediate-live-search-scroll">
          {results ? (
            
              this._resultsElement(resultsDisplay, {
                results: results,
                onSelectResult: onSelectResult,
                className: `mediate-live-search-results-grid ${className}`,
              })
            
          ) : null}
          </SimpleBar>
        </Form.Field>
      </Form>
    );
  }
}

LiveSearch.contextType = SearchContext;
