import React, { Component } from "react";
import { Form, Button, Label, Icon, Message } from "semantic-ui-react";
import lodash from "lodash";
import ConfirmDelete from "./ConfirmDelete";

function LocalFileDisplay(props) {
  const { file, onDelete } = props;
  return (
    <Label className="file-display">
      {file.name}
      <Icon name="delete" onClick={onDelete} />
    </Label>
  );
}

function RemoteFileDisplay(props) {
  const { file, onDelete, readOnly } = props;
  const fileLabel = (
    <Label className="file-display">
      {file.originalFileBasename}
      {!readOnly ? <Icon name="delete" /> : null}
    </Label>
  );
  if (readOnly) return fileLabel;
  return (
    <ConfirmDelete
      deleteButton={fileLabel}
      onDelete={onDelete}
      deleteKey={file.id}
      title={file.originalFileBasename}
    />
  );
}

export default class FileField extends Component {
  state = {
    files: [],
    errorMessage: null,
  };

  constructor(props) {
    super(props);
    this.fileRef = React.createRef(null);
  }

  componentDidUpdate(prevProps) {
    const { currentFiles } = this.props;
    if (currentFiles) {
      if (currentFiles.length !== prevProps.currentFiles.length) {
        this.updateFiles();
      }
    }
  }

  updateFiles = () => {
    const { currentFiles } = this.props;
    const { files } = this.state;
    if (currentFiles) {
      // remove local files
      const updated = files.filter(
        (f) => !currentFiles.some((cf) => cf.originalFileBasename === f.name)
      );
      this.setState({
        files: updated,
      });
    }
  };

  mergeFiles = (origFiles, newFiles) => {
    return lodash.unionWith(origFiles, newFiles, (a, b) => a.name === b.name);
  };

  duplicateFileWarning = (dupes) => {
    this.setState({
      errorMessage: `Files ${dupes
        .map((d) => d.originalFileBasename)
        .join(",")} already exist remotely.`,
    });
  };

  handleChange = () => {
    if (this.fileRef.current) {
      const { files } = this.fileRef.current;
      const { multiple } = this.props;
      const localFiles = this.mergeFiles(this.state.files, Array.from(files));
      const { currentFiles } = this.props;
      // check to see if any of the remote files are already saved
      const dupes = currentFiles ? currentFiles.filter((f) =>
        localFiles.some((lf) => f.originalFileBasename === lf.name)
      ) : [];
      if (dupes.length > 0) {
        this.duplicateFileWarning(dupes);
      } else {
        this.setState(
          {
            files: multiple
              ? this.mergeFiles(this.state.files, Array.from(files))
              : Array.from(files),
            errorMessage: null,
          },
          this.onChangeCallback
        );
      }
    }
  };

  onChangeCallback = () => {
    const { onChange, multiple } = this.props;
    const { files } = this.state;
    if (multiple) {
      onChange(files);
    } else {
      onChange(files[0]);
    }
  };

  handleOpenFileDialog = () => {
    if (this.fileRef.current) {
      this.fileRef.current.value = null;
      this.fileRef.current.click();
    }
  };

  removeFile = (idx) => {
    const { files } = this.state;
    const updated = [...files];
    updated.splice(idx, 1);
    this.setState(
      {
        files: updated,
      },
      this.onChangeCallback
    );
  };

  renderFiles = () => {
    const { files } = this.state;
    const {
      defaultDisplay,
      currentFiles,
      onDeleteCurrent,
      readOnly,
      multiple,
    } = this.props;
    if (!currentFiles || currentFiles.length === 0) {
      if (files.length === 0) return defaultDisplay;
    }
    const localFiles = files.map((file, idx) => (
      <LocalFileDisplay
        key={file.name}
        file={file}
        onDelete={() => this.removeFile(idx)}
      />
    ));
    const remoteFiles = currentFiles
      ? currentFiles.map((f, idx) => (
          <RemoteFileDisplay
            key={`${f.uuid}-${f.id}`}
            file={f}
            readOnly={readOnly}
            onDelete={
              onDeleteCurrent ? onDeleteCurrent : () => this.removeFile(idx)
            }
          />
        ))
      : [];
    return multiple
      ? localFiles.concat(remoteFiles)
      : localFiles.length > 0
      ? localFiles
      : remoteFiles;
  };

  render() {
    const { mediaTypes, label, multiple, size, as, ...rest } = this.props;
    const { errorMessage } = this.state;
    const s = size ? size : "large";
    return (
      <Form.Field className="mediate-form-file-field">
        <label className="mediate-form-label">{label}</label>
        <input
          hidden
          multiple={multiple}
          ref={this.fileRef}
          type="file"
          accept={mediaTypes}
          onChange={this.handleChange}
        />
        {as ? React.createElement(as, { ...rest, onClick: this.handleOpenFileDialog }) : 
        <div className="mediate-form-file-field-container">
          <Button
            icon="upload"
            circular
            onClick={this.handleOpenFileDialog}
            size={s}
          />
          <span className="mediate-form-upload-info">{this.renderFiles()}</span>
        </div>
        } 
        {errorMessage && !as ? (
          <Message
            compact
            floating
            size="mini"
            className="mediate-file-field-message"
          >
            {errorMessage}
          </Message>
        ) : null}
      </Form.Field>
    );
  }
}
