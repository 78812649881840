import React, { useState, Fragment, useCallback } from "react";
import {
  Accordion,
  Segment,
  Icon,
  Dropdown,
  Header,
  Label,
  Button,
  Radio,
} from "semantic-ui-react";
import { fetchSchemaByResearchGroup } from "../api/MediateFetch";
import MediateSchemaFilter from "./MediateSchemaFilter";
import SimpleBar from "simplebar-react";
import DataDownloader from "./DataDownloader";

function formatQueryData(qd) {
  if (!qd.length) {
    return [];
  }
  return qd.map((d) => {
    const {
      filmTimeCode,
      researchGroupMarkerSetType,
      researchGroupFilm,
      researchGroupMarkerSet,
      ...rest
    } = d;
    const { researchGroupMarkerSetGroup, ...markerType } =
      researchGroupMarkerSetType;
    return {
      ...rest,
      frameNumber: filmTimeCode.frameNo,
      project: researchGroupFilm.project,
      media: researchGroupFilm.media,
      markerType,
    };
  });
}

function formatCSVData(data) {
  if (!data.length) {
    return [];
  }
  return data.map((d) => {
    const { createdDate, frameNumber, schema, markerTypeName, media, note, owner } =
      d;
    return {
      ["created date"]: createdDate,
      ["frame number"]: frameNumber,
      media: media,
      ["marker type"]: markerTypeName,
      // description: markerType.description,
      note: (note && note.noteText) || "None",
      owner: owner,
      schema: schema.name,
      ["schema id"]: schema.id,
    };
  });
}

export default function ProjectDataQuery(props) {
  const { queryOptions, queryPending, onRunQuery, queryResults } = props;
  const [activeIndexes, setActiveIndexes] = useState(new Set());
  const [selectedMarkerTypes, setSelectedMarkerTypes] = useState({});
  const [selectedUsers, setSelectedUsers] = useState({});
  const [selectedMedia, setSelectedMedia] = useState(new Set());
  const [fileFormat, setFileFormat] = useState("csv");

  const [schemas, setSchemas] = useState({});

  function hasActiveIndex(idx) {
    return activeIndexes.has(idx);
  }

  function handleRunQuery() {
    const markerTypes = new Set(
      Object.values(selectedMarkerTypes).reduce((a, b) => a.concat(b), [])
    );

    const media = Object.values(selectedMedia).reduce(
      (a, b) => a.concat(b),
      []
    );

    const users = new Set(
      Object.values(selectedUsers).reduce((a, b) => a.concat(b), [])
    );

    onRunQuery({
      marker_types: [...markerTypes],
      media: [...media],
      users: [...users],
    });
  }

  function handleSelectUsers(schemaId, users) {
    setSelectedUsers((u) => ({
      ...u,
      [schemaId]: users.map((user) => user.user.id),
    }));
  }

  function handleSelectMedia(projectId, media) {
    setSelectedMedia((m) => ({
      ...m,
      [projectId]: media,
    }));
  }

  const handleSelectMarkerTypes = useCallback((schemaId, markerTypes) => {
    setSelectedMarkerTypes((mt) => ({ ...mt, [schemaId]: markerTypes }));
  }, []);

  function handleSelectFileFormat(evt, { value }) {
    setFileFormat(value);
  }

  function isQueryReady() {
    function isEmpty(obj) {
      for (let key in obj) {
        if (obj[key].length > 0) {
          return false;
        }
      }
      return true;
    }
    return !(
      isEmpty(selectedUsers) ||
      isEmpty(selectedMedia) ||
      isEmpty(selectedMarkerTypes)
    );
  }

  async function handleClickProject(id) {
    const updated = new Set(activeIndexes);
    if (activeIndexes.has(id)) {
      updated.delete(id);
    } else {
      updated.add(id);
    }
    setActiveIndexes(updated);
    if (!schemas[id]) {
      const clonedSchemas = { ...schemas };
      const s = await fetchSchemaByResearchGroup(id);
      clonedSchemas[id] = s;
      // TODO check schemas across all other projects to prevent loading again
      setSchemas(clonedSchemas);
    }
  }

  function formatLabels() {
    const labels = [];

    const markerCount = new Set(
      Object.values(selectedMarkerTypes).reduce((a, b) => a.concat(b), [])
    ).size;

    const mediaCount = Object.values(selectedMedia).reduce(
      (a, b) => a.concat(b),
      []
    ).length;

    const userCount = new Set(
      Object.values(selectedUsers).reduce((a, b) => a.concat(b), [])
    ).size;

    labels.push(
      <Label key="media">
        Media
        <Label.Detail>{mediaCount}</Label.Detail>
      </Label>
    );

    labels.push(
      <Label key="markers">
        Markers
        <Label.Detail>{markerCount}</Label.Detail>
      </Label>
    );

    labels.push(
      <Label key="users">
        Users
        <Label.Detail>{userCount}</Label.Detail>
      </Label>
    );

    return labels;
  }
  const formattedData = queryResults;// formatQueryData(queryResults);
  const csvData = formatCSVData(formattedData);
  return (
    <div className="mediate-project-data-query-container">
      <Segment
        padded="very"
        inverted
        className="mediate-project-data-query-accordion"
      >
        <Header size="huge" className="mediate-project-data-query-heading">
          My Projects
        </Header>
        <SimpleBar
          style={{ overflowX: "hidden", scrollBehavior: "smooth" }}
          className="mediate-project-data-query-scroll-container"
        >
          <Accordion inverted>
            {queryOptions.map((q) => {
              const isActive = hasActiveIndex(q.id);
              const options = q.films.map((rgf) => ({
                key: rgf.id,
                value: rgf.id,
                text: rgf.title,
              }));
              const rgSchemas = schemas[q.id];
              return (
                <Fragment key={q.id}>
                  <Accordion.Title
                    className="mediate-project-data-query-accordion-title"
                    as={Header}
                    active={isActive}
                    onClick={() => handleClickProject(q.id)}
                  >
                    <Icon name="dropdown" />
                    {q.name}
                  </Accordion.Title>
                  <Accordion.Content active={isActive}>
                    <Dropdown
                      fluid
                      multiple
                      selection
                      search
                      options={options}
                      onChange={(evt, { value }) =>
                        handleSelectMedia(q.id, value)
                      }
                      className="mediate-project-data-query-dropdown"
                    />
                    {rgSchemas
                      ? rgSchemas.map((s) => (
                          <div
                            key={s.id}
                            className="mediate-project-data-query-schema-container"
                          >
                            <MediateSchemaFilter
                              schema={s}
                              collaborators={q.collaborators}
                              onFilter={handleSelectMarkerTypes}
                              onFilterCollaborators={(users) =>
                                handleSelectUsers(s.id, users)
                              }
                            />
                          </div>
                        ))
                      : null}
                  </Accordion.Content>
                </Fragment>
              );
            })}
          </Accordion>
        </SimpleBar>
      </Segment>
      <Segment
        padded="very"
        inverted
        className="mediate-project-data-query-results-container"
      >
        <Header size="huge" className="mediate-project-data-query-heading">
          Data
        </Header>
        <div className="mediate-project-data-query-overview-container">
          <Header
            className="mediate-project-data-query-label-heading"
            size="medium"
          >
            Query
          </Header>
          <div className="mediate-project-data-query-overview">
            {formatLabels()}
          </div>
          <div className="mediate-project-data-query-button-container">
            <Button
              onClick={handleRunQuery}
              disabled={!isQueryReady()}
              className="mediate-project-data-query-button"
              size="tiny"
              icon
              labelPosition="right"
              color="blue"
            >
              <Icon name="right arrow" />
              Run Query
            </Button>
            <div className="mediate-project-data-query-format-container">
              <Radio
                label="json"
                name="fileFormatGroup"
                checked={fileFormat === "json"}
                value="json"
                onChange={handleSelectFileFormat}
              />
              <Radio
                label="csv"
                name="fileFormatGroup"
                checked={fileFormat === "csv"}
                value="csv"
                onChange={handleSelectFileFormat}
              />
            </div>
            {fileFormat === "csv" ? (
              <span className="mediate-project-data-query-format-label">
                Note: exported comments are only available in json format
              </span>
            ) : null}
            <DataDownloader
              queryPending={queryPending}
              data={formattedData}
              csvData={csvData}
              fileFormat={fileFormat}
            />
          </div>
        </div>
      </Segment>
    </div>
  );
}
