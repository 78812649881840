/* @flow */

// React
import React, { Component } from "react";

// Stardust
import { Dropdown, Button } from "semantic-ui-react";

// Components
import MediateMarkerDisplay from "./MediateMarkerDisplay";
import MediateMarkerModal from "./MediateMarkerModal";
import MediateSchemaModal from "./MediateSchemaSidebar";
import MediateShareLink from "./MediateShareLink";
import SchemaSelector from "./SchemaSelector";
// Mousetrap
import Mousetrap from "mousetrap";

// Binary Search Tree
import BinarySearchTree from "../utils/BinarySearch";

// lodash
import lodash from "lodash";

import { Link } from "react-router-dom";

export default class MediateMarkerMenu extends Component {
  state = {
    currentFilterValues: [],
    currentMarkerValues: null,
    selectedMarkerId: null,
    searchQuery: null,
    showFilter: false,
  };

  constructor(props: Object) {
    super(props);
    this.handleAddMarkerChange = this.handleAddMarkerChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.getMarkerTypesFromIds = this.getMarkerTypesFromIds.bind(this);
    this.bindShortcuts = this.bindShortcuts.bind(this);
    this.unBindShortcuts = this.unBindShortcuts.bind(this);
    this.removeCurrentMarker = this.removeCurrentMarker.bind(this);
  }

  componentDidMount() {
    const { shortcuts } = this.props;
    // move this up? Need to rethink this because we need to bind shortcuts too
    Mousetrap.bind("ctrl+a", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.props.handleSubmitMarker(
        this.props.currentMarkerTypes.map((markerType) => markerType.id)
      );
    });
    // TODO make this easier to reason about
    Mousetrap.bind("ctrl+d", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.props.handleDeleteMarker();
    });
    if (shortcuts) {
      this.bindShortcuts();
    }
  }

  componentDidUpdate(prevProps) {
    const { shortcuts } = this.props;
    if (!lodash.isEqual(shortcuts, prevProps.shortcuts)) {
      this.unBindShortcuts();
      this.bindShortcuts();
    }
  }

  componentWillUnmount() {
    Mousetrap.unbind("ctrl+a");
    Mousetrap.unbind("ctrl+d");
    this.unBindShortcuts();
  }

  bindShortcuts() {
    const { shortcuts, handleSubmitMarker } = this.props;
    for (let id in shortcuts) {
      const keystroke = shortcuts[id];
      Mousetrap.bind(keystroke, (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmitMarker([id]);
        return false;
      });
    }
  }

  unBindShortcuts() {
    const { shortcuts } = this.props;
    for (let id in shortcuts) {
      const keystroke = shortcuts[id];
      Mousetrap.unbind(keystroke);
    }
  }

  getMarkerTypesFromIds(ids: Array<number>): Array<Object> {
    return this.props.markerTypes.filter((markerType) => {
      return ids.includes(markerType.id);
    });
  }

  removeCurrentMarker(index: number): void {
    const cloned = this.props.currentMarkerTypes.slice(0);
    cloned.splice(index, 1);
    this.props.setCurrentMarkerTypes(cloned);
  }

  handleAddMarkerChange(e, { value }) {
    this.setState({ currentMarkerValues: value }, () =>
      this.props.setCurrentMarkerTypes(
        this.getMarkerTypesFromIds(this.state.currentMarkerValues)
      )
    );
  }

  handleSearchChange(e, value) {
    this.setState({ searchQuery: value });
  }

  render() {
    const { currentFilterValues, currentMarkerValues, showFilter } = this.state;
    const {
      filterOptions,
      markerTypes,
      currentMarkerTypes,
      setCurrentMarkerTypes,
      setCurrentShortcuts,
      placeholderText,
      handleSubmitMarker,
      markerTypeSetId,
      schema,
      currentSchema,
      handleSelectSchema,
      onToggleInfo,
      onToggleFilter,
      onToggleSchemaView,
    } = this.props;

    let filterClass = "mediate-filter-marker-dropdown";
    filterClass += showFilter !== false ? " show" : " hide";

    return (
      <div className="add-marker-container">
        {/* Don't think we actually need this since we have the modal now
        <Dropdown
          className="mediate-add-marker-dropdown"
          options={filterOptions}
          placeholder="Select Markers"
          search
          selection
          fluid
          multiple
          value={currentMarkerTypes.map(markerType => markerType.id)}
          onSearchChange={this.handleSearchChange}
          onChange={this.handleAddMarkerChange}
        />*/}

        <SchemaSelector
          schema={schema}
          currentSchema={currentSchema}
          onSelect={handleSelectSchema}
        />
        {/*<MediateMarkerModal
          markerTypes={markerTypes}
          currentMarkerTypes={currentMarkerTypes}
          setCurrentMarkerTypes={setCurrentMarkerTypes}
          setCurrentShortcuts={setCurrentShortcuts}
          markerTypeSetId={markerTypeSetId}
        />*/}
        {onToggleSchemaView ? (
          <Button
            title="Select Markers"
            className="schema-modal-button"
            icon="tags"
            onClick={onToggleSchemaView}
          />
        ) : null}
        <Button
          title="Add Marker: ctrl + a"
          className="add-marker-button"
          onClick={() => {
            handleSubmitMarker(
              currentMarkerTypes.map((markerType) => markerType.id)
            );
          }}
          icon="plus"
        />
        {onToggleInfo ? (
          <Button
            title="Toggle Info"
            className="toggle-info-button"
            onClick={onToggleInfo}
            icon="info"
          />
        ) : null}
        {onToggleFilter ? (
          <Button
            title="Filter Markers: ctrl + f"
            className="toggle-filter-button"
            onClick={onToggleFilter}
            icon="filter"
          />
        ) : null}
        <Button
          title="Documentation"
          className="documentation-button"
          as={Link}
          to={{ pathname: "https://dslab.lib.rochester.edu/mediate" }}
          target="_blank"
          icon="help"
        />
        <MediateShareLink schema={currentSchema} />
        <div className="mediate-current-schema-label">
          {currentSchema ? currentSchema.name : "Select a Schema to Start"}
        </div>
      </div>
    );
  }
}
