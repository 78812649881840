/* @flow */
import React, { Component } from 'react';

import { Button, Icon } from 'semantic-ui-react';

import sleep from '../utils/sleep';

import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class MediateShareLink extends Component {
  state = { showLink: false, sharedLink: null }
  SHARED_PATH = 'present'; // will likely change
  constructor(props: Object) {
    super(props);
    (this: any).constructSharedLink = this.constructSharedLink.bind(this);
    (this: any).sharedLinkTimeout = this.sharedLinkTimeout.bind(this);
    (this: any).confirmSharedLink = this.confirmSharedLink.bind(this);
  }

  componentDidMount() {
    this.setState({
      sharedLink: this.constructSharedLink(),
    });
  }

  componentDidUpdate(prevProps) {
    const { schema } = this.props;
    if (prevProps.schema !== schema) {
      this.setState({
        sharedLink: this.constructSharedLink()
      })
    }
  }

  constructSharedLink() {
    const { schema } = this.props;
    const { origin, pathname } = window.location;
    let params = pathname.split('/');
    params = params.slice(2);
    params.unshift(this.SHARED_PATH);
    if (schema) {
      params.push(schema.id);
    }
    return origin + '/' + params.join('/');
  }

  confirmSharedLink() {
    this.setState({
      showLink: true,
    }, this.sharedLinkTimeout);
  }

  sharedLinkTimeout() {
    return sleep(2000).then(() => {
      this.setState({
        showLink: false,
      });
    });
  }



  render() {
    const { showLink, sharedLink } = this.state;
    let baseClass = "mediate-shared-link-url ";
    baseClass += (showLink === true) ? "show" : "hide";
    const icon = showLink ? "check circle" : "share alternate"
    return (
      <div className="mediate-share-link-container">
        <CopyToClipboard text={sharedLink} onCopy={this.confirmSharedLink}>
          <Button
            title="Get Shared Link"
            className="mediate-share-link-button"
            icon={icon}
          />
        </CopyToClipboard>
      </div>
    );
  }
}
