import React, { useState } from "react";
import { Confirm, Label, Button, Icon } from "semantic-ui-react";
import ConfirmDelete from "./ConfirmDelete";

const advancedOptions = {
  onDuplicate: {
    name: "Copy",
    icon: "copy",
  },
  onEmbed: {
    name: "Embed",
    icon: "code",
  },
  onExport: {
    name: "Export",
    icon: "cloud download",
  },
  onShare: {
    name: "Share",
    icon: "share square",
  },
};

const AdvancedOptionButton = (props) => (
  <Label className="mediate-project-option-button" onClick={props.onClick}>
    <Icon name={props.icon} />
    {props.name}
  </Label>
);

function getAdvancedOptions(props, advancedOptionNames = {}) {
  const options = [];
  for (let [key, val] of Object.entries(props)) {
    let advancedOption = advancedOptions[key];
    const name = advancedOptionNames[key];
    if (name) {
      advancedOption = { ...advancedOption, name: name };
    }
    if (advancedOption) {
      if (val) {
        options.push(
          <AdvancedOptionButton
            key={advancedOption.name}
            onClick={val}
            {...advancedOption}
          />
        );
      }
    }
  }
  return options;
}

const ProjectOptions = (props) => {
  const {
    readOnly,
    onEdit,
    onView,
    onDelete,
    id,
    title,
    basic,
    customButtons,
    advancedOptionNames,
  } = props;

  const basicOptions = !readOnly ? (
    <div className="mediate-project-writable-options-container">
      {onEdit ? (
        <Button
          labelPosition="right"
          compact
          onClick={onEdit}
          size="tiny"
          icon
          color="blue"
          className="mediate-project-option-edit mediate-project-option-button"
        >
          <Icon name="pencil" />
          Edit
        </Button>
      ) : null}
      {onView ? (
        <Button
          labelPosition="right"
          compact
          onClick={onView}
          size="tiny"
          icon
          color="teal"
          className="mediate-project-option-edit mediate-project-option-button"
        >
          <Icon name="eye" />
          View
        </Button>
      ) : null}
      {onDelete ? (
        <ConfirmDelete title={title} onDelete={onDelete} deleteKey={id} />
      ) : null}
      {customButtons ? customButtons : null}
    </div>
  ) : null;
  const advancedOptions = getAdvancedOptions(props, advancedOptionNames);
  if (basic) return basicOptions;
  return (
    <div className="mediate-project-options-buttons">
      {advancedOptions}
      {basicOptions}
    </div>
  );
};

export default ProjectOptions;
