// React
import React, { Component, PropTypes } from "react";

import MediateInterface, { playerOptions, markerSetToFormOptions } from './MediateInterface';

// Components
import MediateMarkerList from "./MediateMarkerList";
import VideoPlayer from "./VideoPlayer";


// Timecodes
import {
  frameToSeconds,
  secondsToFrames
} from "../timecodes/TimecodeConversions";

// VJS Plugins
import nleControls from "../vjs-plugins/NLEControls";

// CSS
import videojsCSS from "video.js/dist/video-js.css";

export default class MediatePresentationInterface extends MediateInterface {

  render() {
    // TODO this is pretty messy --- should probably clean it up
    const {
      researchGroupFilmMarkerTypeSet,
      mediaSources
    } = this.props.researchGroupFilmData;
    const { currentFrame, currentFilterValues } = this.state;
    const researchGroupFilmMarkers = this.props.researchGroupFilmData.researchGroupFilmMarkers.sort(
      (marker1, marker2) => {
        if (marker1.filmTimeCode.frameNo > marker2.filmTimeCode.frameNo) {
          return 1;
        }
        if (marker1.filmTimeCode.frameNo < marker2.filmTimeCode.frameNo) {
          return -1;
        }
        return 0;
      }
    );

    const plugins = [
      {
        plugin: nleControls,
        name: "nleControls",
        options: {
          framerate: mediaSources.framerate,
          smpteTimecode: true,
          frameControls: true,
          duration: frameToSeconds(mediaSources.duration)
        }
      }
    ];
    let filterOptions = markerSetToFormOptions(
      researchGroupFilmMarkerTypeSet.markerTypes
    );
    const { webm, framerate, mp4, captions } = mediaSources;
    const source = [
      { src: mp4.src, type: mp4.type, framerate: framerate },
      { src: webm.src, type: webm.type, framerate: framerate }
    ];
    const textTracks = [];
    // eventually support multiple captions but not right now
    if (captions !== null) {
      textTracks.push({ kind: "captions", label: "en", src: captions});
    }
    return (
      <div className="mediate-ui-container">
        <div className="mediate-main-container">
          <MediateMarkerList
            currentFilterValues={currentFilterValues}
            markerTypes={researchGroupFilmMarkerTypeSet.markerTypes}
            markers={researchGroupFilmMarkers}
            framerate={framerate}
            currentFrame={currentFrame}
            handleClickMarker={this.handleClickMarker}
            filteredMarkerList={researchGroupFilmMarkers}
            setPosition={this.setPosition}
            readOnly={true}
          />
          <div className="mediate-video-container">
            <div className="video-player-container">
              <VideoPlayer
                ref="videoPlayer"
                src={source}
                textTracks={textTracks}
                playerOptions={playerOptions}
                plugins={plugins}
                seekBarImages={{ slitscan: mediaSources.slitscan, waveform: mediaSources.waveform }}
                onTimeUpdate={this.onTimeUpdate}
                mediaType={mediaSources.originalMediaType}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
