// React
import React, {Component} from 'react';
import {connect} from 'react-redux'
import Header from '../componets/Header';


class Mediate extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        console.log('here?');
        if(this.props.location.pathname === '/login'){
            return(this.props.children);
        } else {
            return(
                <div className="mediate-root-container">
                    <Header/>
                    {this.props.children}
                </div>
            );
        }

    }
}

export default connect()(Mediate);
