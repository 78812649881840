import React, { useState } from "react";
import LiveSearch from "./LiveSearch";
import { SEARCH_KEYS } from "../../constants/Application";
import { DraggableAdminMarkerLabel } from "./AdminMarkerLabel";
import DynamicGrid from "../DynamicGrid";
import SimpleBar from "simplebar-react";

export default function SearchMarkers(props) {
  const { onSelectResult, visible } = props;
  return (
    <LiveSearch
      searchLabel="Search Markers By Name"
      className="mediate-search-marker-resuls-display"
      searchLabelAsPlaceholder={true}
      searchKey={SEARCH_KEYS.MARKERS}
      resultsDisplay={MarkerResultsDisplay}
      onSelectResult={onSelectResult}
    />
  );
}

export function MarkerResultsDisplay(props) {
  const { results, onSelectResult, className } = props;
  return (
    <DynamicGrid
      divided={true}
      columns={3}
      className={className}
      data={results}
      renderCell={(props) => (
        <DraggableAdminMarkerLabel readOnly={true} {...props} />
      )}
      onSelectCell={onSelectResult}
    />
  );
}
