import React, { Component } from "react";

import { Router, Route } from "react-router-dom";

import { Switch, Redirect } from "react-router";

// Redux
import { connect } from "react-redux";

// history
import history from "../utils/history";

// Containers
import LoginPage from "./LoginPage";
import SignUpContainer from "./SignUpContainer";
import DashboardPage from "./DashboardPage";
import MediateContainer from "./MediateContainer";
import MediatePresentationContainer from "./MediatePresentationContainer";
import Mediate from "./Mediate";
import GetDataContainer from "./GetDataContainer";
import Logout from "./LogoutContainer";
import MediateAdminContainer from "./admin/AdminContainer";
import ProjectContainer from "./admin/ProjectContainer";
import SchemaContainer from "./SchemaContainer";
import VerifyAccountContainer from "./VerifyAccountContainer";

// Components
import PageLoader from "../componets/Loading";
import Header from "../componets/Header";

// Actions
import {
  setAuthentication,
  userLoggedOut,
  resetLoginErrorMessage,
  authenticateUser,
} from "../actions/AnnotateActions";
import UserAccountContainer from "./admin/UserAccountContainer";

const AuthenticatingLoader = () => <PageLoader text="Authenticating ..." />;
const basename = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "";

class MediateRouter extends Component {
  _element = React.createElement;

  state = {
    authenticationAttempts: 0,
  };

  constructor(props: Object) {
    super(props);
    (this: any).authenticateRoute = this.authenticateRoute.bind(this);
  }

  authenticateRoute(props: Object, component: Component) {
    const { user } = this.props;
    const { authenticateAttempted } = user;
    if (user.loggedIn !== false) {
      return this._element(component, props, null);
    } else {
      if (authenticateAttempted === false) {
        this.props.authenticateUser();
        return <AuthenticatingLoader />;
      } else {
        return <Redirect to="/login" />;
      }
    }
  }

  render() {
    const { user } = this.props;
    return (
      <Router basename={basename} history={history}>
        <div className="mediate-root-container">
          <Header loggedIn={user.loggedIn} />
          <Route
            exact
            path="/"
            render={(props) => this.authenticateRoute(props, DashboardPage)}
          />
          <Route
            path="/dashboard"
            render={(props) => this.authenticateRoute(props, DashboardPage)}
          />
          <Route path="/signup" component={SignUpContainer} />
          <Route path="/login" component={LoginPage} />
          <Route path="/logout" component={Logout} />
          <Route
            path="/admin/account"
            render={(props) =>
              this.authenticateRoute(props, UserAccountContainer)
            }
          />
          <Route
            path="/mediate/:groupFilmId/"
            render={(props) => this.authenticateRoute(props, MediateContainer)}
          />
          <Route
            path="/present/:groupFilmId/:schemaId"
            component={MediateContainer}
          />
          <Route
            path="/data"
            render={(props) => this.authenticateRoute(props, GetDataContainer)}
          />
          <Route
            exact
            path="/admin"
            render={(props) =>
              this.authenticateRoute(props, MediateAdminContainer)
            }
          />
          <Route
            path="/admin/projects/:researchGroupId/"
            render={(props) => this.authenticateRoute(props, ProjectContainer)}
          />
          <Route
            path="/admin/schema/:schemaId/"
            render={(props) => this.authenticateRoute(props, SchemaContainer)}
          />
          <Route path="/verify/:key" component={VerifyAccountContainer} />
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    params: ownProps,
  };
}

export default connect(mapStateToProps, { authenticateUser })(MediateRouter);
