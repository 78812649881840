import lodash from "lodash";

class Node {
  constructor(value) {
    this.value = value;
    this.left = null;
    this.right = null;
  }
}

export default class BinarySearchTree {
  MAX = Number.MAX_VALUE;
  constructor(sortKey = null) {
    this.root = null;
    this.sortKey = sortKey;
  }

  insert(value) {
    if (!this.root) {
      this.root = new Node(value);
      return;
    }
    if (this.sortKey && value.constructor === Object) {
      return this._insertObject(this.root, value);
    }
    return this._insert(this.root, value);
  }

  _insertObject(node, value) {
    const nodeVal = lodash.get(node.value, this.sortKey);
    const newVal = lodash.get(value, this.sortKey);
    if (nodeVal > newVal) {
      if (!node.left) {
        node.left = new Node(value);
      } else {
        this._insertObject(node.left, value);
      }
    } else {
      if (!node.right) {
        node.right = new Node(value);
      } else {
        this._insertObject(node.right, value);
      }
    }
  }

  _insert(node, value) {
    if (node.value > value && !node.left) {
      node.left = new Node(value);
    } else if (node.value > value) {
      this._insert(node.left, value);
    } else if (node.value < value && !node.right) {
      node.right = new Node(value);
    } else if (node.value < value) {
      this._insert(node.right, value);
    }
  }

  nearest(value) {
    if (this.sortKey && value.constructor === Object) {
      return this._nearestObject(this.root, value);
    }
    return this._nearest(this.root, value);
  }

  _nearest(node, value) {
    let min = this.MAX;
    let closest;
    const findNearest = (node, value) => {
      if (!node) return;
      let absNum = Math.abs(node.value - value);
      if (absNum < min) {
        min = absNum;
        closest = node.value;
      }
      if (value < node.value) {
        findNearest(node.left, value);
      } else {
        findNearest(node.right, value);
      }
    };
    findNearest(node, value);
    return closest;
  }

  _nearestObject(node, value) {
    const nodeVal = lodash.get(node.value, this.sortKey);
    const newVal = lodash.get(value, this.sortKey);
    let min = this.MAX;
    let closest;
    const findNearest = (node, value) => {
      if (!node) return;
      let absNum = Math.abs(nodeVal - newVal);
      if (absNum < min) {
        min = absNum;
        closest = node.value;
      }
      if (nodeVal < newVal) {
        findNearest(node.left, value);
      } else {
        findNearest(node.right, value);
      }
    };
    findNearest(node, value);
    return closest;
  }

  // https://articles.leetcode.com/convert-sorted-array-into-balanced/
  fromSortedArray(arr) {
    let start = 0;
    let end = arr.length;
    let middle = Math.floor((start + end) / 2);
    this.root = new Node(arr[middle]);
    this.root.left = this._fromSortedArray(arr, start, middle - 1);
    this.root.right = this._fromSortedArray(arr, middle + 1, end);
    return this;
  }

  _fromSortedArray(arr, start, end) {
    if (start > end) return null;
    let middle = Math.floor((start + end) / 2);
    let node = new Node(arr[middle]);
    node.left = this._fromSortedArray(arr, start, middle - 1);
    node.right = this._fromSortedArray(arr, middle + 1, end);
    return node;
  }
  // in-order traversal
  toArray() {
    const output = [];
    this._toArray(this.root, output, 0);
    return output;
  }

  _toArray(root, arr, idx) {
    let _idx = idx;
    if (root.left !== null) {
      _idx = this._toArray(root.left, arr, _idx);
    }
    if (root.value) {
      arr[_idx++] = root.value;
    }
    if (root.right !== null) {
      _idx = this._toArray(root.right, arr, _idx);
    }
    return _idx;
  }

  clear() {
    this.root = null;
  }
}
