/* @flow */

// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// Components
import DroppableMediateMarkerShortcutSlot from '../componets/MediateMarkerShortcutSlot';

// Actions
import {
  setCurrentShortcutsAction,
} from "../actions/AnnotateActions";

class MediateShortcutSlotContainer extends Component {

  constructor(props: Object) {
    super(props);
    (this: any).setCurrentMarker = this.setCurrentMarker.bind(this);
    (this: any).removeCurrentMarker = this.removeCurrentMarker.bind(this);
    (this: any).setCurrentShortcuts = this.setCurrentShortcuts.bind(this);
  }
  // this needs to be managed via redux
  setCurrentMarker(index: number, marker: Object) {
    let cloned = this.props.shortcuts.slice(0);
    cloned[index].currentMarker = marker;
    this.props.setCurrentShortcuts(cloned, this.props.markerTypeSetId);
  }

  removeCurrentMarker(index: number) {
    let cloned = this.props.shortcuts.slice(0);
    cloned[index].currentMarker = null;
    this.props.setCurrentShortcuts(cloned, this.props.markerTypeSetId);
  }

  setCurrentShortcuts(markerTypeSetId: number): (shortcuts: Array<Object>) => void {
    return (shortcuts) => {
      setCurrentShortcutsAction(shortcuts, markerTypeSetId)
    }
  }

  render() {
    const { setCurrentShortcuts, shortcuts } = this.props;
    return(
      <div className="mediate-marker-modal-shortcuts-container mediate-ui-widget">
        <h4 className="mediate-ui-widget-header">My Shortcuts</h4>
        {shortcuts.map((shortcut, index) =>
          <DroppableMediateMarkerShortcutSlot
            marker={shortcut.currentMarker}
            index={index}
            key={index}
            setCurrentMarker={this.setCurrentMarker}
            removeCurrentMarker={this.removeCurrentMarker}
            label={shortcut.label}
          />
          )}
        </div>
      );
  }
}

function mapStateToProps(state, ownProps) {
  const currentSchema = state.mediate.currentSchema;
  return {
    shortcuts: state.mediate.shortcuts,
    markerTypeSetId: currentSchema ? currentSchema.id : null,
  };
}

export default connect(mapStateToProps, {setCurrentShortcutsAction})(MediateShortcutSlotContainer);
