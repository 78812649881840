import React, { Component } from "react";
import { Dropdown, Button } from "semantic-ui-react";

export default class SchemaSelector extends Component {
  state = { currentSchemaId: 0, currentSchema: null };
  constructor(props) {
    super(props);
    this.selectSchema = this.selectSchema.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidMount() {
    const { currentSchema } = this.props;
    if (currentSchema) {
      this.setState({
        currentSchemaId: currentSchema.id
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentSchemaId } = this.state;
    const { currentSchema } = this.props;
    if (!currentSchemaId && currentSchema) {
      this.setState({
        currentSchemaId: currentSchema.id
      });
    }
  }

  selectSchema(id) {
    const { schema } = this.props;
    this.setState(
      {
        currentSchemaId: id
      },
      this.handleCallback
    );
  }

  handleCallback() {
    const { onSelect, schema } = this.props;
    const { currentSchemaId } = this.state;
    const currentSchema = schema.find(s => s.id === currentSchemaId);
    if (onSelect) {
      onSelect(currentSchema);
    }
  }

  render() {
    const { schema } = this.props;
    const { currentSchemaId } = this.state;
    return (
      <Dropdown
        title="Select Schema"
        alt="Select Schema"
        button
        className="mediate-schema-selector-popup icon"
        icon="database"
      >
        <Dropdown.Menu>
          <Dropdown.Header icon="tags" content="Select Schema" />
          <Dropdown.Divider />
          {schema.map(s => (
            <Dropdown.Item
              key={s.id}
              onClick={() => this.selectSchema(s.id)}
              active={s.id === currentSchemaId}
            >
              {s.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
