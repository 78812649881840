import { takeEvery, call, take, put, select } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import { WS_ACTIONS, MEDIATE_ACTIONS } from "../constants/Actions";
import { WS_BACKEND_URL } from "../constants/Backend";
import { sendCeleryMessage, sendMessage } from "../api/FetchWrapper";
let currentChannel = null;
// TODO we could have some sort of current channels state

function parseMessageContent(message) {
  if (!message.content) {
    throw new Error("Message content is missing!");
  }
  try {
    message.content = JSON.parse(message.content);
    return message;
  } catch (error) {
    return message;
  }
}

function createWebSocketChannel(groupName) {
  const url = `${WS_BACKEND_URL}/${groupName}/`;
  const ws = new WebSocket(url);
  return eventChannel((emit) => {
    const messageHandler = (event) => {
      const { data } = event;
      const { message } = JSON.parse(data);
      const parsedMessage = parseMessageContent(message);
      emit(parsedMessage);
    };

    const errorHandler = (errorEvent) => {
      emit(new Error(errorEvent.reason));
    };

    const unsubscribe = () => {
      ws.close();
    };

    ws.onmessage = messageHandler;
    ws.onerror = errorHandler;
    return unsubscribe;
  });
}

function* handleMarkerSaved(marker) {
  // check to make sure real-time updates don't happen for marker owner
  const userId = yield select((state) => state.user.session.userId);
  if (marker.owner.id !== userId) {
    yield put({
      type: MEDIATE_ACTIONS.MARKER_SAVED,
      researchGroupFilmMarker: { ...marker, note: null },
    });
  }
}

export function* messageListener(groupName) {
  const socketChannel = yield call(createWebSocketChannel, groupName);
  if (currentChannel) {
    currentChannel.unsubscribe();
    currentChannel = socketChannel;
  }

  while (true) {
    try {
      const payload = yield take(socketChannel);
      const { redux_action, ...data } = payload;
      if (redux_action === "MARKER_SAVED") {
        yield handleMarkerSaved(data.content);
      } else {
        const action = {
          data: data,
          type: redux_action ? redux_action : WS_ACTIONS.UNCATEGORIZED,
        };
        yield put(action);
      }
    } catch (error) {
      console.error(error);
      // EMIT MESSAGE THAT CHANNEL WAS CLOSED
      socketChannel.close();
    }
  }
}

export function* subscribeToChannelSaga(action) {
  const { researchGroup } = action;
  try {
    yield messageListener(researchGroup);
  } catch (error) {
    // TODO put some message saying connection failed
    console.error(error);
  }
}

export function* unsubscribeFromChannelSaga() {
  if (currentChannel) {
    currentChannel.unsubscribe();
    // perhaps put some message about unsubscribing
  }
}

export function* watchForSubscribeToChannel() {
  yield takeEvery(WS_ACTIONS.SUBSCRIBE, subscribeToChannelSaga);
}

export function* watchForUnsubscribeFromChannel() {
  yield takeEvery(WS_ACTIONS.UNSUBSCRIBE, unsubscribeFromChannelSaga);
}

export default function* wsSagas() {
  yield [watchForSubscribeToChannel(), watchForUnsubscribeFromChannel()];
}
