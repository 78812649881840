import { ADMIN_ACTIONS, AUTH_ACTIONS, WS_ACTIONS } from "../constants/Actions";
import { getLanguageFromCaptionsUrl } from "../utils/Captions";
import { cloneDeep, get, set } from "lodash";
import {
  deleteElementImmutable,
  findNestedIndex,
  updateElementImmutable,
  updateArrayImmutable,
  updateArrayOrAdd,
} from "../utils/ArrayUtils";

// set flag in global state for requiring update
const initialAdminState = {
  researchGroups: null,
  media: [], // Film
  uploads: new Map(),
  projectMedia: [], // ResearchGroupFilm
  schema: [], // ResearchGroupFilmMarkerSet
  markers: [],
  message: null,
  createdUser: null, // for signup only
};

function removeMediaFromResearchGroups(groups, mediaId) {
  return groups.map((group) => {
    const g = { ...group };
    g.researchGroupFilms = deleteElementImmutable(
      "film.id",
      mediaId,
      g.researchGroupFilms
    );
    return g;
  });
}

function addMediaToResearchGroups(groups, media) {
  return groups.map((group) => {
    const g = { ...group };
    g.researchGroupFilms = updateElementImmutable(
      "film.id",
      media.id,
      g.researchGroupFilms,
      "film",
      media
    );
    return g;
  });
}

function addSchemaToResearchGroup(groups, groupId, schema) {
  const rg = cloneDeep(groups);
  const idx = findNestedIndex("id", groupId, rg);
  if (idx > -1) {
    rg[idx].researchGroupMarkerSets.push(schema);
  }
  return rg;
}

function updateResearchGroupSchema(groups, groupId, schemaId, newSchema) {
  const rg = cloneDeep(groups);
  const idx = findNestedIndex("id", groupId, rg);
  if (idx > -1) {
    rg[idx].researchGroupMarkerSets = updateArrayImmutable(
      "id",
      schemaId,
      rg[idx].researchGroupMarkerSets,
      newSchema
    );
  }
  return rg;
}

function removeSchemaFromResearchGroup(groups, groupId, schemaId) {
  // only need shallow cloning because rg[idx].researchGroupMarkerSets will be cloned
  const rg = [...groups];
  const idx = findNestedIndex("id", groupId, rg);
  if (idx > -1) {
    rg[idx].researchGroupMarkerSets = deleteElementImmutable(
      "id",
      schemaId,
      rg[idx].researchGroupMarkerSets
    );
  }
  return rg;
}

function addProjectMediaToResearchGroups(groups, projectMedia) {
  return groups.map((group) => {
    if (group.id === projectMedia.researchGroup) {
      group.researchGroupFilms.push(projectMedia);
    }
    return group;
  });
}

function removeCaptionsFromMedia(media, captionsId) {
  return media.map((m) => {
    const mediaUpload = m.mediaUpload[0];
    const derivative = mediaUpload.derivative[0];
    const { captionsFiles } = mediaUpload;
    const captionsIdx = findNestedIndex("id", captionsId, captionsFiles);
    const captions = captionsFiles[captionsIdx];
    mediaUpload.captionsFiles = deleteElementImmutable(
      "id",
      captionsId,
      captionsFiles
    );
    const { captionsUrls } = derivative;
    derivative.captionsUrls = captionsUrls.filter(
      (cap) => getLanguageFromCaptionsUrl(cap) !== captions.language
    );
    mediaUpload.derivative = [derivative];
    m.mediaUpload = [mediaUpload];
    return m;
  });
}

function findResearchGroupByResearchGroupUser(
  researchGroups,
  rgu,
  returnIdx = false
) {
  const searchFunc = (rg) => rg.id === rgu.researchGroup;
  if (returnIdx) {
    return researchGroups.findIndex(searchFunc);
  }
  const rg = researchGroups.find(searchFunc);
  return rg ? rg : null;
}

function addResearchGroupUserImmutable(researchGroups, rgu) {
  const rg = cloneDeep(researchGroups);
  const researchGroupIdx = findResearchGroupByResearchGroupUser(
    researchGroups,
    rgu,
    true
  );
  if (researchGroupIdx > -1) {
    rg[researchGroupIdx].researchGroupUsers.push(rgu);
  }
  return rg;
}

function updateResearchGroupUserImmutable(groups, rgu) {
  return groups.map((group) => {
    const g = { ...group };
    g.researchGroupUsers = updateArrayImmutable(
      "id",
      rgu.id,
      g.researchGroupUsers,
      rgu
    );
    return g;
  });
}

function deleteResearchGroupUserImmutable(groups, id) {
  return groups.map((group) => {
    const g = { ...group };
    g.researchGroupUsers = deleteElementImmutable(
      "id",
      id,
      g.researchGroupUsers
    );
    return g;
  });
}

function markersFromSchema(schema) {
  const allGroups = schema.reduce((a, b) => a.concat(b.groups), []);
  const allMarkers = allGroups.reduce((a, b) => a.concat(b.markers), []);
  return allMarkers;
}

export function generateUploadKey(upload) {
  return `${upload.title}__${upload.file.name}`;
}

function addUpload(uploads, upload) {
  const cloned = new Map(uploads);
  if (upload) {
    cloned.set(generateUploadKey(upload), upload);
  }
  return cloned;
}

function removeUpload(uploads, upload) {
  const cloned = new Map(uploads);
  if (upload) {
    cloned.delete(generateUploadKey(upload));
  }
  return cloned;
}

export default function adminReducer(state = initialAdminState, action) {
  let updatedResearchGroups;
  let updatedMedia;
  switch (action.type) {
    case ADMIN_ACTIONS.RESEARCH_GROUPS_LOADED:
      const flattened = action.researchGroups.reduce(
        (a, b) => a.concat(b.researchGroupFilms),
        []
      );
      return {
        ...state,
        researchGroups: action.researchGroups,
        projectMedia: flattened,
      };

    case ADMIN_ACTIONS.RESEARCH_GROUP_LOADED:
      return {
        ...state,
        researchGroups: updateArrayOrAdd(
          "id",
          action.researchGroup.id,
          state.researchGroups || [],
          action.researchGroup
        ),
        projectMedia: action.researchGroup.researchGroupFilms,
      };
    // TODO update this to show the overview image etc
    case ADMIN_ACTIONS.MEDIA_ADDED:
      return {
        ...state,
        media: [...state.media, ...[action.media]],
      };

    case ADMIN_ACTIONS.MEDIA_UPLOAD_STARTED:
      return {
        ...state,
        uploads: addUpload(state.uploads, action.upload),
      };

    case ADMIN_ACTIONS.MEDIA_UPLOAD_PROGRESS:
      return {
        ...state,
        uploads: addUpload(state.uploads, action.upload),
      };

    case ADMIN_ACTIONS.MEDIA_UPLOAD_DONE:
      return {
        ...state,
        uploads: removeUpload(state.uploads, action.upload),
      };

    case ADMIN_ACTIONS.CANCEL_MEDIA_UPLOAD:
      return {
        ...state,
        uploads: addUpload(state.uploads, {
          ...action.upload,
          cancelRequested: true,
        }),
      };

    case ADMIN_ACTIONS.MEDIA_UPLOAD_CANCELLED:
      return {
        ...state,
        uploads: removeUpload(state.uploads, action.upload),
      };

    case ADMIN_ACTIONS.MEDIA_UPDATED:
      return {
        ...state,
        media: updateArrayImmutable(
          "id",
          action.media.id,
          state.media,
          action.media
        ),
      };

    case ADMIN_ACTIONS.PROJECT_MEDIA_ADDED:
      return {
        ...state,
        projectMedia: [...state.projectMedia, ...[action.media]],
        researchGroups: addProjectMediaToResearchGroups(
          state.researchGroups,
          action.media
        ),
      };

    case ADMIN_ACTIONS.MEDIA_LOADED:
      return {
        ...state,
        media: action.media,
      };

    case ADMIN_ACTIONS.SCHEMA_LOADED:
      return {
        ...state,
        schema: action.schema,
        markers: markersFromSchema(action.schema),
      };

    case ADMIN_ACTIONS.SCHEMA_SAVED:
    case ADMIN_ACTIONS.SCHEMA_CLONED:
      return {
        ...state,
        schema: updateArrayOrAdd(
          "id",
          action.schema.id,
          state.schema,
          action.schema
        ),
      };

    case ADMIN_ACTIONS.SCHEMA_DELETED:
      return {
        ...state,
        schema: deleteElementImmutable("id", action.id, state.schema),
      };

    case ADMIN_ACTIONS.SCHEMA_ADDED_TO_PROJECT:
      return {
        ...state,
        researchGroups: addSchemaToResearchGroup(
          state.researchGroups,
          action.projectId,
          action.schema
        ),
      };

    case ADMIN_ACTIONS.PROJECT_SCHEMA_CLONED:
      return {
        ...state,
        researchGroups: updateResearchGroupSchema(
          state.researchGroups,
          action.projectId,
          action.oldSchemaId,
          action.newSchema
        ),
      };

    case ADMIN_ACTIONS.SCHEMA_REMOVED_FROM_PROJECT:
      return {
        ...state,
        researchGroups: removeSchemaFromResearchGroup(
          state.researchGroups,
          action.projectId,
          action.schemaId
        ),
      };

    case ADMIN_ACTIONS.SCHEMA_REMOVED_FROM_PROJECT:
      return {
        ...state,
        researchGroups: removeSchemaFromResearchGroup(
          state.researchGroups,
          action.projectId,
          action.schemaId
        ),
      };

    case ADMIN_ACTIONS.PROJECT_MEDIA_DELETED:
      return {
        ...state,
        projectMedia: deleteElementImmutable(
          "id",
          action.id,
          state.projectMedia
        ),
      };

    case ADMIN_ACTIONS.RESEARCH_GROUP_DELETED:
      return {
        ...state,
        researchGroups: deleteElementImmutable(
          "id",
          action.id,
          state.researchGroups
        ),
      };

    case ADMIN_ACTIONS.MEDIA_DELETED:
      // Is there a better way of doing this? The problem is that we don't know the researchGroupFilmId of the deleted media
      updatedMedia = deleteElementImmutable("id", action.id, state.media);
      updatedResearchGroups = removeMediaFromResearchGroups(
        state.researchGroups,
        action.id
      );
      return {
        ...state,
        media: updatedMedia,
        projectMedia: updatedResearchGroups.reduce(
          (a, b) => a.concat(b.researchGroupFilms),
          []
        ),
        researchGroups: updatedResearchGroups,
      };

    case ADMIN_ACTIONS.CAPTIONS_DELETED:
      updatedMedia = removeCaptionsFromMedia(state.media, action.id);
      updatedResearchGroups = addMediaToResearchGroups(
        state.researchGroups,
        updatedMedia
      );
      return {
        ...state,
        media: updatedMedia,
      };

    case ADMIN_ACTIONS.ADMIN_INFO:
      return {
        ...state,
        message: action.data,
      };

    case ADMIN_ACTIONS.CLEAR_ADMIN_INFO:
      return {
        ...state,
        message: null,
      };

    case ADMIN_ACTIONS.RESEARCH_GROUP_USER_ADDED:
      return {
        ...state,
        researchGroups: addResearchGroupUserImmutable(
          state.researchGroups,
          action.researchGroupUser
        ),
      };

    case ADMIN_ACTIONS.RESEARCH_GROUP_USER_UPDATED:
      return {
        ...state,
        researchGroups: updateResearchGroupUserImmutable(
          state.researchGroups,
          action.rgu
        ),
      };

    case ADMIN_ACTIONS.RESEARCH_GROUP_USER_DELETED:
      return {
        ...state,
        researchGroups: deleteResearchGroupUserImmutable(
          state.researchGroups,
          action.id
        ),
      };

    case WS_ACTIONS.ADMIN_INFO:
      return {
        ...state,
        message: action.data,
      };

    case WS_ACTIONS.ADMIN_DATA:
      // TODO keep it this way for now but need to figure out the type of media - maybe duck typing?
      const media = action.data.content;
      updatedResearchGroups = addMediaToResearchGroups(
        state.researchGroups,
        media
      );
      return {
        ...state,
        researchGroups: updatedResearchGroups,
        projectMedia: updatedResearchGroups.reduce(
          (a, b) => a.concat(b.researchGroupFilms),
          []
        ),
        media: updateArrayImmutable("id", media.id, state.media, media),
      };

    default:
      return state;
  }
}
