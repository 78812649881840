import {createStore, applyMiddleware} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {reducer} from '../reducers/AnnotateReducer'
import rootSaga from './Sagas'


// remove export
export const sagaMiddleware = createSagaMiddleware();

//export const defaultState = {user:{name:"nate"}};
export const defaultState = {};

// main annotate store
export const AnnotateStore = createStore(
    reducer,
    defaultState,
    applyMiddleware(sagaMiddleware)
);

//start running the sagas
sagaMiddleware.run(rootSaga);
