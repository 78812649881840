/* @flow */
import React, { Component } from "react";
import { Icon, Label } from "semantic-ui-react";

export default class ToggleIcon extends Component {
  state = { toggled: false };

  constructor(props: Object) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { initialVal } = this.props;
    if (initialVal) {
      this.setState({
        toggled: initialVal,
      });
    }
  }

  handleClick(event: SyntheticEvent) {
    this.setState(
      {
        toggled: !this.state.toggled,
      },
      () => this.props.onClick(event, this.state.toggled)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { val } = this.props;
    if (val !== undefined) {
      if (prevProps.val !== val) {
        this.setState({
          toggled: val,
        });
      }
    }
  }

  render() {
    const {
      onIcon,
      offIcon,
      onColor,
      offColor,
      className,
      onClick,
      onLabel,
      offLabel,
      size,
      compact,
      disabled,
    } = this.props;

    let _className = className;
    const { toggled } = this.state;
    let _icon = offIcon;
    let _label = offLabel;
    let _color = offColor;
    if (toggled === true) {
      _icon = onIcon;
      _label = onLabel;
      _color = onColor;
    }
    const toggleSize = size || "large";
    if (compact) {
      return (
        <Icon
          key={0}
          onClick={!disabled ? this.handleClick : undefined}
          color={_color}
          size={toggleSize}
          className={(_className += " toggle-icon")}
          id="show-password"
          name={_icon}
        />
      );
    }

    return (
      <Label
        size={toggleSize}
        onClick={!disabled ? this.handleClick : undefined}
        color={_color}
        key={0}
        className="toggle-icon-label"
      >
        <Icon
          key={0}
          className={(_className += " toggle-icon")}
          id="show-password"
          name={_icon}
        />
        {_label ? _label : null}
      </Label>
    );
  }
}
