import React, { useMemo } from "react";
import { Image, Popup, Icon, Button } from "semantic-ui-react";
import logoIcon from "../../images/logo-icon.png";
import { useRandomElement } from "../../utils/Hooks";
import { Link } from "react-router-dom";
import ProjectOptions from "./ProjectOptions";
import SimpleBar from "simplebar-react";
import { CircularAvatar } from "../UserCard";
import { useSelector } from "react-redux";
import BasicAdminAssetDisplay from "./BasicAdminAssetDisplay";

// TODO implement thumbnail feature, otherwise use the Label
export const CollaboratorIcon = (props) => {
  const { firstName, lastName, username, email, profile } = props.user;
  const imageSrc = profile && profile.imageUrl ? profile.imageUrl : logoIcon;
  return (
    <div className="mediate-collaborator-icon">
      <Popup
        basic
        content={profile ? profile.organization : email}
        header={`${username} ${props.isOwner ? "(Owner)" : ""}`}
        trigger={<CircularAvatar image={imageSrc} title={username} />}
      />
    </div>
  );
};
export const CollaboratorDisplay = (props) => {
  const { user } = props;
  const collabsLength = props.collaborators.length - 1;
  return (
    <div className="mediate-project-collaborators-container">
      <h4 className="mediate-collaborator-display-text">
        {props.collaborators.length - 1}{" "}
        {collabsLength > 1 || collabsLength === 0
          ? "Collaborators"
          : "Collaborator"}
      </h4>
      <div className="mediate-project-collaborators">
        {props.collaborators
          .filter(
            (collaborator) =>
              !user || collaborator.user.username !== user.username
          )
          .map((collaborator, index) => (
            <CollaboratorIcon
              key={index}
              user={collaborator.user}
              isOwner={collaborator.user.id === props.owner}
            />
          ))}
      </div>
    </div>
  );
};

export const ProjectDisplay = (props) => {
  const { owner, user } = props;
  const isReadOnly = user.id !== owner;
  const rgf = props.researchGroupFilms[0];
  const overviewImage = rgf && rgf.film.overviewImage;
  return (
    <div className="mediate-project-display">
      {isReadOnly ? (
        <h2 className="mediate-project-title">{props.name} (Read Only)</h2>
      ) : (
        <Link
          className="mediate-project-link"
          to={`/admin/projects/${props.id}`}
        >
          <h2 className="mediate-project-title">{props.name}</h2>
        </Link>
      )}
      {props.description ? <h3 className="mediate-project-description">{props.description}</h3> : null}
      {props.researchGroupFilms.length > 0 && overviewImage ? (
        <div className="mediate-project-films">
          <div className="mediate-project-film-display">
            <Image src={overviewImage} fluid />
          </div>
        </div>
      ) : null}
      <div className="mediate-project-options">
        <CollaboratorDisplay
          collaborators={props.researchGroupUsers}
          user={props.user}
          owner={owner}
        />
        <ProjectOptions
          readOnly={isReadOnly}
          onDelete={props.onDelete}
          id={props.id}
          title={props.name}
        />
      </div>
    </div>
  );
};

const ProjectOverview = (props) => {
  const { researchGroups, user, onAddProject, onDeleteProject } = props;
  const renderCell = (rg) => (
    <ProjectDisplay
      key={rg.id}
      user={user}
      onDelete={onDeleteProject}
      {...rg}
    />
  );
  const d = useMemo(() => researchGroups.filter((rg) => rg.owner === user.id), [
    researchGroups,
    user,
  ]);
  return (
    <BasicAdminAssetDisplay
      columns={1}
      title="My Projects"
      data={d}
      renderCell={renderCell}
      filterKey="name"
      onAdd={onAddProject}
    />
  );
};

export default ProjectOverview;
