import { NOTES_ACTIONS } from "../constants/Actions";

const initalState = {
  noteError: null,
  commentError: null,
};

export default function noteReducer(state = initalState, action) {
  switch (action.type) {
    case NOTES_ACTIONS.NOTE_ERROR:
      return {
        ...state,
        noteError: action.error,
      };

    case NOTES_ACTIONS.COMMENT_ERROR:
      return {
        ...state,
        commentError: action.error,
      };

    case NOTES_ACTIONS.CLEAR_NOTE_ERROR:
      return {
        ...state,
        noteError: null,
      };

    case NOTES_ACTIONS.CLEAR_COMMENT_ERROR:
      return {
        ...state,
        commentError: null,
      };

    default:
      return state;
  }
}
