// Fetch Wrapper
import { doPost, doGet, doDelete, doUpdate } from "./FetchWrapper";

// Constants
import backendUrl from "../constants/Backend";

import { replaceSchemaKeys, propsToParams } from "./AdminFetch";

function patchMediaFileURLS(researchGroupData: Object): Object {
  const { slitscan, waveform, mp4, webm } = researchGroupData.mediaSources;
  let { captions } = researchGroupData.mediaSources;
  if (slitscan !== undefined) {
    slitscan.url = backendUrl + "/" + slitscan.url;
  }
  if (waveform !== undefined) {
    waveform.url = backendUrl + "/" + waveform.url;
  }
  if (captions !== null) {
    captions = backendUrl + "/" + captions;
  }
  researchGroupData.mediaSources.captions = captions;
  mp4.src = backendUrl + "/" + mp4.src;
  webm.src = backendUrl + "/" + webm.src;
  researchGroupData.researchGroupFilmMarkers = patchThumbnailURLs(
    researchGroupData.researchGroupFilmMarkers
  );
  return researchGroupData;
}

function patchThumbnailURLs(researchGroupFilmMarkers) {
  return researchGroupFilmMarkers.map((marker) => {
    marker.filmTimeCode.thumbnailUrl =
      backendUrl + "/" + marker.filmTimeCode.thumbnailUrl;
    return marker;
  });
}

export function fetchMediateData(researchGroupFilmId) {
  return doGet(`/annotate/api/research-group-films/${researchGroupFilmId}`);
}

export function fetchSchemaByResearchGroup(researchGroupId) {
  return doGet(
    `/annotate/api/research-group-marker-sets?research_group=${researchGroupId}`
  ).then((schema) => schema.map((s) => replaceSchemaKeys(s)));
}

export function fetchMediateMarkers(researchGroupFilmQuerySet) {
  const { researchGroupFilmId, schemaId } = researchGroupFilmQuerySet;
  const body = `groupfilmid=${researchGroupFilmId}&schemaid=${schemaId}`;
  return doPost("/annotate/get-markers/", body, (json) => {
    return new Promise((resolve, reject) => {
      json.researchGroupFilmMarkers = patchThumbnailURLs(
        json.researchGroupFilmMarkers
      );
      resolve(json);
    });
  });
}

export function fetchMediatePresentationData(researchGroupFilmQuerySet) {
  const body =
    "groupfilmid=" +
    researchGroupFilmQuerySet.researchGroupFilmId +
    "&groupfilmmarkersetid=" +
    researchGroupFilmQuerySet.researchGroupFilmMarkerSetId;

  return doPost("/annotate/load-mediate-read-only/", body, (json) => {
    // patching for backendUrl - we need a better solution than this
    return patchMediaFileURLS(json);
  });
}

export function fetchAddMarker(researchGroupFilmMarker) {
  const { frameNo, researchGroupFilm, ...rest } = researchGroupFilmMarker;
  const ftBody = propsToParams({
    frameNo: frameNo,
    film: researchGroupFilm.film.id,
  });
  return doPost("/annotate/api/film-time-codes/", ftBody).then((ftc) => {
    const body = propsToParams({
      ...rest,
      researchGroupFilm: researchGroupFilm.id,
      filmTimeCode: ftc.id,
    });
    return doPost("/annotate/api/research-group-film-markers/", body);
  });
}

export function fetchDeleteMarker(researchGroupFilmMarkerId) {
  return doDelete(
    "/annotate/api/research-group-film-markers/" +
      researchGroupFilmMarkerId +
      "/"
  ).catch((error) => console.log(error));
}

export function fetchMarker(markerId) {
  return doGet(`/annotate/api/research-group-film-markers/${markerId}/`);
}

export function fetchUpdateMarker(researchGroupFilmMarker) {
  const body =
    "research_group_film=" +
    researchGroupFilmMarker.researchGroupFilm +
    "&film_time_code=" +
    researchGroupFilmMarker.filmTimeCode.id +
    "&research_group_film_marker_set_type=" +
    researchGroupFilmMarker.researchGroupFilmMarkerSetType +
    "&note=" +
    researchGroupFilmMarker.note +
    "&research_group_film_marker_set=" +
    researchGroupFilmMarker.researchGroupFilmMarkerSet;

  return doUpdate(
    "/annotate/api/research-group-film-markers/" +
      researchGroupFilmMarker.id +
      "/",
    body,
    null,
    true
  );
}

export function fetchQueryOptions() {
  return doGet("/annotate/get-data-options/");
}

export function queryMarkers(query) {
  const body = JSON.stringify(query);
  return doPost("/annotate/query-markers/", body);
}

export function fetchGetMarkersByMarkerType(markerTypeIds) {
  const body = JSON.stringify({
    marker_type_ids: markerTypeIds,
  });
  return doPost("/annotate/get-marker-data/", body);
}

export function fetchGetCSRFToken() {
  // Need to do a GET to get a CSRF set to the browser
  return doGet("/annotate/token");
}

export function fetchGetQueryResultsForPlayer(markerTypeObj) {
  // Get the film
  let researchGroupFilmId = Object.keys(markerTypeObj)[0];
  return doGet(
    "/annotate/api/research-group-films/" + researchGroupFilmId,
    (researchGroupFilm) => {
      return doGet(
        "/annotate/api/media-file-uploads/" + "?film=" + researchGroupFilm.film,
        (media) => {
          let derivative = media[0].derivative[0];
          let mp4URL = backendUrl + "/" + derivative.mp4_url;
          let webmURL = backendUrl + "/" + derivative.webmUrl;
          return fetchGetMarkersByMarkerType(markerTypeObj).then(
            (markerTypeResults) => {
              return {
                sources: {
                  mediaFiles: [
                    { src: webmURL, type: "video/webm" },
                    { src: mp4URL, type: "video/mp4" },
                  ],
                  framerate: media[0].derivative_framerate,
                  duration: derivative.mp4_duration_frames,
                },
                queryResults: markerTypeResults.queryResults,
              };
            }
          );
        }
      );
    }
  );
}
