import { uniqueId, isPlainObject, isArray, mapValues, get, set } from "lodash";

const TEMP_ID_STORE = new Set();

export function isTempId(id) {
  return TEMP_ID_STORE.has(id);
}

export function createTempId(prefix) {
  return () => {
    const id = uniqueId(prefix);
    TEMP_ID_STORE.add(id);
    return id;
  };
}

export function removeTempId(obj, key = "id") {
  if (isTempId(obj[key])) {
    const { [key]: val, ...rest } = obj;
    return rest;
  }
  return obj;
}

export function removeTempIds(obj, key = "id") {
  return walkDeep(obj, (o) => removeTempId(o, key));
}
// func must always return obj!
export function walkDeep(obj, func) {
  if (isArray(obj)) {
    return obj.map((v) => walkDeep(v, func));
  } else if (isPlainObject(obj)) {
    // check if object has any children that are objects
    const hasChildren = applyPredicate(
      obj,
      (o) => isPlainObject(o) || isArray(o)
    );
    if (hasChildren) {
      // apply func to this object and then walk through children
      return mapValues(func(obj), (v, k, o) => walkDeep(v, func));
    } else {
      return func(obj);
    }
  }
  // do nothing to it
  return obj;
}

export function applyPredicate(obj, func, deep = false) {
  return Object.values(obj).some((o) => func(o));
}

export function swapKeyVal(obj) {
  const cloned = {};
  for (const [key, val] of Object.entries(obj)) {
    cloned[val] = key;
  }
  return cloned;
}

export function replaceKeys(obj, keyMapping, reverse = false) {
  // shallow copy
  const cloned = {};
  const km = reverse ? swapKeyVal(keyMapping) : keyMapping;
  for (const [key, val] of Object.entries(obj)) {
    if (km[key] !== undefined) {
      cloned[km[key]] = val;
    } else {
      cloned[key] = obj[key];
    }
  }
  return cloned;
}

export function createMergeObj(key, val) {
  const acc = {};
  const keys = key.split(".");
  const root = keys.shift();
  const update = (obj, val) => {
    if (keys.length === 1) {
      obj[keys[0]] = val;
      return;
    }
    const k = keys.shift();
    obj[k] = {};
    return update(obj[k], val);
  };
  update(acc, val);
  return { toMerge: acc, root: root };
}

export function truncateText(text, maxWords, skipPeriod = true) {
  const words = text.split(" ");
  if (words.length < maxWords) {
    return text;
  }
  const rest = words.splice(maxWords, words.length - maxWords);
  const lastWord = words[words.length - 1];
  // avoid ellipses following periods
  if (skipPeriod) {
    if (lastWord[lastWord.length - 1] === ".") {
      words.push(rest[0]);
    }
  }
  words.push("...");
  return words.join(" ");
}

// TODO move validate code here
export function validateObj(obj, fields) {
  const problems = {};
  let valid = true;
  const validate = (fields, parent) => {
    for (let key in fields) {
      const field = fields[key];
      const path = parent ? `${parent}.${key}` : key;
      if (isPlainObject(field)) {
        validate(field, path);
      } else if (field.constructor === Array) {
        const sField = get(obj, path);
        if (sField) {
          for (let i = 0; i < sField.length; i++) {
            const f = field[0];
            validate(f, `${path}[${i}]`);
          }
        }
      } else {
        if (!field(get(obj, path))) {
          set(problems, path, {
            valid: false,
            content: "This field is required",
            pointing: "above",
          });
          valid = false;
        } else {
          set(problems, path, { valid: true });
        }
      }
    }
  };
  validate(fields, null);
  return { problems, valid };
}

export function getErrorForField(problems, field) {
  if (!problems) return null;
  const problem = get(problems, field);
  if (isPlainObject(problem) && problem.valid === undefined) return problem;
  if (problem && problem.valid === false) {
    const { valid, ...rest } = problem;
    return rest;
  }
}

// based on https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function displayBytes(bytes) {
  if (bytes === 0) return "0 B";
  const sizes = ["B", "KB", "MB", "GB"];
  // 1KB = pow(1024,1), 1MB = pow(1024,2), 1GB = pow(1024, 3), ...etc
  // 1024 = pow(2, 10), log10(1024) = 3.01, so  exponent is log10(1024) / 3.01
  const exp = Math.floor(Math.log10(bytes) / 3);
  const decimal = exp > 1 ? 2 : 0;
  // divide total bytes by pow(2014, exp)
  return `${(bytes / Math.pow(1024, exp)).toFixed(decimal)} ${sizes[exp]}`;
}

export function inRange(num1, num2, threshold) {
  const upperBound = num2 + threshold;
  const lowerBound = num2 - threshold;
  return num1 >= lowerBound && num1 <= upperBound;
}
