import React, { Component } from "react";
import PageLoader from "../Loading";
import ProjectOverview from "./ProjectsOveriew";
import AddProject from "./AddProject";
import { BasicMediaDisplay } from "./MediaDisplay";
import { BasicSchemaDisplay } from "./SchemaDisplay";
import { Grid, Segment, Transition, Button, Icon } from "semantic-ui-react";
import { AddMediaOverlayTabs } from "./AddMediaOverlay";
import SchemaOverlay from "./SchemaOverlay";
import MarkerOverlay from "./MarkerOverlay";
import { AddProjectForm } from "./AddProject";
import { AdminContext } from "../../containers/admin/AdminContext";
import { EditAction, DeleteAction } from "../../utils/ModalActions";

export default class AdminRoot extends Component {
  state = { sidebarActive: false, activeForm: null };

  toggleSidebar = (form) => {
    this.setState({
      sidebarActive: !this.state.sidebarActive,
      activeForm: form,
    });
  };

  handleSelectMedia = (film, media, captions) => {
    const { user, onAddMedia } = this.props;
    onAddMedia(film, media, captions, user.username);
    this.toggleSidebar(null);
  };

  handleAddProject = (info) => {
    const { user, onAddProject } = this.props;
    const groupInfo = { ...info };
    groupInfo.owner = user.id;
    onAddProject(groupInfo);
  };

  handleSchemaAdded = (schema, deletedContent) => {
    const { onSaveSchema } = this.props;
    onSaveSchema(schema, deletedContent);
    this.toggleSidebar(null);
  };

  render() {
    const {
      user,
      researchGroups,
      onSaveSchema,
      onCloneSchema,
      onDeleteSchema,
      deleteMedia,
      deleteCaptions,
      onDeleteProject,
      media,
      schema,
    } = this.props;
    const { sidebarActive, activeForm } = this.state;
    const backButton = (
      <Button
        size="large"
        icon
        onClick={() => this.toggleSidebar(null)}
        className="mediate-project-tabs-back-button"
        labelPosition="left"
      >
        <Icon name="backward" />
        Admin
      </Button>
    );
    if (researchGroups) {
      if (researchGroups.length > 0) {
        return (
          <div className="mediate-admin-root">
            <Transition
              className="mediate-admin-root-transition"
              visible={sidebarActive}
              duration={500}
              animation="fade"
            >
              <div className="mediate-project-tab">{activeForm}</div>
            </Transition>
            <Transition
              duration={500}
              animation="fade right"
              visible={!sidebarActive}
            >
              <Grid
                relaxed
                columns={2}
                padded
                className="mediate-admin-container"
              >
                <Grid.Column
                  raised={true}
                  width={10}
                  className="mediate-project-overview-column"
                >
                  <Grid.Row
                    as={Segment}
                    raised={true}
                    className="mediate-project-overview-row"
                  >
                    <ProjectOverview
                      researchGroups={researchGroups}
                      user={user}
                      onCreateProject={this.handleAddProject}
                      onDeleteProject={onDeleteProject}
                      onAddProject={() =>
                        this.toggleSidebar(
                          <div className="admin-root-add-project-form-container">
                            <AddProjectForm
                              onSubmit={this.handleAddProject}
                              onDiscard={() => this.toggleSidebar(null)}
                              backButton={backButton}
                            />
                          </div>
                        )
                      }
                    />
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column
                  width={6}
                  stretched
                  className="mediate-admin-all-data-container"
                >
                  <Grid.Row
                    className="mediate-admin-all-data-row"
                    as={Segment}
                    raised={true}
                  >
                    <BasicMediaDisplay
                      columns={2}
                      media={media}
                      onDeleteMedia={deleteMedia}
                      onAddMedia={() =>
                        this.toggleSidebar(
                          <AddMediaOverlayTabs
                            showTabs={false}
                            onSelectMedia={this.handleSelectMedia}
                            backButton={backButton}
                          />
                        )
                      }
                      onEditMedia={(m) =>
                        this.toggleSidebar(
                          <AdminContext.Consumer>
                            {(value) => (
                              <AddMediaOverlayTabs
                                showTabs={false}
                                currentMedia={value.media.find(
                                  (med) => m.id === med.id
                                )}
                                onSelectMedia={this.handleSelectMedia}
                                onDeleteCaptions={deleteCaptions}
                                backButton={backButton}
                              />
                            )}
                          </AdminContext.Consumer>
                        )
                      }
                    />
                  </Grid.Row>
                  <Grid.Row
                    className="mediate-admin-all-data-row"
                    as={Segment}
                    raised={true}
                  >
                    <BasicSchemaDisplay
                      user={user}
                      schema={schema}
                      onAddSchema={() =>
                        this.toggleSidebar(
                          <SchemaOverlay
                            showOptionsToggle={true}
                            backButton={backButton}
                            onSave={this.handleSchemaAdded}
                            onClone={onCloneSchema}
                            onDelete={onDeleteSchema}
                            user={user}
                          />
                        )
                      }
                      actions={[
                        <EditAction
                          onClick={(_schema) =>
                            this.toggleSidebar(
                              <AdminContext.Consumer>
                                {(value) => (
                                  <SchemaOverlay
                                    showOptionsToggle={false}
                                    backButton={backButton}
                                    onSave={onSaveSchema}
                                    onDelete={onDeleteSchema}
                                    showOptions={false}
                                    user={user}
                                    currentSchema={value.schema.find(
                                      (s) => _schema.id === s.id
                                    )}
                                  />
                                )}
                              </AdminContext.Consumer>
                            )
                          }
                        />,
                        <DeleteAction
                          onClick={(_schema) => onDeleteSchema(_schema.id)}
                        />,
                      ]}
                    />
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            </Transition>
          </div>
        );
      } else {
        return (
          <div className="mediate-admin-container">
            <AddProject onSubmit={this.handleAddProject} />
          </div>
        );
      }
    } else {
      return <PageLoader text="Loading Projects ..." />;
    }
  }
}

AdminRoot.contextType = AdminContext;
