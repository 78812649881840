/* @flow */
// TODO this probably needs its own container

// React
import React, { Component, createRef } from "react";

// Components
import MediateMarkerDisplay from "./MediateMarkerDisplay";

// Binary Search Tree
import BinarySearchTree from "../utils/BinarySearch";

// lodash
import lodash from "lodash";

import SimpleBar from "simplebar-react";

import { inRange } from "../utils/Application";

export const SCROLL_BEHAVIOR = {
  NEAREST: "nearest",
  EXACT: "exact"
}

export default class MediateMarkerList extends Component {
  constructor(props) {
    super(props);
    this.isFrameRepeated = this.isFrameRepeated.bind(this);
    this.setMarkerListRefs = this.setMarkerListRefs.bind(this);
    this.initSearchTree = this.initSearchTree.bind(this);
    this.scrollToNearestMarker = this.scrollToNearestMarker.bind(this);
    this.scrollToMarker = this.scrollToMarker.bind(this);
    this.scrollByFrame = this.scrollByFrame.bind(this);
    this.markerListRefs = {};
    this.totalMarkerCount = 0;
    this.scrollRef = createRef(null);
    this._refreshSearchTree = false;
  }

  shouldComponentUpdate(nextProps: Object, nextState: Object): boolean {
    if (
      !lodash.isEqual(
        nextProps.filteredMarkerList,
        this.props.filteredMarkerList
      )
    ) {
      this._refreshSearchTree = true;
      return true;
    }

    if (!lodash.isEqual(nextProps.currentUser, this.props.currentUser)) {
      this._refreshSearchTree = true;
      return true;
    }

    if (nextProps.currentFrame !== this.props.currentFrame) {
      return true;
    }
    return false;
  }

  initSearchTree() {
    let frames = this.props.filteredMarkerList.map(
      (marker) => marker.filmTimeCode.frameNo
    );
    this.markerTree = new BinarySearchTree().fromSortedArray(frames);
    this._refreshSearchTree = false;
  }

  componentDidMount() {
    this.initSearchTree();
    if (this.scrollRef.current) {
      this.scrollRef.current.style.scrollBehavior = "smooth";
    }
  }

  componentDidUpdate(prevProps) {
    const { filteredMarkerList, scrollBehavior } = this.props;
    if (this._refreshSearchTree) {
      this.initSearchTree();
    }
    // if a marker is added or deleted don't scroll - too confusing
    if (filteredMarkerList.length === prevProps.filteredMarkerList.length) {
      if (scrollBehavior === SCROLL_BEHAVIOR.NEAREST) {
        this.scrollToNearestMarker(this.props.currentFrame);
      } else {
        this.scrollToMarker(this.props.currentFrame);
      }

    }
  }

  isFrameRepeated(filteredMarkerList, index, marker) {
    // need to return whether it's repeated and whether or not next sibling is repeated
    let repeated,
      siblingRepeated = false;
    try {
      // if index+1 overflows
      if (index > 0) {
        const previousMarkerFrame =
          filteredMarkerList[index - 1].filmTimeCode.frameNo;
        if (previousMarkerFrame === marker.filmTimeCode.frameNo) {
          repeated = true;
        }
      }
      const nextMarkerFrame =
        filteredMarkerList[index + 1].filmTimeCode.frameNo;
      if (nextMarkerFrame === marker.filmTimeCode.frameNo) {
        siblingRepeated = true;
      }
    } catch (error) {
      return [repeated, siblingRepeated];
    }
    return [repeated, siblingRepeated];
  }

  setMarkerListRefs(ref): void {
    if (ref !== null) {
      const { frameNo } = ref.props.marker.filmTimeCode;
      this.markerListRefs[frameNo] = ref.markerDisplayDOMElement;
    }
  }
  // TODO - when playing - only scroll to EXACT marker
  scrollToNearestMarker(frame: number) {
    const nearest = this.markerTree.nearest(frame);
    if (nearest !== undefined) {
      this.scrollByFrame(nearest);
    }
  }

  scrollToMarker(frame) {
    const nearest = this.markerTree.nearest(frame);
    if (nearest !== undefined && inRange(nearest, frame, 10)) {
      this.scrollByFrame(nearest);
    }
  }

  scrollByFrame(frame) {
    const target = this.markerListRefs[frame];
    if (this.scrollRef.current && target && target.parentNode) {
      this.scrollRef.current.scrollTop =
        target.offsetTop - target.parentNode.offsetTop;
    }
  }

  static checkUserStatus(user: Object, marker: Object): boolean {
    if (user === undefined) return true;
    if (user.isSuperUser === true) return false;
    if (user.id === marker.owner.id) return false;
    return true;
  }

  render() {
    const {
      currentFilterValues,
      markers,
      filteredMarkerList,
      framerate,
      setPosition,
      handleDeleteMarker,
      handleClickMarker,
      updateMarkerAction,
      readOnly,
      currentUser,
    } = this.props;
    return (
      <div className="mediate-marker-container">
        <SimpleBar
          scrollableNodeProps={{ ref: this.scrollRef }}
          className="app-marker-list-container"
        >
          {filteredMarkerList.map((marker, index) => {
            const [frameRepeated, siblingRepeated] = this.isFrameRepeated(
              filteredMarkerList,
              index,
              marker
            );
            return (
              <MediateMarkerDisplay
                tabIndexNo={index}
                marker={marker}
                ref={this.setMarkerListRefs}
                framerate={framerate}
                key={marker.id}
                setPosition={setPosition}
                updateMarkerAction={updateMarkerAction}
                handleSelectMarker={this.handleSelectMarker}
                handleDeleteMarker={handleDeleteMarker}
                handleClickMarker={handleClickMarker}
                readOnly={MediateMarkerList.checkUserStatus(
                  currentUser,
                  marker
                )}
                timecodeRepeated={frameRepeated}
                siblingTimecodeRepeated={siblingRepeated}
              />
            );
          })}
        </SimpleBar>
      </div>
    );
  }
}
