import React, { useState } from "react";
import { Confirm, Button, Icon } from "semantic-ui-react";

export default function ConfirmDelete(props) {
  const { onDelete, deleteKey, title, deleteButton, style } = props;
  const [confirm, setConfirm] = useState(false);
  return (
    <div className="confirm-delete-container" style={style}>
      {!deleteButton ? (
        <Button
          labelPosition="right"
          onClick={() => setConfirm(true)}
          size="tiny"
          compact
          icon
          color="red"
          className="mediate-project-option-delete mediate-project-option-button"
        >
          <Icon name="close" />
          Delete
        </Button>
      ) : (
        React.cloneElement(deleteButton, {
          ...deleteButton.props,
          ...{ onClick: () => setConfirm(true) },
        })
      )}
      <Confirm
        className="mediate-confirm-delete"
        open={confirm}
        content={`Are you sure you want to remove ${title} ? This can't be undone.`}
        onConfirm={() => {
          onDelete(deleteKey !== undefined ? deleteKey : null);
          setConfirm(false);
        }}
        onCancel={() => setConfirm(false)}
      />
    </div>
  );
}
