import React, { Component } from "react";
import { Provider } from "react-redux";
import { AnnotateStore } from "./store/AnnotateStore";
import createRoutes from "./routes";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// Router
import MediateRouter from "./containers/RouterContainer";
import UserContainer from "./containers/User";
export default class App extends Component {
  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <Provider store={AnnotateStore}>
          <UserContainer>
            <MediateRouter />
          </UserContainer>
        </Provider>
      </DndProvider>
    );
  }
}
