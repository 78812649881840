import React, { Component } from "react";
import { connect } from "react-redux";
import { runSearch } from "../actions/SearchActions";

export const SearchContext = React.createContext();
class Search extends Component {
  render() {
    const { children, ...rest } = this.props;
    return (
      <SearchContext.Provider
        value={rest}
      >
        {children}
      </SearchContext.Provider>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.search,
  };
}

export default connect(mapStateToProps, { runSearch })(Search);