import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addNoteToMarker,
  updateNote,
  deleteNote,
  addCommentToNote,
  addReplyToComment,
  updateComment,
  deleteComment,
  clearNoteError,
  clearCommentError,
} from "../actions/NotesActions";

/**
 * Adds all note action creates to NotesContext so they can be accessed
 * down the tree
 */
export const NotesContext = React.createContext();

class NotesCtxProvider extends Component {
  render() {
    const { children, ...rest } = this.props;
    return (
      <NotesContext.Provider value={rest}>{children}</NotesContext.Provider>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { ...state.notes };
}

export default connect(mapStateToProps, {
  addNoteToMarker,
  updateNote,
  deleteNote,
  addCommentToNote,
  addReplyToComment,
  updateComment,
  deleteComment,
  clearNoteError,
  clearCommentError,
})(NotesCtxProvider);
