// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import Search from "../Search";

import {
  loadResearchGroups,
  loadUserMedia,
  loadSchema,
  saveSchema,
  cloneSchema,
  deleteSchema,
  addResearchGroup,
  deleteResearchGroup,
  addMedia,
  deleteMedia,
  deleteCaptions,
  clearAdminInfo,
} from "../../actions/AdminActions";

import {
  subscribeToChannel,
  unsubscribeFromChannel,
} from "../../actions/WebSocketActions";

import AdminRoot from "../../componets/admin/AdminRoot";
import AdminNotification from "../../componets/admin/AdminNotification";
import AdminContextProvider from "./AdminContext";

class MediateAdminContainer extends Component {
  componentDidMount() {
    const { user } = this.props;
    if (user.isAuthenticated) {
      this.props.loadResearchGroups(user.userId);
      this.props.loadUserMedia(user.userId);
      this.props.loadSchema(user.userId);
      this.props.subscribeToChannel(user.username);
    }
  }

  handleAddResearchGroup = (groupInfo) => {
    const { id } = this.props.user;
    groupInfo.owner = id;
    this.props.addResearchGroup(groupInfo);
  };

  componentWillUnmount() {
    this.props.unsubscribeFromChannel();
  }

  render() {
    const {
      user,
      researchGroups,
      addResearchGroup,
      deleteResearchGroup,
      clearAdminInfo,
      saveSchema,
      cloneSchema,
      deleteSchema,
      addMedia,
      deleteMedia,
      deleteCaptions,
      media,
      schema,
      message,
    } = this.props;
    return (
      <AdminContextProvider>
        <Search>
          <AdminNotification
            timeout={10000}
            message={message}
            onTimeout={clearAdminInfo}
          >
            <AdminRoot
              user={user}
              researchGroups={researchGroups}
              onSaveSchema={saveSchema}
              onCloneSchema={cloneSchema}
              onDeleteSchema={deleteSchema}
              onAddProject={addResearchGroup}
              onDeleteProject={deleteResearchGroup}
              onAddMedia={addMedia}
              deleteMedia={deleteMedia}
              deleteCaptions={deleteCaptions}
              media={media}
              schema={schema}
            />
          </AdminNotification>
        </Search>
      </AdminContextProvider>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    message: state.admin.message,
    researchGroups: state.admin.researchGroups,
    media: state.admin.media,
    user: state.user.session,
    schema: state.admin.schema,
  };
}

export default connect(mapStateToProps, {
  subscribeToChannel,
  unsubscribeFromChannel,
  loadResearchGroups,
  loadUserMedia,
  loadSchema,
  saveSchema,
  cloneSchema,
  deleteSchema,
  addResearchGroup,
  deleteResearchGroup,
  addMedia,
  deleteMedia,
  deleteCaptions,
  clearAdminInfo,
})(MediateAdminContainer);
