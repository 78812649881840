// React
import React, { Component } from "react";

// Semantic UI React
import { Form, Message, Button } from "semantic-ui-react";

import ToggleIcon from "./ToggleIcon";

import logo from "../images/logo.png";

export default class SignUp extends Component {
  state = { username: "", email: "", password: "", showPassword: false };

  handleChange = (key, val) => {
    this.setState({
      [key]: val,
    });
  };

  handleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;

    const { username, email, password } = this.state;
    onSubmit(username, email, password);
  };

  render() {
    const { error, createdUser, createdUserError } = this.props;
    const { username, email, password, showPassword } = this.state;
    return (
      <div className="hp-body-container">
        <div className="hp-overlay">
          <div className="hp-content">
            <div className="hp-logo-container">
              <img src={logo} className="hp-logo" />
            </div>
            <h2 className="mediate-sign-up-header">Sign Up for Mediate</h2>
            <div className="mediate-sign-up-container">
              <Form className="mediate-sign-up-form">
                <Form.Input
                  className="login-form-field"
                  icon="mail"
                  iconPosition="left"
                  onChange={(event, { value }) =>
                    this.handleChange("email", value)
                  }
                  placeholder="email"
                  name="email"
                  type="text"
                  error={error}
                  value={email}
                />
                <Form.Input
                  className="login-form-field"
                  icon="lock"
                  iconPosition="left"
                  onChange={(event, { value }) =>
                    this.handleChange("password", value)
                  }
                  placeholder="password"
                  name="password"
                  value={password}
                  type={showPassword === true ? "text" : "password"}
                  error={error}
                />
                <Form.Input
                  className="login-form-field"
                  icon="user circle"
                  iconPosition="left"
                  onChange={(event, { value }) =>
                    this.handleChange("username", value)
                  }
                  placeholder="username"
                  name="username"
                  value={username}
                  type="text"
                  error={error}
                />
                {createdUserError ? (
                  <Message negative>
                    <Message.Header>
                      There was a problem creating your account!
                    </Message.Header>
                    <p>{createdUserError}</p>
                  </Message>
                ) : null}
                {createdUser ? (
                  <Message className="mediate-sign-up-confirmation">
                    <Message.Header>Email Verification Required</Message.Header>
                    We just sent an email to {createdUser.email}. Follow the
                    link in the message to verify your account.
                  </Message>
                ) : null}
                <Button
                  className="login-submit"
                  onClick={this.handleSubmit}
                  color="blue"
                >
                  create
                </Button>
                <ToggleIcon
                  className="login-form-button"
                  id="show-password"
                  onClick={this.handleShowPassword}
                  onColor="grey"
                  offColor="black"
                  onIcon="hide"
                  offIcon="eye"
                  onLabel="hide password"
                  offLabel="show password"
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
