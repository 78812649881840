import { WS_ACTIONS } from "../constants/Actions";

export function subscribeToChannel(researchGroup) {
    const formatted = researchGroup.replace(/\s+/g, "-").toLowerCase();
    return {
        type: WS_ACTIONS.SUBSCRIBE,
        researchGroup: formatted
    }
}

export function unsubscribeFromChannel() {
    return {
        type: WS_ACTIONS.UNSUBSCRIBE
    }
}