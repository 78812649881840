// React
import React, { Component } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";

// Components
import PageLoader from "../componets/Loading";
import MediateInterface from "../componets/MediateInterface";

// Actions
import {
  getMediateDataAction,
  loadMarkersAction,
  addMarkerAction,
  deleteMarkerAction,
  updateMarkerAction,
  setCurrentSchemaAction,
  loadCurrentSchemaAction,
  setCurrentMarkerTypesAction,
  saveShortcut,
  loadShortcutsAction,
  clearMediateInterfaceAction,
  clearMarkersUpdateStatus,
} from "../actions/AnnotateActions";

import {
  subscribeToChannel,
  unsubscribeFromChannel,
} from "../actions/WebSocketActions";

class MediateContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getMediateDataAction(this.props.researchGroupFilmId);
  }

  componentDidUpdate(prevProps) {
    const { schemaId, researchGroupFilm, researchGroupSchema, currentSchema, setCurrentSchemaAction } = this.props;
    if (!prevProps.researchGroupFilm && researchGroupFilm) {
      this.props.subscribeToChannel(researchGroupFilm.researchGroupChannel);
    }
    const selectedSchema = researchGroupSchema.find((schema) => schema.id === schemaId);
    if (selectedSchema && researchGroupFilm && !currentSchema) {
      setCurrentSchemaAction(selectedSchema, researchGroupFilm.researchGroupId);
    }
  }

  componentWillUnmount() {
    this.props.clearMediateInterfaceAction();
    this.props.unsubscribeFromChannel();
  }

  render() {
    const {
      researchGroupFilm,
      researchGroupSchema,
      researchGroupFilmId,
      mediateLoaded,
      addMarkerAction,
      loadMarkersAction,
      deleteMarkerAction,
      updateMarkerAction,
      markerStatus,
      currentMarkerTypes,
      setCurrentMarkerTypesAction,
      saveShortcut,
      setCurrentSchemaAction,
      loadCurrentSchemaAction,
      currentSchema,
      loadShortcutsAction,
      shortcuts,
      user,
      markersNeedUpdate,
      clearMarkersUpdateStatus,
      presentationMode
    } = this.props;

    let myResearchGroupFilm = [];
    if (researchGroupFilm !== null) {
      myResearchGroupFilm = researchGroupFilm;
    }

    if (mediateLoaded) {
      if ("error" in myResearchGroupFilm) {
        return <div>The page you requested could not be loaded</div>;
      } else {
        return (
          <MediateInterface
            addMarkerAction={addMarkerAction}
            loadMarkers={loadMarkersAction}
            deleteMarkerAction={deleteMarkerAction}
            updateMarkerAction={updateMarkerAction}
            researchGroupFilmId={researchGroupFilmId}
            researchGroupFilm={myResearchGroupFilm}
            markerStatus={markerStatus}
            currentMarkerTypes={currentMarkerTypes}
            setCurrentMarkerTypes={setCurrentMarkerTypesAction}
            saveShortcut={saveShortcut}
            setCurrentSchema={setCurrentSchemaAction}
            loadCurrentSchema={loadCurrentSchemaAction}
            currentSchema={currentSchema}
            researchGroupSchema={researchGroupSchema}
            loadShortcuts={loadShortcutsAction}
            shortcuts={shortcuts}
            currentUser={user}
            markersNeedUpdate={markersNeedUpdate}
            onMarkersUpdated={clearMarkersUpdateStatus}
            presentationMode={presentationMode}
          />
        );
      }
    } else {
      let loaderText = "Loading Mediate";
      return <PageLoader text={loaderText} />;
    }
  }
}

MediateContainer.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    researchGroupFilm: state.mediate.researchGroupFilm,
    markersNeedUpdate: state.mediate.markersNeedUpdate,
    researchGroupSchema: state.mediate.researchGroupSchema,
    mediateLoaded: state.mediate.mediateLoaded,
    markerStatus: state.mediate.markerStatus,
    researchGroupFilmId: parseInt(ownProps.match.params.groupFilmId, 10),
    schemaId: ownProps.match.params.schemaId ? parseInt(ownProps.match.params.schemaId, 10) : null,
    presentationMode: ownProps.location.pathname.includes("present"),
    currentSchema: state.mediate.currentSchema,
    currentMarkerTypes: state.mediate.currentMarkerTypes,
    shortcuts: state.mediate.shortcuts,
    user: { ...state.user.session },
  };
}

export default connect(mapStateToProps, {
  getMediateDataAction,
  loadMarkersAction,
  addMarkerAction,
  deleteMarkerAction,
  updateMarkerAction,
  setCurrentSchemaAction,
  loadCurrentSchemaAction,
  setCurrentMarkerTypesAction,
  saveShortcut,
  loadShortcutsAction,
  clearMediateInterfaceAction,
  clearMarkersUpdateStatus,
  subscribeToChannel,
  unsubscribeFromChannel,
})(MediateContainer);
