export default function sleep(duration) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

export function cancellableSleep(duration) {
  let timer;
  const promise = new Promise((resolve, reject) => {
    timer = setTimeout(() => {
      resolve();
    }, duration);
  });
  const cancel = () => clearTimeout(timer);
  return [promise, cancel];
}
