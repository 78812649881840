import React, { Component, useState, useCallback, useEffect } from "react";
import {
  Form,
  Accordion,
  Icon,
  Label,
  Popup,
  Card,
  Portal,
} from "semantic-ui-react";
import ConfirmDelete from "./ConfirmDelete";
import { useDrag } from "react-dnd";
import { MARKER_SELECT, MARKER_SELECT_ID } from "../../constants/ItemTypes";
import { CircularAvatar } from "../UserCard";
import logoIcon from "../../images/logo-icon.png";
import { Link } from "react-router-dom";
import { withModalActions } from "../../utils/ModalActions";
import { getErrorForField } from "../../utils/Application";
// TODO move to new API for DnD

export function ReadOnlyAdminMarkerLabel(props) {
  const {
    id,
    owner,
    name,
    description,
    color,
    actions,
    extraContent,
    active,
  } = props;
  const { profile } = owner;
  const imageSrc = profile && profile.imageUrl ? profile.imageUrl : logoIcon;
  const [showPortal, setShowPortal] = useState(false);
  const togglePortal = useCallback((val) => {
    setShowPortal(val);
  }, []);
  const wrappedActions = actions
    ? withModalActions(actions, props, () => togglePortal(false))
    : null;
  const content = (
    <Card className="admin-marker-label-portal">
      <Card.Content key="admin-marker-label-content">
        <Card.Header>{name}</Card.Header>
        <Card.Meta className="admin-marker-label-description">
          {description}
        </Card.Meta>
      </Card.Content>
      <Card.Content
        key="admin-marker-label-actions"
        extra
        className="admin-marker-label-extra-content"
      >
        {wrappedActions ? (
          wrappedActions
        ) : (
          <Link
            className="mediate-admin-link mediate-search-result-owner-link"
            to={`/admin/users/${owner.id}`}
          >
            <CircularAvatar image={imageSrc} />
          </Link>
        )}
      </Card.Content>
    </Card>
  );
  const style = { marginBottom: "5px", paddingLeft: "5px" };
  const borderStyle = color ? `2px solid ${color}` : undefined;
  if (active) {
    style.border = borderStyle;
  } else {
    style.borderLeft = borderStyle;
  }
  return (
    <Portal
      key={id}
      closeOnTriggerClick={true}
      openOnTriggerClick={false}
      open={showPortal}
      onClose={() => togglePortal(false)}
      trigger={
        <div
          onClick={() => togglePortal(true)}
          style={
            color
              ? {
                  borderLeft: `2px solid ${color}`,
                  marginBottom: "5px",
                  paddingLeft: "5px",
                }
              : null
          }
          className="mediate-admin-marker-label-read-only"
        >
          <div
            key="marker-label-name"
            className="mediate-admin-marker-label-read-only-name"
          >
            {name}
            {extraContent ? (
              <div className="mediate-admin-marker-label-read-only-extra">
                {extraContent}
              </div>
            ) : null}
          </div>
          <div
            key="marker-label-owner"
            className="mediate-admin-marker-label-read-only-owner"
          >
            @{owner.username}
          </div>
        </div>
      }
    >
      {content}
    </Portal>
  );
}

export default function AdminMarkerLabel(props) {
  const KEYS = {
    NAME: "name",
    DESCRIPTION: "description",
  };
  const {
    style,
    id,
    color,
    group,
    readOnly,
    onUpdate,
    active,
    onDelete,
    errors,
  } = props;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showDescription, setShowDescription] = useState(false);
  const handleUpdate = useCallback(() => {
    if (onUpdate) {
      onUpdate({
        name: name,
        description: description,
        id: id,
      });
    }
  }, [name, description]);

  useEffect(() => {
    handleUpdate();
  }, [name, description]);

  useEffect(() => {
    setName(props.name);
    setDescription(props.description);
  }, []);

  const handleChange = useCallback((key, value) => {
    switch (key) {
      case KEYS.NAME:
        setName(value);
        break;

      case KEYS.DESCRIPTION:
        setDescription(value);
        break;

      default:
        break;
    }
  });
  const shouldShowDescription = showDescription || errors;
  if (readOnly) {
    return <ReadOnlyAdminMarkerLabel {...props} />;
  } else {
    return (
      <Accordion
        className={`mediate-admin-marker-label ${
          getErrorForField(errors, "name") ||
          getErrorForField(errors, "description")
            ? " has-error"
            : ""
        }`}
      >
        <div
          className="admin-marker-label-color"
          style={{ background: color }}
        />
        {active ? (
          <ConfirmDelete
            deleteButton={
              <Icon name="close" className="admin-marker-label-delete" />
            }
            deleteKey={null}
            title={`${name} from ${group.name}`}
            onDelete={onDelete}
          />
        ) : null}
        <Accordion.Title
          className="admin-marker-label-container"
          active={showDescription}
        >
          <Icon
            name="dropdown"
            className="admin-marker-label-toggle-desc"
            size="large"
            onClick={() => setShowDescription(!showDescription)}
          />
          <Form.Input
            className="admin-marker-label-field"
            transparent
            value={name}
            placeholder="Name ..."
            onChange={(evt, { value }) => handleChange(KEYS.NAME, value)}
          />
        </Accordion.Title>
        {shouldShowDescription ? (
          <Accordion.Content active={shouldShowDescription}>
            <Form.Input
              className="admin-marker-label-field"
              id="admin-marker-label-description-field"
              transparent
              value={description}
              Ï
              placeholder="Description ..."
              onChange={(evt, { value }) =>
                handleChange(KEYS.DESCRIPTION, value)
              }
            />
          </Accordion.Content>
        ) : null}
      </Accordion>
    );
  }
}

export function DraggableAdminMarkerLabel(props) {
  const { onBegin, onEnd, ...rest } = props;
  const [collectedProps, drag] = useDrag({
    item: { id: MARKER_SELECT_ID, type: MARKER_SELECT },
    begin: () => {
      if (onBegin) onBegin();
      return { marker: rest };
    },
    end: () => {
      if (onEnd) onEnd();
    },
  });
  return (
    <div ref={drag} className="mediate-admin-marker-label-draggable">
      <AdminMarkerLabel {...rest} />
    </div>
  );
}

export function BasicAdminMarkerLabel(props) {
  const { name } = props;
  const [showOptions, setShowOptions] = useState(false);
  const toggleOptions = useCallback(() => {
    setShowOptions(!showOptions);
  }, [showOptions]);
  return (
    <div
      onClick={toggleOptions}
      className="mediate-basic-admin-marker-label-container"
    >
      <div className="mediate-basic-admin-marker-label">
        <h4 className="mediate-admin-marker-label-title">{name}</h4>
        <div className="mediate-admin-marker-options">
          <Icon
            className="mediate-options-button"
            name="eye"
            color="grey"
            size="large"
          />
          <Icon
            className="mediate-options-button"
            name="close"
            color="red"
            size="large"
          />
        </div>
      </div>
    </div>
  );
}
