// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import UserAccount from "../../componets/admin/UserAccount";

import { saveUserProfile } from "../../actions/AdminActions";

class UserAccountContainer extends Component {
  render() {
    const { user, saveUserProfile } = this.props;
    return <UserAccount user={user} onSaveProfile={saveUserProfile} />;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.session,
  };
}

export default connect(mapStateToProps, { saveUserProfile })(
  UserAccountContainer
);
