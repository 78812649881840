import React from "react";
import { Button, Icon } from "semantic-ui-react";
import ConfirmDelete from "../componets/admin/ConfirmDelete";

/**
 *  Modal Action button with modalObject (i.e. the actual object - schema, media, user, etc) displayed in the modal
 *  injected in onClick method, as well as modal toggling logic.
 */
function ModalAction(props) {
  const { onClick, modalObject, toggleModal, color, title, icon } = props;
  return (
    <Button
      className="mediate-admin-actions-button"
      onClick={() => {
        onClick(modalObject);
        if (toggleModal) {
          toggleModal();
        }
      }}
      compact
      size="tiny"
      icon
      color={color}
      labelPosition="right"
    >
      <Icon name={icon} />
      {title}
    </Button>
  );
}

export function AddAction(props) {
  return <ModalAction color="blue" title="Add" icon="plus" {...props} />;
}

export function EditAction(props) {
  return <ModalAction color="blue" title="Edit" icon="pencil" {...props} />;
}

export function CloneAction(props) {
  return <ModalAction color="grey" title="Copy" icon="copy" {...props} />;
}

export function DeleteAction(props) {
  const { onClick, modalObject, toggleModal } = props;
  const title = modalObject.title || modalObject.name;

  return (
    <ConfirmDelete
      title={title ? title : "Are you sure you want to delete this item? This can't be undone."}
      onDelete={() => {
        onClick(modalObject);
        if (toggleModal) {
          toggleModal();
        }
      }}
    />
  );
}

export function ViewAction(props) {
  return <ModalAction color="teal" title="View" icon="eye" {...props} />;
}

function withModalAction(WrappedComponent, modalObject, toggleModal) {
  return (props = {}) => {
    if (React.isValidElement(WrappedComponent)) {
      return React.cloneElement(WrappedComponent, {
        ...WrappedComponent.props,
        modalObject: modalObject,
        toggleModal: toggleModal,
      });
    }
    return (
      <WrappedComponent
        modalObject={modalObject}
        toggleModal={toggleModal}
        {...props}
      />
    );
  };
}

export function withModalActions(actions, modalObject, toggleModal = null) {
  if (!actions) return null;
  return actions.map((a) => withModalAction(a, modalObject, toggleModal)());
}
