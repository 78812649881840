/* @flow */

export default class LocalStorageAdapter {
  static get(key: string): Promise {
    return new Promise((resolve, reject) => {
      let result = localStorage.getItem(key);
      if (result !== null) resolve(result);
      reject("The item for key " + key + " wasn't found!")
    });
  }

  static set(key: string, value: string): Promise {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem(key, value);
        resolve({ key: key, value: value, success: true });
      } catch(error) {
        reject({ key: key, value: value, success: false, error: error });
      }
    });
  }

}
