import { SEARCH_ACTIONS } from "../constants/Actions";

const initalState = {
  results: {},
  resultsPending: false,
};

function mergeResults(oldResults, key, newResults) {
  const results = { ...oldResults };
  results[key] = newResults;
  return results;
}

export default function SearchReducer(state = initalState, action) {
  switch (action.type) {
    case SEARCH_ACTIONS.SEARCH_RESULTS_READY:
      return {
        ...state,
        results: mergeResults(
          state.results,
          action.key,
          action.results
        ),
      };
    case SEARCH_ACTIONS.SEARCH_RESULTS_PENDING:
      return {
        ...state,
        resultsPending: action.resultsPending
      }
    default:
      return state;
  }
}
