import React, { Component } from "react";
import {
  Form,
  Statistic,
  Segment,
  Label,
  Button,
  Icon,
  Divider
} from "semantic-ui-react";

export default class AccountSettings extends Component {
  qualityOptions = [
    { key: "l", text: "low", value: 0 },
    { key: "m", text: "medium", value: 1 },
    { key: "h", text: "high", value: 2 },
  ];
  getStorageColor = () => {
    const { user } = this.props;
    // TODO expose isSuperUser for all users
    if (!user.uploadQuota) {
      return "green";
    }
    // get from user quota - placeholder for now
    const { currentSizeMb, quotaMb } = user.uploadQuota;
    const used = currentSizeMb / quotaMb;
    let color = "green";
    if (used > 0.5 && used < 0.75) {
      color = "yellow";
    }
    if (used > 0.75 && used < 1.0) {
      color = "orange";
    }
    if (used >= 1.0) {
      color = "red";
    }
    return color;
  };

  renderStats = () => {
    const { user } = this.props;
    // TODO only super users should have no upload quota
    if (!user.uploadQuota) {
      return (
        <Segment raised className="mediate-stats-container">
          <Label attached="top" className="mediate-stats-header">
            You have no upload quota, however, you should still be mindful of
            how many large files you upload.
          </Label>
        </Segment>
      );
    }
    const { currentSizeMb, quotaGb, currentSizeGb } = user.uploadQuota;
    return (
      <Segment raised className="mediate-stats-container">
        <Label attached="top" className="mediate-stats-header">
          Upload Stats
        </Label>
        <Statistic.Group
          horizontal
          widths="2"
          size="large"
          className="mediate-stats-group"
        >
          <Statistic color={this.getStorageColor()} className="mediate-stat">
            <Statistic.Value>
              {currentSizeMb > 1024
                ? `${parseInt(currentSizeGb, 10)}GB`
                : `${parseInt(currentSizeMb, 10)}MB`}
            </Statistic.Value>
            <Statistic.Label>Used</Statistic.Label>
          </Statistic>
          <Divider horizontal>Of</Divider>
          <Statistic className="mediate-stat">
            <Statistic.Value>{quotaGb}GB</Statistic.Value>
            <Statistic.Label>Total Available</Statistic.Label>
          </Statistic>
        </Statistic.Group>
        <Divider />
        <div className="mediate-stats-options">
          <Button icon size="tiny" color="blue" labelPosition="right">
            <Icon name="plus" />
            Upgrade
          </Button>
        </div>
      </Segment>
    );
  };

  renderForm = () => {
    return (
      <Segment raised className="mediate-tech-settings-container">
        <Label attached="top" className="mediate-tech-settings-header">
          Technical Settings
        </Label>
        <h4 className="mediate-tech-settings-label">Video</h4>
        <Form fluid className="mediate-tech-settings-form">
          <Form.Group widths="1">
            <Form.Select
              label="Quality"
              options={this.qualityOptions}
              placeholder="Select ..."
            />
          </Form.Group>
          <Divider />
          <h4 className="mediate-tech-settings-label">Audio</h4>
          <Form.Group widths="1">
            <Form.Select
              label="Quality"
              options={this.qualityOptions}
              placeholder="Select ..."
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  };

  render() {
    return (
      <div className="mediate-account-settings-container">
        {this.renderStats()}
        {this.renderForm()}
      </div>
    );
  }
}
