/* @flow */

// React
import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux';
// Components
import MediateContainer from './MediateContainer';
import MediatePresentationInterface from '../componets/MediatePresentationInterface';
import PageLoader from '../componets/Loading';

// Actions
import { getMediatePresentationDataAction } from '../actions/AnnotateActions';

class MediatePresentationContainer extends MediateContainer {

  componentWillMount() {
    this.props.getMediatePresentationDataAction(this.props.researchGroupFilmQuerySet);
  }

  render() {
    const {
      researchGroupFilmData,
      researchGroupFilmQuerySet,
      mediateLoaded,
    } = this.props;
    let myResearchGroupFilmData = [];
    if (researchGroupFilmData !== null) {
      myResearchGroupFilmData = researchGroupFilmData;
    }
    if (mediateLoaded) {
      if ("error" in myResearchGroupFilmData) {
        return <div>The page you requested could not be loaded</div>;
      } else {
        return (
          <MediatePresentationInterface
            researchGroupFilmId={
              this.props.researchGroupFilmQuerySet.researchGroupFilmId
            }
            researchGroupFilmData={myResearchGroupFilmData}
          />
        );
      }
    } else {
      let loaderText = "Loading Mediate";
      return <PageLoader text={loaderText} />;
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    researchGroupFilmData: state.mediate.researchGroupFilmData,
    mediateLoaded: state.mediate.mediateLoaded,
    markerStatus: state.mediate.markerStatus,
    researchGroupFilmQuerySet: {
      researchGroupFilmId: ownProps.match.params.groupFilmId,
      researchGroupFilmMarkerSetId: ownProps.match.params.groupFilmMarkerSetId
    },
  };
}

export default connect(mapStateToProps, {
  getMediatePresentationDataAction,
})(MediatePresentationContainer);
