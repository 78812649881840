// React
import React, { Component } from "react";

// Components
import { CircularAvatar } from "./UserCard";

// Timecodes
import { frameToSeconds, toSMPTE } from "../utils/timecodes";

// Stardust
import {
  Dropdown,
  Icon,
  Button,
  Loader,
  Segment,
  Header,
  Portal,
  Card,
} from "semantic-ui-react";

import { DEFAULT_GROUP_COLOR } from "../constants/Application";

import NotesCtxProvider from "../containers/Notes";

import MediateBasicNoteThread from "./MediateBasicNoteThread";

import VerticalSidebar from "./VerticalSidebar";

// could be refactored to use a function component
class HandleNote extends Component {
  render() {
    const {
      marker,
      addNote,
      title,
      timecode,
      onCloseModal,
      onClosePortal,
      onShowNoteInput,
      thumbnail,
      showNote,
      readOnly,
    } = this.props;
    const { note } = marker;
    if (addNote) {
      return (
        <VerticalSidebar visible={addNote} onClose={onCloseModal}>
          <div className="mediate-basic-note-thread-header">
            Note Thread for {title} ({timecode})
          </div>
          <div className="mediate-basic-note-thread-content">
            <MediateBasicNoteThread marker={marker} canModifyNote={!readOnly} />
          </div>
        </VerticalSidebar>
      );
    } else if (note) {
      return (
        <Portal
          open={showNote}
          onClose={onClosePortal}
          closeOnEscape={true}
          closeOnTriggerBlur={true}
        >
          <Card className="mediate-note-portal">
            <Card.Content>
              <Card.Header>{title}</Card.Header>
              <Card.Meta className="mediate-note-portal-description">
                {note.noteText}
              </Card.Meta>
            </Card.Content>
            <Card.Content extra className="mediate-note-portal-extra-content">
              <Button
                className="mediate-admin-actions-button"
                onClick={onShowNoteInput}
                compact
                size="tiny"
                icon
                color="blue"
                labelPosition="right"
              >
                <Icon name="file" />
                View Thread
              </Button>
            </Card.Content>
          </Card>
        </Portal>
      );
    } else {
      return null;
    }
  }
}

const MarkerPlaceholder = (props) => {
  return <Loader active />;
};

const EditMarkerPlaceholder = (props) => {
  return <span className="marker-edit" />;
};

export default class MediateMarkerDisplay extends Component {
  state = { addNote: false, noteText: null, showNote: false };
  constructor(props) {
    super(props);
    this.toggleNoteInput = this.toggleNoteInput.bind(this);
    this.formatMarkerDisplay = this.formatMarkerDisplay.bind(this);
    this.frameToSMPTE = this.frameToSMPTE.bind(this);
    this.handleAddNote = this.handleAddNote.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.setRef = this.setRef.bind(this);
    this.toggleNote = this.toggleNote.bind(this);
  }

  setRef(ref) {
    this.markerDisplayDOMElement = ref;
  }

  toggleNote() {
    this.setState({
      showNote: !this.state.showNote,
    });
  }

  frameToSMPTE(frameNo, framerate) {
    let seconds = frameToSeconds(frameNo, framerate);
    return toSMPTE(seconds, framerate);
  }

  toggleNoteInput() {
    this.setState({ addNote: !this.state.addNote });
  }

  handleAddNote(note) {
    let updatedMarker = { ...this.props.marker, ...note };
    this.props.updateMarkerAction(updatedMarker);
    this.setState({ addNote: false });
  }

  renderDropdown(marker) {
    return (
      <span className="marker-edit">
        <Dropdown
          icon={"chevron down"}
          className="ui icon top right pointing dropdown button"
        >
          <Dropdown.Menu>
            {marker.note !== null ? (
              <Dropdown.Item
                text="Edit Note"
                icon={"sticky note"}
                onClick={this.toggleNoteInput}
              />
            ) : (
              <Dropdown.Item
                text="Add Note"
                icon={"sticky note"}
                onClick={this.toggleNoteInput}
              />
            )}
            <Dropdown.Item
              text="Remove (ctrl+d)"
              icon={"remove"}
              onClick={() => {
                this.props.handleDeleteMarker(marker.id);
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </span>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    }
    return false;
  }

  formatMarkerDisplay(marker, framerate, tabIndexNo) {
    const { timecodeRepeated, siblingTimecodeRepeated } = this.props;
    const createdBy = marker.owner;
    const markerTypeName = marker.researchGroupMarkerSetType.name;
    const frame = marker.filmTimeCode.frameNo;
    const markerStyle = {
      backgroundColor: marker.color || DEFAULT_GROUP_COLOR,
    };
    const thumbnailUrl = marker.filmTimeCode.thumbnailUrl;
    const showThumb = marker.showThumb;
    const timecode = this.frameToSMPTE(frame, framerate);
    const thumbnail =
      marker.filmTimeCode.thumbnail !== false
        ? marker.filmTimeCode.thumbnailUrl
        : null;
    return (
      <li
        tabIndex={tabIndexNo}
        className={`marker${timecodeRepeated ? " repeated" : ""} ${
          siblingTimecodeRepeated ? " sibling-repeated" : ""
        }`}
        ref={this.setRef}
        onClick={() => {
          this.props.setPosition(frame);
          this.props.handleClickMarker(marker);
          this.toggleNote();
        }}
      >
        {/** Lazy Loading is broken - TODO switch to virtualized list 
        {thumbnailUrl !== null && showThumb ? (
          <LazyLoad
            height={170}
            offset={170}
            overflow={true}
            debounce={100}
            once
            placeholder={<MarkerPlaceholder />}
          >
            <CSSTransitionGroup
              key={tabIndexNo}
              className="mediate-marker-thumbnail"
              transitionName="mediate-fade"
              transitionAppear={true}
              transitionAppearTimeout={1000}
              transitionEnterTimeout={1000}
              transitionLeaveTimeout={1000}
            >
              <div className="marker-color-bar" style={markerStyle} />
              <img src={thumbnailUrl} className="marker-shot-image" />
            </CSSTransitionGroup>
          </LazyLoad>
        ) : (
          <div className="marker-color-bar" style={markerStyle} />
        )}*/}
        {timecodeRepeated === true ? (
          <span className="marker-timecode repeated">
            <p style={{ visibility: "hidden" }}>{timecode}</p>
          </span>
        ) : (
          <span className="marker-timecode">{timecode}</span>
        )}
        <div className="marker-color-bar" style={markerStyle} />
        <span className="marker-type">
          {marker.note !== null ? (
            <span
              className="marker-icon-container"
              icon="sticky note"
              alt="has note"
            >
              {markerTypeName}{" "}
              <Icon className="marker-note-icon" name="sticky note" />
            </span>
          ) : (
            markerTypeName
          )}
          <span className="marker-headshot">
            <CircularAvatar
              title={createdBy.username}
              subtitle={new Date(marker.createdDate).toLocaleString()}
              showTitle
              size="small"
              image={createdBy.profile && createdBy.profile.imageUrl}
            />
          </span>
        </span>
        {this.props.readOnly !== true ? this.renderDropdown(marker) : null}
        <HandleNote
          addNote={this.state.addNote}
          marker={marker}
          title={marker.researchGroupMarkerSetType.name}
          showNote={this.state.showNote}
          onClosePortal={this.toggleNote}
          onCloseModal={this.toggleNoteInput}
          onShowNoteInput={this.toggleNoteInput}
          thumbnail={thumbnail}
          timecode={this.frameToSMPTE(
            marker.filmTimeCode.frameNo,
            this.props.framerate
          )}
          readOnly={this.props.readOnly}
        />
      </li>
    );
  }
  render() {
    const { marker, framerate, handleSelectMarker, tabIndexNo } = this.props;
    return (
      <NotesCtxProvider>
        {this.formatMarkerDisplay(marker, framerate, tabIndexNo)}
      </NotesCtxProvider>
    );
  }
}
