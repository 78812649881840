import { ADMIN_ACTIONS } from "../constants/Actions";

export function loadResearchGroups(userId) {
  return {
    type: ADMIN_ACTIONS.LOAD_RESEARCH_GROUPS,
    user: userId,
  };
}

export function loadResearchGroupDetail(id) {
  return {
    type: ADMIN_ACTIONS.LOAD_RESEARCH_GROUP_DETAIL,
    id: id,
  };
}

export function loadSchema(ownerId) {
  return {
    type: ADMIN_ACTIONS.LOAD_SCHEMA,
    ownerId: ownerId,
  };
}

export function saveSchema(schema, deletedContent = null) {
  return {
    type: ADMIN_ACTIONS.SAVE_SCHEMA,
    schema: schema,
    deletedContent: deletedContent,
  };
}

export function deleteSchema(schemaId) {
  return {
    type: ADMIN_ACTIONS.DELETE_SCHEMA,
    schemaId: schemaId,
  };
}

export function addSchemaToProject(schema, projectId) {
  return {
    type: ADMIN_ACTIONS.ADD_SCHEMA_TO_PROJECT,
    schema: schema,
    projectId: projectId,
  };
}

export function cloneProjectSchema(schema, projectId) {
  return {
    type: ADMIN_ACTIONS.CLONE_PROJECT_SCHEMA,
    schema: schema,
    projectId: projectId,
  };
}

export function cloneSchema(schema) {
  return {
    type: ADMIN_ACTIONS.CLONE_SCHEMA,
    schema: schema,
  };
}

export function removeSchemaFromProject(schemaId, projectId) {
  return {
    type: ADMIN_ACTIONS.REMOVE_SCHEMA_FROM_PROJECT,
    schemaId: schemaId,
    projectId: projectId,
  };
}

/* when we switch to typescript media will be 
    interface IMedia {
        file?: File; 
        captions?: File;
        url?: string;
    }
*/

export function addProjectMedia(
  film,
  media,
  captions,
  researchGroup,
  id = null
) {
  return {
    type: ADMIN_ACTIONS.ADD_PROJECT_MEDIA,
    media: media,
    film: film,
    captions: captions,
    researchGroup: researchGroup,
    id: id,
  };
}

export function addMedia(film, media, captions, channel) {
  return {
    type: ADMIN_ACTIONS.ADD_MEDIA,
    film: film,
    media: media,
    captions: captions,
    channel: channel,
  };
}

export function cancelMediaUpload(upload) {
  return {
    type: ADMIN_ACTIONS.CANCEL_MEDIA_UPLOAD,
    upload: upload,
  };
}

export function loadUserMedia(userId) {
  return {
    type: ADMIN_ACTIONS.LOAD_USER_MEDIA,
    userId: userId,
  };
}

export function addResearchGroup(groupInfo) {
  return {
    type: ADMIN_ACTIONS.ADD_RESEARCH_GROUP,
    info: groupInfo,
  };
}

export function addResearchGroupUser(userId, groupId) {
  return {
    type: ADMIN_ACTIONS.ADD_RESEARCH_GROUP_USER,
    groupId: groupId,
    userId: userId,
  };
}

export function updateResearchGroupUser(id, properties) {
  return {
    type: ADMIN_ACTIONS.UPDATE_RESEARCH_GROUP_USER,
    id: id,
    properties: properties,
  };
}

export function deleteResearchGroup(id) {
  return {
    type: ADMIN_ACTIONS.DELETE_RESEARCH_GROUP,
    id: id,
  };
}

export function deleteResearchGroupUser(userId) {
  return {
    type: ADMIN_ACTIONS.DELETE_RESEARCH_GROUP_USER,
    userId: userId,
  };
}

export function deleteProjectMedia(mediaId) {
  return {
    type: ADMIN_ACTIONS.DELETE_PROJECT_MEDIA,
    mediaId: mediaId,
  };
}

export function deleteMedia(mediaId) {
  return {
    type: ADMIN_ACTIONS.DELETE_MEDIA,
    mediaId: mediaId,
  };
}

export function deleteCaptions(captionsId) {
  return {
    type: ADMIN_ACTIONS.DELETE_CAPTIONS,
    captionsId: captionsId,
  };
}

export function searchMedia(query) {
  return {
    type: ADMIN_ACTIONS.SEARCH_MEDIA,
    query: query,
  };
}

export function postAdminInfo(title, message) {
  return {
    type: ADMIN_ACTIONS.ADMIN_INFO,
    data: {
      content: message,
      title: title,
    },
  };
}

export function clearAdminInfo() {
  return {
    type: ADMIN_ACTIONS.CLEAR_ADMIN_INFO,
  };
}

export function saveUserProfile(user, profile) {
  return {
    type: ADMIN_ACTIONS.SAVE_USER_PROFILE,
    user: user,
    profile: profile,
  };
}

export function inviteUser(email, message, researchGroupId) {
  return {
    type: ADMIN_ACTIONS.INVITE_USER,
    email: email,
    message: message,
    researchGroupId: researchGroupId,
  };
}
