import React, { cloneElement, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, List, Icon } from "semantic-ui-react";
import { CircularAvatar } from "../UserCard";

function SchemaNode(props) {
  const { id, name, owner, clonedFrom, level } = props;
  return (
    <List className="schema-cloned-hierarchy-node">
      <List.Item to={`/admin/schema/${id}`} target="_blank" as={Link}>
        <List.Icon
          name={!clonedFrom ? "tags" : "copy outline"}
          color={!clonedFrom ? "blue" : "teal"}
        />
        <List.Content>
          <List.Header>{`${name} ${
            !clonedFrom ? "-- original" : ""
          }`}</List.Header>
          <List.Description>{`@${owner.username}`}</List.Description>
          {clonedFrom ? <SchemaNode {...clonedFrom} level={level + 1} /> : null}
        </List.Content>
      </List.Item>
    </List>
  );
}

export default function SchemaCloneHierarchy(props) {
  return (
    <Modal
      className="schema-hierarchy-modal"
      trigger={<Icon circular color="teal" inverted name="eye" />}
      scrolling
    >
      <Modal.Header>Schema Hierarchy</Modal.Header>
      <Modal.Content>
        <SchemaNode {...props} level={0} />
      </Modal.Content>
    </Modal>
  );
}
