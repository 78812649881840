import { doPost } from "./FetchWrapper";
import { SEARCH_MAP } from "../constants/Application";

export function fetchSearch(query) {
    const { key, searchText } = query;
    const queryInfo = SEARCH_MAP[key];
    if (queryInfo) {
        const { model, attribute } = queryInfo;
        const body = `model=${model}&attribute=${attribute}&search_text=${searchText}`;
        return doPost("/annotate/search/", body);
    } else {
        throw new Error(`Invalid Search Key: ${key}`);
    }
}