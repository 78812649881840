import { MEDIATE_ACTIONS, AUTH_ACTIONS } from "../constants/Actions";
/**
 *
 * @returns {{type: string}}
 */
export function loginUser(login) {
  return {
    type: AUTH_ACTIONS.LOGIN_USER,
    login: login
  };
}

export function createUser(username, email, password) {
  return {
    type: AUTH_ACTIONS.CREATE_USER,
    username: username,
    email: email,
    password: password,
  }
}

export function verifyUser(key) {
  return {
    type: AUTH_ACTIONS.VERIFY_USER,
    key: key
  }
}

export function authenticateUser() {
  return {
    type: AUTH_ACTIONS.AUTHENTICATE_USER,
  }
}

export function logoutUser() {
  return {
    type: AUTH_ACTIONS.LOGOUT_USER,
  }
}

export function userLoggedOut() {
  return {
    type: AUTH_ACTIONS.USER_LOGGED_OUT,
    loggedIn: false
  };
}

export function resetLoginErrorMessage() {
  return {
    type: AUTH_ACTIONS.REMOVE_LOGIN_ERROR
  };
}

export function loadUser(data) {
  return {
    type: AUTH_ACTIONS.LOAD_USER,
    userData: data
  };
}

export function getSession() {
  return {
    type: AUTH_ACTIONS.GET_SESSION
  };
}

export function setAuthentication() {
  return {
    type: AUTH_ACTIONS.USER_AUTHENTICATED,
    loggedIn: true
  };
}

export function getDashboardData() {
  return {
    type: AUTH_ACTIONS.GET_DASHBOARD,
    dashboardLoaded: false
  };
}

export function loadMarkersAction(groupFilmId, schemaId) {
  return {
    type: MEDIATE_ACTIONS.LOAD_MARKERS,
    researchGroupFilmId: groupFilmId,
    schemaId: schemaId
  }
}

export function setCurrentSchemaAction(schema, groupId) {
  return {
    type: MEDIATE_ACTIONS.SCHEMA_SELECTED,
    currentSchema: schema,
    groupId: groupId
  }
}

export function loadCurrentSchemaAction(groupId) {
  return {
    type: MEDIATE_ACTIONS.LOAD_CURRENT_SCHEMA,
    groupId: groupId
  }
}

export function setCurrentMarkerTypesAction(markerTypes: Array<Object>) {
  return {
    type: MEDIATE_ACTIONS.CURRENT_MARKER_TYPES_UPDATED,
    markerTypes: markerTypes
  };
}

export function saveShortcut(schemaId, keystrokes, markerTypeId) {
  return {
    type: MEDIATE_ACTIONS.SAVE_SHORTCUT,
    schemaId: schemaId,
    keystrokes: keystrokes,
    markerTypeId: markerTypeId
  }
}

export function setCurrentShortcutsAction(
  shortcuts: Array<Object>,
  markerTypeSetId: number
): Object {
  return {
    type: MEDIATE_ACTIONS.SET_CURRENT_SHORTCUTS,
    shortcuts: shortcuts,
    markerTypeSetId: markerTypeSetId
  };
}

export function loadShortcutsAction(schemaId: number): Object {
  return {
    type: MEDIATE_ACTIONS.LOAD_SHORTCUTS,
    schemaId: schemaId
  };
}

export function getMediateDataAction(researchGroupFilmId) {
  return {
    type: MEDIATE_ACTIONS.LOAD_MEDIATE_DATA,
    researchGroupFilmId: researchGroupFilmId
  };
}

export function clearMediateInterfaceAction() {
  return {
    type: MEDIATE_ACTIONS.CLEAR_INTERFACE,
  }
}

export function getMediatePresentationDataAction(researchGroupFilmQuerySet) {
  return {
    type: MEDIATE_ACTIONS.LOAD_MEDIATE_PRESENTATION_DATA,
    researchGroupFilmQuerySet: researchGroupFilmQuerySet
  };
}

export function addMarkerAction(researchGroupFilmMarker) {
  return {
    type: MEDIATE_ACTIONS.ADD_MARKER,
    researchGroupFilmMarker: researchGroupFilmMarker
  };
}

export function deleteMarkerAction(researchGroupFilmMarkerId) {
  return {
    type: MEDIATE_ACTIONS.DELETE_MARKER,
    researchGroupFilmMarkerId: researchGroupFilmMarkerId
  };
}

export function updateMarkerAction(researchGroupFilmMarker) {
  const { note } = researchGroupFilmMarker;
  researchGroupFilmMarker.note = encodeURIComponent(note);
  return {
    type: MEDIATE_ACTIONS.UPDATE_MARKER,
    researchGroupFilmMarker: researchGroupFilmMarker
  };
}

export function loadQueryOptions(queryOptions) {
  return {
    type: MEDIATE_ACTIONS.LOAD_QUERY_OPTIONS,
    queryOptions: queryOptions
  };
}

export function getMarkersByMarkerTypeAction(markerTypeIdArray) {
  return {
    type: MEDIATE_ACTIONS.GET_MARKERS_BY_MARKER_TYPE,
    markerTypeIds: markerTypeIdArray
  };
}

export function getQueryResultsForPlayer(markerTypeObj) {
  return {
    type: MEDIATE_ACTIONS.GET_QUERY_RESULT_PLAYER,
    markerTypeObj: markerTypeObj
  };
}

export function clearMarkersUpdateStatus() {
  return {
    type: MEDIATE_ACTIONS.CLEAR_MARKERS_UPDATE_STATUS
  }
}

export function queryMarkers(query) {
  return {
    type: MEDIATE_ACTIONS.QUERY_MARKERS,
    query
  }
}
