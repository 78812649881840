import React, { Component, useState } from "react";
import {
  Button,
  Label,
  Modal,
  Card,
  Grid,
  Segment,
  Icon,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { CircularAvatar } from "../UserCard";
import { withModalActions } from "../../utils/ModalActions";
import LiveSearch from "./LiveSearch";
import { SEARCH_KEYS } from "../../constants/Application";
import DynamicGrid from "../DynamicGrid";
import SchemaCloneTree from "./SchemaCloneTree";

export default function SearchSchema(props) {
  const { onSelectResult, user, actions } = props;
  return (
    <LiveSearch
      searchLabel="Search Schema By Name"
      className="mediate-search-marker-resuls-display"
      searchKey={SEARCH_KEYS.SCHEMA}
      resultsDisplay={(props) => (
        <SchemaResultsDisplay {...{ ...props, user: user, actions: actions }} />
      )}
      onSelectResult={onSelectResult}
    />
  );
}

export function SchemaResultsDisplay(props) {
  const { results, onSelectResult, user, actions } = props;
  return (
    <DynamicGrid
      divided={true}
      columns={3}
      className="mediate-search-results-display"
      data={results}
      renderCell={(props) => (
        <SchemaResultDisplay {...{ ...props, user: user, actions: actions }} />
      )}
      onSelectCell={onSelectResult}
    />
  );
}

export function SchemaResultDisplay(props) {
  const {
    user,
    id,
    owner,
    name,
    description,
    forwardRef,
    onToggleModal,
    totalMarkerTypeCount,
    totalResearchGroups,
    actions,
    clonedFrom,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const isOwnedByUser = owner.id === user.id;
  const wrappedActions = withModalActions(actions, props, () =>
    setShowModal(false)
  );
  return (
    <Modal
      className="mediate-search-result-detail-popup"
      ref={forwardRef}
      open={showModal}
      trigger={
        <div
          className="mediate-search-result mediate-schema-search-result"
          onClick={() => {
            setShowModal(true);
            if (onToggleModal) onToggleModal(id, true);
          }}
        >
          <Icon
            name={`${!clonedFrom ? "tags" : "clone outline"}`}
            color={`${!clonedFrom ? "blue" : "teal"}`}
          />
          <div className="mediate-search-result-info">
            <span className="mediate-search-result-title">{name}</span>
            <span className="mediate-search-result-owner">
              @{owner.username}
            </span>
          </div>
        </div>
      }
      onClose={() => {
        setShowModal(false);
        if (onToggleModal) onToggleModal(id, false);
      }}
      dimmer="blurring"
      basic
    >
      <Modal.Content>
        <Card fluid className="mediate-search-result-detail-card">
          <Card.Content className="mediate-schema-result-detail-card-content">
            <Card.Header className="mediate-search-result-header mediate-schema-result-header">
              {name}
            </Card.Header>
            <Card.Description>
              <div className="mediate-search-result-info">
                <div className="mediate-search-result-description">
                  {description}
                </div>
                <Grid
                  divided
                  className="mediate-schema-result-info-container"
                  columns={2}
                  width="equal"
                >
                  <Grid.Column className="mediate-schema-result-created-container">
                    <span className="mediate-search-result-created-info">
                      <Link
                        className="mediate-admin-link mediate-search-result-owner-link"
                        to={`/admin/users/${owner.id}`}
                      >
                        <CircularAvatar
                          size="medium"
                          title={owner.username}
                          subtitle={
                            owner.profile ? owner.profile.organization : null
                          }
                          image={owner.profile ? owner.profile.imageUrl : null}
                          showTitle={true}
                        />
                      </Link>
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    <SchemaUsageStats
                      markerCount={totalMarkerTypeCount}
                      projectCount={totalResearchGroups}
                      schema={props}
                    />
                  </Grid.Column>
                </Grid>
              </div>
            </Card.Description>
          </Card.Content>
          <Card.Content extra className="mediate-search-result-options">
            <Modal.Actions>
              {wrappedActions ? wrappedActions : null}
            </Modal.Actions>
          </Card.Content>
        </Card>
      </Modal.Content>
    </Modal>
  );
}

export function SchemaUsageStats(props) {
  const { projectCount, schema } = props;
  return (
    <div className="mediate-schema-usage-stats">
      <Segment compact className="mediate-schema-usage-stat">
        <Label attached="top">Projects</Label>
        <h3 className="mediate-admin-subheader">{projectCount}</h3>
      </Segment>
      {schema.clonedFrom ? (
        <Segment compact className="mediate-schema-usage-stat">
          <Label attached="top">Hierarchy</Label>
          <SchemaCloneTree {...schema} />
        </Segment>
      ) : null}
    </div>
  );
}
