import { SEARCH_ACTIONS } from "../constants/Actions";
import { call, put, takeEvery, all } from "redux-saga/effects";
import { fetchSearch } from "../api/SearchFetch";

function* runSearchSaga(action) {
  try {
    yield put({
      type: SEARCH_ACTIONS.SEARCH_RESULTS_PENDING,
      resultsPending: true,
    });
    const { results } = yield fetchSearch(action.query);
    const { key } = action.query;
    yield put({
      type: SEARCH_ACTIONS.SEARCH_RESULTS_READY,
      results: results,
      key: key,
    });
    yield put({
      type: SEARCH_ACTIONS.SEARCH_RESULTS_PENDING,
      resultsPending: false,
    });
  } catch (error) {
    console.log(error);
  }
}

function* waitForRunSearch() {
  yield takeEvery(SEARCH_ACTIONS.RUN_SEARCH, runSearchSaga);
}

export default function* searchSagas() {
  yield all([waitForRunSearch()]);
}
