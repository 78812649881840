import React, { useCallback, useEffect, useState } from "react";
import { DetailedSchemaDisplay } from "./admin/SchemaDisplay";
import { Header, Icon } from "semantic-ui-react";
import MediateMarkerLabel from "./MediateMarkerLabel";
import { AddAction } from "../utils/ModalActions";
import { findNestedIndex } from "../utils/ArrayUtils";
import SimpleBar from "simplebar-react";
import CreateShortcut from "./CreateShortcut";

const RESERVED_SHORTCUTS = ["ctrl+a", "ctrl+d"];
// wrapped version of CreateShortcut
function CreateMarkerShortcut(props) {
  const { shortcuts, schema, onSave, modalObject } = props;
  const handleSave = useCallback(
    (shortcut) => {
      if (onSave) {
        onSave(schema.id, shortcut, modalObject.id);
      }
    },
    [modalObject]
  );
  return (
    <CreateShortcut
      onSave={handleSave}
      currentShortcut={shortcuts && shortcuts[modalObject.id]}
      reservedShortcuts={RESERVED_SHORTCUTS}
    />
  );
}

// Can't seem to override height of Sidebar so will have to roll our own basic one
export default function MediateSchemaSidebar(props) {
  const { schema, visible, shortcuts, saveShortcut } = props;
  const [activeMarkers, setActiveMarkers] = useState([]);
  const handleAddActiveMarker = useCallback(
    (marker) => {
      const idx = findNestedIndex("id", marker.id, activeMarkers);
      if (idx === -1) {
        const updated = [...activeMarkers];
        updated.push(marker);
        setActiveMarkers(updated);
      }
    },
    [activeMarkers]
  );
  const handleKeyPress = useCallback((evt) => {
    const { keyCode } = evt;
    const { onClose } = props;
    if (keyCode === 27) {
      if (onClose) onClose();
    }
  }, []);
  const handleRemoveActiveMarker = useCallback(
    (marker) => {
      const idx = findNestedIndex("id", marker.id, activeMarkers);
      if (idx > -1) {
        const updated = [...activeMarkers];
        updated.splice(idx, 1);
        setActiveMarkers(updated);
      }
    },
    [activeMarkers]
  );
  useEffect(() => {
    const { onActiveMarkersChange } = props;
    if (onActiveMarkersChange) {
      onActiveMarkersChange(activeMarkers);
    }
  }, [activeMarkers]);
  const SetActiveAction = (
    <AddAction
      key="set-active"
      title="Use Marker"
      size="mini"
      onClick={(marker) => handleAddActiveMarker(marker)}
    />
  );
  useEffect(() => {
    setActiveMarkers([]);
  }, [schema]);
  useEffect(() => {
    if (visible) {
      document.addEventListener("keydown", handleKeyPress);
    }
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [visible]);
  if (schema) {
    return (
      <div className={`mediate-schema-sidebar ${visible ? "show" : "hide"}`}>
        <div className="mediate-schema-sidebar-active-markers-container">
          <div className="mediate-schema-sidebar-active-marker-count">
            {activeMarkers.length} Active Markers
          </div>
          <SimpleBar
            style={{ overflowX: "hidden" }}
            className="mediate-schema-sidebar-active-markers-list"
          >
            {activeMarkers.map((marker) => (
              <span
                key={marker.id}
                className="mediate-schema-active-marker"
                style={{ background: marker.color }}
              >
                <MediateMarkerLabel marker={marker} />
                <Icon
                  onClick={() => handleRemoveActiveMarker(marker)}
                  className="mediate-marker-shortcut-remove"
                  color="gray"
                  name="window close"
                  inverted
                />
              </span>
            ))}
          </SimpleBar>
        </div>
        <DetailedSchemaDisplay
          currentSchema={schema}
          shortcuts={shortcuts}
          readOnly={true}
          markerActions={[
            <CreateMarkerShortcut
              key="create-shortcut"
              schema={schema}
              shortcuts={shortcuts}
              onSave={saveShortcut}
            />,
            SetActiveAction,
          ]}
        />
      </div>
    );
  }
  return (
    <div className={`mediate-schema-sidebar ${visible ? "show" : "hide"}`}>
      <Header className="mediate-schema-sidebar-header" size="huge">
        No schema currently set. Please select one to get started!
      </Header>
    </div>
  );
}
