// React
import React, { Component, createRef } from "react";

// Components
import MediateMarkerList, { SCROLL_BEHAVIOR } from "./MediateMarkerList";
import VideoPlayer from "./VideoPlayer";
import MarkerStatus from "./MarkerStatus";
import MediateMarkerMenu from "./MediateMarkerMenu";
import MediateMarkerLabel from "./MediateMarkerLabel";
import MediateUIInfo from "./MediateUIInfo";
import { Button, Sidebar, Icon } from "semantic-ui-react";
import MediateSchemaFilter from "./MediateSchemaFilter";
import MediateSchemaSidebar from "./MediateSchemaSidebar";

// Timecodes
import {
  frameToSeconds,
  secondsToFrames,
} from "../timecodes/TimecodeConversions";

// VJS Plugins
import nleControls from "../vjs-plugins/NLEControls";

// CSS
import videojsCSS from "video.js/dist/video-js.css";

import lodash from "lodash";
import { MarkerResultsDisplay } from "./admin/AdminSearchMarkers";
import backendUrl from "../constants/Backend";
import { Link } from "react-router-dom";

export function markerSetToFormOptions(markerSet) {
  let options = [];
  markerSet.forEach((marker) => {
    let formatted = { text: marker.name, value: marker.id };
    options.push(formatted);
  });
  return options;
}

export const playerOptions = {
  controls: true,
  inactivityTimeout: false,
  fluid: true,
};

function MediateAnnotationLink(props) {
  const { researchGroupFilm } = props;
  return (
    <div className="annotate-in-mediate-button">
      <Link to={`/mediate/${researchGroupFilm.id}`} target="_blank">
        <Button
          icon
          size="mini"
          labelPosition="left"
          color="blue"
          className="mediate-basic-note-thread-save-button"
        >
          <Icon name="pencil" />
          Annotate in Mediate
        </Button>
      </Link>
    </div>
  );
}

export default class MediateInterface extends Component {
  // TODO set current schema as local state for MediateInterface
  state = {
    currentValues: [],
    currentFilterValues: [],
    currentFilteredUserValues: [],
    currentFrame: 0,
    currentMarker: null,
    showInfo: false,
    showFilter: false,
    showSchemaView: false,
    filteredMarkerList: [],
    shortcutLabels: [],
  };
  playerRef = createRef();
  constructor(props) {
    super(props);
    this.getMediaUploadProperty = this.getMediaUploadProperty.bind(this);
    this.getDerivativeProperty = this.getDerivativeProperty.bind(this);
    this.setPosition = this.setPosition.bind(this);
    this.handleSubmitMarker = this.handleSubmitMarker.bind(this);
    this.handleDeleteMarker = this.handleDeleteMarker.bind(this);
    this.handleClickMarker = this.handleClickMarker.bind(this);
    this.handleSelectSchema = this.handleSelectSchema.bind(this);
    this.setCurrentFilterValues = this.setCurrentFilterValues.bind(this);
    this.setShortcutLabels = this.setShortcutLabels.bind(this);
    this.filterMarkers = this.filterMarkers.bind(this);
    this.filterCollaborators = this.filterCollaborators.bind(this);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.isPaused = this.isPaused.bind(this);
    this.filterMemo = {};
  }

  componentDidMount() {
    const { schema, collaborators } = this.props.researchGroupFilm;
    const { currentSchema } = this.props;
    // TODO replace
    if (!currentSchema) {
      const { researchGroupId } = this.props.researchGroupFilm;
      this.props.loadCurrentSchema(researchGroupId);
    } else {
      if (this.props.loadShortcuts !== undefined) {
        this.props.loadShortcuts(currentSchema.id);
      }
    }
    this.setState({
      currentFilteredUserValues: collaborators.map((c) => c.user.id),
    });
  }

  componentDidUpdate(prevProps: Object, prevState: Object) {
    const {
      setCurrentMarkerTypes,
      loadShortcuts,
      researchGroupFilm,
      currentSchema,
      markersNeedUpdate,
      onMarkersUpdated,
      shortcuts,
    } = this.props;
    const { currentFilterValues, currentFilteredUserValues } = this.state;
    const { researchGroupFilmMarkers } = researchGroupFilm;
    const prevCurrentSchema = prevProps.currentSchema;
    if (!lodash.isEqual(prevCurrentSchema, currentSchema)) {
      // loadMarkers(researchGroupFilmId, currentSchema.id);
      setCurrentMarkerTypes([]);
      loadShortcuts(currentSchema.id);
    }
    if (markersNeedUpdate) {
      this.filterMemo = {};
      if (onMarkersUpdated) onMarkersUpdated();
      this.setState({
        filteredMarkerList: this.filterMarkers(
          researchGroupFilmMarkers,
          currentFilterValues,
          currentFilteredUserValues
        ),
      });
    }
    // this shouldn't be too much of a performance issue - if it is we can figure out a better way
    if (!lodash.isEqual(prevProps.shortcuts, shortcuts)) {
      this.setShortcutLabels();
    }
  }

  componentWillUnmount() {
    this.props.setCurrentSchema(null);
  }

  getMediaUploadProperty(key) {
    const { mediaUpload } = this.props.researchGroupFilm.film;
    if (mediaUpload.length > 0) {
      return mediaUpload[0][key];
    }
    return null;
  }

  getDerivativeProperty(key) {
    const derivative = this.getMediaUploadProperty("derivative");
    if (derivative.length > 0) {
      return derivative[0][key];
    }
    return null;
  }

  setPosition(frame) {
    const framerate = this.getMediaUploadProperty("framerate");
    if (this.playerRef.current) {
      this.playerRef.current.player.currentTime(
        frameToSeconds(frame, framerate)
      );
    }
  }

  onTimeUpdate(frame: number) {
    this.setState({ currentFrame: frame });
  }

  handleSubmitMarker(markerIds) {
    for (let i = 0; i < markerIds.length; i++) {
      const researchGroupFilmMarker = {
        researchGroupFilm: this.props.researchGroupFilm,
        researchGroupMarkerSetType: markerIds[i],
        researchGroupMarkerSet: this.props.currentSchema.id,
        frameNo: this.playerRef.current
          ? secondsToFrames(
              this.playerRef.current.player.currentTime(),
              this.getMediaUploadProperty("framerate")
            )
          : 0,
      };
      this.props.addMarkerAction(researchGroupFilmMarker);
    }
  }

  handleDeleteMarker() {
    const { currentUser } = this.props;
    const { currentMarker } = this.state;
    if (currentUser.id === currentMarker.owner.id) {
      this.props.deleteMarkerAction(currentMarker.id);
    }
  }

  handleClickMarker(marker: Object): void {
    this.setState({ currentMarker: marker });
  }

  handleSelectSchema(schema) {
    this.props.setCurrentSchema(
      schema,
      this.props.researchGroupFilm.researchGroupId
    );
  }

  handleToggleInfo = () => {
    const { showInfo } = this.state;
    this.setState({ showInfo: !showInfo });
  };

  handleToggleFilter = () => {
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
  };

  handleToggleSchemaView = () => {
    const { showSchemaView } = this.state;
    this.setState({ showSchemaView: !showSchemaView });
  };

  setCurrentFilterValues(schemaId, values: Array<string>): void {
    const { researchGroupFilmMarkers } = this.props.researchGroupFilm;
    const { currentFilteredUserValues } = this.state;
    this.setState({
      currentFilterValues: values,
      filteredMarkerList: this.filterMarkers(
        researchGroupFilmMarkers,
        values,
        currentFilteredUserValues
      ),
    });
  }

  setShortcutLabels() {
    const { shortcuts, currentSchema } = this.props;
    if (currentSchema && shortcuts) {
      let labels = [];
      const { groups } = currentSchema;
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        const { color, markers } = group;
        for (let j = 0; j < markers.length; j++) {
          const marker = markers[j];
          const shortcut = shortcuts[marker.id];
          if (shortcut) {
            labels.push(
              <span
                key={marker.id}
                style={{ background: color }}
                className="mediate-marker-shortcut-label-detailed"
              >
                <span className="mediate-marker-shortcut-label-name">
                  {marker.name}
                </span>
                <span className="mediate-marker-shortcut-label-shortcut">
                  {shortcut}
                </span>
              </span>
            );
          }
        }
      }
      this.setState({ shortcutLabels: labels });
    }
  }

  generateFilterKey = (schema, filterValues, users) => {
    return `${schema.id}__${filterValues.join("_")}__${users.join("_")}`;
  };

  filterCollaborators(collabs) {
    const { researchGroupFilmMarkers } = this.props;
    const { currentFilterValues } = this.state;
    const values = collabs.map((c) => c.user.id);
    this.setState({
      currentFilteredUserValues: values,
      filteredMarkerList: this.filterMarkers(
        researchGroupFilmMarkers,
        currentFilterValues,
        values
      ),
    });
  }
  // TODO - optimize
  filterMarkers(markers, currentFilterValues, currentFilteredUserValues) {
    const { currentSchema, researchGroupFilm } = this.props;
    if (
      currentFilterValues.length === 0 &&
      researchGroupFilm.collaborators.length ===
        currentFilteredUserValues.length
    ) {
      return [];
    }
    if (!currentSchema) return [];
    // pre-filter based on current schema and inject colors
    const { id } = currentSchema;
    let filteredBySchema = this.filterMemo[id];
    if (!filteredBySchema) {
      filteredBySchema = [];
      const colors = currentSchema.groups.reduce((a, b) => {
        a[b.id] = b.color;
        return a;
      }, {});
      for (let i = 0; i < markers.length; i++) {
        const m = markers[i];
        // todo change to "schema" some day ...
        if (m.researchGroupMarkerSet === id) {
          const mGroups =
            m.researchGroupMarkerSetType.researchGroupMarkerSetGroup;
          for (let j = 0; j < mGroups.length; j++) {
            const color = colors[mGroups[j]];
            if (color) {
              m.color = color;
              break;
            }
          }
          filteredBySchema.push(m);
        }
      }
      this.filterMemo[id] = filteredBySchema;
    }
    const filterKey = this.generateFilterKey(
      currentSchema,
      currentFilterValues,
      currentFilteredUserValues
    );
    let filteredMarkerList = this.filterMemo[filterKey];
    if (!filteredMarkerList) {
      filteredMarkerList = filteredBySchema.filter(
        (m) =>
          currentFilterValues.includes(m.researchGroupMarkerSetType.id) &&
          currentFilteredUserValues.includes(m.owner.id)
      );
      this.filterMemo[filterKey] = filteredMarkerList;
    }
    return filteredMarkerList;
  }

  isPaused() {
    if (this.playerRef.current) {
      return this.playerRef.current.player.paused();
    }
    return true;
  }

  render() {
    // TODO this is pretty messy --- should probably clean it up
    const {
      markerStatus,
      currentMarkerTypes,
      setCurrentMarkerTypes,
      saveShortcut,
      shortcuts,
      currentSchema,
      currentUser,
      researchGroupSchema,
      researchGroupFilm,
      presentationMode,
    } = this.props;

    const {
      currentFrame,
      currentFilterValues,
      showInfo,
      showFilter,
      showSchemaView,
      filteredMarkerList,
      shortcutLabels,
    } = this.state;
    const framerate = this.getMediaUploadProperty("framerate") || 29.97;
    const { researchGroupFilmMarkers } = this.props.researchGroupFilm;
    const isPaused = this.isPaused();
    const plugins = [
      {
        plugin: nleControls,
        name: "nleControls",
        options: {
          framerate: framerate,
          smpteTimecode: true,
          frameControls: true,
          duration: frameToSeconds(this.getMediaUploadProperty("duration")),
        },
      },
    ];
    let filterOptions = markerSetToFormOptions(
      currentSchema ? currentSchema.groups : []
    );
    const captionsUrls = this.getDerivativeProperty("captionsUrls");
    const source = [
      {
        src: this.getDerivativeProperty("mp4Url"),
        type: "video/mp4",
        framerate: framerate,
      },
      {
        src: this.getDerivativeProperty("webmUrl"),
        type: "video/webm",
        framerate: framerate,
      },
    ];
    let textTracks = [];
    if (captionsUrls.length > 0) {
      textTracks = captionsUrls.map((captions) => ({
        kind: "captions",
        label: "en",
        src: `${backendUrl}/${captions}`,
      }));
    }
    const uiInfo = [
      {
        label: "Selected Markers",
        value:
          currentMarkerTypes.length > 0 ? (
            <div className="mediate-ui-info-current-marker-types-list">
              {currentMarkerTypes.map((m) => (
                <span
                  key={m.id}
                  className="mediate-schema-active-marker"
                  style={{ background: m.color }}
                >
                  <MediateMarkerLabel marker={m} />
                </span>
              ))}
            </div>
          ) : (
            <div className="mediate-ui-info-current-marker-types-list">
              None
            </div>
          ),
      },
      {
        label: "Shortcuts",
        value: (
          <div className="mediate-marker-shortcut-labels-container">
            {shortcutLabels}
          </div>
        ),
      },
    ];
    return (
      <div className="mediate-ui-container">
        {presentationMode ? (
          <MediateAnnotationLink researchGroupFilm={researchGroupFilm} />
        ) : null}
        {!presentationMode ? (
          <MediateMarkerMenu
            filterOptions={filterOptions}
            markerTypes={currentSchema ? currentSchema.markerTypes : []}
            handleSubmitMarker={this.handleSubmitMarker}
            updateMarkerAction={this.props.updateMarkerAction}
            handleDeleteMarker={this.handleDeleteMarker}
            handleSelectSchema={this.handleSelectSchema}
            currentMarkerTypes={currentMarkerTypes}
            setCurrentMarkerTypes={setCurrentMarkerTypes}
            schema={researchGroupSchema}
            currentSchema={currentSchema}
            shortcuts={shortcuts}
            onToggleInfo={this.handleToggleInfo}
            onToggleFilter={this.handleToggleFilter}
            onToggleSchemaView={this.handleToggleSchemaView}
            markerTypeSetId={currentSchema ? currentSchema.id : null}
          />
        ) : null}
        <Sidebar
          animation="push"
          visible={showFilter}
          className="mediate-schema-filter-container"
        >
          <MediateSchemaFilter
            schema={currentSchema}
            collaborators={researchGroupFilm.collaborators}
            onFilter={this.setCurrentFilterValues}
            onFilterCollaborators={this.filterCollaborators}
          />
        </Sidebar>
        <div
          className={`mediate-schema-sidebar-dimmer${
            showSchemaView ? " active" : ""
          }`}
          onClick={this.handleToggleSchemaView}
        />
        <MediateSchemaSidebar
          schema={currentSchema}
          visible={showSchemaView}
          onActiveMarkersChange={setCurrentMarkerTypes}
          saveShortcut={saveShortcut}
          onClose={this.handleToggleSchemaView}
          shortcuts={shortcuts}
        />
        <Sidebar.Pusher className="mediate-main-container">
          <MediateMarkerList
            currentFilterValues={currentFilterValues}
            filterOptions={filterOptions}
            filteredMarkerList={filteredMarkerList}
            markerTypes={researchGroupSchema}
            placeholderText="Filter Markers"
            markers={researchGroupFilmMarkers}
            framerate={framerate}
            currentFrame={currentFrame}
            handleDeleteMarker={this.handleDeleteMarker}
            handleClickMarker={this.handleClickMarker}
            updateMarkerAction={this.props.updateMarkerAction}
            setPosition={this.setPosition}
            currentUser={currentUser}
            scrollBehavior={
              isPaused ? SCROLL_BEHAVIOR.NEAREST : SCROLL_BEHAVIOR.EXACT
            }
          />
          <div className="mediate-video-container">
            <div className="video-player-container">
              <VideoPlayer
                ref={this.playerRef}
                src={source}
                textTracks={textTracks}
                playerOptions={playerOptions}
                plugins={plugins}
                seekBarImages={{
                  slitscan: this.getDerivativeProperty("slitscan"),
                  waveform: this.getDerivativeProperty("waveform"),
                }}
                onTimeUpdate={this.onTimeUpdate}
                mediaType={this.getMediaUploadProperty("originalMediaType")}
              />
            </div>
            <MarkerStatus markerStatus={markerStatus} framerate={framerate} />
            <MediateUIInfo info={uiInfo} visible={showInfo} />
          </div>
        </Sidebar.Pusher>
      </div>
    );
  }
}
