import { cloneDeep, set, get, differenceWith } from "lodash";
import { walkDeep } from "./Application";

export function deleteElementImmutable(key, val, arr) {
  const cloned = cloneDeep(arr);
  const index = findNestedIndex(key, val, cloned);
  if (index > -1) {
    cloned.splice(index, 1);
  }
  return cloned;
}

export function findNestedIndex(key, val, arr) {
  return arr.findIndex((elem) => {
    const value = get(elem, key);
    return value === val;
  });
}

export function updateElementImmutable(key, val, arr, path, element) {
  const cloned = cloneDeep(arr);
  const index = findNestedIndex(key, val, cloned);
  if (index > -1) {
    set(cloned[index], path, element);
  }
  return cloned;
}

export function updateArrayImmutable(key, val, arr, element) {
  const cloned = cloneDeep(arr);
  const index = findNestedIndex(key, val, cloned);
  if (index > -1) {
    cloned.splice(index, 1, element);
  }
  return cloned;
}

export function updateArrayOrAdd(key, val, arr, element) {
  const cloned = cloneDeep(arr);
  const index = findNestedIndex(key, val, cloned);
  if (index > -1) {
    cloned.splice(index, 1, element);
  } else {
    cloned.push(element);
  }
  return cloned;
}

export function deletedElements(oldArr, newArr, key, deleteFlag = null) {
  let deleted = differenceWith(oldArr, newArr, (oldVal, newVal) => {
    if (!newVal) return false;
    return oldVal[key] === newVal[key];
  });
  const notDeleted = differenceWith(oldArr, deleted, (oldVal, newVal) => {
    if (!newVal) return false;
    return oldVal[key] === newVal[key];
  });
  if (deleteFlag) {
    deleted = walkDeep(deleted, (elem) => { 
      elem[deleteFlag] = true;
      return elem; 
    });
  }
  return [deleted, notDeleted];
}

export function notIn(val, key, arr) {
  return arr.findIndex((elem) => elem[key] === val) === -1;
}

export const getRandomElement = (elements) => {
  return elements[Math.floor(Math.random() * elements.length)];
}