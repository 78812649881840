import React from "react";
import { Segment, Icon } from "semantic-ui-react";
import { UserCardModal } from "../UserCard";
import ConfirmDelete from "./ConfirmDelete";
import BasicAdminAssetDisplay from "./BasicAdminAssetDisplay";
import ToggleIcon from "../ToggleIcon";

export default function ProjectCollaboratorDisplay(props) {
  const {
    collaborators,
    onAddCollaborator,
    onDeleteCollaborator,
    onUpdateCollaborator,
    project,
  } = props;

  const handleUpdateCollaborator = (id, key, val) => {
    onUpdateCollaborator(id, { [key]: val });
  };

  const renderCell = (collaborator) => {
    return (
      <UserCardModal
        key={collaborator.id}
        user={collaborator.user}
        actions={[
          <ToggleIcon
            key="actions-0"
            size="small"
            initialVal={collaborator.privateAnnotations}
            val={collaborator.privateAnnotations}
            onIcon="eye slash"
            offIcon="eye"
            onColor="grey"
            offColor="teal"
            onLabel="Private"
            offLabel="Public"
            onClick={(event, val) =>
              handleUpdateCollaborator(
                collaborator.id,
                "privateAnnotations",
                val
              )
            }
          />,
          <ConfirmDelete
            key="actions-1"
            deleteKey={collaborator.id}
            onDelete={onDeleteCollaborator}
            deleteButton={
              <Icon
                className="mediate-collaborator-options-button"
                name="close"
                color="red"
                size="large"
              />
            }
            title={`${collaborator.user.username} from ${project.name}`}
          />,
        ]}
      />
    );
  };
  return (
    <Segment raised className="mediate-collaborator-display-container">
      <BasicAdminAssetDisplay
        title="Collaborators"
        data={collaborators}
        onAdd={onAddCollaborator}
        data={collaborators}
        columns={1}
        renderCell={renderCell}
        filterKey="user.username"
      />
    </Segment>
  );
}
