import React, { Component } from "react";
import { loadSchemaById } from "../actions/PublicPageActions";
import { connect } from "react-redux";
import { DetailedSchemaDisplay } from "../componets/admin/SchemaDisplay";
import PageLoader from "../componets/Loading";
/**
 * Allows for Read-Only viewing of a schema and its contents
 */
class SchemaContainer extends Component {
  componentDidMount() {
    const { schemaId, loadSchemaById } = this.props;
    if (schemaId) {
      loadSchemaById(schemaId);
    }
  }
  render() {
    const { schema } = this.props;
    if (schema) {
      return <DetailedSchemaDisplay currentSchema={schema} readOnly={true} />;
    } else {
      return <PageLoader text="Loading Schema ..." />;
    }
  }
}

function mapStateToProps(state, ownProps) {
    return {
        schemaId: ownProps.match.params.schemaId,
        schema: state.publicPage.schema
    }
}

export default connect(mapStateToProps, { loadSchemaById })(SchemaContainer);
