import React from "react";
import LiveSearch from "./LiveSearch";
import { SEARCH_KEYS } from "../../constants/Application";
import DynamicGrid from "../DynamicGrid";
import { UserCardModal } from "../UserCard";
import { Button, Icon } from "semantic-ui-react";
export default function SearchUsers(props) {
  const { onSelectResult } = props;
  return (
    <LiveSearch
      searchLabel="Search By Username or Email"
      searchKey={SEARCH_KEYS.USERS}
      resultsDisplay={UserResultsDisplay}
      onSelectResult={onSelectResult}
    />
  );
}

export function UserResultsDisplay(props) {
  const { results, onSelectResult } = props;
  return (
    <DynamicGrid
      divided={true}
      columns={3}
      className="mediate-search-user-results-display"
      data={results}
      renderCell={UserResultDisplay}
      onSelectCell={onSelectResult}
    />
  );
}

export function UserResultDisplay(props) {
  const { onSelect, onSelectButton, id } = props;
  const actions = [];
  if (onSelectButton) {
    actions.push(onSelectButton);
  } else {
    if (onSelect) {
      actions.push(
        <Button
          key="actions-0"
          compact
          size="tiny"
          icon
          labelPosition="right"
          onClick={() => onSelect(id)}
          color="blue"
        >
          <Icon name="plus" />
          Add
        </Button>
      );
    }
  }
  return <UserCardModal user={props} modalActions={actions} />;
}
