import React, { Component, useState, useEffect } from "react";
import { Icon, Form, List, Popup, Transition } from "semantic-ui-react";
import { SketchPicker } from "react-color";
import {
  DraggableAdminMarkerLabel,
  ReadOnlyAdminMarkerLabel,
} from "./AdminMarkerLabel";
import SimpleBar from "simplebar-react";
import ConfirmDelete from "./ConfirmDelete";
import { useDrop } from "react-dnd";
import { MARKER_SELECT } from "../../constants/ItemTypes";
import { createTempId, getErrorForField } from "../../utils/Application";
import sleep from "../../utils/sleep";

const TEMP_ID_PREFIX = "marker_temp_";
const tempId = createTempId(TEMP_ID_PREFIX);
const DEFAULT_MARKER = {
  name: "",
  description: "",
  id: tempId(),
  public: false,
};

function ColorPickerPopUp(props) {
  const { onPickColor, color } = props;
  return (
    <Popup
      on="click"
      hoverable={false}
      trigger={
        <div
          className="mediate-color-picker-popup-trigger"
          style={{ background: color }}
        ></div>
      }
      content={
        <SketchPicker
          color={color}
          onChangeComplete={(c) => onPickColor(c.hex)}
        />
      }
    />
  );
}

class SchemaGroup extends Component {
  state = {
    name: "",
    markers: [],
    color: "#fff",
    activeMarkerIdx: 0,
  };

  handleFormChange = (key, value) => {
    const { onUpdate } = this.props;
    // controlled
    if (onUpdate) {
      onUpdate(key, value);
    } else {
      this.setState({ [key]: value });
    }
  };

  handleAddMarker = () => {
    const { onUpdate } = this.props;
    let markers;
    const m = { ...DEFAULT_MARKER, id: tempId() };
    if (onUpdate) {
      markers = [...this.props.markers];
      markers.push(m);
      onUpdate("markers", markers);
    } else {
      markers = [...this.state.markers];
      markers.push(m);
      this.setState({ markers: markers });
    }
  };

  handleUpdateMarker = (idx, marker) => {
    const { onUpdate } = this.props;
    if (onUpdate) {
      const markers = [...this.props.markers];
      markers.splice(idx, 1, marker);
      onUpdate("markers", markers);
    } else {
      const markers = [...this.state.markers];
      markers.splice(idx, 1, marker);
      this.setState({ markers: markers });
    }
  };

  handleDeleteMarker = (idx) => {
    const { onUpdate } = this.props;
    if (onUpdate) {
      const markers = [...this.props.markers];
      markers.splice(idx, 1);
      onUpdate("markers", markers);
    } else {
      const markers = [...this.state.markers];
      markers.splice(idx, 1);
      this.setState({ markers: markers });
    }
  };

  setActiveMarkerIdx = (idx) => {
    this.setState({ activeMarkerIdx: idx });
  };

  componentDidMount() {
    const { currentGroup } = this.props;
    if (currentGroup) {
      this.setState({ ...currentGroup });
    }
  }

  render() {
    const {
      name,
      id,
      color,
      markers,
      readOnly,
      onDelete,
      onMarkerDragBegin,
      onMarkerDragEnd,
      compact,
      activity,
      user,
      errors
    } = this.props;
    const { activeMarkerIdx } = this.state;
    const deleteButton = (
      <ConfirmDelete
        title={`${name} and its ${markers.length} markers`}
        onDelete={onDelete}
        style={{ visibility: readOnly ? "hidden" : "visibile" }}
        deleteButton={
          <Icon name="close" className="schema-group-delete-button" />
        }
      />
    );
    const activityDisplay = (
      <Transition.Group
        as="span"
        className="schema-group-activity"
        animation="fade"
        duration={500}
      >
        <div className="schema-group-markers-count">
          <Icon name="tags" size="small" />
          {markers.length}
        </div>
        {activity}
      </Transition.Group>
    );
    if (compact) {
      return (
        <Form key={id} className="schema-group compact">
          {deleteButton}
          <Form.Field className="schema-group-settings">
            <ColorPickerPopUp
              onPickColor={(c) => this.handleFormChange("color", c)}
              color={color}
            />
            <Form.Input
              disabled={readOnly}
              className="schema-group-name-field"
              transparent
              value={name}
              placeholder="Group Name ..."
              onChange={(event, { value }) =>
                this.handleFormChange("name", value)
              }
            />
          </Form.Field>
          {activityDisplay}
        </Form>
      );
    } else {
      return (
        <Form key={id} className="schema-group">
          {deleteButton}
          <Form.Field className="schema-group-settings">
            <ColorPickerPopUp
              onPickColor={(c) => this.handleFormChange("color", c)}
              color={color}
            />
            <Form.Input
              disabled={readOnly}
              className={`schema-group-name-field ${getErrorForField(errors, "name") ? " has-error" : ""}`}
              transparent
              value={name}
              placeholder="Group Name ..."
              onChange={(event, { value }) =>
                this.handleFormChange("name", value)
              }
            />
          </Form.Field>
          {activityDisplay}
          <List as={SimpleBar} className="schema-group-marker-list">
            {markers.map((marker, idx) => (
              <List.Item key={marker.id || `${marker.name}-${idx}`}>
                <List.Content onClick={() => this.setActiveMarkerIdx(idx)}>
                  {!marker.owner || user.id === marker.owner.id ? (
                    <DraggableAdminMarkerLabel
                      onBegin={() => onMarkerDragBegin()}
                      onEnd={() => onMarkerDragEnd()}
                      active={idx === activeMarkerIdx}
                      color={color}
                      group={{ id: id, name: name }}
                      name={marker.name}
                      id={marker.id}
                      description={marker.description}
                      onDelete={() => this.handleDeleteMarker(idx)}
                      onUpdate={(m) => this.handleUpdateMarker(idx, m)}
                      errors={getErrorForField(errors, `markers[${idx}]`)}
                    />
                  ) : (
                    <ReadOnlyAdminMarkerLabel
                      color={color}
                      {...marker}
                      extraContent={
                        <span className="mediate-admin-marker-label-read-only-actions">
                          <Icon name="lock" />
                          <ConfirmDelete
                            deleteButton={
                              <Icon name="close" />
                            }
                            deleteKey={null}
                            title={`${marker.name} from ${name}`}
                            onDelete={() => this.handleDeleteMarker(idx)}
                          />
                        </span>
                      }
                    />
                  )}
                </List.Content>
              </List.Item>
            ))}
          </List>
          <div
            className="schema-group-add-marker-button"
            style={{ background: color }}
            onClick={this.handleAddMarker}
          >
            <div className="schema-group-add-marker-button-text">
              Add Marker
              <Icon name="plus" size="small" />
            </div>
          </div>
        </Form>
      );
    }
  }
}

export class ReadOnlySchemaGroup extends SchemaGroup {
  render() {
    const { name, markers, color, markerActions, shortcuts } = this.props;
    return (
      <Form className="schema-group read-only">
        <Form.Field className="schema-group-settings">
          <div
            className="schema-group-read-only-color-display"
            style={{
              background: color,
            }}
          />
          <Form.Input
            disabled
            className="schema-group-name-field"
            transparent
            value={name}
          />
        </Form.Field>
        <div className="schema-group-markers-count">
          <Icon name="tags" size="small" />
          {markers.length}
        </div>
        <List as={SimpleBar} className="schema-group-marker-list">
          {markers.map((marker) => {
            let shortcutLabel = null;
            if (shortcuts && shortcuts[marker.id]) {
              shortcutLabel = (
                <span className="mediate-marker-shortcut-label">
                  {shortcuts[marker.id]}
                </span>
              );
            }
            return (
              <List.Item key={marker.id}>
                <ReadOnlyAdminMarkerLabel
                  color={color}
                  actions={markerActions}
                  extraContent={shortcutLabel}
                  {...marker}
                />
              </List.Item>
            );
          })}
        </List>
      </Form>
    );
  }
}

export function DroppableSchemaGroup(props) {
  const { onDropMarker, ...rest } = props;
  const [activity, setActivity] = useState(null);
  useEffect(() => {
    if (activity) {
      sleep(1000).then(() => setActivity(null));
    }
  }, [activity]);
  const onDrop = (item) => {
    const { marker } = item;
    const { id } = props;
    if (marker.group) {
      if (marker.group.id !== id) {
        onDropMarker({ ...marker }, marker.group.id, id);
      }
    } else {
      onDropMarker({ ...marker }, null, id);
    }
    const droppedStatus = (
      <div className="mediate-marker-drop-activity">{`+ ${marker.name}`}</div>
    );
    setActivity(droppedStatus);
  };
  const [collectedProps, drop] = useDrop({
    accept: MARKER_SELECT,
    drop: onDrop,
  });
  return (
    <div ref={drop} className="mediate-droppable-schema-group">
      <SchemaGroup {...rest} activity={activity} />
    </div>
  );
}
