// Fetch
import "whatwg-fetch";

// Cookies
import Cookies from "js-cookie";

// Backend
import backendUrl, { WS_BACKEND_HTTP_URL } from "../constants/Backend";
// Response handler needs to be a promise

function handleResponse(json, responseHandler) {
  if (responseHandler) {
    return responseHandler(json);
  } else {
    return Promise.resolve(json);
  }
}

function getContentType(body) {
  if (!body) {
    return "application/x-www-form-urlencoded";
  }
  switch (body.constructor.name) {
    case "FormData":
      /* can't set content type to multipart/form-data, but for some reason
       * not setting content type works with FormData ...
       */
      return null;
    case "String":
      try {
        JSON.parse(body);
        return "application/json";
      } catch (error) {
        return "application/x-www-form-urlencoded";
      }
    default:
      return "application/x-www-form-urlencoded";
  }
}

export function doPost(myUrl, body, responseHandler, postToWsServer = false) {
  const csrftoken = Cookies.get("csrftoken");
  const headers = {
    "X-CSRFToken": csrftoken,
  };
  const contentType = getContentType(body);
  if (contentType !== null) {
    headers["Content-Type"] = contentType;
  }
  const endpoint = postToWsServer ? WS_BACKEND_HTTP_URL : backendUrl;
  return fetch(endpoint + myUrl, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: body,
  })
    .then((response) => {
      if (response.status >= 400) {
        return Promise.reject({
          responseStatus: response.status,
          // in case response isn't actually json
          message: response.json(),
        });
      } else {
        return response.json();
      }
    })
    .then((json) => handleResponse(json, responseHandler))
    .catch((error) => {
      throw error;
    });
}

export function doUpdate(myUrl, body, responseHandler, partial = false) {
  const csrftoken = Cookies.get("csrftoken");
  const headers = {
    "X-CSRFToken": csrftoken,
  };
  const contentType = getContentType(body);
  if (contentType !== null) {
    headers["Content-Type"] = contentType;
  }
  return fetch(backendUrl + myUrl, {
    method: partial ? "PATCH" : "PUT",
    headers: headers,
    credentials: "include",
    body: body,
  })
    .then((response) => {
      if (response.status >= 400) {
        return Promise.reject({
          responseStatus: response.status,
          message: response.json(),
        });
      } else {
        return response.json();
      }
    })
    .then((json) => handleResponse(json, responseHandler))
    .catch((error) => {
      throw error;
    });
}

export function doDelete(myUrl, responseHandler) {
  const csrftoken = Cookies.get("csrftoken");
  return fetch(backendUrl + myUrl, {
    method: "DELETE",
    headers: {
      "X-CSRFToken": csrftoken,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    credentials: "include",
  })
    .then((response) => Promise.resolve({ responseStatus: response.status }))
    .then((json) => handleResponse(json, responseHandler))
    .catch((error) => console.log(error));
}

export function doGet(myUrl, responseHandler) {
  return fetch(backendUrl + myUrl, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => response.json())
    .then((json) => handleResponse(json, responseHandler))
    .catch((error) => console.log(error));
}

export function doUpload(
  url,
  body,
  responseHandler,
  onProgress,
  update = false
) {
  const req = new XMLHttpRequest();
  const cancel = () => {
    req.addEventListener("abort", (e) => {
      Promise.resolve({
        responseStatus: req.status,
        message: "Upload cancelled by user",
      });
    });
    req.abort();
  };
  const task = new Promise((resolve, reject) => {
    const csrftoken = Cookies.get("csrftoken");
    req.withCredentials = true;
    const method = update ? "PUT" : "POST";
    req.open(method, backendUrl + url);
    req.setRequestHeader("X-CSRFToken", csrftoken);
    req.upload.addEventListener("progress", (e) => {
      if (onProgress) {
        onProgress((e.loaded / e.total) * 100);
      }
    });
    req.addEventListener("abort", (e) => {
      reject({
        responseStatus: req.status,
        message: "Upload cancelled by user",
      });
    });
    req.addEventListener("readystatechange", (e) => {
      const { response, readyState } = req;
      if (readyState === 4) {
        if (req.status >= 400) {
          let msg;
          try {
            msg = JSON.parse(response);
          } catch (error) {
            msg =
              "Encountered an error after uploading the file to the Mediate server. Please contact your admin if this error persists.";
          }
          return reject({
            responseStatus: req.status,
            message: msg,
          });
        } else {
          // cancelled - let abort listener handle this
          if (req.status !== 0) {
            const resp = JSON.parse(response);
            handleResponse(resp, responseHandler);
            return resolve(resp);
          }
        }
      }
    });
    req.addEventListener("error", (e) =>
      reject({ responseStatus: req.status, message: e.message })
    );
    req.send(body);
  });
  return [task, cancel];
}
// SENDS MESSAGE DIRECTLY TO WS SERVER
export function sendMessage(group, message) {
  const body = new FormData();
  body.set("group", group);
  body.set("message", message);
  return doPost("/message-broker/", body, undefined, true);
}
// SENDS MESSAGE TO CELERY WORKER -> WS SERVER
export function sendCeleryMessage(group, message) {
  const body = new FormData();
  body.set("group", group);
  body.set("message", message);
  return doPost("/annotate/send-celery-message/", body);
}

// TODO fix this for nested arrays
export function getServerErrorMessage(message) {
  if (typeof message === "string") return `${message}\n`;
  let m = "";
  try {
    for (let key in message) {
      const val = message[key];
      if (val.constructor === Array) {
        m += val.map((v) => getServerErrorMessage(v)).join("\n");
      } else {
        m += `\n ${key}: ${val}`;
      }
    }
    return m;
  } catch (error) {
    return "There was an error processing the request.";
  }
}
