import React, { Component } from "react";
import { connect } from "react-redux";

export const UserContext = React.createContext();
class User extends Component {
  render() {
    const { children, ...rest } = this.props;
    return (
      <UserContext.Provider
        value={rest}
      >
        {children}
      </UserContext.Provider>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.user,
  };
}

export default connect(mapStateToProps)(User);