import React, { useCallback, useEffect, useRef, useState } from "react";
import { Header, Transition, Divider } from "semantic-ui-react";
import ToggleIcon from "./ToggleIcon";
import SimpleBar from "simplebar-react";
import { get } from "lodash";

export function FilterList(props) {
  const {
    data,
    color,
    visible,
    needsUpdate,
    parentToggleVal,
    updateData,
    onFilter,
    labelKey,
  } = props;
  const [filteredData, setFilteredData] = useState(data);

  const findDataIndex = (data) => {
    return filteredData.findIndex((d) => data.id === d.id);
  };

  const handleFilterData = (data, visibility) => {
    setFilteredData((prevData) => {
      const updated = [...prevData];
      const idx = findDataIndex(data);
      if (idx > -1 && !visibility) {
        updated.splice(idx, 1);
      } else if (idx === -1 && visibility) {
        updated.push(data);
      }
      return updated;
    });
  };

  useEffect(() => {
    if (onFilter && !needsUpdate) {
      onFilter(filteredData);
    }
  }, [filteredData, needsUpdate]);

  useEffect(() => {
    if (needsUpdate && updateData) {
      setFilteredData(updateData);
    }
  }, [needsUpdate, updateData]);

  return (
    <div
      className={`schema-group-filter-list ${visible ? "show" : "hide"}`}
      style={{
        borderLeft: `3px solid ${color}`,
      }}
    >
      {data.map((d, idx) => (
        <div key={`${labelKey}-${idx}`} className="schema-group-filter-item">
          {get(d, labelKey)}
          <ToggleIcon
            initialVal={true}
            className="schema-filter-toggle-icon"
            compact
            offIcon="eye slash"
            onIcon="eye"
            offColor="grey"
            onColor="grey"
            size="small"
            val={needsUpdate ? parentToggleVal : undefined}
            onClick={(event, val) => handleFilterData(d, val)}
          />
        </div>
      ))}
    </div>
  );
}

function GroupFilterList(props) {
  const { id, color, markers, onGroupFiltered } = props;
  const [visible, setVisible] = useState(false);
  const [toggleVal, setToggleVal] = useState(true);
  const prevToggleVal = useRef(null);
  const [filteredMarkerTypes, setFilteredMarkerTypes] = useState(markers);

  const handleClick = () => {
    setVisible(!visible);
  };

  const handleToggle = (event, val) => {
    let updatedFilteredMarkerTypes;
    if (val) {
      updatedFilteredMarkerTypes = markers;
    } else {
      updatedFilteredMarkerTypes = [];
    }
    prevToggleVal.current = toggleVal;
    setToggleVal(val);
    setFilteredMarkerTypes(updatedFilteredMarkerTypes);
  };

  const handleMarkerTypesFiltered = (mt) => {
    setFilteredMarkerTypes(mt);
  };

  useEffect(() => {
    let toggle;
    if (filteredMarkerTypes.length === 0) {
      toggle = false;
    } else {
      toggle = true;
    }
    if (prevToggleVal.current !== toggle) {
      setToggleVal(toggle);
      prevToggleVal.current = toggle;
    }
    if (onGroupFiltered) {
      const updatedGroup = {
        id: id,
        markers: filteredMarkerTypes,
      };
      onGroupFiltered(updatedGroup);
    }
  }, [filteredMarkerTypes, prevToggleVal, setToggleVal]);

  return (
    <div className="schema-group-filter-container">
      <Header size="medium" className="schema-group-filter-header">
        <div
          className="schema-group-filter-color"
          onClick={handleClick}
          style={{ background: color }}
        />
        {props.name}
        <ToggleIcon
          initialVal={true}
          className="schema-group-filter-toggle-icon"
          compact
          offIcon="eye slash"
          onIcon="eye"
          offColor="grey"
          onColor="grey"
          size="mini"
          val={toggleVal}
          onClick={handleToggle}
        />
      </Header>
      <FilterList
        labelKey="name"
        visible={visible}
        parentToggleVal={toggleVal}
        needsUpdate={prevToggleVal.current !== toggleVal}
        data={markers}
        updateData={
          prevToggleVal.current !== toggleVal ? filteredMarkerTypes : null
        }
        color={color}
        onFilter={handleMarkerTypesFiltered}
      />
    </div>
  );
}

function CollabFilter(props) {
  const { collaborators, onFilter } = props;
  const [visible, setVisible] = useState(false);

  const handleClick = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  return (
    <div className="mediate-schema-filter-collaborators schema-group-filter-container">
      <Header size="medium" className="schema-group-filter-header">
        <div
          className="schema-group-filter-color"
          onClick={handleClick}
          style={{ background: "teal" }}
        />
        users
      </Header>
      <FilterList
        labelKey="user.username"
        data={collaborators}
        visible={visible}
        color="teal"
        onFilter={onFilter}
      />
    </div>
  );
}

export default function MediateSchemaFilter(props) {
  const { schema, collaborators, onFilter, onFilterCollaborators } = props;
  const [filteredGroups, setFilteredGroups] = useState(
    schema ? schema.groups : []
  );
  const findGroupIndex = (group) => {
    return filteredGroups.findIndex((g) => group.id === g.id);
  };
  const handleGroupFiltered = (group) => {
    setFilteredGroups((prevGroups) => {
      const updated = [...prevGroups];
      const idx = findGroupIndex(group);
      if (idx > -1) {
        if (group.markers.length > 0) {
          const g = schema.groups[idx];
          updated.splice(idx, 1, { ...g, ...group });
        } else {
          updated.splice(idx, 1);
        }
      } else if (idx === -1) {
        updated.push(group);
      }
      return updated;
    });
  };

  useEffect(() => {
    if (schema) {
      setFilteredGroups(schema.groups);
    }
  }, [schema]);

  useEffect(() => {
    if (onFilter) {
      // reduce to flat with ids
      const ret = filteredGroups.reduce(
        (a, b) => a.concat(b.markers.map((m) => m.id)),
        []
      );
      onFilter(schema && schema.id, ret);
    }
  }, [schema, onFilter, filteredGroups]);

  if (!schema) {
    return (
      <Header size="large" style={{ color: "#fff", padding: "10px" }}>
        No schema currently set. Please select one to get started!
      </Header>
    );
  }
  return (
    <SimpleBar>
      <div className="mediate-schema-filter-root">
        <Header size="medium" style={{ color: "#fff", padding: "10px" }}>
          {schema ? schema.name : "Schema"}
        </Header>
        {schema.groups.map((group) => (
          <GroupFilterList
            key={group.id}
            {...group}
            onGroupFiltered={handleGroupFiltered}
          />
        ))}
        <Divider />
        <Header size="medium" style={{ color: "#fff", padding: "10px" }}>
          Collaborators
        </Header>
        <CollabFilter
          collaborators={collaborators}
          onFilter={onFilterCollaborators}
        />
      </div>
    </SimpleBar>
  );
}
