import React, { Component } from "react";
import { Sidebar, Label, Segment, Icon } from "semantic-ui-react";
import { cancellableSleep } from "../../utils/sleep";
import lodash from "lodash";

export default class AdminNotification extends Component {
  state = { visible: false };
  constructor(props) {
    super(props);
    this.handleTimeout = this.handleTimeout.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
  }

  componentDidMount() {
    const { message } = this.props;
    if (message) {
      this.show();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { message } = this.props;
    const { visible } = this.state;
    if (message !== null && !prevState.visible && !visible) {
      this.show();
    }
  }

  handleTimeout() {
    const { timeout } = this.props;
    const [task, cancel] = cancellableSleep(timeout);
    this.setState({
      cancel: cancel,
    });
    task.then(this.hide);
  }

  show() {
    this.setState(
      {
        visible: true,
      },
      this.handleTimeout
    );
  }

  cancelTimeout = () => {
    const { cancel } = this.state;
    if (cancel) {
      cancel();
    }
  };

  hide(cancelTimeout = false) {
    const { onTimeout } = this.props;
    this.setState(
      {
        visible: false,
      },
      () => {
        if (cancelTimeout) this.cancelTimeout();
        if (onTimeout) onTimeout();
      }
    );
  }
  // TODO add ability to turn off update popups

  render() {
    const { message, children } = this.props;
    const { visible } = this.state;
    return (
      <Sidebar.Pushable className="mediate-admin-notification" as="div">
        <Sidebar
          className="mediate-admin-notification-sidebar"
          as={Segment}
          animation="overlay"
          direction="bottom"
          width="wide"
          raised
          visible={visible}
        >
          {message ? (
            <div className="mediate-admin-notification-container">
              <div className="mediate-admin-notification-header">
                <h2 className="mediate-admin-notification-title">
                  {message.title}
                </h2>
                <Icon
                  className="mediate-admin-notificaton-close-button"
                  name="caret down"
                  onClick={() => this.hide(true)}
                />
              </div>
              <p className="mediate-admin-notification-content">
                {message.content}
              </p>
              <div className="mediate-admin-notification-disable">
                Turn off real-time updates <Icon name="close" color="red" />
              </div>
            </div>
          ) : null}
        </Sidebar>
        <Sidebar.Pusher className="admin-notification-children">
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
