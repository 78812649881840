import React from "react";
import { Grid } from "semantic-ui-react";

export default function DynamicGrid(props) {
  const {
    columns,
    data,
    renderCell,
    className,
    onSelectCell,
    ...gridProps
  } = props;
  const layout = [];
  const total = data.length;
  const rows = Math.ceil(total / columns);
  let index = 0;
  for (let i = 0; i < rows; i++) {
    layout[i] = [];
    for (let j = 0; j < columns; j++, index++) {
      if (data[index] !== undefined) {
        layout[i][j] = data[index];
      }
    }
  }
  return (
    <Grid className={className} columns={columns} {...gridProps}>
        {layout.map((row, colIndex) => (
          <Grid.Row key={`grid-${colIndex}`}>
            {row.map((vals, rowIndex) => (
              <Grid.Column key={`grid-${colIndex}-${rowIndex}`}>
                {React.createElement(renderCell, {
                  ...vals,
                  onSelect: vals.onSelect ? vals.onSelect : onSelectCell,
                })}
              </Grid.Column>
            ))}
          </Grid.Row>
        ))}
    </Grid>
  );
}
