/* @flow */

// React
import React, { useCallback, useState } from "react";
import SimpleBar from "simplebar-react";
import ToggleIcon from "./ToggleIcon";
import { Tab } from "semantic-ui-react";

const MediateUIInfo = (props: Object) => {
  const { info, visible } = props;
  const [active, setActive] = useState(new Array(info.length).fill(true));
  const handleSetActive = useCallback(
    (idx, val) => {
      const updated = [...active];
      updated[idx] = val;
      setActive(updated);
    },
    [info, active]
  );
  const panes = info.map((i) => ({
    menuItem: i.label,
    render: () => (
      <Tab.Pane className="mediate-ui-info-pane">
        <SimpleBar
          className="mediate-ui-info-scroll"
          style={{ overflowX: "hidden" }}
        >
          {i.value}
        </SimpleBar>
      </Tab.Pane>
    ),
  }));
  return (
    <div className={`mediate-ui-info-container ${visible ? "show" : "hide"}`}>
      <Tab
        className="mediate-ui-info-tabs"
        menu={{
          borderless: true,
          tabular: false,
          attached: false,
          inverted: true,
        }}
        panes={panes}
      />
    </div>
  );
};

export default MediateUIInfo;
