import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './css/master.css';
import 'semantic-ui-css/semantic.min.css'
import 'simplebar/dist/simplebar.min.css'
ReactDOM.render(
    <App />,
    document.getElementById('root')
);
