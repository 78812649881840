import React, { useCallback, useState, useEffect } from "react";
import { Label, Image, Icon, Button } from "semantic-ui-react";
import ProjectOptions from "./ProjectOptions";
import { MediaResultDisplay } from "./AdminSearchMedia";
import SimpleBar from "simplebar-react";
import DynamicGrid from "../DynamicGrid";
import MediaUploadDisplay from "./MediaUploadDisplay";
import BasicAdminAssetDisplay from "./BasicAdminAssetDisplay";

const MediaDisplay = (props) => {
  const {
    overviewImage,
    title,
    basic,
    mediaUpload,
    markerCount,
    id,
    onDeleteMedia,
    inverted,
    onView,
    customButtons,
  } = props;
  const derivativeDuration =
    mediaUpload && mediaUpload.length > 0 ? (
      <Label className="mediate-project-film-duration mediate-admin-label-inverted">
        {mediaUpload[0].derivativeDuration
          ? mediaUpload[0].derivativeDuration
          : "Currently Processing File"}
      </Label>
    ) : null;
  if (basic) {
    return (
      <div className="mediate-project-film-display">
        <div className="mediate-project-film-display-text-container">
          <h5 className="mediate-project-film-title">{title}</h5>
        </div>
        <Image
          className="mediate-project-overview-image"
          src={overviewImage}
          fluid
        />
      </div>
    );
  } else {
    return (
      <div
        className={`mediate-project-asset-display ${
          inverted ? "inverted" : ""
        }`}
      >
        <div className="mediate-project-asset-display-text-container">
          <h3 className="mediate-project-asset-title">{title}</h3>
          <div className="mediate-project-asset-meta">
            <Label
              color={inverted ? "grey" : ""}
              className="mediate-project-film-marker-count"
            >
              <Icon name="tags" />
              {markerCount}
            </Label>
            {derivativeDuration}
          </div>
        </div>
        <Image
          className="mediate-project-overview-image"
          src={overviewImage}
          fluid
        />
        <ProjectOptions
          id={id}
          title={title}
          onDelete={onDeleteMedia}
          onView={onView}
          customButtons={customButtons}
        />
      </div>
    );
  }
};

export const ProjectMediaDisplay = (props) => {
  const { media, onAddMedia, onDeleteMedia } = props;
  return (
    <div className="mediate-project-media mediate-project-asset-container">
      <span className="mediate-project-asset-header">
        <h3 className="mediate-project-asset-title">Media ({media.length})</h3>
        <Button
          compact
          size="tiny"
          icon
          color="blue"
          labelPosition="right"
          onClick={onAddMedia}
        >
          <Icon name="plus" />
          Add
        </Button>
      </span>
      <SimpleBar className="mediate-project-asset-list">
        {media.map((rgf) => (
          <MediaDisplay
            key={rgf.id}
            {...rgf.film}
            id={rgf.id}
            markerCount={rgf.researchGroupFilmMarkerCount}
            onDeleteMedia={onDeleteMedia}
          />
        ))}
      </SimpleBar>
    </div>
  );
};

export const BasicMediaDisplay = (props) => {
  const {
    media,
    onAddMedia,
    onDeleteMedia,
    onEditMedia,
    onSelectButton,
    onSelect,
    columns,
    inverted,
  } = props;
  const initialMediaDisplayState = media.map((m) => ({
    id: m.id,
    showModal: false,
  }));
  const [mediaDisplayState, setShowResultModal] = useState(
    initialMediaDisplayState
  );
  useEffect(() => {
    setShowResultModal(
      media.map((m) => ({
        id: m.id,
        showModal: false,
      }))
    );
  }, [media]);

  const handleToggleModal = useCallback(
    (id, val) =>
      setShowResultModal(
        mediaDisplayState.map((displayState) => {
          if (displayState.id === id) {
            displayState.showModal = val;
          }
          return displayState;
        })
      ),
    [media, mediaDisplayState]
  );
  const handleDelete = useCallback(
    (id) => {
      onDeleteMedia(id);
      handleToggleModal(id);
    },
    [media]
  );
  // TODO this needs refactoring to work with ../../utils/ModalActions
  const mediaDisplay = media.map((m, index) => {
    const { id, title } = m;
    const open =
      mediaDisplayState[index] !== undefined
        ? mediaDisplayState[index].showModal
        : false;
    let onSelectComponent;
    let handleSelect;
    if (onSelectButton) {
      onSelectComponent = React.createElement(onSelectButton, { ...m });
    } else {
      if (!onSelect) {
        onSelectComponent = (
          <ProjectOptions
            id={id}
            title={title}
            onDelete={handleDelete}
            onEdit={() => {
              onEditMedia(m);
              handleToggleModal(m.id, false);
            }}
            basic
          />
        );
      } else {
        handleSelect = (id) => {
          onSelect(id);
          handleToggleModal(id, false);
        };
      }
    }
    const props = {
      open: open,
      onSelectButton: onSelectComponent,
      onSelect: handleSelect,
      onToggleModal: handleToggleModal,
    };
    return { ...m, ...props };
  });
  return (
    <BasicAdminAssetDisplay
      title="Media"
      columns={columns}
      onAdd={onAddMedia}
      extraHeaderContent={<MediaUploadDisplay />}
      data={mediaDisplay}
      renderCell={MediaResultDisplay}
      filterKey="title"
    />
  );
};

export default MediaDisplay;
