// React
import React, { Component, PropTypes } from "react";

// Stardust
import { Icon, Message } from "semantic-ui-react";

// Utils
import { frameToSeconds, toSMPTE } from "../timecodes/TimecodeConversions";

// constants
import {
  MARKER_STATUS_SAVED,
  MARKER_STATUS_FAILED,
  MARKER_STATUS_DELETED,
  MARKER_STATUS_PENDING
} from "../constants/Application";

// Classnames
const classNames = require("classnames");

const MarkerStatus = props => {
  let result = props.markerStatus.result;
  let saveStatus = props.markerStatus.saveStatus;
  let framerate = props.framerate;

  let content;
  let graphic;

  if (saveStatus === MARKER_STATUS_SAVED) {
    graphic = <Icon name="checkmark" color="green" bordered={false} />;
    content = (
      <h4>
        {result.researchGroupMarkerSetType.name +
          " marker saved at " +
          toSMPTE(
            frameToSeconds(result.filmTimeCode.frameNo, framerate),
            framerate
          )}
      </h4>
    );
  }

  if (saveStatus === MARKER_STATUS_FAILED) {
    graphic = <Icon name="warning sign" color="red" bordered={false} />;
    content = <h4>{result.displayError}</h4>;
  }

  if (saveStatus === MARKER_STATUS_DELETED) {
    graphic = <Icon name="checkmark" color="green" bordered={false} />;
    content = <h4>{"marker successfully deleted!"}</h4>;
  }

  return (
    <Message
      hidden={saveStatus === MARKER_STATUS_PENDING || saveStatus === null}
      className="marker-status"
      icon
    >
      {graphic}
      <Message.Content>{content}</Message.Content>
    </Message>
  );
};

export default MarkerStatus;
