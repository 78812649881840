// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";
import { verifyUser } from "../actions/AnnotateActions";
import { Message } from "semantic-ui-react";
import logo from "../images/logo.png";
import sleep from "../utils/sleep";
import { Redirect } from "react-router";

class VerifyAccountContainer extends Component {
  state = { redirect: false };
  componentDidMount() {
    const { verifyKey, verifyUser } = this.props;
    verifyUser(verifyKey);
  }

  componentDidUpdate() {
    if (this.props.verified && !this.state.redirect) {
      sleep(2000).then(() => {
        this.setState({ redirect: true });
      });
    }
  }

  render() {
    const { verifyUser, verifyKey, verified } = this.props;
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="hp-body-container">
        <div className="hp-overlay">
          <div className="hp-content">
            <div className="hp-logo-container">
              <img src={logo} className="hp-logo" />
            </div>
            <div className="mediate-verify-account-container">
              {!verified ? (
                <Message size="huge" className="mediate-verify-account-message">
                  <Message.Header>Verifying Your Account</Message.Header>
                  We are currently verifying your account. If you don't see a
                  confirmation, please contact us.
                </Message>
              ) : (
                <Message size="huge" className="mediate-verify-account-message">
                  <Message.Header>Account Verified</Message.Header>
                  Your account has been verified. You will be redirected to the
                  login page momentarily.
                </Message>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    verified: state.user.verified,
    verifyKey: ownProps.match.params.key,
  };
}

export default connect(mapStateToProps, { verifyUser })(VerifyAccountContainer);
