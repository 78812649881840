import React, { createContext, Component } from "react";

// Redux
import { connect } from "react-redux";

import { postAdminInfo, loadSchema, cancelMediaUpload } from "../../actions/AdminActions";

export const AdminContext = createContext({});

class AdminContextProvider extends Component {
  render() {
    const {
      media,
      schema,
      markers,
      uploads,
      children,
      loadSchema,
      postAdminInfo,
      cancelMediaUpload
    } = this.props;
    return (
      <AdminContext.Provider
        value={{
          media: media,
          schema: schema,
          markers: markers,
          uploads: uploads,
          actions: { loadSchema, postAdminInfo, cancelMediaUpload },
        }}
      >
        {children}
      </AdminContext.Provider>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    media: state.admin.media,
    schema: state.admin.schema,
    markers: state.admin.markers,
    uploads: state.admin.uploads,
  };
}

export default connect(mapStateToProps, { postAdminInfo, loadSchema, cancelMediaUpload })(
  AdminContextProvider
);
