import React, { Component } from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import logoIcon from "../images/logo-icon.png";

// history
import history from "../utils/history";

class Header extends Component {
  constructor(props) {
    super(props);
  }

  dashboardClick() {
    history.push("/dashboard");
  }

  signOutClick() {
    history.push("/logout");
  }

  getDataClick() {
    history.push("/data");
  }

  accountClick() {
    history.push("/admin/account");
  }

  adminClick() {
    history.push("/admin");
  }

  render() {
    const { loggedIn, compact } = this.props;
    const trigger = <img src={logoIcon} className="headshot" />;
    if (loggedIn === true) {
      return (
        <div className={`nav-container ${compact ? "compact" : ""}`}>
          <Dropdown
            trigger={trigger}
            icon={null}
            className="ui top right pointing dropdown button"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text="Dashboard"
                icon="user"
                onClick={this.dashboardClick}
              />
              <Dropdown.Item
                text="Admin"
                icon="chart bar"
                onClick={this.adminClick}
              />
              <Dropdown.Item
                text="Account"
                icon="settings"
                onClick={this.accountClick}
              />
              {
                <Dropdown.Item
                  text="Get Data"
                  icon="database"
                  onClick={this.getDataClick}
                />
              }
              <Dropdown.Divider />
              <Dropdown.Item
                text="Sign Out"
                icon="sign out"
                onClick={this.signOutClick}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Header;
