export const SHORTCUT_COUNT = 5;
export const SHORTCUT_KEY = 'mediate-shortcuts';
export const VIDEO_ICON = 'file video outline';
export const AUDIO_ICON = 'file audio outline';
export const MEDIA_TYPE_VIDEO = 'video';
export const MEDIA_TYPE_AUDIO = 'audio';
export const SLITSCAN = 'slitscan';
export const WAVEFORM = 'waveform';
export const SLITSCAN_LABEL = 'Show slitscan';
export const WAVEFORM_LABEL = 'Show waveform';
export const MARKER_STATUS_SAVED = 0;
export const MARKER_STATUS_DELETED = 1;
export const MARKER_STATUS_PENDING = 2;
export const MARKER_STATUS_FAILED = 3;
export const CURRENT_SCHEMA_KEY = "CURRENT_SCHEMA_KEY";
export const DEFAULT_GROUP_COLOR = "#7F7F7F";
export const ACCEPTED_MEDIA_TYPES = {
    MEDIA: "video/*, audio/*",
    CAPTIONS: ".vtt, .srt"
}

export const SEARCH_KEYS = {
    MEDIA: "MEDIA",
    SCHEMA: "SCHEMA",
    MARKERS: "MARKERS",
    USERS: "USERS",
}

export const SEARCH_MAP = {
    MEDIA: {
        model: "Film",
        attribute: "title"
    },
    USERS: {
        model: "User",
        attribute: "username+email"
    },
    MARKERS: {
        model: "ResearchGroupMarkerSetType",
        attribute: "name"
    },
    SCHEMA: {
        model: "ResearchGroupMarkerSet",
        attribute: "name"
    }
}

export const SCHEMA_KEYMAPPING = {
    researchGroupMarkerSetGroups: "groups",
    schema: "researchGroupMarkerSet",
    project: "researchGroup"
}

export const GROUP_KEYMAPPING = {
    researchGroupMarkerSet: "schema",
    researchGroupMarkerSetTypes: "markers"
}