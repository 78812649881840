import { NOTES_ACTIONS } from "../constants/Actions";

export function addNoteToMarker(researchGroupFilmMarker, noteText) {
  return {
    type: NOTES_ACTIONS.ADD_NOTE_TO_MARKER,
    noteText: noteText,
    researchGroupFilmMarker,
  };
}

export function updateNote(note) {
  return {
    type: NOTES_ACTIONS.UPDATE_NOTE,
    note,
  };
}

export function deleteNote(note) {
  return {
    type: NOTES_ACTIONS.DELETE_NOTE,
    note,
  };
}

export function clearNoteError() {
  return { type: NOTES_ACTIONS.CLEAR_NOTE_ERROR };
}

export function addCommentToNote(note, content) {
  return {
    type: NOTES_ACTIONS.ADD_COMMENT_TO_NOTE,
    note,
    content,
  };
}

export function addReplyToComment(comment, content) {
  return {
    type: NOTES_ACTIONS.ADD_REPLY_TO_COMMENT,
    comment,
    content,
  };
}

export function updateComment(comment) {
  return {
    type: NOTES_ACTIONS.UPDATE_COMMENT,
    comment,
  };
}

export function deleteComment(comment) {
  return {
    type: NOTES_ACTIONS.DELETE_COMMENT,
    comment,
  };
}
export function clearCommentError() {
  return { type: NOTES_ACTIONS.CLEAR_COMMENT_ERROR };
}
