import React, { Component, useCallback } from "react";
import {
  Button,
  Icon,
  Label,
  Sidebar,
  TransitionablePortal,
  Form,
  Card,
} from "semantic-ui-react";
import ToggleIcon from "../ToggleIcon";
import SimpleBar from "simplebar-react";
import DynamicGrid from "../DynamicGrid";
import { SchemaResultDisplay, SchemaUsageStats } from "./AdminSearchSchema";
import ProjectOptions from "./ProjectOptions";
import { DroppableSchemaGroup, ReadOnlySchemaGroup } from "./SchemaGroup";
import AdminSearchMarkers from "./AdminSearchMarkers";
import {
  createMergeObj,
  createTempId,
  removeTempIds,
  truncateText,
  validateObj,
  getErrorForField,
} from "../../utils/Application";
import {
  findNestedIndex,
  deleteElementImmutable,
  deletedElements,
} from "../../utils/ArrayUtils";
import TextAreaAutosize from "react-textarea-autosize";
import ScrollDrag, { SCROLL_DIRECTIONS } from "../ScrollDrag";
import { cloneDeep } from "lodash";
import { CircularAvatar } from "../UserCard";
import { Link } from "react-router-dom";
import { DEFAULT_GROUP_COLOR } from "../../constants/Application";
import BasicAdminAssetDisplay from "./BasicAdminAssetDisplay";

const TEMP_ID_PREFIX = "group_temp_";
const tempId = createTempId(TEMP_ID_PREFIX);
const DEFAULT_GROUP = {
  name: "Default",
  markers: [],
  color: DEFAULT_GROUP_COLOR,
  id: tempId(),
};

export const DEFAULT_SCHEMA = {
  name: "",
  description: "",
  groups: [DEFAULT_GROUP],
  public: false,
};

// no point in cloning schema you already own from within a project
function canCloneSchema(schema, project) {
  return !schema.researchGroup.includes(project.id);
}

export function ProjectSchemaDisplay(props) {
  const { schema, onAddSchema, onCloneSchema, onDeleteSchema, project } = props;
  return (
    <div className="mediate-project-schema mediate-project-asset-container">
      <span className="mediate-project-asset-header">
        <h3 className="mediate-project-asset-title">
          Schema ({schema.length})
        </h3>
        <Button
          compact
          size="tiny"
          icon
          color="blue"
          labelPosition="right"
          onClick={onAddSchema}
        >
          <Icon name="plus" />
          Add
        </Button>
      </span>
      <SimpleBar className="mediate-project-asset-list">
        {schema.map((s, idx) => (
          <SchemaDisplay
            {...s}
            key={idx}
            onDelete={onDeleteSchema}
            onClone={canCloneSchema(s, project) ? onCloneSchema : null}
          />
        ))}
      </SimpleBar>
    </div>
  );
}

export function SchemaDisplay(props) {
  const { onDelete, onClone, ...rest } = props;
  const {
    name,
    id,
    description,
    totalMarkerTypeCount,
    owner,
    createdDate,
  } = rest;
  return (
    <div className="mediate-project-asset-display">
      <div className="mediate-project-asset-display-text-container">
        <h3 className="mediate-project-asset-title">{name}</h3>
        <div className="mediate-project-asset-stats">
          <Link
            className="mediate-admin-link mediate-search-result-owner-link"
            target="_blank"
            to={`/admin/users/${owner.id}`}
          >
            <CircularAvatar
              size="small"
              title={owner.username}
              subtitle={owner.profile ? owner.profile.organization : null}
              image={owner.profile ? owner.profile.imageUrl : null}
              showTitle={false}
            />
          </Link>
          <Label
            title="total marker types"
            className="mediate-project-asset-count"
          >
            <Icon name="tags" />
            {totalMarkerTypeCount}
          </Label>
          <Label
            title="total annotations"
            className="mediate-project-asset-count"
          >
            <Icon name="pencil" />
          </Label>
        </div>
      </div>
      <div className="mediate-project-asset-meta">
        <div className="mediate-project-asset-description">
          {description && truncateText(description, 25)}
        </div>
        <h6 className="mediate-project-asset-created">
          Created on {new Date(createdDate).toLocaleDateString()}
        </h6>
      </div>
      <ProjectOptions
        id={id}
        title={name}
        onDuplicate={onClone ? () => onClone(rest) : null}
        onView={() => window.open(`/admin/schema/${id}`)}
        onDelete={() => onDelete(id)}
        advancedOptionNames={{ onDuplicate: "Copy to My Schema" }}
      />
    </div>
  );
}

export function BasicSchemaDisplay(props) {
  const { user, schema, columns, onAddSchema, actions, inverted } = props;
  const renderCell = (props) => {
    const p = { ...props, actions: actions, user: user };
    return <SchemaResultDisplay {...p} />;
  };
  return (
    <BasicAdminAssetDisplay
      renderCell={renderCell}
      data={schema}
      onAdd={onAddSchema}
      title="Schema"
      filterKey="name"
    />
  );
}

export class DetailedSchemaDisplay extends Component {
  state = {
    schema: { ...DEFAULT_SCHEMA },
    isMarkerDragging: false,
    showSearch: false,
    showInfo: true,
    showErrorMessage: false,
    problems: null,
  };

  componentDidMount() {
    const { currentSchema } = this.props;
    if (currentSchema) {
      this.hydrateSchema(currentSchema);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { schema } = this.state;
    const { currentSchema } = this.props;
    if (currentSchema && !prevProps.currentSchema) {
      this.hydrateSchema(currentSchema);
    }
    if (currentSchema && currentSchema.id !== schema.id) {
      this.hydrateSchema(currentSchema);
    }
  }

  hydrateSchema = (s) => {
    const cloned = cloneDeep(s);
    const { groups } = cloned;
    // ensure default is always first, otherwise sort by name
    const defaultIdx = groups.findIndex((g) => g.name.toLowerCase() === DEFAULT_GROUP.name.toLowerCase());
    [groups[0], groups[defaultIdx]] = [groups[defaultIdx], groups[0]];
    this.setState({ schema: cloned });
  };

  getDeletedGroups = (schema) => {
    const { currentSchema } = this.props;
    if (currentSchema) {
      const [deletedGroups, intactGroups] = deletedElements(
        currentSchema.groups,
        schema.groups,
        "id"
      );
      return deletedGroups;
    }
    return [];
  };

  validateSchema = (schema) => {
    const isTextFieldSet = (t) => t && t !== "";
    const fields = {
      name: isTextFieldSet,
      description: isTextFieldSet,
      groups: [
        {
          name: isTextFieldSet,
          markers: [{ name: isTextFieldSet, description: isTextFieldSet }],
        },
      ],
    };
    return validateObj(schema, fields);
  };

  handleSaveSchema = () => {
    const { onSave } = this.props;
    const { schema } = this.state;
    if (onSave) {
      const updated = removeTempIds(this.stripReadOnlySchemaProps(schema));
      const { problems, valid } = this.validateSchema(schema);
      if (valid) {
        const deletedGroups = this.getDeletedGroups(updated);
        if (this.state.problems) {
          this.setState({ problems: null, showErrorMessage: false });
        }
        onSave(updated, { groups: deletedGroups });
      } else {
        this.setState({
          problems: problems,
          showErrorMessage: true,
        });
        // show info if a name or description isn't set
        if (!problems.name.valid || !problems.description.valid) {
          this.handleToggleInfo(true);
        }
      }
    }
  };

  stripReadOnlySchemaProps = (s) => {
    const filtered = { id: s.id };
    for (let key in DEFAULT_SCHEMA) {
      filtered[key] = s[key];
    }
    return filtered;
  };

  handleMarkerDragState = (val) => {
    this.setState({ isMarkerDragging: val });
  };

  handleAddGroup = () => {
    const { schema } = this.state;
    const cloned = { ...schema };
    cloned.groups.push({ ...DEFAULT_GROUP, name: "", id: tempId() });
    this.setState({ schema: cloned });
  };

  handleFormChange = (key, value) => {
    const { toMerge, root } = createMergeObj(key, value);
    this.setState({ [root]: { ...this.state[root], ...toMerge } });
  };

  handleToggleInfo = (val = null) => {
    const { showInfo } = this.state;
    const newVal = val !== null ? val : !showInfo;
    this.setState({ showInfo: newVal });
  };

  handleToggleErrorMessage = (val) => {
    this.setState({ showErrorMessage: val });
  };

  handleToggleSearch = () => {
    const { showSearch } = this.state;
    this.setState({ showSearch: !showSearch });
  };

  handleSearchAnimationEnd = () => {
    const { compactGroups } = this.state;
    this.setState({ compactGroup: !compactGroups });
  };

  cloneGroups = () => {
    const { schema } = this.state;
    return cloneDeep(schema.groups);
  };

  handleDeleteGroup = (idx) => {
    const groups = this.cloneGroups();
    groups.splice(idx, 1);
    this.setState({ schema: { ...this.state.schema, groups: groups } });
  };

  handleUpdateGroup = (idx, key, value) => {
    const { groups } = this.state.schema;
    const group = groups[idx];
    groups[idx] = { ...group, ...{ [key]: value } };
    this.setState({ schema: { ...this.state.schema, groups: groups } });
  };
  // make swapMarker + addExistingMarker more DRY
  swapMarker = (marker, prevGroupIdx, nextGroupIdx) => {
    const { groups } = this.state.schema;
    const prevGroup = groups[prevGroupIdx];
    const nextGroup = groups[nextGroupIdx];
    const m = { ...marker, group: { name: nextGroup.name, id: nextGroup.id } };
    const nextMarkers = [...nextGroup.markers];
    const prevMarkers = [...prevGroup.markers];
    nextMarkers.push(m);
    this.handleUpdateGroup(nextGroupIdx, "markers", nextMarkers);
    this.handleUpdateGroup(
      prevGroupIdx,
      "markers",
      deleteElementImmutable("id", m.id, prevMarkers)
    );
  };

  addExistingMarker = (marker, nextGroupIdx) => {
    const { groups } = this.state.schema;
    const nextGroup = groups[nextGroupIdx];
    const m = { ...marker, group: { name: nextGroup.name, id: nextGroup.id } };
    const nextMarkers = [...nextGroup.markers];
    nextMarkers.push(m);
    this.handleUpdateGroup(nextGroupIdx, "markers", nextMarkers);
  };

  handleDropMarker = (marker, prevGroupId, nextGroupId) => {
    const { groups } = this.state.schema;
    const gi = findNestedIndex("id", nextGroupId, groups);
    const pgi = findNestedIndex("id", prevGroupId, groups);
    if (pgi > -1) {
      this.swapMarker(marker, pgi, gi);
    } else {
      this.addExistingMarker(marker, gi);
    }
  };

  renderMutable = () => {
    const {
      schema,
      showInfo,
      showSearch,
      isMarkerDragging,
      problems,
      showErrorMessage,
    } = this.state;
    const { currentSchema, user } = this.props;
    const isPublic = currentSchema ? currentSchema.public : schema.public;
    return (
      <Sidebar.Pushable className="mediate-schema-container">
        <Sidebar
          onHide={() => this.handleToggleInfo(false)}
          direction="right"
          animation="push"
          visible={showInfo}
          className="mediate-schema-info-container"
        >
          <Form.TextArea
            control={TextAreaAutosize}
            className={`mediate-schema-text-field ${getErrorForField(problems, "name") ? " has-error" : ""
              }`}
            id="mediate-schema-name-field"
            placeholder="Name ..."
            required
            value={schema.name}
            onChange={(event) =>
              this.handleFormChange("schema.name", event.target.value)
            }
          />
          <Form.TextArea
            control={TextAreaAutosize}
            className={`mediate-schema-text-field ${getErrorForField(problems, "description") ? " has-error" : ""
              }`}
            id="mediate-schema-description-field"
            placeholder="Description ..."
            required
            value={schema.description}
            onChange={(event) =>
              this.handleFormChange("schema.description", event.target.value)
            }
          />
          <div className="mediate-tab-form-toggle-public">
            <ToggleIcon
              size="large"
              onIcon="eye"
              offIcon="eye slash"
              onColor="green"
              offColor="red"
              onLabel="Public"
              offLabel="Private"
              initialVal={isPublic}
              onClick={(evt, val) =>
                this.handleFormChange("schema.public", val)
              }
            />
            <h4 className="mediate-tab-form-toggle-public-text">
              {schema.public
                ? "Anyone Can Add This Schema and its Markers to Their Projects"
                : "This Schema and its Markers Can Only be Added to Your Projects"}
            </h4>
          </div>
        </Sidebar>
        <Sidebar.Pusher
          dimmed={showInfo}
          className="mediate-schema-create-group"
        >
          <div className="mediate-schema-options-container">
            <div className="mediate-schema-group-options">
              <Button
                compact
                className="mediate-schema-add-group-button"
                onClick={this.handleAddGroup}
                icon
                labelPosition="right"
              >
                <Icon name="plus" />
                Add Group
              </Button>
            </div>
            <div className="mediate-schema-options">
              <Button
                compact
                className="mediate-schema-enable-search-button"
                onClick={this.handleToggleSearch}
                icon
                labelPosition="right"
              >
                <Icon name="search" />
                Search
              </Button>
              <Button
                onClick={this.handleSaveSchema}
                compact
                className="mediate-schema-save-button"
                size="medium"
                icon
                labelPosition="right"
                color="blue"
              >
                <Icon name="save" />
                Save
              </Button>
              <Button
                compact
                className="mediate-schema-info-trigger"
                size="medium"
                icon
                labelPosition="right"
                onClick={this.handleToggleInfo}
                color="teal"
              >
                <Icon name="info circle" />
                Info
              </Button>
            </div>
          </div>
          <div
            onTransitionEnd={this.handleSearchAnimationEnd}
            className={`mediate-marker-search-container ${showSearch ? "with-search" : ""
              }`}
          >
            <AdminSearchMarkers
              key={"admin-search-markers"}
              visible={showSearch}
            />
          </div>
          {/* TODO may have to disable transition animations for groups that aren't visible */}
          <ScrollDrag
            disableDrag={isMarkerDragging}
            direction={SCROLL_DIRECTIONS.HORIZONTAL}
            style={{ overflowY: "hidden", overflowX: "scroll" }}
            className={`schema-group-container ${showSearch ? "compact" : ""}`}
          >
            {schema.groups.map((g, idx) => (
              <DroppableSchemaGroup
                compact={showSearch ? true : false}
                onMarkerDragBegin={() => this.handleMarkerDragState(true)}
                onMarkerDragEnd={() => this.handleMarkerDragState(false)}
                onDropMarker={this.handleDropMarker}
                name={g.name}
                id={g.id}
                color={g.color}
                markers={g.markers}
                key={g.id}
                onDelete={() => this.handleDeleteGroup(idx)}
                readOnly={g.name.toLowerCase() === DEFAULT_GROUP.name.toLowerCase()}
                onUpdate={(key, val) => this.handleUpdateGroup(idx, key, val)}
                user={user}
                errors={getErrorForField(problems, `groups[${idx}]`)}
              />
            ))}
          </ScrollDrag>
          <TransitionablePortal
            onClose={() => this.handleToggleErrorMessage(false)}
            open={showErrorMessage}
          >
            <Card className="mediate-note-portal">
              <Card.Content>
                <Card.Header>Unable To Save Schema</Card.Header>
                <Card.Meta className="mediate-note-portal-description">
                  Your schema has some errors. Fill out the highlighted fields
                  and try again.
                </Card.Meta>
              </Card.Content>
            </Card>
          </TransitionablePortal>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  };

  renderImmutable = () => {
    const { showInfo, showSearch, isMarkerDragging } = this.state;
    const { currentSchema, markerActions, shortcuts } = this.props;
    const {
      owner,
      totalMarkerTypeCount,
      totalResearchGroups,
      createdDate,
    } = currentSchema;
    const isPublic = currentSchema.public;
    return (
      <Sidebar.Pushable className="mediate-schema-container">
        <Sidebar
          direction="right"
          animation="push"
          visible={showInfo}
          onHide={() => this.handleToggleInfo(false)}
          className="mediate-schema-info-container"
        >
          <div
            className="mediate-schema-text-field read-only"
            id="mediate-schema-name-field"
          >
            {currentSchema.name}
          </div>
          <div
            className="mediate-schema-text-field read-only"
            id="mediate-schema-description-field"
          >
            {currentSchema.description}
          </div>
          <div className="mediate-schema-extra-info">
            <h4 className="mediate-schema-created-info">Created By</h4>
            <Link
              className="mediate-admin-link mediate-search-result-owner-link"
              to={`/admin/users/${owner.id}`}
            >
              <CircularAvatar
                size="medium"
                title={owner.username}
                subtitle={owner.profile ? owner.profile.organization : null}
                image={owner.profile ? owner.profile.imageUrl : null}
                showTitle={true}
              />
            </Link>
            <h4 className="mediate-schema-created-info">
              on {new Date(createdDate).toLocaleDateString()}
            </h4>
            <SchemaUsageStats
              markerCount={totalMarkerTypeCount}
              projectCount={totalResearchGroups}
              schema={currentSchema}
            />
          </div>
        </Sidebar>
        <Sidebar.Pusher
          dimmed={showInfo}
          className="mediate-schema-create-group"
        >
          <div className="mediate-schema-options-container read-only">
            <h2 className="mediate-schema-read-only-title">
              {currentSchema.name}
            </h2>
            <Button
              compact
              className="mediate-schema-info-trigger"
              size="medium"
              icon
              labelPosition="right"
              onClick={this.handleToggleInfo}
              color="teal"
            >
              <Icon name="info circle" />
              Info
            </Button>
          </div>
          <ScrollDrag
            disableDrag={isMarkerDragging}
            direction={SCROLL_DIRECTIONS.HORIZONTAL}
            style={{ overflowY: "hidden", overflowX: "scroll" }}
            className={`schema-group-container ${showSearch ? "compact" : ""}`}
          >
            {currentSchema.groups.map((g, idx) => (
              <ReadOnlySchemaGroup
                compact={false}
                onMarkerDragBegin={() => this.handleMarkerDragState(true)}
                onMarkerDragEnd={() => this.handleMarkerDragState(false)}
                name={g.name}
                color={g.color}
                markers={g.markers}
                key={g.id}
                shortcuts={shortcuts}
                markerActions={markerActions}
              />
            ))}
          </ScrollDrag>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  };

  render() {
    const { readOnly } = this.props;
    if (!readOnly) {
      return this.renderMutable();
    }
    return this.renderImmutable();
  }
}
