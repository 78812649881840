import { fork, all, put, takeEvery, take, select } from "redux-saga/effects";
import { END, eventChannel } from "redux-saga";
import { ADMIN_ACTIONS, AUTH_ACTIONS } from "../constants/Actions";
import * as AdminFetch from "../api/AdminFetch";
import { fetchCreateUser, fetchVerifyUser } from "../api/User";
import { getServerErrorMessage } from "../api/FetchWrapper";
import { generateUploadKey } from "../reducers/AdminReducer";
import { displayBytes } from "../utils/Application";

function* createUserSaga(action) {
  try {
    const { username, email, password } = action;
    const result = yield fetchCreateUser(username, email, password);
    if (result) {
      yield put({
        type: AUTH_ACTIONS.USER_CREATED,
        createdUser: { username, email },
      });
    } else {
      throw new Error("Unable to create account. Please try again.");
    }
  } catch (error) {
    // put signup error
    const err = yield handleError(error);
    yield put({
      type: AUTH_ACTIONS.USER_CREATED_ERROR,
      error: err,
    });
  }
}

function* verifyUserSaga(action) {
  try {
    const { key } = action;
    const verified = yield fetchVerifyUser(key);
    if (verified) {
      yield put({ type: AUTH_ACTIONS.USER_VERIFIED });
    }
  } catch (error) {
    console.error(error);
  }
}

function* addResearchGroupUserSaga(action) {
  try {
    const { userId, groupId } = action;
    const researchGroupUser = yield AdminFetch.fetchAddResearchGroupUser(
      userId,
      groupId
    );
    if (researchGroupUser) {
      yield put({
        type: ADMIN_ACTIONS.RESEARCH_GROUP_USER_ADDED,
        researchGroupUser: researchGroupUser,
      });
      yield put({
        type: ADMIN_ACTIONS.ADMIN_INFO,
        data: {
          content: `Successfully Added a Collaborator`,
          title: "Add Collaborator Update",
        },
      });
    }
  } catch (error) {
    yield handleError(error);
  }
}

function* addResearchGroupSaga(action) {
  try {
    const researchGroup = yield AdminFetch.fetchAddResearchGroup(action.info);
    if (researchGroup) {
      const { owner } = action.info;
      // add research admin as research group user
      const researchGroupUser = yield AdminFetch.fetchAddResearchGroupUser(
        owner,
        researchGroup.id
      );
      if (researchGroupUser) {
        yield loadResearchGroupsSaga({ user: owner });
      }
      yield put({
        type: ADMIN_ACTIONS.ADMIN_INFO,
        data: {
          content: `Successfully created project ${researchGroup.name}.`,
          title: "Add Project Update",
        },
      });
    }
  } catch (error) {
    // push admin error
    yield handleError(error);
  }
}

function* deleteResearchGroupSaga(action) {
  try {
    const { id } = action;
    const result = yield AdminFetch.fetchDeleteResearchGroup(id);
    if (result) {
      yield put({ type: ADMIN_ACTIONS.RESEARCH_GROUP_DELETED, id: id });
    }
  } catch (error) {
    handleError(error);
  }
}

function* updateResearchGroupUserSaga(action) {
  try {
    const { id, properties } = action;
    const result = yield AdminFetch.fetchUpdateResearchGroupUser(
      id,
      properties
    );
    if (result) {
      yield put({
        type: ADMIN_ACTIONS.RESEARCH_GROUP_USER_UPDATED,
        rgu: result,
      });
    }
  } catch (error) {
    handleError(error);
  }
}

export function* loadResearchGroupDetailSaga(action) {
  try {
    const { id } = action;
    const result = yield AdminFetch.fetchResearchGroupDetail(id);
    // replace non-detail group
    if (result) {
      yield put({
        type: ADMIN_ACTIONS.RESEARCH_GROUP_LOADED,
        researchGroup: result,
      });
    }
  } catch (error) {
    yield handleError(error);
  }
}

export function* loadResearchGroupsSaga(action) {
  try {
    const { user } = action;
    const result = yield AdminFetch.fetchResearchGroups(user);
    yield put({
      type: ADMIN_ACTIONS.RESEARCH_GROUPS_LOADED,
      researchGroups: result,
    });
  } catch (error) {
    yield handleError(error);
  }
}

export function* loadSchemaSaga(action) {
  try {
    const { ownerId } = action;
    const result = yield AdminFetch.fetchSchema(ownerId);
    yield put({ type: ADMIN_ACTIONS.SCHEMA_LOADED, schema: result });
  } catch (error) {
    handleError(error);
  }
}

export function* saveSchemaSaga(action) {
  try {
    const { schema, deletedContent } = action;
    // delete groups based on deletedContent
    const result = yield AdminFetch.saveSchema(schema);
    if (result) {
      const { groups } = deletedContent;
      const groupsDeleted = yield AdminFetch.deleteSchemaGroups(groups);
      if (!groupsDeleted) {
        throw new Error(`Unable to delete groups ${groups.join(", ")}.`);
      }
      yield put({ type: ADMIN_ACTIONS.SCHEMA_SAVED, schema: result });
      yield put({
        type: ADMIN_ACTIONS.ADMIN_INFO,
        data: {
          content: `Successfully saved schema ${schema.name} `,
          title: `${schema.id ? "Edit" : "Add"} Schema Update`,
        },
      });
    }
  } catch (error) {
    yield handleError(error);
  }
}

export function* deleteSchemaSaga(action) {
  try {
    const { schemaId } = action;
    const result = yield AdminFetch.deleteSchema(schemaId);
    if (result) {
      yield put({ type: ADMIN_ACTIONS.SCHEMA_DELETED, id: schemaId });
    }
  } catch (error) {
    yield handleError(error);
  }
}

export function* addSchemaToProjectSaga(action) {
  const { schema, projectId } = action;
  try {
    const result = yield AdminFetch.addSchemaToProject(schema.id, projectId);
    if (result) {
      yield put({
        type: ADMIN_ACTIONS.SCHEMA_ADDED_TO_PROJECT,
        schema: schema,
        projectId: projectId,
      });
      yield put({
        type: ADMIN_ACTIONS.ADMIN_INFO,
        data: {
          content: `Successfully added schema ${schema.name} to project.`,
          title: "Project Update",
        },
      });
    }
  } catch (error) {
    yield handleError(error);
  }
}

export function* cloneSchemaSaga(action) {
  const { schema } = action;
  try {
    const result = yield AdminFetch.cloneSchema(schema.id);
    if (result) {
      yield put({ type: ADMIN_ACTIONS.SCHEMA_CLONED, schema: result });
      yield put({
        type: ADMIN_ACTIONS.ADMIN_INFO,
        data: {
          content: `Successfully copied schema ${schema.name}. It is now available to edit in the "My Schema" panel.`,
          title: "Schema Update",
        },
      });
    }
  } catch (error) {
    yield handleError(error);
  }
}

export function* cloneSchemaToProjectSaga(action) {
  const { schema, projectId } = action;
  try {
    const result = yield AdminFetch.cloneSchemaToProject(schema.id, projectId);
    if (result) {
      yield put({
        type: ADMIN_ACTIONS.PROJECT_SCHEMA_CLONED,
        oldSchemaId: schema.id,
        projectId: projectId,
        newSchema: result,
      });
      yield put({
        type: ADMIN_ACTIONS.ADMIN_INFO,
        data: {
          content: `Successfully cloned schema ${schema.name}. It is now available to edit in the "My Schema" panel.`,
          title: "Project Update",
        },
      });
    }
  } catch (error) {
    yield handleError(error);
  }
}

export function* removeSchemaFromProjectSaga(action) {
  const { schemaId, projectId } = action;
  try {
    const result = yield AdminFetch.removeSchemaFromProject(
      schemaId,
      projectId
    );
    if (result) {
      yield put({
        type: ADMIN_ACTIONS.SCHEMA_REMOVED_FROM_PROJECT,
        schemaId: schemaId,
        projectId: projectId,
      });
    }
  } catch (error) {
    yield handleError(error);
  }
}

export function* loadUserMediaSaga(action) {
  try {
    const { userId } = action;
    const result = yield AdminFetch.fetchGetMedia(userId);
    yield put({ type: ADMIN_ACTIONS.MEDIA_LOADED, media: result });
  } catch (error) {
    yield handleError(error);
  }
}

function createEventChannel() {
  let emitter = null;
  const ec = eventChannel((emit) => {
    emitter = emit;
    return () => {};
  });
  return [ec, emitter];
}

// TODO add ability to cancel upload since xhr has an abort method
function* watchUploadProgress(channel, cancel) {
  while (true) {
    const upload = yield take(channel);
    // grab current upload from state and check if cancel requested
    const uploads = yield select((state) => state.admin.uploads);
    const key = generateUploadKey(upload);
    const u = uploads.get(key);
    if (u && u.cancelRequested) {
      const cancelled = yield cancel();
      if (cancelled) {
        yield put({ type: ADMIN_ACTIONS.MEDIA_UPLOAD_CANCELLED, upload: u });
        channel.close();
        break;
      }
    } else if (upload.progress < 100) {
      yield put({ type: ADMIN_ACTIONS.MEDIA_UPLOAD_PROGRESS, upload: upload });
    } else {
      channel.close();
      break;
    }
  }
}

export function* addMediaSaga(action) {
  const [uploadChannel, emitter] = createEventChannel();
  const { film, media, captions, channel } = action;
  try {
    // check for upload quota
    const canUpload = media.file
      ? yield AdminFetch.canUserUploadFile(media.file)
      : { canUploadFile: true, reason: null };
    const { canUploadFile, reason } = canUpload;
    if (!canUploadFile) {
      throw new Error(reason);
    }
    const update = media.id !== null && media.file !== null && film.id !== null;
    const onProgress = (progress) => {
      const upload = {
        title: film.title,
        file: media.file,
        progress: progress,
        cancelRequested: false,
      };
      emitter(upload);
    };
    const progressCB = media.file ? onProgress : null;
    const [promise, cancel] = AdminFetch.fetchAddMedia(
      film,
      media,
      captions,
      channel,
      update,
      progressCB
    );
    if (media.file) {
      yield put({
        type: ADMIN_ACTIONS.MEDIA_UPLOAD_STARTED,
        upload: {
          title: film.title,
          file: media.file,
          progress: 0,
          cancelRequested: false,
        },
      });
      yield fork(watchUploadProgress, uploadChannel, cancel);
    }
    const result = yield promise;
    if (media.file) {
      yield put({
        type: ADMIN_ACTIONS.MEDIA_UPLOAD_DONE,
        upload: {
          title: film.title,
          file: media.file,
          progress: 100,
          cancelRequested: false,
        },
      });
    }
    const content = media.file
      ? `Successfully uploaded ${media.file.name}. It has been added to the processing queue.`
      : `Successfully updated ${film.title}.`;
    yield put({
      type: ADMIN_ACTIONS.ADMIN_INFO,
      data: {
        content: content,
        title: "Add Media Update",
      },
    });
    const actionType = update
      ? ADMIN_ACTIONS.MEDIA_UPDATED
      : ADMIN_ACTIONS.MEDIA_ADDED;
    yield put({ type: actionType, media: result });
  } catch (error) {
    // push a notification
    emitter(END);
    yield put({
      type: ADMIN_ACTIONS.MEDIA_UPLOAD_DONE,
      upload: { title: film.title, file: media.file, progress: 0 },
    });
    yield handleError(error);
  }
}

export function* addProjectMediaSaga(action) {
  try {
    const { film, media, researchGroup, id } = action;
    let result;
    let content;
    if (id) {
      result = yield AdminFetch.fetchAddResearchGroupFilm(id, researchGroup.id);
      content = `Successfully added media to ${researchGroup.name}.`;
    } else {
      result = yield AdminFetch.fetchAddProjectMedia(
        film,
        media,
        researchGroup.id,
        researchGroup.name
      );
      content = `Successfully uploaded ${media.file.name}. It has been added to the processing queue.`;
    }
    yield put({ type: ADMIN_ACTIONS.PROJECT_MEDIA_ADDED, media: result });
    yield put({
      type: ADMIN_ACTIONS.ADMIN_INFO,
      data: {
        content: content,
        title: "Add Media Update",
      },
    });
  } catch (error) {
    // UPDATE STATE HERE
    yield handleError(error);
  }
}

function* handleError(error) {
  let message;
  if (error.message) {
    try {
      // in case response isn't actually json
      message = yield error.message;
    } catch (error) {
      // pass
    }
  }
  const m = message
    ? getServerErrorMessage(message)
    : "There was an error processing the request.";
  yield put({
    type: ADMIN_ACTIONS.ADMIN_INFO,
    data: {
      content: m,
      title:
        error.responseStatus && error.responseStatus >= 400
          ? "Server Error"
          : "Warning",
    },
  });
  console.error(error);
  return m;
}

function* deleteResearchGroupUserSaga(action) {
  try {
    const { userId } = action;
    const result = yield AdminFetch.fetchDeleteResearchGroupUser(userId);
    if (result) {
      yield put({
        type: ADMIN_ACTIONS.RESEARCH_GROUP_USER_DELETED,
        id: userId,
      });
    }
  } catch (error) {
    yield handleError(error);
  }
}

function* deleteMediaSaga(action) {
  try {
    const { mediaId } = action;
    const result = yield AdminFetch.fetchDeleteMedia(mediaId);
    if (result) {
      yield put({ type: ADMIN_ACTIONS.MEDIA_DELETED, id: mediaId });
    }
    // else put error
  } catch (error) {
    yield handleError(error);
  }
}

function* deleteProjectMediaSaga(action) {
  try {
    const { mediaId } = action;
    const result = yield AdminFetch.fetchDeleteProjectMedia(mediaId);
    if (result) {
      yield put({ type: ADMIN_ACTIONS.PROJECT_MEDIA_DELETED, id: mediaId });
    }
  } catch (error) {
    yield handleError(error);
  }
}

function* deleteCaptionsSaga(action) {
  try {
    const { captionsId } = action;
    const result = yield AdminFetch.fetchDeleteCaptions(captionsId);
    if (result) {
      yield put({ type: ADMIN_ACTIONS.CAPTIONS_DELETED, id: captionsId });
    }
  } catch (error) {
    yield handleError(error);
  }
}

function* saveUserProfileSaga(action) {
  const { user, profile } = action;
  try {
    const result = yield AdminFetch.fetchSaveUserProfile(user, profile);
    if (result) {
      yield put({ type: ADMIN_ACTIONS.USER_PROFILE_SAVED, profile: result });
    }
  } catch (error) {
    yield handleError(error);
  }
}

function* inviteUserSaga(action) {
  try {
    const { email, message, researchGroupId } = action;
    const result = yield AdminFetch.fetchInviteUser(
      email,
      message,
      researchGroupId
    );
    if (result) {
      // put admin info
    }
  } catch (error) {
    yield handleError(error);
  }
}

function* watchForCreateUser() {
  yield takeEvery(AUTH_ACTIONS.CREATE_USER, createUserSaga);
}

function* watchForVerifyUser() {
  yield takeEvery(AUTH_ACTIONS.VERIFY_USER, verifyUserSaga);
}

function* watchForAddResearchGroupUser() {
  yield takeEvery(
    ADMIN_ACTIONS.ADD_RESEARCH_GROUP_USER,
    addResearchGroupUserSaga
  );
}

function* watchForUpdateResearchGroupUser() {
  yield takeEvery(
    ADMIN_ACTIONS.UPDATE_RESEARCH_GROUP_USER,
    updateResearchGroupUserSaga
  );
}

function* watchForLoadResearchGroups() {
  yield takeEvery(ADMIN_ACTIONS.LOAD_RESEARCH_GROUPS, loadResearchGroupsSaga);
}

function* watchForLoadResearchGroupDetail() {
  yield takeEvery(
    ADMIN_ACTIONS.LOAD_RESEARCH_GROUP_DETAIL,
    loadResearchGroupDetailSaga
  );
}

function* watchForAddMedia() {
  yield takeEvery(ADMIN_ACTIONS.ADD_MEDIA, addMediaSaga);
}

function* watchForLoadUserMedia() {
  yield takeEvery(ADMIN_ACTIONS.LOAD_USER_MEDIA, loadUserMediaSaga);
}

function* watchForLoadSchema() {
  yield takeEvery(ADMIN_ACTIONS.LOAD_SCHEMA, loadSchemaSaga);
}

function* watchForSaveSchema() {
  yield takeEvery(ADMIN_ACTIONS.SAVE_SCHEMA, saveSchemaSaga);
}

function* watchForDeleteSchema() {
  yield takeEvery(ADMIN_ACTIONS.DELETE_SCHEMA, deleteSchemaSaga);
}

function* watchForAddSchemaToProject() {
  yield takeEvery(ADMIN_ACTIONS.ADD_SCHEMA_TO_PROJECT, addSchemaToProjectSaga);
}

function* watchForCloneSchema() {
  yield takeEvery(ADMIN_ACTIONS.CLONE_SCHEMA, cloneSchemaSaga);
}

function* watchForCloneSchemaToProject() {
  yield takeEvery(ADMIN_ACTIONS.CLONE_PROJECT_SCHEMA, cloneSchemaToProjectSaga);
}

function* watchForRemoveSchemaFromProject() {
  yield takeEvery(
    ADMIN_ACTIONS.REMOVE_SCHEMA_FROM_PROJECT,
    removeSchemaFromProjectSaga
  );
}

function* watchForAddResearchGroup() {
  yield takeEvery(ADMIN_ACTIONS.ADD_RESEARCH_GROUP, addResearchGroupSaga);
}

function* watchForDeleteResearchGroup() {
  yield takeEvery(ADMIN_ACTIONS.DELETE_RESEARCH_GROUP, deleteResearchGroupSaga);
}

function* watchForAddProjectMediaSaga() {
  yield takeEvery(ADMIN_ACTIONS.ADD_PROJECT_MEDIA, addProjectMediaSaga);
}

function* watchForDeleteResearchGroupUser() {
  yield takeEvery(
    ADMIN_ACTIONS.DELETE_RESEARCH_GROUP_USER,
    deleteResearchGroupUserSaga
  );
}

function* watchForDeleteMediaSaga() {
  yield takeEvery(ADMIN_ACTIONS.DELETE_MEDIA, deleteMediaSaga);
}

function* watchForDeleteProjectMediaSaga() {
  yield takeEvery(ADMIN_ACTIONS.DELETE_PROJECT_MEDIA, deleteProjectMediaSaga);
}

function* watchForDeleteCaptionsSaga() {
  yield takeEvery(ADMIN_ACTIONS.DELETE_CAPTIONS, deleteCaptionsSaga);
}

function* watchForSaveUserProfileSaga() {
  yield takeEvery(ADMIN_ACTIONS.SAVE_USER_PROFILE, saveUserProfileSaga);
}

export default function* adminSaga() {
  yield all([
    watchForCreateUser(),
    watchForVerifyUser(),
    watchForAddResearchGroupUser(),
    watchForUpdateResearchGroupUser(),
    watchForAddResearchGroup(),
    watchForLoadResearchGroups(),
    watchForLoadResearchGroupDetail(),
    watchForLoadSchema(),
    watchForSaveSchema(),
    watchForCloneSchema(),
    watchForDeleteSchema(),
    watchForAddSchemaToProject(),
    watchForCloneSchemaToProject(),
    watchForRemoveSchemaFromProject(),
    watchForLoadUserMedia(),
    watchForAddMedia(),
    watchForAddProjectMediaSaga(),
    watchForDeleteResearchGroup(),
    watchForDeleteResearchGroupUser(),
    watchForDeleteMediaSaga(),
    watchForDeleteProjectMediaSaga(),
    watchForDeleteCaptionsSaga(),
    watchForSaveUserProfileSaga(),
  ]);
}
