/* @flow */

// React
import React, { Component } from "react";

import videojs from "video.js";

// utils
import { toSMPTE } from "../utils/timecodes";

// Seek Buttons
import MediateSeekButtons from "./MediateSeekButtons";

// Semantic UI React
import { Icon } from "semantic-ui-react";

// Constants
import {
  MEDIA_TYPE_AUDIO,
  MEDIA_TYPE_VIDEO,
  WAVEFORM,
  WAVEFORM_LABEL,
  SLITSCAN,
  SLITSCAN_LABEL,
  AUDIO_ICON,
  VIDEO_ICON,
} from "../constants/Application";

const SeekBarImageSelector = (props: Object) => {
  const { imageType, className, onClick } = props;
  let name = VIDEO_ICON;
  let title = SLITSCAN_LABEL;
  let toggleVal = SLITSCAN;
  if (imageType === SLITSCAN) {
    name = AUDIO_ICON;
    title = WAVEFORM_LABEL;
    toggleVal = WAVEFORM;
  }
  const toggle = () => {
    onClick(toggleVal);
  };
  let _class = className;
  return (
    <span
      title={title}
      onClick={toggle}
      className={(_class += " mediate-seekbar-image-select-button")}
    >
      <Icon
        className="mediate-seekbar-image-select-icon"
        name={name}
        size="large"
      />
    </span>
  );
};

const hasWaveformAndSlitscan = (seekBarImages: Object): boolean => {
  return (
    seekBarImages[SLITSCAN] && seekBarImages[WAVEFORM]
  );
};

export default class MediateSeekBar extends Component {
  seekBar: videojs.SeekBar;
  seekBarCanvas: HTMLCanvasElement;
  _offscreenCanvas: HTMLCanvasElement;
  _offscreenContext: CanvasRenderingContext2D;
  _onscreenContext: CanvasRenderingContext2D;
  _canvasFont: string = "100 12px lato";
  _smpteTextSize: number;
  _smptePlaceholder: string = "00:00:00:00";
  // update on window resize
  state = {
    canvas: {
      width: 0,
      height: 0,
    },
    seekBarImage: {
      type: null,
      data: null,
    },
    seekX: 0,
    dragging: false,
    timeMarkers: [],
  };

  constructor(props: Object) {
    super(props);
    (this: any).initSeekbar = this.initSeekbar.bind(this);
    (this: any).getTimeMarkers = this.getTimeMarkers.bind(this);
    (this: any).drawPositionIndicator = this.drawPositionIndicator;
    (this: any).loadBackgroundImage = this.loadBackgroundImage.bind(this);
    (this: any).drawBackgroundImage = this.drawBackgroundImage.bind(this);
    (this: any).animate = this.animate.bind(this);
    (this: any).handleWindowResize = this.handleWindowResize.bind(this);
    (this: any).handleTimeUpdate = this.handleTimeUpdate.bind(this);
    (this: any).resize = this.resize.bind(this);
    (this: any).handleDrag = this.handleDrag.bind(this);
    (this: any).handleCanvasClick = this.handleCanvasClick.bind(this);
    (this: any).setCanvasRef = this.setCanvasRef.bind(this);
    (this: any).setSeek = this.setSeek.bind(this);
    (this: any).setPosition = this.setPosition.bind(this);
    (this: any).toggleDragStart = this.toggleDragStart.bind(this);
    (this: any).toggleDragEnd = this.toggleDragEnd.bind(this);
    (this: any).clear = this.clear.bind(this);
    (this: any).seekToDuration = this.seekToDuration.bind(this);
    (this: any).durationToSeek = this.durationToSeek.bind(this);
    (this: any).toggleSeekBarImage = this.toggleSeekBarImage.bind(this);
  }

  componentDidMount(): void {
    const { player, mediaType, seekBarImages } = this.props;
    player.on("loadedmetadata", () => {
      this.initSeekbar();
    });
    player.on("timeupdate", this.handleTimeUpdate);
    window.addEventListener("resize", this.handleWindowResize);
    const isAudio = !seekBarImages.slitscan && seekBarImages.waveform;
    if (!isAudio) {
      this.setState({
        seekBarImage: {
          type: SLITSCAN,
          data: seekBarImages.slitscan,
        },
      });
    } else {
      this.setState({
        seekBarImage: {
          type: WAVEFORM,
          data: seekBarImages.waveform,
        },
      });
    }
  }

  componentDidUpdate(prevProps: Object): void {
    // THERE SHOULD ALWAYS BE A WAVEFORM, IF NOT WE HAVE BIGGER PROBLEMS
    const { seekBarImages, mediaType } = this.props;
    if (prevProps.seekBarImages.waveform !== seekBarImages.waveform) {
      if (mediaType === MEDIA_TYPE_VIDEO) {
        this.setState({
          seekBarImage: {
            type: SLITSCAN,
            data: seekBarImages.slitscan,
          },
        });
      } else {
        this.setState({
          seekBarImage: {
            type: WAVEFORM,
            data: seekBarImages.waveform,
          },
        });
      }
    }
  }

  toggleSeekBarImage(key: string): void {
    this.setState(
      {
        seekBarImage: {
          type: key,
          data: this.props.seekBarImages[key],
        },
      },
      this.loadBackgroundImage
    );
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  clear(): void {
    let { width, height } = this.state.canvas;
    this._offscreenContext.clearRect(0, 0, width, height);
    this._onscreenContext.clearRect(0, 0, width, height);
  }

  animate(): void {
    this.clear();
    this._onscreenContext.drawImage(this._offscreenCanvasBackground, 0, 0);
    this.drawPositionIndicator();
    this._onscreenContext.drawImage(this._offscreenCanvas, 0, 0);
    requestAnimationFrame(this.animate);
  }

  durationToSeek(currentTime: number): number {
    let { width } = this.state.canvas;
    let duration = this.props.player.duration();
    return (currentTime * width) / duration;
  }

  seekToDuration(seekPos: number): number {
    let { width } = this.state.canvas;
    let duration = this.props.player.duration();
    return (seekPos * duration) / width;
  }

  drawPositionIndicator(): void {
    const { seekX, dragging } = this.state;
    const ctx = this._offscreenContext;
    ctx.strokeStyle = "#ccc";
    const gr = ctx.createLinearGradient(0, 0, 0, 40);
    gr.addColorStop(0, "rgba(127,127,127, 1.0)");
    gr.addColorStop(1, "rgba(0,0,0,0.2)");
    ctx.lineWidth = 1;
    ctx.lineJoin = "round";
    ctx.beginPath();
    ctx.fillStyle = gr;
    ctx.moveTo(seekX, 2);
    ctx.lineTo(seekX + 10, 2);
    ctx.lineTo(seekX + 10, 12);
    ctx.lineTo(seekX + 5, 22);
    ctx.lineTo(seekX, 12);
    ctx.lineTo(seekX, 2);
    ctx.moveTo(seekX + 5, 22);
    ctx.lineTo(seekX + 5, this._offscreenCanvas.height);
    ctx.stroke();
    ctx.fill();
    ctx.closePath();
    if (dragging) {
      const duration = this.props.player.duration();
      const time = toSMPTE(
        (seekX * duration) / this._offscreenCanvas.width,
        this.props.playerInfo.framerate
      );
      ctx.fillStyle = "#ccc";
      ctx.fillText(time, seekX + 10, this._offscreenCanvas.height / 2.5);
      ctx.fillStyle = gr;
      ctx.fillRect(
        seekX + 5,
        this._offscreenCanvas.height / 4,
        this._smpteTextSize + 10,
        this._offscreenCanvas.height / 4
      );
    }
  }

  loadBackgroundImage(): void {
    if (this.state.seekBarImage.data) {
      let { url, width, height } = this.state.seekBarImage.data;
      this.backgroundImage = new Image(width, height);
      this.backgroundImage.src = url;
      this.backgroundImage.onload = () => {
        this.drawBackgroundImage();
      };
    }
  }

  drawBackgroundImage(): void {
    let sWidth = this.state.seekBarImage.data.width;
    let sHeight = this.state.seekBarImage.data.height;
    let { width, height } = this.seekBarCanvas;
    let ctx = this._offscreenContextBackground;
    ctx.clearRect(0, 0, width, height);
    let { timeMarkers } = this.state;
    ctx.fillStyle = "#ccc";
    ctx.strokeStyle = "#444";
    let step = width / 7;
    let x = step;
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(width, 0);
    ctx.stroke();
    ctx.closePath();
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(0, 20);
    ctx.stroke();
    ctx.closePath();
    let marker = this._smptePlaceholder;
    ctx.fillText(marker, 10, 20);
    for (let i = 0; i < timeMarkers.length; i++, x += step) {
      ctx.beginPath();
      ctx.moveTo(x + 5, 0);
      ctx.lineTo(x + 5, 20);
      ctx.stroke();
      ctx.closePath();
      marker = timeMarkers[i];
      ctx.fillText(marker, x + 10, 20);
    }
    const aspect = Math.floor(width / height);
    // draw the last tick
    ctx.beginPath();
    ctx.moveTo(width, 0);
    ctx.lineTo(width, 20);
    ctx.stroke();
    ctx.closePath();
    ctx.drawImage(
      this.backgroundImage,
      0,
      0,
      sWidth,
      sHeight,
      0,
      height / 2,
      width,
      height / 2
    );
  }

  handleWindowResize(event: Event): void {
    const { clientWidth, clientHeight } = this.seekBarCanvas;
    this.resize(clientWidth, clientHeight);
  }

  handleTimeUpdate(): void {
    const time = this.props.player.currentTime();
    if (!this.state.dragging) {
      this.setState({
        seekX: this.durationToSeek(time),
      });
    }
  }

  resize(width: number, height: number): void {
    this.setState(
      {
        canvas: {
          width: width,
          height: height,
        },
      },
      () => {
        const { width, height } = this.state.canvas;
        this._offscreenCanvas.width = width;
        this._offscreenCanvas.height = height;
        this._offscreenCanvasBackground.width = width;
        this._offscreenCanvasBackground.height = height;
        this.seekBarCanvas.width = width;
        this.seekBarCanvas.height = height;
        if (this.backgroundImage) {
          this.drawBackgroundImage();
        }
      }
    );
  }

  toggleDrag(val: boolean): void {
    this.setState({
      dragging: val,
    });
    if (!val) {
      this.setPosition(this.state.seekX);
    }
  }

  toggleDragStart(): void {
    this.toggleDrag(true);
  }

  toggleDragEnd(): void {
    this.toggleDrag(false);
  }

  handleDrag(event: SyntheticEvent): void {
    if (this.state.dragging) {
      event.preventDefault();
      event.stopPropagation();
      this.setSeek(event);
    }
  }

  handleCanvasClick(event: SyntheticEvent): void {
    if (!this.state.isDragging) {
      this.setSeek(event, this.setPosition);
    }
  }

  getTimeMarkers(): Array<string> {
    let timeMarkers = [];
    let duration = this.props.player.duration();
    for (let i = duration / 7; i < duration; i += duration / 7) {
      timeMarkers.push(toSMPTE(i, this.props.playerInfo.framerate));
    }
    return timeMarkers;
  }

  setSeek(event: SyntheticEvent, callback: (seekVal) => void): void {
    const rect = this.seekBarCanvas.getBoundingClientRect();
    const xScale = this.seekBarCanvas.width / rect.width;
    const mouseX = (event.clientX - rect.left) * xScale;
    let seekVal = mouseX;
    if (mouseX >= this.seekBarCanvas.width - 10)
      seekVal = this.seekBarCanvas.width - 10;
    if (mouseX < 0) seekVal = 0;
    this.setState({ seekX: seekVal }, () => {
      if (callback) callback(this.state.seekX);
    });
  }

  setPosition(pos: number): void {
    this.props.player.currentTime(this.seekToDuration(pos));
  }

  setCanvasRef(ref): void {
    this.seekBarCanvas = ref;
  }

  initSeekbar(): void {
    const { width, height, clientWidth, clientHeight } = this.seekBarCanvas;
    this._offscreenCanvasBackground = document.createElement("canvas");
    this._offscreenCanvas = document.createElement("canvas");
    this._offscreenContext = this._offscreenCanvas.getContext("2d");
    this._offscreenContextBackground = this._offscreenCanvasBackground.getContext(
      "2d"
    );
    this._offscreenContextBackground.font = this._canvasFont;
    this._offscreenContext.font = this._canvasFont;
    this._smpteTextSize = this._offscreenContext.measureText(
      this._smptePlaceholder
    ).width;
    this._onscreenContext = this.seekBarCanvas.getContext("2d");
    this.setState(
      {
        timeMarkers: this.getTimeMarkers(),
      },
      () => {
        this.loadBackgroundImage();
        this.resize(clientWidth, clientHeight);
        this.animate();
      }
    );
  }

  render() {
    const { player, playerInfo } = this.props;
    const { seekBarImage } = this.state;
    return (
      <div className="mediate-player-seekbar">
        <div className="mediate-player-seekbar-controls-container">
          {hasWaveformAndSlitscan(this.props.seekBarImages) ? (
            <SeekBarImageSelector
              onClick={this.toggleSeekBarImage}
              className="mediate-player-seekbar-controls"
              imageType={seekBarImage.type}
            />
          ) : (
            null
          )}
          <MediateSeekButtons
            className="mediate-player-seekbar-controls"
            player={player}
            framerate={playerInfo.framerate}
          />
        </div>
        <div draggable={false} className="mediate-player-seekbar-container">
          <canvas
            draggable={false}
            ref={this.setCanvasRef}
            onMouseDown={this.toggleDragStart}
            onMouseMove={this.handleDrag}
            onMouseUp={this.toggleDragEnd}
            onClick={this.handleCanvasClick}
            className="mediate-player-seekbar-canvas"
          />
        </div>
      </div>
    );
  }
}
