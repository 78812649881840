// Note - this is just to make sure all of the endpoints work etc
import React, { useState, useContext, useEffect, useRef } from "react";
import { NotesContext } from "../containers/Notes";
import { UserContext } from "../containers/User";
import ConfirmDelete from "./admin/ConfirmDelete";
import TextAreaAutosize from "react-textarea-autosize";
import {
  Segment,
  Form,
  Button,
  Comment,
  Transition,
  Icon,
  Ref,
} from "semantic-ui-react";
import logoIcon from "../images/logo-icon.png";
import { CircularAvatar } from "./UserCard";

function CommentCircularAvatar(props) {
  const { image } = props;
  return (
    <div className="mediate-comments-thread-circular-avatar">
      <CircularAvatar image={image} />
    </div>
  );
}

/**
 * A basic root component for the note thread
 *
 * Contains the Note textarea (could be broken out into its own component)
 * and renders the threaded comments
 */
export default function MediateBasicNoteThread(props) {
  const { marker, canModifyNote } = props;
  /* NotesContext exposes all note-related action cretors bound to dispatch
   * so they can be called directly and will be consumed by the NoteSaga.
   * if successful, the marker will be updated and the changed components will re-render
   *
   * Action creators can be found here: src/actions/NotesActions.js
   * addNoteToMarker
   * updateNote
   * deleteNote
   * addCommentToNote,
   * addReplyToComment,
   * updateComment,
   * deleteComment,
   * clearNoteError,
   * clearCommentError
   *
   * It also contains basic state for storing both note and comment API errors.
   * These can be accessed via the noteError and commentError properties. These props
   * will be updated every time an error occurs in one of the note sagas.
   * and can be cleared out by calling clearNoteError and clearCommentError respectively
   */
  const { addNoteToMarker, updateNote, noteError, clearNoteError } =
    useContext(NotesContext);
  // local component state
  const [note, setNote] = useState("");

  /* semantic-ui specific callback that adds a second parameter
   *  with the value set - if you don't end up using it
   *  event.target.value will also work
   */
  function handleSetNote(evt) {
    const { value } = evt.target;
    setNote(value);
  }

  function handleSaveNoteClick() {
    if (note && note !== "") {
      const trimmed = note.trim();
      marker.note
        ? updateNote({ ...marker.note, noteText: trimmed })
        : addNoteToMarker(marker, trimmed);
    }
  }

  // hydrates the note state whenever marker changes
  useEffect(() => {
    if (marker.note) {
      setNote(marker.note.noteText);
    }
  }, [marker]);

  const commentsDisabled = marker.note === null;
  return (
    <Segment
      vertical
      className={`mediate-basic-note-thread-container ${commentsDisabled ? "no-comments" : ""
        }`}
    >
      <Form
        onSubmit={handleSaveNoteClick}
        className="mediate-basic-note-thread-form"
      >
        <Form.TextArea
          control={TextAreaAutosize}
          value={note}
          onChange={handleSetNote}
          disabled={!canModifyNote}
          className="mediate-basic-comment-thread-textarea"
        />
        {canModifyNote ? (
          <Button
            icon
            size="mini"
            labelPosition="left"
            action="submit"
            color="blue"
            className="mediate-basic-note-thread-save-button"
          >
            <Icon name="save" />
            Save
          </Button>
        ) : null}
      </Form>
      {!commentsDisabled ? (
        <CommentsThread
          note={marker.note}
          comments={(marker.note && marker.note.comments) || []}
        />
      ) : null}
    </Segment>
  );
}

const COMMENT_FORM_MODE = {
  ADD: "ADD",
  EDIT: "EDIT",
  REPLY: "REPLY",
};

/**
 * Component for rendering the root comments thread and handling all actions
 */
function CommentsThread(props) {
  const { comments, note } = props;
  const { addCommentToNote } = useContext(NotesContext);
  const [addingComment, setAddingComment] = useState(false);
  const [comment, setComment] = useState("");
  const { session } = useContext(UserContext);

  function handleAddComment() {
    addCommentToNote(note, comment);
    setAddingComment(false);
    setComment("");
  }

  function handleCancelAddComment() {
    setAddingComment(false);
  }

  function handleContentChange(evt) {
    const { value } = evt.target;
    setComment(value);
  }
  // testing to make sure errors propagate and can be cleared

  // calls all of the necessary action creators - these all hit the API

  /**
   * Possible additions:
   * 1) Scroll Form into view when a user clicks reply / edit on a comment
   *    if using semantic-ui-react, a Ref component will have to be used to
   *    expose the inner ref - if not, the useRef hook can be used instead.
   * 2) Highlight the comment set for editing / replying
   * 3) Expand / collapse comment groups on click
   */
  const canComment = !(!session || !session.userId);
  return (
    <Comment.Group threaded className="mediate-basic-comment-thread-group">
      {!addingComment && canComment ? (
        <div className="mediate-basic-comment-thread-add-new-container">
          <Button
            color="teal"
            content="Add comment"
            labelPosition="left"
            icon="edit"
            size="tiny"
            onClick={() => setAddingComment(true)}
          />
        </div>
      ) : null}
      {!addingComment ? (
        comments.map((c, idx) => (
          <ThreadedComment
            key={idx}
            note={note}
            replies={c.replies || []}
            showThread={(c.replies.length && c.replies.length > 1) || false}
            comment={c.comment}
          />
        ))
      ) : (
        <Form
          onSubmit={handleAddComment}
          className="mediate-basic-comment-add-form"
          size="small"
        >
          <Form.TextArea
            control={TextAreaAutosize}
            value={comment}
            onChange={handleContentChange}
            className="mediate-basic-comment-thread-textarea"
          />
          <Button
            action="submit"
            content="Save"
            labelPosition="left"
            icon="edit"
            size="mini"
            primary
          />
          <Button
            onClick={handleCancelAddComment}
            type="button"
            color="grey"
            content="Cancel"
            labelPosition="left"
            icon="close"
            size="mini"
          />
        </Form>
      )}
    </Comment.Group>
  );
}

/**
 * Recursive component for rendering comments and their replies
 */

function ThreadedComment(props) {
  // UserContext supplies session information for the currently loggin in user
  const { session } = useContext(UserContext);
  const {
    addReplyToComment,
    updateComment,
    deleteComment,
    commentError,
    clearCommentError,
  } = useContext(NotesContext);
  const [content, setContent] = useState("");
  const [showChildren, setShowChildren] = useState(false);
  const [currentFormMode, setCurrentFormMode] = useState(COMMENT_FORM_MODE.ADD);
  const [editing, setEditing] = useState(false);
  const editFormRef = useRef(null);

  const { note, comment, replies, onReply, onEdit, onDelete, formMode } = props;
  const { author } = comment;
  const avatar = (author.profile && author.profile.imageUrl) || logoIcon;

  function handleDeleteConfirm() {
    deleteComment(comment);
    if (onDelete) {
      onDelete(comment);
    }
  }

  function handleSaveComment() {
    switch (currentFormMode) {
      case COMMENT_FORM_MODE.EDIT:
        updateComment({ ...comment, content });
        break;
      case COMMENT_FORM_MODE.REPLY:
        addReplyToComment(comment, content);
        break;
    }
    setEditing(false);
    setContent("");
  }

  // semantic-ui specific onChange callback
  function handleSetContent(evt) {
    const { value } = evt.target;
    setContent(value);
  }

  function handleReplyClick() {
    setEditing(true);
    setContent("");
    setCurrentFormMode(COMMENT_FORM_MODE.REPLY);
  }

  function handleEditClick() {
    setEditing(true);
    setContent(comment.content);
    setCurrentFormMode(COMMENT_FORM_MODE.EDIT);
  }

  function handleEditCancel() {
    setEditing(false);
    setContent("");
  }

  function getFormLabel() {
    switch (currentFormMode) {
      case COMMENT_FORM_MODE.EDIT:
        return "Edit";
      case COMMENT_FORM_MODE.REPLY:
        return "Reply";
    }
  }

  useEffect(() => {
    if (editing && editFormRef.current) {
      editFormRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [editing, editFormRef]);

  const buttonLabel = getFormLabel();

  const canModifyComment = comment.author.id === session.userId;
  // TODO add more robust "canReply" that checks to see if the user is actually part of the project
  const canReply = !(!session || !session.userId);
  return (
    <Comment className="mediate-basic-comment-thread-comment">
      <Comment.Avatar image={avatar} as={CommentCircularAvatar} />
      <Comment.Content className="mediate-basic-comment-thread-comment-content">
        <Comment.Author className="mediate-basic-comment-thread-comment-author">
          {author.username}
        </Comment.Author>
        <Comment.Metadata className="mediate-basic-comment-thread-comment-date">
          {new Date(comment.createdDate).toLocaleString()}
        </Comment.Metadata>
        <Comment.Text className="mediate-basic-comment-thread-comment-text">
          {comment.content}
        </Comment.Text>
        <Comment.Actions className="mediate-basic-comment-thread-comment-actions">
          {canReply ? <a onClick={handleReplyClick}>Reply</a> : null}
          {canModifyComment ? <a onClick={handleEditClick}>Edit</a> : null}
          {canModifyComment ? (
            <ConfirmDelete
              deleteButton={<a>Delete</a>}
              title="this comment"
              onDelete={handleDeleteConfirm}
            />
          ) : null}
        </Comment.Actions>
        <Transition.Group>
          {editing ? (
            <Ref innerRef={editFormRef}>
              <Form
                onSubmit={handleSaveComment}
                className="mediate-basic-comment-form"
                size="small"
              >
                <Form.TextArea
                  control={TextAreaAutosize}
                  value={content}
                  onChange={handleSetContent}
                  className="mediate-basic-comment-thread-textarea"
                />
                <Button
                  action="submit"
                  content={buttonLabel}
                  labelPosition="left"
                  icon="edit"
                  size="mini"
                  primary
                />
                <Button
                  onClick={handleEditCancel}
                  type="button"
                  color="grey"
                  content="Cancel"
                  labelPosition="left"
                  icon="close"
                  size="mini"
                />
              </Form>
            </Ref>
          ) : null}
        </Transition.Group>
        {replies.length > 0 ? (
          <span
            className="mediate-basic-comment-thread-replies-toggle"
            onClick={() => setShowChildren(!showChildren)}
          >
            <Icon name={showChildren ? "caret up" : "caret down"} />
            {`${showChildren ? "Hide" : "Show"} ${replies.length} ${replies.length === 1 ? "reply" : "replies"
              }`}
          </span>
        ) : null}
      </Comment.Content>
      {/* recursively render all replies */}
      <Transition.Group>
        {showChildren ? (
          <Comment.Group className="mediate-basic-comment-thread-replies">
            {replies.map((c) => (
              <ThreadedComment
                note={note}
                formMode={formMode}
                key={c.comment.id}
                comment={c.comment}
                replies={c.replies || []}
                onReply={onReply}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))}
          </Comment.Group>
        ) : null}
      </Transition.Group>
    </Comment>
  );
}
