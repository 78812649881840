import React, { Component } from "react";
import { Form, Segment, Button, Icon } from "semantic-ui-react";

export default class InviteUserForm extends Component {
  state = {
    email: "",
    message: "",
  };

  handleChange = (key, val) => {
    this.setState({ [key]: val });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { email, message } = this.state;
    onSubmit(email, message);
  };

  render() {
    const { email, message } = this.state;
    return (
      <Form inverted as={Segment} className="mediate-tab-form" size="big">
        <Form.Field required>
          <label className="mediate-form-label">Email</label>
          <Form.Input
            type="text"
            onChange={(event, { value }) => this.handleChange("email", value)}
            value={email}
          />
          <Form.Field required>
            <label className="mediate-form-label">Message</label>
            <Form.TextArea
              onChange={(event, { value }) =>
                this.handleChange("message", value)
              }
              value={message}
            />
          </Form.Field>
        </Form.Field>
        <Form.Field className="mediate-form-add-button">
          <Button
            onClick={this.handleSubmit}
            icon
            color="blue"
            labelPosition="right"
          >
            <Icon name="mail" />
            Invite
          </Button>
        </Form.Field>
      </Form>
    );
  }
}
