// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// Actions
import { loadResearchGroups } from "../actions/AdminActions";
import { queryMarkers } from "../actions/AnnotateActions";

// Components
import PageLoader from "../componets/Loading";
import ProjectDataQuery from "../componets/ProjectDataQuery";

class GetDataContainer extends Component {
  state = { queryData: {} };
  componentWillMount() {
    const { user, loadResearchGroups } = this.props;
    if (user && user.isAuthenticated) {
      loadResearchGroups(user.userId);
    }
  }

  render() {
    const { researchGroups, queryPending, queryResults, queryMarkers } =
      this.props;
    const researchGroupQueryData = researchGroups
      ? researchGroups.map((rg) => ({
          id: rg.id,
          name: rg.name,
          collaborators: rg.researchGroupUsers,
          films: rg.researchGroupFilms.map((rgf) => ({
            title: rgf.film.title,
            id: rgf.id,
          })),
        }))
      : null;
    if (researchGroupQueryData) {
      return (
        <ProjectDataQuery
          queryOptions={researchGroupQueryData}
          onRunQuery={queryMarkers}
          queryResults={queryResults}
          queryPending={queryPending}
        />
      );
    }
    return <PageLoader />;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    /* queryOptions: state.mediate.queryOptions,
    queryResults: state.mediate.queryResults,
    queryPending: state.mediate.queryPending,
    playerQueryResults: state.mediate.playerQueryResults,*/
    queryPending: state.mediate.queryPending,
    queryResults: state.mediate.queryResults,
    researchGroups: state.admin.researchGroups,
    user: state.user.session,
  };
}

export default connect(mapStateToProps, {
  queryMarkers,
  loadResearchGroups,
})(GetDataContainer);
