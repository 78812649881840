import React, { useCallback, useContext, useEffect } from "react";
import { AdminContext } from "../../containers/admin/AdminContext";
import {
  Icon,
  TransitionablePortal,
  Card,
  List,
  Progress,
  Button,
} from "semantic-ui-react";
import { displayBytes } from "../../utils/Application";
import SimpleBar from "simplebar-react";

function getMediaIcon(file) {
  const t = file.type.split("/")[0];
  return t === "video" ? "file video outline" : "file audio outline";
}

export default function MediaUploadDisplay(props) {
  const adminCtx = useContext(AdminContext);
  const { uploads, actions } = adminCtx;
  const { cancelMediaUpload } = actions;
  const handleBeforeUnload = useCallback(
    (evt) => {
      if (uploads.size > 0) {
        const msg = `${uploads.size} active uploads will be cancelled. Are you sure you want to leave?`;
        evt.returnValue = msg;
        return msg;
      }
    },
    [uploads]
  );
  const handleCancel = useCallback(
    (upload) => {
      cancelMediaUpload(upload);
    },
    [uploads]
  );
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploads]);
  const uploadsArr = Array.from(uploads);
  return (
    <div className="media-upload-info-container">
      <TransitionablePortal
        className="media-upload-info-portal"
        closeOnTriggerClick
        trigger={
          <Button
            compact
            className="media-upload-info-trigger"
            size="tiny"
            icon
            color="teal"
            labelPosition="right"
          >
            <Icon name="cloud upload" />
            {uploadsArr.length} Active Uploads
          </Button>
        }
      >
        <Card className="media-upload-info-detail-card">
          <Card.Content>
            <Card.Header className="media-upload-info-detail-header">
              Active Uploads ({uploadsArr.length})
            </Card.Header>
            <Card.Description>
              <SimpleBar className="media-upload-info-detail-list">
                <List divided relaxed>
                  {uploadsArr.map(([uploadKey, upload]) => (
                    <List.Item key={uploadKey}>
                      <List.Icon
                        size="large"
                        name={getMediaIcon(upload.file)}
                      />
                      <List.Content>
                        <List.Header>{upload.title}</List.Header>
                        <List.Description>
                          {upload.file.name} - {displayBytes(upload.file.size)}
                        </List.Description>
                      </List.Content>
                      <Progress
                        size="tiny"
                        color="teal"
                        percent={upload.progress}
                        active
                      />
                      <Button
                        onClick={() => handleCancel(upload)}
                        compact
                        className="media-upload-info-trigger"
                        size="tiny"
                        icon
                        color="red"
                        labelPosition="right"
                      >
                        <Icon name="close" />
                        cancel
                      </Button>
                    </List.Item>
                  ))}
                </List>
              </SimpleBar>
            </Card.Description>
          </Card.Content>
        </Card>
      </TransitionablePortal>
    </div>
  );
}
