/* @flow */

// React
import React, { Component } from "react";

// Semantic UI
import { Icon, Button } from "semantic-ui-react";

const sleep = duration => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), duration);
  });
};

export default class MediateSeekBar extends Component {
  state = {
    seekVal: {
      index: 0,
      value: 0,
      rate: 0
    },
    isSeeking: false,
    direction: 1
  };

  SEEK_VALS = [1, 2, 4, 6, 8, 10];

  constructor(props: Object) {
    super(props);
    (this: any).setSeekVal = this.setSeekVal.bind(this);
    (this: any).seek = this.seek.bind(this);
    (this: any).stopSeek = this.stopSeek.bind(this);
    (this: any).seekForward = this.seekForward.bind(this);
    (this: any).seekBackward = this.seekBackward.bind(this);
    (this: any).getNextValue = this.getNextValue.bind(this);
  }

  componentDidMount() {
    this.props.player.on('ended', this.stopSeek);
    this.props.player.on('play', this.stopSeek);
  }

  setSeekVal(direction: number): void {
    const { index, value } = this.state.seekVal;
    let newIndex;
    if (this.state.seekVal.direction === direction) {
      if (index === this.SEEK_VALS.length-1 || value === 0) {
        newIndex = 0;
      } else {
        newIndex = index + 1;
      }
    } else {
      newIndex = index;
    }

    let val = this.SEEK_VALS[newIndex];
    this.setState(
      {
        seekVal: {
          index: newIndex,
          value: val,
          direction: direction
        },
        isSeeking: val !== 0
      },
      () => {
        if (this.state.isSeeking) this.seek();
      }
    );
  }

  seekForward(): void {
    this.setSeekVal(1);
  }

  seekBackward(): void {
    this.setSeekVal(0);
  }

  seek() {
    // TODO make this smoother
    const { player } = this.props;
    const { seekVal, isSeeking } = this.state;
    if (isSeeking) {
      return sleep(500).then(() => {
        let current = player.currentTime();
        if (current === 0) {
          this.stopSeek();
          return null;
        }
        if (!player.paused()) player.pause();
        if (seekVal.direction === 1) {
          player.currentTime(current + seekVal.value);
        } else {
          player.currentTime(current - seekVal.value);
        }
        return this.seek();
      });
    }
  }

  stopSeek(): void {
    if (this.state.isSeeking) {
      this.setState({
        seekVal: {
          ...this.state.seekVal,
          index: 0,
          value: 0
        },
        isSeeking: false,
      });
    }
  }

  getNextValue(_direction: number): void {
    const { index, direction, value } = this.state.seekVal;
    let nextIndex;
    if (value === 0) {
      return this.SEEK_VALS[0];
    }
    if (direction === _direction) {
      nextIndex = (index === this.SEEK_VALS.length-1) ? 0 : index + 1;
    } else {
      nextIndex = index;
    }
    return this.SEEK_VALS[nextIndex];
  }

  render() {
    const { value } = this.state.seekVal;
    const { isSeeking } = this.state;
    const { className } = this.props;

    let baseClass = "mediate-seek-rate";
    baseClass += isSeeking ? " show" : " hide";
    return (
      <div className={ className + " mediate-seek-buttons-container" }>
        <span
          className="mediate-seek-button"
          id="reverse"
          title={"Back " + this.getNextValue(0) + "x"}
          onClick={this.seekBackward}
        >
          <Icon name="fast backward" />
        </span>
        <span
          className="mediate-seek-button"
          id="forward"
          title={"Forward " + this.getNextValue(1) + "x"}
          onClick={this.seekForward}
        >
          <Icon name="fast forward" />
        </span>
        <span className={baseClass}>{value + "x"}</span>
      </div>
    );
  }
}
