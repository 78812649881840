export const MEDIATE_ACTIONS = {
  LOAD_MEDIATE_DATA: "LOAD_MEDIATE_DATA",
  LOAD_MARKERS: "LOAD_MARKERS",
  MARKERS_LOADED: "MARKERS_LOADED",
  CLEAR_INTERFACE: "CLEAR_INTERFACE",
  LOAD_MEDIATE_PRESENTATION_DATA: "LOAD_MEDIATE_PRESENTATION_DATA",
  MEDIATE_DATA_LOADED: "MEDIATE_DATA_LOADED",
  SELECT_SCHEMA: "SELECT_SCHEMA",
  SCHEMA_SELECTED: "SCHEMA_SELECTED",
  LOAD_CURRENT_SCHEMA: "LOAD_CURRENT_SCHEMA",
  SCHEMA_LOADED: "SCHEMA_LOADED",
  RESEARCH_GROUP_SCHEMA_LOADED: "RESEARCH_GROUP_SCHEMA_LOADED",
  ADD_MARKER: "ADD_MARKER",
  DELETE_MARKER: "DELETE_MARKER",
  UPDATE_MARKER: "UPDATE_MARKER",
  MARKER_SAVED: "MARKER_SAVED",
  MARKER_DELETED: "MARKER_DELETED",
  MARKER_UPDATED: "MARKER_UPDATED",
  MARKER_SAVE_SUCCESSFUL: "MARKER_SAVE_SUCCESSFUL",
  MARKER_SAVE_FAILED: "MARKER_SAVE_FAILED",
  MARKER_SAVE_PENDING: "MARKER_SAVE_PENDING",
  GET_MARKERS_BY_MARKER_TYPE: "GET_MARKERS_BY_MARKER_TYPE",
  QUERY_OPTIONS_LOADED: "QUERY_OPTIONS_LOADED",
  QUERY_MARKERS: "QUERY_MARKERS",
  QUERY_RESULTS_PENDING: "QUERY_RESULTS_PENDING",
  QUERY_RESULTS_LOADED: "QUERY_RESULTS_LOADED",
  LOAD_QUERY_OPTIONS: "LOAD_QUERY_OPTIONS",
  PLAYER_QUERY_RESULTS_LOADED: "PLAYER_QUERY_RESULTS_LOADED",
  PLAYER_QUERY_RESULTS_PENDING: "PLAYER_QUERY_RESULTS_PENDING",
  GET_QUERY_RESULT_PLAYER: "GET_QUERY_RESULT_PLAYER",
  CURRENT_MARKER_TYPES_UPDATED: "CURRENT_MARKER_TYPES_UPDATED",
  SHORTCUTS_UPDATED: "SHORTCUTS_UPDATED",
  SET_CURRENT_SHORTCUTS: "SET_CURRENT_SHORTCUTS",
  SAVE_SHORTCUT: "SAVE_SHORTCUT",
  LOAD_SHORTCUTS: "LOAD_SHORTCUTS",
  CLEAR_MARKERS_UPDATE_STATUS: "CLEAR_MARKERS_UPDATE_STATUS",
};

export const AUTH_ACTIONS = {
  AUTHENTICATE_USER: "AUTHENTICATE_USER",
  AUTHENTICATE_ATTEMPTED: "AUTHENTICATE_ATTEMPTED",
  USER_AUTHENTICATED: "USER_AUTHENTICATED",
  USER_LOGGED_OUT: "USER_LOGGED_OUT",
  LOGIN_ERROR: "LOGIN_ERROR",
  REMOVE_LOGIN_ERROR: "REMOVE_LOGIN_ERROR",
  LOAD_DASHBOARD: "LOAD_DASHBOARD",
  LOAD_SESSION: "LOAD_SESSION",
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  GET_SESSION: "GET_SESSION",
  GET_DASHBOARD: "GET_DASHBOARD",
  CREATE_USER: "CREATE_USER",
  USER_CREATED: "USER_CREATED",
  USER_CREATED_ERROR: "USER_CREATED_ERROR",
  VERIFY_USER: "VERIFY_USER",
  USER_VERIFIED: "USER_VERIFIED",
};

export const ADMIN_ACTIONS = {
  LOAD_SCHEMA: "LOAD_SCHEMA",
  SCHEMA_LOADED: "SCHEMA_LOADED",
  SAVE_SCHEMA: "SAVE_SCHEMA",
  SCHEMA_SAVED: "SCHEMA_SAVED",
  DELETE_SCHEMA: "DELETE_SCHEMA",
  SCHEMA_DELETED: "SCHEMA_DELETED",
  ADD_SCHEMA_TO_PROJECT: "ADD_SCHEMA_TO_PROJECT",
  SCHEMA_ADDED_TO_PROJECT: "SCHEMA_ADDED_TO_PROJECT",
  REMOVE_SCHEMA_FROM_PROJECT: "REMOVE_SCHEMA_FROM_PROJECT",
  SCHEMA_REMOVED_FROM_PROJECT: "SCHEMA_REMOVED_FROM_PROJECT",
  CLONE_SCHEMA: "CLONE_SCHEMA",
  SCHEMA_CLONED: "SCHEMA_CLONED",
  CLONE_PROJECT_SCHEMA: "CLONE_PROJECT_SCHEMA",
  PROJECT_SCHEMA_CLONED: "PROJECT_SCHEMA_CLONED",
  LOAD_RESEARCH_GROUPS: "LOAD_RESEARCH_GROUPS",
  LOAD_RESEARCH_GROUP_DETAIL: "LOAD_RESEARCH_GROUP_DETAIL",
  ADD_RESEARCH_GROUP: "ADD_RESEARCH_GROUP",
  DELETE_RESEARCH_GROUP: "DELETE_RESEARCH_GROUP",
  RESEARCH_GROUP_DELETED: "RESEARCH_GROUP_DELETED",
  ADD_RESEARCH_GROUP_USER: "ADD_RESEARCH_GROUP_USER",
  UPDATE_RESEARCH_GROUP_USER: "UPDATE_RESEARCH_GROUP_USER",
  RESEARCH_GROUP_USER_ADDED: "RESEARCH_GROUP_USER_ADDED",
  RESEARCH_GROUP_USER_UPDATED: "RESEARCH_GROUP_USER_UPDATED",
  DELETE_RESEARCH_GROUP_USER: "DELETE_RESEARCH_GROUP_USER",
  RESEARCH_GROUP_USER_DELETED: "RESEARCH_GROUP_USER_DELETED",
  RESEARCH_GROUPS_LOADED: "RESEARCH_GROUPS_LOADED",
  RESEARCH_GROUP_LOADED: "RESEARCH_GROUP_LOADED",
  ADD_MEDIA: "ADD_MEDIA",
  ADD_PROJECT_MEDIA: "ADD_PROJECT_MEDIA",
  MEDIA_UPLOAD_STARTED: "MEDIA_UPLOAD_STARTED",
  MEDIA_UPLOAD_PROGRESS: "MEDIA_UPLOAD_PROGRESS",
  MEDIA_UPLOAD_DONE: "MEDIA_UPLOAD_FINISHED",
  CANCEL_MEDIA_UPLOAD: "CANCEL_MEDIA_UPLOAD",
  MEDIA_UPLOAD_CANCELLED: "MEDIA_UPLOAD_CANCELLED",
  MEDIA_ADDED: "MEDIA_ADDED",
  MEDIA_UPDATED: "MEDIA_UPDATED",
  DELETE_MEDIA: "DELETE_MEDIA",
  MEDIA_DELETED: "MEDIA_DELETED",
  LOAD_USER_MEDIA: "LOAD_USER_MEDIA",
  MEDIA_LOADED: "MEDIA_LOADED",
  DELETE_PROJECT_MEDIA: "DELETE_PROJECT_MEDIA",
  PROJECT_MEDIA_ADDED: "PROJECT_MEDIA_ADDED",
  PROJECT_MEDIA_DELETED: "PROJECT_MEDIA_DELETED",
  ADMIN_INFO: "ADMIN_INFO_HTTP",
  CLEAR_ADMIN_INFO: "CLEAR_ADMIN_INFO",
  DELETE_CAPTIONS: "DELETE_CAPTIONS",
  CAPTIONS_DELETED: "CAPTIONS_DELETED",
  SAVE_USER_PROFILE: "SAVE_USER_PROFILE",
  USER_PROFILE_SAVED: "USER_PROFILE_SAVED",
  INVITE_USER: "INVITE_USER",
  USER_INVITED: "USER_INVITED",
  INVITED_USER_CONFIRMED: "INVITED_USER_CONFIRMED",
};

export const SEARCH_ACTIONS = {
  RUN_SEARCH: "RUN_SEARCH",
  SEARCH_RESULTS_READY: "SEARCH_RESULTS_READY",
  SEARCH_RESULTS_PENDING: "SEARCH_RESULTS_PENDING",
};

export const PUBLIC_PAGE_ACTIONS = {
  LOAD_SCHEMA_BY_ID: "LOAD_SCHEMA_BY_ID",
  PUBLIC_PAGE_SCHEMA_LOADED: "PUBLIC_PAGE_SCHEMA_LOADED",
};

export const WS_ACTIONS = {
  SUBSCRIBE: "SUBSCRIBE",
  UNSUBSCRIBE: "UNSUBSCRIBE",
  ADMIN_INFO: "ADMIN_INFO",
  CLIENT_INFO: "CLIENT_INFO",
  UNCATEGORIZED: "UNCATEGORIZED",
  ADMIN_DATA: "ADMIN_DATA",
};

export const NOTES_ACTIONS = {
  ADD_NOTE_TO_MARKER: "ADD_NOTE_TO_MARKER",
  UPDATE_NOTE: "UPDATE_NOTE",
  DELETE_NOTE: "DELETE_NOTE",
  NOTE_ERROR: "NOTE_ERROR",
  CLEAR_NOTE_ERROR: "CLEAR_NOTE_ERROR",
  ADD_COMMENT_TO_NOTE: "ADD_COMMENT_TO_NOTE",
  ADD_REPLY_TO_COMMENT: "ADD_REPLY_TO_COMMENT",
  UPDATE_COMMENT: "UPDATE_COMMENT",
  DELETE_COMMENT: "DELETE_COMMENT",
  COMMENT_ERROR: "COMMENT_ERROR",
  CLEAR_COMMENT_ERROR: "CLEAR_COMMENT_ERROR",
};
