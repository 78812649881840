import { call } from "redux-saga/effects";
import { doPost, doDelete, doUpdate } from "./FetchWrapper";

export function addNoteToMarker(marker, noteText, callback) {
  const body = JSON.stringify({
    research_group_film_marker: marker.id,
    note_text: noteText,
  });
  return doPost(
    "/annotate/api/research-group-film-marker-notes/",
    body,
    callback
  );
}

export function updateMarkerNote(markerNote, callback) {
  const body = JSON.stringify({ note_text: markerNote.noteText });
  return doUpdate(
    `/annotate/api/research-group-film-marker-notes/${markerNote.id}/`,
    body,
    callback,
    true
  );
}

export function deleteMarkerNote(markerNote, callback) {
  return doDelete(
    `/annotate/api/research-group-film-marker-notes/${markerNote.id}/`,
    callback
  );
}

export function addRootCommentToNote(markerNote, content, callback) {
  const body = JSON.stringify({ content });
  return doPost(
    `/annotate/api/research-group-film-marker-notes/${markerNote.id}/add-comment/`,
    body,
    callback
  );
}

export function addReplyToComment(comment, content, callback) {
  const body = JSON.stringify({ content });
  return doPost(
    `/annotate/api/threaded-note-comments/${comment.id}/add-reply/`,
    body,
    callback
  );
}

export function updateComment(comment, callback) {
  const body = JSON.stringify({ content: comment.content });
  return doUpdate(
    `/annotate/api/threaded-note-comments/${comment.id}/`,
    body,
    callback,
    true
  );
}

export function deleteComment(comment, callback) {
  return doDelete(
    `/annotate/api/threaded-note-comments/${comment.id}/`,
    callback
  );
}
