// TODO replace "http://localhost:5000/backend" with "" and force REACT_APP_API_URL to be set at build stage
const backendUrlInfo = {
    BaseServerUrl: (process.env.REACT_APP_API_URL) ? process.env.REACT_APP_API_URL : "http://localhost:5000/backend",
    
};

const backendUrl = backendUrlInfo.BaseServerUrl;

export const WS_BACKEND_URL = (process.env.REACT_APP_WS_URL) ? process.env.REACT_APP_WS_URL : "ws://localhost:5000/ws/message";
export const WS_BACKEND_HTTP_URL = (process.env.REACT_APP_WS_HTTP_URL) ? process.env.REACT_APP_WS_HTTP_URL : "http://localhost:5000/ws";

export default backendUrl;
