/* @flow */

// React
import React, { Component } from "react";

// Constants
import { MARKER_SELECT } from "../constants/ItemTypes";

// PropTypes
import PropTypes from "prop-types";

// DnD
import { DragSource } from "react-dnd";

// Semantic UI
import { Label, Button, Dimmer, Icon, Popup, Grid } from "semantic-ui-react";

// Components
import MediateMarkerLabel from "./MediateMarkerLabel";

// Per react-dnd documentation http://react-dnd.github.io/react-dnd/

const markerSelectSource = {
  beginDrag(props) {
    return {
      marker: props.marker,
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

const propTypes = {
  marker: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired
};

class MediateMarkerSelect extends Component {
  state = { showDescription: false };

  constructor(props: Object) {
    super(props);
    (this: any).handleClickMarker = this.handleClickMarker.bind(this);
  }

  handleClickMarker(event: SyntheticEvent): void {
    this.props.onClickMarker(this.props.marker);
  }

  render() {
    const { isDragging, connectDragSource, marker } = this.props;
    const { showDescription } = this.state;
    let descriptionStyle = {};
    descriptionStyle["z-index"] = "5001 !important";
    return connectDragSource(
      <div
        className="mediate-marker-modal-select-marker"
        onClick={this.handleClickMarker}
        title={marker.description}
      >
        <MediateMarkerLabel
          className="mediate-marker-shortcut-label"
          marker={marker}
          clamp={false}
        />
      </div>
    );
  }
}

MediateMarkerSelect.propTypes = propTypes;

const DraggableMarkerSelect = DragSource(
  MARKER_SELECT,
  markerSelectSource,
  collect
)(MediateMarkerSelect);

export const MediateMarkerSelectGrid = (props: Object) => {
  const { markerTypes, columns, title, onClickMarker } = props;
  let layout = [];
  let total = markerTypes.length;
  let rows = Math.ceil(total / columns);
  let markerIndex = 0;
  for (let i = 0; i < rows; i++) {
    layout[i] = [];
    for (let j = 0; j < columns; j++, markerIndex++) {
      if (markerTypes[markerIndex] !== undefined) {
        layout[i][j] = markerTypes[markerIndex];
      }
    }
  }
  return (
    <Grid columns={columns}>
      {layout.map(row => (
        <Grid.Row>
          {row.map(marker => (
            <Grid.Column key={marker.id}>
              <DraggableMarkerSelect
                onClickMarker={onClickMarker}
                key={marker.id}
                marker={marker}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      ))}
    </Grid>
  );
};

export default DraggableMarkerSelect;
