import { PUBLIC_PAGE_ACTIONS } from "../constants/Actions";

const initalState = {
  schema: null,
};

export default function PublicPageReducer(state = initalState, action) {
  switch (action.type) {
    case PUBLIC_PAGE_ACTIONS.PUBLIC_PAGE_SCHEMA_LOADED:
      return {
        ...state,
        schema: action.schema
      };
    default:
      return state;
  }
}
