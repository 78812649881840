// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";
import { createUser } from "../actions/AnnotateActions";

import SignUp from "../componets/SignUp";
class SignUpContainer extends Component {
  render() {
    const { createUser, createdUser, createdUserError } = this.props;
    console.log(this.props);
    return (
      <SignUp
        onSubmit={createUser}
        createdUser={createdUser}
        createdUserError={createdUserError}
      />
    );
  }
}

function mapStateToProps(state) {
  // add some bool that shows an account was created
  return {
    createdUser: state.user.createdUser,
    createdUserError: state.user.createdUserError,
  };
}

export default connect(mapStateToProps, { createUser })(SignUpContainer);
