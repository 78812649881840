import React, { Component, createRef } from "react";
import {
  Form,
  Button,
  Divider,
  Accordion,
  Icon,
  Segment,
  Sidebar,
  Tab,
  Menu,
} from "semantic-ui-react";
import SearchUsers from "./AdminSearchUsers";
import InviteUserForm from "./InviteUserForm";
import ToggleIcon from "../ToggleIcon";

export default class AddCollaboratorOverlayTabs extends Component {
  state = {
    activeIndex: 0,
    showOptions: false,
  };
  searchPane = {
    menuItem: (
      <Menu.Item key="search" className="mediate-form-tabs-item">
        <Button
          icon
          onClick={(evt) => this.toggleOptions(evt, false)}
          fluid
          className="mediate-form-tabs-item-button"
          labelPosition="right"
        >
          <Icon name="search" />
          Search
        </Button>
      </Menu.Item>
    ),
    render: () => (
      <Tab.Pane>
        <SearchUsers
          onSelectResult={(res) => this.props.onAddCollaborator(res)}
        />
      </Tab.Pane>
    ),
  };
  invitePane = {
    menuItem: (
      <Menu.Item key="invite" className="mediate-form-tabs-item">
        <Button
          fluid
          onClick={(evt) => this.toggleOptions(evt, false)}
          icon
          className="mediate-form-tabs-item-button"
          labelPosition="right"
        >
          <Icon name="user plus" />
          Invite
        </Button>
      </Menu.Item>
    ),
    render: () => (
      <Tab.Pane>
        <InviteUserForm
          onSubmit={(email, message) => console.log(email, message)}
        />
      </Tab.Pane>
    ),
  };

  renderPanes = (...panes) => {
    const { activeIndex } = this.state;
    return [
      panes.map((pane, idx) =>
        React.cloneElement(pane.menuItem, {
          ...pane.menuItem.props,
          ...{
            onClick: () => this.setState({ activeIndex: idx }),
            active: activeIndex === idx,
            index: idx,
          },
        })
      ),
      () => panes[this.state.activeIndex].render(),
    ];
  };

  toggleOptions = (evt, val) => {
    this.setState({ showOptions: val });
  };

  render() {
    const { backButton } = this.props;
    const { showOptions } = this.state;
    const [panes, renderPane] = this.renderPanes(
      this.searchPane,
      this.invitePane
    );
    return (
      <Sidebar.Pushable className="mediate-form-tabs-container">
        <div className="mediate-form-tabs-header">
          {backButton}
          <h2 className="mediate-form-tabs-header-title">Add Collaborators</h2>
          <ToggleIcon
            onIcon="options"
            offIcon="options"
            onColor="blue"
            offColor="grey"
            onLabel="Hide Options"
            offLabel="Show Options"
            val={showOptions}
            onClick={this.toggleOptions}
          />
        </div>
        <div className="mediate-form-tabs-menu-container">
          <Sidebar
            animation="overlay"
            visible={showOptions}
            as={Menu}
            className="mediate-form-tabs-menu"
            inverted={true}
            tabular={false}
            vertical={true}
            fluid={false}
            secondary={true}
          >
            {panes}
          </Sidebar>
          <Sidebar.Pusher className="mediate-form-tabs-active-tab">
            <div
              onClick={(evt) => this.toggleOptions(evt, false)}
              className={`mediate-form-tabs-active-tab-dimmer ${
                showOptions ? "visible" : "hidden"
              }`}
            ></div>
            {renderPane()}
          </Sidebar.Pusher>
        </div>
      </Sidebar.Pushable>
    );
  }
}
