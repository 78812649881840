import React, { Component, useState, useCallback } from "react";
import {
  Card,
  Icon,
  Header,
  Label,
  Button,
  Container,
  Form,
  Modal,
  Image,
  Placeholder,
  Transition,
  ModalActions,
} from "semantic-ui-react";
import logoIcon from "../images/logo-icon.png";

import FileField from "./admin/FileField";
// use background-image for coverage and aspect ratio
function ProfileImageDisplay(props) {
  const { image, onSelect, readOnly } = props;
  const imageStyle = {
    minHeight: "200px",
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center 0",
    backgroundColor: "#22262c",
  };
  if (image) {
    return (
      <Container style={imageStyle} fluid className="profile-image-display">
        {!readOnly ? (
          <FileField
            multiple={false}
            size="massive"
            mediaTypes={"image/*"}
            onChange={onSelect}
            as={Label}
            attached="top left"
            content="Change"
            className="change-profile-image-button"
          />
        ) : null}
      </Container>
    );
  }
  const component = !readOnly ? (
    <FileField
      multiple={false}
      size="massive"
      label={"Upload Profile Picture"}
      mediaTypes={"image/*"}
      onChange={onSelect}
    />
  ) : (
    <Placeholder>
      <Placeholder.Image square />
    </Placeholder>
  );
  return component;
}
// TODO let user update email address and username
export default class UserCard extends Component {
  state = {
    bio: "",
    organization: "",
    image: null,
    localImage: null,
  };
  componentDidMount() {
    const { user } = this.props;
    if (user.profile) {
      // hydrate local state
      const { bio, organization, imageUrl } = user.profile;
      this.setState({
        bio: bio,
        organization: organization,
        image: imageUrl,
      });
    }
  }
  handleImageSelect = (file) => {
    this.setState({
      localImage: file,
      image: URL.createObjectURL(file),
    });
  };

  handleInputChange = (key, val) => {
    const toMerge = {
      [key]: val,
    };
    this.setState({ ...this.state, ...toMerge });
  };

  handleSave = () => {
    const { onSave, user } = this.props;
    const { image, localImage, ...rest } = this.state;
    rest.image = localImage;
    onSave(user, rest);
  };

  render() {
    const { user, readOnly, size, actions } = this.props;
    const { bio, organization, image } = this.state;
    const s = size ? size : "large";
    return (
      <Card raised size={s} className="user-account-profile-card">
        <ProfileImageDisplay
          readOnly={readOnly}
          image={image}
          onSelect={this.handleImageSelect}
        />
        <Card.Content>
          <Card.Header>{user.username}</Card.Header>
          <Card.Meta>
            {!readOnly ? (
              <Form.Input
                fluid
                placeholder="Organization"
                transparent
                value={organization}
                onChange={(evt, { value }) =>
                  this.handleInputChange("organization", value)
                }
              />
            ) : (
              organization
            )}
          </Card.Meta>
          <Card.Meta>
            Joined in {new Date(user.dateJoined).getFullYear()}
          </Card.Meta>
          <Card.Description>
            {!readOnly ? (
              <Form.Input
                transparent
                fluid
                placeholder="Bio"
                value={bio}
                onChange={(evt, { value }) =>
                  this.handleInputChange("bio", value)
                }
              />
            ) : (
              bio
            )}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Card.Header as={Header} size="tiny">
            Projects
          </Card.Header>
          <Label>
            Owner
            <Label.Detail>
              {user.ownerResearchGroups ? user.ownerResearchGroups.length : 0}
            </Label.Detail>
          </Label>
          <Label>
            Collaborator
            <Label.Detail>
              {user.researchGroups ? user.researchGroups.length : 0}
            </Label.Detail>
          </Label>
        </Card.Content>
        <Card.Content extra>
          <Card.Header as={Header} size="tiny">
            Contact
          </Card.Header>
          <Label as="a" href={`mailto://${user.email}`}>
            <Icon name="mail" />
            {user.email}
          </Label>
        </Card.Content>
        {!readOnly ? (
          <Button
            icon
            size="tiny"
            color="blue"
            labelPosition="right"
            onClick={this.handleSave}
          >
            <Icon name="save" />
            Save
          </Button>
        ) : actions ? (
          actions
        ) : null}
      </Card>
    );
  }
}

function UserCardModalTrigger(props) {
  const { user, onToggleModal, actions } = props;
  const [active, setActive] = useState(false);
  const img =
    user.profile && user.profile.imageUrl ? user.profile.imageUrl : null;
  return (
    <div
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      className="mediate-user-card-modal-trigger-container"
    >
      <CircularAvatar
        showTitle={true}
        title={user.username}
        image={img}
        onClick={() => onToggleModal(true)}
      />
      {actions ? (
        <div className="mediate-user-card-modal-trigger-actions">{actions}</div>
      ) : null}
    </div>
  );
}

export function CircularAvatar(props) {
  const sizes = {
    large: 90,
    medium: 60,
    small: 30,
    tiny: 15,
  };
  const { title, subtitle, image, onClick, showTitle, size } = props;
  const img = image ? image : logoIcon;
  const s = sizes[size] !== undefined ? sizes[size] : sizes.small;
  const imageStyle = {
    height: `${s}px`,
    width: `${s}px`,
    borderRadius: `${s / 1.5}px`,
    backgroundImage: `url(${img})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center 0",
    backgroundColor: "#22262c",
  };
  const containerStyle = {};
  if (onClick) {
    containerStyle.cursor = "pointer";
  }
  return (
    <div
      className="mediate-collaborator-image-container"
      alt={title}
      onClick={onClick}
      style={containerStyle}
    >
      <div className="mediate-collaborator-image" style={imageStyle}></div>
      {showTitle ? (
        <div className="mediate-collaborator-image-title-container">
          <div className="mediate-collaborator-image-title">{title}</div>
          {subtitle ? (
            <div className="mediate-collaborator-image-subtitle">
              {subtitle}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export function UserCardModal(props) {
  const { user, actions, modalActions } = props;
  const [showModal, setShowModal] = useState(false);
  const image = user.profile ? user.profile.imageUrl : null;
  const wrapCallbacks = useCallback(
    (components, propName) => {
      if (!components) return [];
      return components.map((component) => {
        const { props } = component;
        let updatedComponent = component;
        if (props.hasOwnProperty(propName)) {
          const newProps = {};
          newProps[propName] = (...args) => {
            props[propName](...args);
            setShowModal(false);
          };
          updatedComponent = React.cloneElement(component, newProps);
        }
        return updatedComponent;
      });
    },
    [actions]
  );
  const wrappedActions = wrapCallbacks(modalActions, "onClick");
  return (
    <Modal
      centered
      size="mini"
      dimmer="blurring"
      open={showModal}
      closeOnDimmerClick={true}
      closeOnEscape={true}
      onClose={() => setShowModal(false)}
      basic
      className="mediate-search-result-detail-popup"
      trigger={
        <UserCardModalTrigger
          user={user}
          onToggleModal={setShowModal}
          actions={actions}
        />
      }
    >
      <Modal.Content className="mediate-user-card-modal-content">
        <UserCard user={user} readOnly={true} actions={wrappedActions} />
      </Modal.Content>
    </Modal>
  );
}
