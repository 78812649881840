import { call, put, takeEvery, all } from "redux-saga/effects";
import { NOTES_ACTIONS, MEDIATE_ACTIONS } from "../constants/Actions";
import * as NotesAPI from "../api/Notes";
import { fetchMarker } from "../api/MediateFetch";
import { getServerErrorMessage } from "../api/FetchWrapper";

function* handleLoadedMarker(loadedMarker, onErr) {
  if (loadedMarker) {
    yield put({
      type: MEDIATE_ACTIONS.MARKER_UPDATED,
      researchGroupFilmMarker: loadedMarker,
    });
  } else {
    yield onErr;
  }
}

function* parseServerError(error, fallback) {
  let message;
  if (error.message) {
    try {
      // in case response isn't actually json
      message = yield error.message;
    } catch (error) {
      // pass
    }
  }
  const m = message ? getServerErrorMessage(message) : fallback;
  return m;
}

function* handleNoteError(error) {
  console.error(error);
  const err = yield parseServerError(
    error,
    "There was a problem saving the note."
  );
  yield put({ type: NOTES_ACTIONS.NOTE_ERROR, error: err });
}

function* handleCommentError(error) {
  console.log(error);
  const err = yield parseServerError(
    error,
    "There was a problem saving the comment."
  );
  yield put({ type: NOTES_ACTIONS.COMMENT_ERROR, error: err });
}

// Note sagas

function* addNoteToMarkerSaga(action) {
  try {
    const { noteText, researchGroupFilmMarker } = action;
    const result = yield NotesAPI.addNoteToMarker(
      researchGroupFilmMarker,
      noteText,
      () => fetchMarker(researchGroupFilmMarker.id)
    );
    yield handleLoadedMarker(
      result,
      yield put({
        type: NOTES_ACTIONS.NOTE_ERROR,
        error: `Unable to add a note to marker ${researchGroupFilmMarker.id}`,
      })
    );
  } catch (error) {
    yield handleNoteError(error);
  }
}

function* updateNoteSaga(action) {
  try {
    const { note } = action;
    const result = yield NotesAPI.updateMarkerNote(note, () =>
      fetchMarker(note.researchGroupFilmMarker)
    );
    yield handleLoadedMarker(
      result,
      put({
        type: NOTES_ACTIONS.NOTE_ERROR,
        error: `Unable to update note ${note.id}`,
      })
    );
  } catch (error) {
    yield handleNoteError(error);
  }
}

function* deleteNoteSaga(action) {
  try {
    const { note } = action;
    const result = yield NotesAPI.deleteMarkerNote(note, () =>
      fetchMarker(note.researchGroupFilmMarker)
    );
    yield handleLoadedMarker(
      result,
      put({
        type: NOTES_ACTIONS.NOTE_ERROR,
        error: `Unable to delete note ${note.id}`,
      })
    );
  } catch (error) {
    yield handleNoteError(error);
  }
}

// Comment sagas

function* addCommentToNoteSaga(action) {
  try {
    const { note, content } = action;
    const result = yield NotesAPI.addRootCommentToNote(note, content, () =>
      fetchMarker(note.researchGroupFilmMarker)
    );
    yield handleLoadedMarker(
      result,
      put({
        type: NOTES_ACTIONS.COMMENT_ERROR,
        error: `Unable to add comment to note ${note.id}`,
      })
    );
  } catch (error) {
    yield handleCommentError(error);
  }
}

function* addReplyToCommentSaga(action) {
  try {
    const { comment, content } = action;
    const result = yield NotesAPI.addReplyToComment(comment, content, () =>
      fetchMarker(comment.noteMarker)
    );
    yield handleLoadedMarker(
      result,
      put({
        type: NOTES_ACTIONS.COMMENT_ERROR,
        error: `Unable to add reply to comment ${comment.id}`,
      })
    );
  } catch (error) {
    yield handleCommentError(error);
  }
}

function* updateCommentSaga(action) {
  try {
    const { comment } = action;
    const result = yield NotesAPI.updateComment(comment, () =>
      fetchMarker(comment.noteMarker)
    );
    yield handleLoadedMarker(
      result,
      put({
        type: NOTES_ACTIONS.COMMENT_ERROR,
        error: `Unable to update comment ${comment.id}`,
      })
    );
  } catch (error) {
    yield handleCommentError(error);
  }
}

function* deleteCommentSaga(action) {
  try {
    const { comment } = action;
    const result = yield NotesAPI.deleteComment(comment, () =>
      fetchMarker(comment.noteMarker)
    );
    yield handleLoadedMarker(
      result,
      put({
        type: NOTES_ACTIONS.COMMENT_ERROR,
        error: `Unable to delete comment ${comment.id}`,
      })
    );
  } catch (error) {
    yield handleCommentError(error);
  }
}

// Observers

function* watchForAddNote() {
  yield takeEvery(NOTES_ACTIONS.ADD_NOTE_TO_MARKER, addNoteToMarkerSaga);
}

function* watchForUpdateNote() {
  yield takeEvery(NOTES_ACTIONS.UPDATE_NOTE, updateNoteSaga);
}

function* watchForDeleteNote() {
  yield takeEvery(NOTES_ACTIONS.DELETE_NOTE, deleteNoteSaga);
}

function* watchForAddCommentToNote() {
  yield takeEvery(NOTES_ACTIONS.ADD_COMMENT_TO_NOTE, addCommentToNoteSaga);
}

function* watchForAddReplyToComment() {
  yield takeEvery(NOTES_ACTIONS.ADD_REPLY_TO_COMMENT, addReplyToCommentSaga);
}

function* watchForUpdateComment() {
  yield takeEvery(NOTES_ACTIONS.UPDATE_COMMENT, updateCommentSaga);
}

function* watchForDeleteComment() {
  yield takeEvery(NOTES_ACTIONS.DELETE_COMMENT, deleteCommentSaga);
}

export default function* notesSaga() {
  yield all([
    watchForAddNote(),
    watchForUpdateNote(),
    watchForDeleteNote(),
    watchForAddCommentToNote(),
    watchForAddReplyToComment(),
    watchForUpdateComment(),
    watchForDeleteComment(),
  ]);
}
