import { PUBLIC_PAGE_ACTIONS } from "../constants/Actions";
import { call, put, takeEvery, all } from "redux-saga/effects";
import * as PublicPageAPI from "../api/PublicPageFetch";

function* loadSchemaByIdSaga(action) {
    const { schemaId } = action;
    try {
        const result = yield PublicPageAPI.fetchSchemaById(schemaId);
        if (result) {
            yield put({ type: PUBLIC_PAGE_ACTIONS.PUBLIC_PAGE_SCHEMA_LOADED, schema: result });
        }
    } catch(error) {

    }
}

function* watchForLoadSchemaById() {
    yield takeEvery(PUBLIC_PAGE_ACTIONS.LOAD_SCHEMA_BY_ID, loadSchemaByIdSaga);
}

export default function* PublicPageSaga() {
    yield all([
        watchForLoadSchemaById(),
    ]);
}