/* @flow */

// React
import React, { Component } from "react";

// PropTypes
import PropTypes from "prop-types";

// VideoJS
import videojs from "video.js";
import "video.js/dist/video-js.css";

// SeekBar
import MediateSeekBar from "./SeekBar";

// Lodash
import lodash from "lodash";

// timecodes
import { secondsToFrames } from "../utils/timecodes";

const uuid = require("uuid/v4");

export default class VideoPlayer extends Component {
  player: videojs.Player;
  state = {
    playerReady: false,
    currentFrame: 0,
  };
  playerKey = uuid();
  seekBarKey = uuid();
  constructor(props: Object) {
    super(props);
    (this: any).handleTimeUpdate = this.handleTimeUpdate.bind(this);
    (this: any).timeUpdateCallback = this.timeUpdateCallback.bind(this);
    (this: any).initSeekbar = this.initSeekbar.bind(this);
    (this: any).setVjsRef = this.setVjsRef.bind(this);
  }
  // Lifecycle methods
  componentDidMount(): void {
    this.initPlayer();
  }
  componentWillReceiveProps(nextProps: Object): void {
    let currentSrc: string = this.props.src;
    let newSrc: string = nextProps.src;
    if (!lodash.isEqual(currentSrc, newSrc)) {
      this.setSrc(newSrc);
    }
  }
  componentWillUnmount(): void {
    this.player.dispose();
  }
  setVjsRef(ref) {
    this.vjsPlayer = ref;
  }
  initSeekbar(props) {
    const { playerReady } = this.state;
    const {
      seekBarImages,
      playerInfo,
      currentFrame,
      mediaType,
      player,
    } = props;
    if (playerReady !== false) {
      return (
        <MediateSeekBar
          key={this.seekBarKey}
          seekBarImages={seekBarImages}
          playerInfo={playerInfo}
          currentFrame={currentFrame}
          mediaType={mediaType}
          player={player}
        />
      );
    } else {
      return null;
    }
  }

  disableCtxMenu = (evt) => {
    evt.preventDefault();
  }

  render() {
    const { seekBarImages, src, mediaType } = this.props;
    const seekbarProps = {
      seekBarImages: seekBarImages,
      playerInfo: { framerate: this.framerate },
      currentFrame: this.state.currentFrame,
      mediaType: mediaType,
      player: this.player,
    };
    return (
      <div className="app-video-container">
        <video
          onContextMenu={this.disableCtxMenu}
          ref={this.setVjsRef}
          key={this.playerKey}
          className="video-js vjs-default-skin vjs-big-play-centered mediate-video-player"
        />
        {this.initSeekbar(seekbarProps)}
      </div>
    );
  }

  // Player related methods
  initPlayer(): void {
    const { playerOptions, textTracks, src } = this.props;
    this.player = videojs(this.getPlayerElement(), playerOptions);
    this.player.src(src);
    this.player.on("timeupdate", this.handleTimeUpdate);
    if (textTracks !== undefined) {
      if (textTracks.length > 0) {
        textTracks.forEach((track) =>
          this.player.addRemoteTextTrack(track, true)
        );
      }
    }
    this.registerPlugins();
    this.framerate = src[0].framerate ? src[0].framerate : 24;
    this.setState({ playerReady: true });
  }
  // Assuming all videojs plugins are written according to https://github.com/videojs/video.js/blob/master/docs/guides/plugins.md
  registerPlugins(): void {
    const plugins = this.props.plugins;
    plugins.forEach((pluginObj) => {
      videojs.plugin(pluginObj.name, pluginObj.plugin);
      this.player[pluginObj.name] = pluginObj.plugin.call(
        this.player,
        pluginObj.options
      );
    });
  }
  getPlayerElement(): HTMLCollection<HTMLElement> {
    return this.vjsPlayer;
  }
  setSrc(src: string) {
    this.player.src(src);
  }
  // events handlers
  handleTimeUpdate(): void {
    this.setState(
      {
        currentFrame: secondsToFrames(
          this.player.currentTime(),
          this.framerate
        ),
      },
      this.timeUpdateCallback
    );
  }
  timeUpdateCallback(): void {
    if (this.props.onTimeUpdate) {
      this.props.onTimeUpdate(this.state.currentFrame);
    }
  }
}

export class BasicVideoPlayer extends VideoPlayer {
  initPlayer() {
    const { playerOptions, textTracks, src } = this.props;
    this.player = videojs(this.getPlayerElement(), playerOptions);
    this.player.src(src);
    if (textTracks !== undefined) {
      if (textTracks.length > 0) {
        textTracks.forEach((track) =>
          this.player.addRemoteTextTrack(track, true)
        );
      }
    }
    this.setState({ playerReady: true });
  }
  render() {
    return (
      <div className="mediate-basic-video-container">
        <video
          ref={this.setVjsRef}
          key={this.playerKey}
          className="video-js vjs-default-skin vjs-big-play-centered mediate-video-player-basic"
        />
      </div>
    );
  }
}

VideoPlayer.propTypes = {
  src: PropTypes.array.isRequired,
  playerOptions: PropTypes.object,
  plugins: PropTypes.array,
};
