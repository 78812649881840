import { doPost, doGet, doDelete, doUpdate, doUpload } from "./FetchWrapper";
import backendUrl from "../constants/Backend";
import { replaceKeys, walkDeep } from "../utils/Application";
import { SCHEMA_KEYMAPPING, GROUP_KEYMAPPING } from "../constants/Application";
import { snakeCase } from "lodash";

// TODO will remove all this when backend names are refactored to match conceptual model
export function replaceSchemaKeys(schema) {
  const formattedSchema = replaceKeys(schema, SCHEMA_KEYMAPPING);
  const { groups } = formattedSchema;
  const formattedGroups = groups.map((g) => replaceKeys(g, GROUP_KEYMAPPING));
  formattedSchema.groups = formattedGroups;
  return formattedSchema;
}

export function fetchSchema(ownerId) {
  return doGet(`/annotate/api/research-group-marker-sets?created_by=${ownerId}`)
    .then((schema) => schema.map(replaceSchemaKeys))
    .catch((error) => console.log(error));
}

export function saveSchemaMarker(marker) {
  const body = propsToParams(marker);
  if (marker.id) {
    return doUpdate(
      `/annotate/api/research-group-marker-set-types/${marker.id}/`,
      body,
      null,
      true
    );
  }
  return doPost("/annotate/api/research-group-marker-set-types/", body);
}

function bulkAddOrUpdate(items, key, endpoint, partial = true) {
  const toUpdate = items.filter((item) => item[key] !== undefined);
  const toAdd = items.filter((item) => item[key] === undefined);
  const addTask =
    toAdd.length > 0
      ? doPost(endpoint, propsToJSON(toAdd))
      : Promise.resolve([]);
  const updateTask =
    toUpdate.length > 0
      ? doUpdate(endpoint, propsToJSON(toUpdate), null, partial)
      : Promise.resolve([]);
  return Promise.all([addTask, updateTask]).then((results) =>
    results.reduce((a, b) => a.concat(b), [])
  );
}

export function saveSchemaMarkers(markers) {
  return bulkAddOrUpdate(
    markers,
    "id",
    "/annotate/api/research-group-marker-set-types/",
    true
  );
}

export function saveSchemaGroupMarkers(group, schema) {
  const { markers, ...rest } = group;
  rest.schema = schema.id;
  const updatedMarkers = markers.map((m) => {
    m.public = schema.public;
    return m;
  });
  // save markers before saving group
  const markerTasks = saveSchemaMarkers(updatedMarkers);
  return markerTasks.then((results) => {
    const savedMarkers = results.map((m) => m.id);
    rest.markers = savedMarkers;
    return rest;
  });
}

export function deleteSchemaGroup(id) {
  return doDelete(`/annotate/api/research-group-marker-set-groups/${id}/`).then(
    responseStatusToBool
  );
}

export function deleteSchemaGroups(groups) {
  const tasks = groups.map((g) => deleteSchemaGroup(g.id));
  return Promise.all(tasks).then((results) =>
    results.every((result) => result === true)
  );
}

export function saveSchemaGroups(groups, schema) {
  const tasks = groups.map((g) => saveSchemaGroupMarkers(g, schema));
  const subtasks = Promise.all(tasks).then((updatedGroups) =>
    bulkAddOrUpdate(
      updatedGroups.map((g) => replaceKeys(g, GROUP_KEYMAPPING, true)),
      "id",
      "/annotate/api/research-group-marker-set-groups/",
      true
    )
  );
  return subtasks;
}

export function saveSchema(schema) {
  const { groups, id, ...rest } = schema;
  const body = propsToParams(rest);
  // set all marker types to public / private
  let task;
  if (schema.id) {
    task = doUpdate(
      `/annotate/api/research-group-marker-sets/${schema.id}/`,
      body,
      null,
      true
    );
  } else {
    task = doPost(`/annotate/api/research-group-marker-sets/`, body);
  }
  return task.then((result) => {
    const groupTasks = saveSchemaGroups(groups, result);
    return groupTasks.then((groups) => {
      const s = replaceKeys(result, SCHEMA_KEYMAPPING);
      s.groups = groups.map((g) => replaceKeys(g, GROUP_KEYMAPPING));
      return s;
    });
  });
}

export function addSchemaToProject(schemaId, projectId) {
  const body = propsToJSON({ researchGroup: projectId });
  return doPost(
    `/annotate/api/research-group-marker-sets/${schemaId}/add_to_research_group/`,
    body
  );
}

export function cloneSchema(schemaId) {
  const body = propsToJSON({});
  return doPost(
    `/annotate/api/research-group-marker-sets/${schemaId}/clone/`,
    body
  ).then((schema) => {
    const s = replaceKeys(schema, SCHEMA_KEYMAPPING);
    s.groups = schema.researchGroupMarkerSetGroups.map((g) =>
      replaceKeys(g, GROUP_KEYMAPPING)
    );
    return s;
  });
}

export function cloneSchemaToProject(schemaId, projectId) {
  const body = propsToJSON({ researchGroup: projectId });
  return doPost(
    `/annotate/api/research-group-marker-sets/${schemaId}/clone_to_research_group/`,
    body
  );
}

export function removeSchemaFromProject(schemaId, projectId) {
  const body = propsToJSON({ researchGroup: projectId });
  return doPost(
    `/annotate/api/research-group-marker-sets/${schemaId}/remove_from_research_group/`,
    body
  );
}

export function deleteSchema(schemaId) {
  return doDelete(`/annotate/api/research-group-marker-sets/${schemaId}/`).then(
    responseStatusToBool
  );
}

export function fetchResearchGroupDetail(id) {
  return doGet(`/annotate/api/research-groups-detail/${id}`);
}

export function fetchResearchGroups(userId) {
  return doGet(
    `/annotate/api/research-groups?research_group_users__user=${userId}`
  );
}

export function fetchAddResearchGroup(groupInfo) {
  const { name, description, owner } = groupInfo;
  const body = `name=${name}&description=${encodeURI(
    description
  )}&owner=${owner}`;
  return doPost(`/annotate/api/research-groups/`, body);
}

export function fetchDeleteResearchGroup(id) {
  return doDelete(`/annotate/api/research-groups/${id}/`).then(
    responseStatusToBool
  );
}

export function fetchResearchGroupFilm(filmId) {
  return doGet(`/annotate/api/research-group-films/${filmId}`).then((rgf) => {
    const { film } = rgf;
    return doGet(`/annotate/api/films/${film}`).then((f) => {
      rgf.film = f;
      return rgf;
    });
  });
}

export function fetchAddResearchGroupUser(userId, groupId) {
  const body = `user=${userId}&research_group=${groupId}&private_annotations=${false}`;
  return doPost(`/annotate/api/research-group-users/`, body);
}

// currently the only field to update is visibility - will change if the model changes

export function fetchUpdateResearchGroupUser(rguId, props) {
  const body = propsToFormData(props);
  return doUpdate(
    `/annotate/api/research-group-users/${rguId}/`,
    body,
    null,
    true
  );
}

// TODO move these functions to FetchWrapper and use them for everything else

export function propsToFormData(props) {
  const fd = new FormData();
  for (let key in props) {
    fd.set(snakeCase(key), props[key]);
  }
  return fd;
}

export function propsToJSON(props) {
  const formatted = walkDeep(props, (obj) => {
    const data = {};
    for (const [key, val] of Object.entries(obj)) {
      data[snakeCase(key)] = val;
    }
    return data;
  });
  return JSON.stringify(formatted);
}

export function propsToParams(props) {
  return Object.keys(props).reduce((a, b, idx) => {
    let param = `${snakeCase(b)}=${props[b]}`;
    if (idx > 0) {
      param = "&" + param;
    }
    a += param;
    return a;
  }, "");
}

export function fetchResearchGroupUser(userId) {
  return doGet(`/annotate/api/research-group-users/${userId}`).then((rgu) => {
    const { user } = rgu;
    return doGet(`/annotate/api/users/${user}`).then((u) => {
      rgu.user = u;
      return rgu;
    });
  });
}

function responseStatusToBool(status) {
  return status.responseStatus.toString().startsWith("2");
}

export function fetchDeleteResearchGroupUser(userId) {
  return doDelete(
    `/annotate/api/research-group-users/${userId}`
  ).then((status) => status.responseStatus.toString().startsWith("2"));
}

export function fetchAddFilm(film, update = false) {
  const { id, ...rest } = film;
  const body = propsToParams(rest);
  if (update) {
    return doUpdate(`/annotate/api/films/${film.id}/`, body);
  }
  return doPost("/annotate/api/films/", body);
}

export function fetchGetFilms(userId) {
  return doGet(`/annotate/api/films/?created_by=${userId}`);
}

export function fetchGetMedia(userId) {
  return fetchGetFilms(userId);
}

function fetchGetFilm(filmId) {
  return doGet(`/annotate/api/films/${filmId}`);
}

export function fetchAddResearchGroupFilm(filmId, researchGroupId) {
  const body = `film=${filmId}&research_group=${researchGroupId}`;
  return doPost("/annotate/api/research-group-films/", body);
}

export function fetchAddCaptions(mediaId, captions, channel) {
  const tasks = captions.map((caption) => {
    const body = new FormData();
    body.set("captions_file", caption.file);
    body.set("channel", channel);
    body.set("media_file", mediaId);
    return doPost(`/annotate/api/captions/`, body);
  });
  return Promise.all(tasks);
}

export function canUserUploadFile(file) {
  const body = JSON.stringify({ upload_size: file.size });
  return doPost(`/annotate/can-user-upload-file/`, body);
}

export function fetchAddMedia(
  film,
  media,
  captions,
  channel,
  update = false,
  onProgress = null
) {
  const { file } = media;
  const body = new FormData();
  if (file) body.set("media_file", file);
  if (channel) body.set("channel", channel.replace(/\s+/g, "").toLowerCase());
  if (!film.id && !update) {
    body.set("film_title", film.title);
    body.set("film_public", film.public);
  }
  const addCaptions = (film, media) => {
    return fetchAddCaptions(media.id, captions, channel).then(
      (createdCaptions) => {
        film.mediaUpload[0].captionsFiles = createdCaptions;
        return film;
      }
    );
  };
  const updateCB = (film, media) => {
    film.mediaUpload[0] = media;
    if (captions && captions.length > 0) {
      return addCaptions(film, media);
    }
    return film;
  };
  const cb = (media) => {
    if (captions && captions.length > 0) {
      return addCaptions(media.film, media);
    }
    return media.film;
  };
  // TODO fix update
  if (update) {
    const [task, cancel] = doUpload(
      `/annotate/api/media-file-uploads/${media.id}/`,
      body,
      null,
      onProgress,
      true
    );
    return [
      fetchAddFilm(film, update).then((film) =>
        task
          .then((updatedMedia) => Promise.resolve([film, updatedMedia]))
          .then(([film, updatedMedia]) => updateCB(film, updatedMedia))
      ),
      cancel,
    ];
  }
  const [task, cancel] = doUpload(
    "/annotate/api/media-file-uploads/",
    body,
    null,
    onProgress
  );
  return [task.then(cb), cancel];
}

// ALWAYS NEED TO ADD A FILM WHEN YOU ADD MEDIA
export function fetchAddProjectMedia(
  film,
  media,
  researchGroupId,
  channel = null
) {
  return fetchAddMedia(film, media, channel).then((createdFilm) =>
    fetchAddResearchGroupFilm(createdFilm.id, researchGroupId)
  );
}
// ResearchGroupFilm
// TODO come up with a prompt to delete the media if user is the owner
export function fetchDeleteProjectMedia(mediaId) {
  // if the user owns the media, delete it completely
  return doDelete(
    "/annotate/api/research-group-films/" + mediaId
  ).then((status) => status.responseStatus.toString().startsWith("2"));
}

export function fetchDeleteMedia(mediaId) {
  return doDelete("/annotate/api/films/" + mediaId).then((status) =>
    status.responseStatus.toString().startsWith("2")
  );
}

export function fetchDeleteCaptions(captionsId) {
  return doDelete(`/annotate/api/captions/${captionsId}`).then((status) =>
    status.responseStatus.toString().startsWith("2")
  );
}

export function fetchResearchGroupFilms(filmIds) {
  const tasks = filmIds.map((filmId) => fetchResearchGroupFilm(filmId));
  return Promise.all(tasks);
}

export function fetchResearchGroupUsers(userIds) {
  const tasks = userIds.map((userId) => fetchResearchGroupUser(userId));
  return Promise.all(tasks);
}

export function fetchSaveUserProfile(user, profile) {
  const fd = new FormData();
  fd.set("user", user.id);
  for (let key in profile) {
    if (profile[key]) {
      fd.set(key, profile[key]);
    }
  }
  if (user.profile) {
    return doUpdate(
      `/annotate/api/user-profiles/${user.profile.id}/`,
      fd,
      null,
      true
    );
  }
  return doPost("/annotate/api/user-profiles/", fd);
}

export function fetchInviteUser(email, message, researchGroupId) {
  const fd = new FormData();
  fd.set("email", email);
  fd.set("message", message);
  fd.set("researchGroup", researchGroupId);
  return doPost("/annotate/api/invited-users/", fd);
}
