// redux
import { combineReducers } from "redux";

// Constants
import {
  MEDIATE_ACTIONS,
  AUTH_ACTIONS,
  ADMIN_ACTIONS,
} from "../constants/Actions";

// utils
import { addMarkerFromShortcut } from "../utils/shortcuts";
import BinarySearch from "../utils/BinarySearch";

// reducers
import admin from "./AdminReducer";
import search from "./SearchReducer";
import publicPage from "./PublicPageReducer";
import notes from "./NotesReducer";

// constants
import {
  MARKER_STATUS_SAVED,
  MARKER_STATUS_FAILED,
  MARKER_STATUS_DELETED,
  MARKER_STATUS_PENDING,
} from "../constants/Application";
import { deleteElementImmutable, updateArrayOrAdd } from "../utils/ArrayUtils";

const _markerSearchTree = new BinarySearch("filmTimeCode.frameNo");

const initialUserState = {
  loggedIn: false,
  loginError: null,
  authenticateAttempted: false,
  dashboardLoaded: false,
  dashboard: {
    researchGroups: [],
  },
  session: [],
  createdUser: null,
  createdUserError: null,
  verified: false,
};

const initialMediateState = {
  researchGroupFilm: null,
  markersNeedUpdate: false,
  researchGroupFilmData: {
    researchGroupFilmMarkers: [],
  },
  markerStatus: {
    saveStatus: null,
    result: {},
  },
  mediateLoaded: false,
  queryOptions: {},
  queryResults: [],
  queryPending: false,
  playerQueryResults: null,
  loginError: null,
  currentSchema: null,
  researchGroupSchema: [],
  currentMarkerTypes: [],
  // or whatever we think the shortcuts should be
  shortcuts: null,
};

function addResearchGroupFilmMarker(researchGroupFilm, marker) {
  const rgf = { ...researchGroupFilm };
  _markerSearchTree.insert(marker);
  rgf.researchGroupFilmMarkers = _markerSearchTree.toArray();
  return rgf;
}

function updateResearchGroupFilmMarker(researchGroupFilm, marker) {
  researchGroupFilm.researchGroupFilmMarkers = updateArrayOrAdd(
    "id",
    marker.id,
    researchGroupFilm.researchGroupFilmMarkers,
    marker
  );
  return researchGroupFilm;
}

function deleteResearchGroupFilmMarker(researchGroupFilm, markerId) {
  const rgf = { ...researchGroupFilm };
  rgf.researchGroupFilmMarkers = deleteElementImmutable(
    "id",
    markerId,
    rgf.researchGroupFilmMarkers
  );
  _markerSearchTree.clear();
  _markerSearchTree.fromSortedArray(rgf.researchGroupFilmMarkers);
  return rgf;
}

// top level reducer
export const reducer = combineReducers({
  user,
  mediate,
  admin,
  search,
  publicPage,
  notes,
});

function user(state = initialUserState, action) {
  switch (action.type) {
    case AUTH_ACTIONS.USER_CREATED:
      return { ...state, createdUser: action.createdUser };

    case AUTH_ACTIONS.USER_CREATED_ERROR:
      return { ...state, createdUserError: action.error };

    case AUTH_ACTIONS.USER_VERIFIED:
      return { ...state, verified: true };

    case AUTH_ACTIONS.USER_AUTHENTICATED:
      return { ...state, loggedIn: action.loggedIn, session: action.session };

    case AUTH_ACTIONS.AUTHENTICATE_ATTEMPTED:
      return { ...state, authenticateAttempted: true };

    case AUTH_ACTIONS.USER_LOGGED_OUT:
      return { ...initialUserState };

    case AUTH_ACTIONS.REMOVE_LOGIN_ERROR:
      return {
        ...state,
        loginError: false,
      };

    case AUTH_ACTIONS.LOAD_DASHBOARD:
      return {
        ...state,
        dashboard: action.dashboardData,
        dashboardLoaded: action.dashboardLoaded,
      };

    case AUTH_ACTIONS.LOGIN_ERROR:
      return {
        ...state,
        loginError: action.reason || true,
      };

    case AUTH_ACTIONS.LOAD_SESSION:
      return { ...state, session: action.session };

    case ADMIN_ACTIONS.USER_PROFILE_SAVED:
      return {
        ...state,
        session: { ...state.session, profile: action.profile },
      };

    default:
      return state;
  }
}

function mediate(state = initialMediateState, action) {
  // TODO MAKE ALL ACTIONS CONSTANTS
  switch (action.type) {
    case MEDIATE_ACTIONS.CURRENT_MARKER_TYPES_UPDATED:
      return {
        ...state,
        currentMarkerTypes: action.markerTypes,
      };

    case MEDIATE_ACTIONS.SHORTCUTS_UPDATED:
      return {
        ...state,
        shortcuts: action.shortcuts,
      };

    case MEDIATE_ACTIONS.MEDIATE_DATA_LOADED:
      if (
        !state.researchGroupFilm ||
        state.researchGroupFilm.id !== action.researchGroupFilm.id
      ) {
        _markerSearchTree.clear();
        _markerSearchTree.fromSortedArray(
          action.researchGroupFilm.researchGroupFilmMarkers
        );
      }
      return {
        ...state,
        researchGroupFilm: action.researchGroupFilm,
        mediateLoaded: action.mediateLoaded,
      };

    case MEDIATE_ACTIONS.SCHEMA_SELECTED:
      return {
        ...state,
        currentSchema: action.currentSchema,
        markersNeedUpdate: true,
      };
    case MEDIATE_ACTIONS.RESEARCH_GROUP_SCHEMA_LOADED:
      return {
        ...state,
        researchGroupSchema: action.schema,
      };
    case MEDIATE_ACTIONS.SCHEMA_LOADED:
      return {
        ...state,
        currentSchema: action.currentSchema,
      };

    // TODO add MEDIATE_ACTIONS.MEDIATE_MARKERS_LOADED

    case MEDIATE_ACTIONS.CLEAR_INTERFACE:
      return {
        ...state,
        mediateLoaded: false,
      };
    case MEDIATE_ACTIONS.MARKER_SAVED:
      return {
        ...state,
        researchGroupFilm: addResearchGroupFilmMarker(
          state.researchGroupFilm,
          action.researchGroupFilmMarker
        ),
        markersNeedUpdate: true,
      };

    case MEDIATE_ACTIONS.MARKER_DELETED:
      return {
        ...state,
        researchGroupFilm: deleteResearchGroupFilmMarker(
          state.researchGroupFilm,
          action.researchGroupFilmMarkerId
        ),
        markersNeedUpdate: true,
        markerStatus: {
          saveStatus: MARKER_STATUS_DELETED,
          result: action.researchGroupFilmMarkerId,
        },
      };

    case MEDIATE_ACTIONS.MARKER_UPDATED:
      return {
        ...state,
        researchGroupFilm: updateResearchGroupFilmMarker(
          state.researchGroupFilm,
          action.researchGroupFilmMarker
        ),
        markersNeedUpdate: true,
      };

    case MEDIATE_ACTIONS.CLEAR_MARKERS_UPDATE_STATUS:
      return {
        ...state,
        markersNeedUpdate: false,
      };

    case MEDIATE_ACTIONS.MARKER_SAVE_SUCCESSFUL:
      return {
        ...state,
        markerStatus: {
          saveStatus: MARKER_STATUS_SAVED,
          result: action.result,
        },
      };

    case MEDIATE_ACTIONS.MARKER_SAVE_FAILED:
      return {
        ...state,
        markerStatus: {
          saveStatus: MARKER_STATUS_FAILED,
          result: action.result,
        },
      };

    case MEDIATE_ACTIONS.MARKER_SAVE_PENDING:
      return {
        ...state,
        markerStatus: {
          saveStatus: MARKER_STATUS_PENDING,
          result: action.result,
        },
      };

    case MEDIATE_ACTIONS.QUERY_OPTIONS_LOADED:
      return {
        ...state,
        queryOptions: action.queryOptions,
      };

    case MEDIATE_ACTIONS.QUERY_RESULTS_PENDING:
      return {
        ...state,
        queryPending: action.queryPending,
      };

    case MEDIATE_ACTIONS.QUERY_RESULTS_LOADED:
      return {
        ...state,
        queryResults: action.queryResults,
      };

    case MEDIATE_ACTIONS.PLAYER_QUERY_RESULTS_PENDING:
      return {
        ...state,
        playerQueryResults: "pending",
      };

    case MEDIATE_ACTIONS.PLAYER_QUERY_RESULTS_LOADED:
      return {
        ...state,
        playerQueryResults: action.playerQueryResults,
      };

    default:
      return state;
  }
}
