import React, { Component } from "react";
import {
  Segment,
  Button,
  Transition,
  Sidebar,
  Grid,
  Label,
  Icon,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { AddMediaOverlayTabs } from "./AddMediaOverlay";
import { ProjectMediaDisplay } from "./MediaDisplay";
import { ProjectSchemaDisplay } from "./SchemaDisplay";
import ProjectCollaboratorDisplay from "./ProjectCollaboratorDisplay";
import AddCollaboratorOverlayTabs from "./AddCollaboratorOverlay";
import SchemaOverlay from "./SchemaOverlay";
import { cloneDeep, isEqual } from "lodash";
import SimpleBar from "simplebar-react";
import { AdminContext } from "../../containers/admin/AdminContext";
import { ViewAction, AddAction } from "../../utils/ModalActions";

const PrivateNotification = (props) => (
  <Segment className="mediate-project-private-notification">
    <SimpleBar className="mediate-project-private-notification-container">
      <h2 className="mediate-project-private-notification-status">Private</h2>
      <h4 className="mediate-project-private-notification-message">
        {props.private
          ? "This project is set to private. Only collaborators will be able to view it"
          : "This project is public. Anyone with the link can view it."}
      </h4>
      <Button compact size="tiny" onClick={props.onPrivate}>
        {props.private ? "Publish" : "Make Private"}
      </Button>
    </SimpleBar>
  </Segment>
);

const SidebarOverlay = (props) => (
  <Sidebar.Pushable as={Segment}>
    <Sidebar direction="left" visible={props.visible}>
      {props.content}
    </Sidebar>
    <Sidebar.Pusher dimmed={props.active}>{props.children}</Sidebar.Pusher>
  </Sidebar.Pushable>
);

export default class ProjectView extends Component {
  state = {
    sidebarActive: false,
    activeForm: null,
    owner: null,
    collaborators: [],
  };

  constructor(props) {
    super(props);
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  componentDidMount() {
    this.updateCollaborators();
    const { user } = this.props;
    const { loadSchema } = this.context.actions;
    loadSchema(user.id);
  }

  updateCollaborators = () => {
    const { owner, researchGroupUsers, user } = this.props;
    // soon owner will be owners
    // cache owner and collaborators until collaborators updated
    const collaborators = cloneDeep(researchGroupUsers);
    const userIdx = researchGroupUsers.findIndex(
      (rgu) => rgu.user.id === user.id
    );

    if (userIdx > -1) {
      collaborators.splice(userIdx, 1);
    }
    this.setState({
      owner: owner,
      collaborators: collaborators,
    });
  };
  // TODO set flag in global state for requiring update
  componentDidUpdate(prevProps, prevState) {
    const { researchGroupUsers } = this.props;
    if (!isEqual(prevProps.researchGroupUsers, researchGroupUsers)) {
      this.updateCollaborators();
    }
  }

  toggleSidebar(activeForm) {
    this.setState({
      sidebarActive: !this.state.sidebarActive,
      activeForm: activeForm,
    });
  }

  handleAddCollaborator = (userId) => {
    const { id, onAddCollaborator } = this.props;
    if (onAddCollaborator) {
      onAddCollaborator(userId, id);
    }
  };

  handleAddSchema = (schema) => {
    const { onSelectSchema, id } = this.props;
    if (onSelectSchema) {
      onSelectSchema(schema, id);
    }
  };

  handleCloneSchema = (schema) => {
    const { onCloneSchema, id } = this.props;
    if (onCloneSchema) {
      onCloneSchema(schema, id);
    }
  };

  render() {
    const {
      onSelectMedia,
      onDeleteMedia,
      onDeleteSchema,
      userMedia,
      onDeleteCollaborator,
      onUpdateCollaborator,
      researchGroupMarkerSets,
      user,
      ...rest
    } = this.props;
    const { name, media, privateAnnotations, id } = rest;
    const { sidebarActive, activeForm, collaborators, owner } = this.state;
    const backButton = (
      <Button
        size="large"
        icon
        onClick={() => this.toggleSidebar(null)}
        className="mediate-project-tabs-back-button"
        labelPosition="left"
      >
        <Icon name="backward" />
        {name}
      </Button>
    );
    return (
      <div className="mediate-project-view-root">
        <Transition
          className="mediate-project-view-transition"
          visible={sidebarActive}
          duration={500}
          animation="fade"
        >
          <div className="mediate-project-tab">{activeForm}</div>
        </Transition>
        <Transition
          duration={500}
          animation="fade right"
          visible={!sidebarActive}
        >
          <div className="mediate-project-view-container">
            <Grid relaxed columns="equal" className="mediate-project-view-grid">
              <Grid.Column className="mediate-project-display-left">
                <Grid.Row className="mediate-project-meta">
                  <div className="mediate-project-admin-link-container">
                    <Link to="/admin" className="mediate-project-admin-link">
                      <Icon name="angle left" size="large" color="black" />
                      <span className="mediate-project-admin-link-text">
                        Admin
                      </span>
                    </Link>
                  </div>
                  <h1 className="mediate-project-title-large">{name}</h1>
                </Grid.Row>
                <Grid.Row className="mediate-project-assets-container">
                  <ProjectMediaDisplay
                    onAddMedia={() =>
                      this.toggleSidebar(
                        <AddMediaOverlayTabs
                          onSelectMedia={onSelectMedia}
                          researchGroup={rest}
                          userMedia={userMedia}
                          backButton={backButton}
                        />
                      )
                    }
                    media={media}
                    onDeleteMedia={onDeleteMedia}
                  />
                  <ProjectSchemaDisplay
                    project={rest}
                    schema={researchGroupMarkerSets}
                    onAddSchema={() =>
                      this.toggleSidebar(
                        <SchemaOverlay
                          showOptionsToggle={true}
                          readOnly={true}
                          backButton={backButton}
                          userSchema={this.context.schema}
                          user={user}
                          actions={[
                            <ViewAction
                              onClick={(schema) =>
                                window.open(`/admin/schema/${schema.id}`)
                              }
                            />,
                            <AddAction
                              onClick={(schema) => this.handleAddSchema(schema)}
                            />,
                          ]}
                        />
                      )
                    }
                    onCloneSchema={(schema) => this.handleCloneSchema(schema)}
                    onDeleteSchema={(schemaId) => onDeleteSchema(schemaId, id)}
                  />
                </Grid.Row>
              </Grid.Column>
              <Grid.Column className="mediate-project-display-right">
                <Grid.Row className="mediate-project-social-container">
                  <PrivateNotification private={privateAnnotations} />
                  <ProjectCollaboratorDisplay
                    collaborators={collaborators}
                    project={rest}
                    owner={owner}
                    onDeleteCollaborator={onDeleteCollaborator}
                    onUpdateCollaborator={onUpdateCollaborator}
                    onAddCollaborator={() =>
                      this.toggleSidebar(
                        <AddCollaboratorOverlayTabs
                          onAddCollaborator={this.handleAddCollaborator}
                          backButton={backButton}
                        />
                      )
                    }
                  />
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </div>
        </Transition>
      </div>
    );
  }
}

ProjectView.contextType = AdminContext;
