import React, { Component } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { loginUser, getSession } from "../actions/AnnotateActions";
import logo from "../images/logo.png";
import { Form, Button, Message, Radio, Icon } from "semantic-ui-react";
import ToggleIcon from "../componets/ToggleIcon";
import { Redirect, Link } from "react-router-dom";
class LoginPage extends Component {
  // TODO rewrite with semantic ui form
  state = {
    email: null,
    password: null,
    showPassword: false,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleShowPassword = this.handleShowPassword.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.loggedIn) {
      this.props.history.push("/dashboard");
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const { email, password } = this.state;
    if (this.props.loggedIn) {
      this.context.router.push("/");
    } else {
      const loginInfo = {
        username: email,
        password: encodeURIComponent(password),
      };
      this.props.loginUser(loginInfo);
      this.setState({
        loginAttempted: true,
      });
    }
  }

  handleEmailChange(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      email: event.target.value,
    });
  }

  handlePasswordChange(event, { value }) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      password: value,
    });
  }

  handleShowPassword(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleKeyPress(event) {
    if (event.charCode === 13) {
      this.handleSubmit(event);
    }
  }

  render() {
    const { password, showPassword } = this.state;
    const { loginError, loggedIn } = this.props;
    let messageClass = "mediate-login-error ";
    messageClass += loginError ? "show" : "hide";
    if (loggedIn === false) {
      return (
        <div className="hp-body-container">
          <div className="hp-overlay">
            <div className="hp-content">
              <div className="hp-logo-container">
                <img src={logo} className="hp-logo" />
              </div>
              <div className="hp-login-container">
                <h2 className="hp-tagline">Collaborative Media Annotation</h2>
                <div className="ui middle aligned center aligned grid">
                  <div className="column">
                    <Form
                      className="mediate-login-form"
                      onSubmit={this.handleSubmit}
                    >
                      <Form.Input
                        className="mediate-login-form-field"
                        icon="mail"
                        iconPosition="left"
                        onKeyPress={this.handleKeyPress}
                        onChange={this.handleEmailChange}
                        placeholder="email"
                        name="email"
                        type="text"
                        error={loginError !== null}
                      />
                      <Form.Input
                        className="mediate-login-form-field"
                        icon="lock"
                        iconPosition="left"
                        onKeyPress={this.handleKeyPress}
                        onChange={this.handlePasswordChange}
                        placeholder="password"
                        name="password"
                        value={password}
                        type={showPassword === true ? "text" : "password"}
                        error={loginError !== null}
                      />
                      <Message negative className={messageClass}>
                        <Message.Header>
                          There was a problem logging in!
                        </Message.Header>
                        {typeof loginError === "string" ? (
                          <p>{loginError}</p>
                        ) : (
                          <p>Username or password is incorrect</p>
                        )}
                      </Message>
                      <Button
                        className="mediate-login-submit"
                        type="submit"
                        color="blue"
                      >
                        Login
                      </Button>
                      <ToggleIcon
                        className="mediate-login-form-button"
                        id="show-password"
                        onClick={this.handleShowPassword}
                        onColor="white"
                        offColor="black"
                        onIcon="hide"
                        offIcon="eye"
                        onLabel="hide password"
                        offLabel="show password"
                      />
                    </Form>
                  </div>
                </div>
                <div className="hp-forgot-pass-container">
                  <a href="/signup">Sign Up</a>
                  <a href="/backend/accounts/password/reset/">
                    Forgot Password
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/dashboard" />;
    }
  }
}

LoginPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    loggedIn: state.user.loggedIn,
    loginError: state.user.loginError,
  };
}

//donot connect to store
export default connect(mapStateToProps, { loginUser, getSession })(LoginPage);
