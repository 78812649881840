import React, { Component } from "react";
import { Segment, Button, Icon, Form, Divider } from "semantic-ui-react";

export function AddProjectPrompt(props) {
  const { onClick } = props;
  return (
    <Segment className="mediate-add-project-prompt">
      <h2 className="mediate-project-title">No Projects Found</h2>
      <Button
        onClick={onClick}
        icon
        size="massive"
        labelPosition="right"
        color="blue"
      >
        <Icon name="plus" />
        Create One
      </Button>
    </Segment>
  );
}

export class AddProjectForm extends Component {
  state = { name: null, description: null, fieldErrors: {} };

  setVal = (key, val) => {
    const cloned = { ...this.state };
    cloned[key] = val;
    this.setState(cloned);
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { fieldErrors, ...rest } = this.state;
    const validation = this.validate();
    if (validation && onSubmit) {
      onSubmit(rest);
    }

  };

  validate = () => {
    const { name, description } = this.state;
    const errors = {};
    if (!name) {
      errors.name = true;
    }

    if (!description) {
      errors.description = true;
    }
    this.setState({
      fieldErrors: errors
    });
    return Object.keys(errors).length > 0 ? false : true;
  }

  render() {
    const { backButton } = this.props;
    const { fieldErrors } = this.state;
    return (
      <div className="mediate-add-project-form-container">
        <div className="mediate-add-project-form-header">
          {backButton ? backButton : null}
          <h2 className="mediate-add-project-form-title">Create Project</h2>
        </div>
        <Form inverted size="huge" className="mediate-add-project-form">
          <Form.Field required error={fieldErrors.name === true} className="mediate-add-project-form-field">
            <label>Name</label>
            <input
              placeholder="Project Name"
              onChange={(event) => this.setVal("name", event.target.value)}
            />
          </Form.Field>
          <Form.Field required error={fieldErrors.description === true} className="mediate-add-project-form-field">
            <label>Description</label>
            <input
              placeholder="Project Description"
              onChange={(event) =>
                this.setVal("description", event.target.value)
              }
            />
          </Form.Field>
          <Button
            onClick={this.handleSubmit}
            icon
            color="blue"
            labelPosition="right"
          >
            <Icon name="plus" />
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

export default class AddProject extends Component {
  state = { active: false };

  showForm = () => {
    this.setState({ active: true });
  };

  hideForm = () => {
    this.setState({ active: false });
  };

  handleSubmit = (projectInfo) => {
    const { onSubmit } = this.props;
    if (onSubmit) {
      this.props.onSubmit(projectInfo);
    }
  };

  render() {
    const { active } = this.state;
    if (!active) {
      return <AddProjectPrompt onClick={this.showForm} />;
    }
    return (
      <AddProjectForm onDiscard={this.hideForm} onSubmit={this.handleSubmit} />
    );
  }
}
