import React, { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { Button, Icon, Input } from "semantic-ui-react";
import { get } from "lodash";

import DynamicGrid from "../DynamicGrid";

export default function BasicAdminAssetDisplay(props) {
  const {
    title,
    data,
    columns,
    onAdd,
    inverted,
    renderCell,
    extraHeaderContent,
    filterKey,
  } = props;

  const [filteredData, setFilteredData] = useState(data);
  const [filterVal, setFilterVal] = useState("");
  const onFilter = (_, { value }) => {
    const v = value.toLowerCase();
    setFilterVal(value);
    setFilteredData(data.filter((d) => get(d, filterKey).toLowerCase().includes(v)));
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <div className="mediate-basic-display-container">
      <div className="mediate-project-overview-header-container mediate-basic-asset-display-header">
        <h1 className="mediate-project-overview-header mediate-basic-asset-title">
          {`${title} (${filterKey ? filteredData.length + " /" : ""} ${
            data.length
          })`}
        </h1>
        {onAdd ? (
          <Button
            compact
            size="tiny"
            icon
            color="blue"
            labelPosition="right"
            onClick={onAdd}
          >
            <Icon name="plus" />
            Add
          </Button>
        ) : null}
        {extraHeaderContent || null}
        {filterKey ? (
          <div className="mediate-basic-asset-filter">
            <Icon name="filter" className="mediate-basic-asset-filter-icon" />
            <Input
              size="huge"
              onChange={onFilter}
              value={filterVal}
              transparent
              placeholder={`filter ${title.toLowerCase()} ...`}
            />
          </div>
        ) : null}
      </div>
      <SimpleBar
        style={{ overflowX: "hidden" }}
        className={`mediate-basic-list ${inverted ? "inverted" : ""}`}
      >
        <DynamicGrid
          stackable
          columns={columns !== undefined ? columns : 2}
          className="mediate-basic-assets-result-display"
          data={filterKey ? filteredData : data}
          renderCell={renderCell}
        />
      </SimpleBar>
    </div>
  );
}
