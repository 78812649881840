// React
import React, {
  createRef,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import { VIDEO_ICON, AUDIO_ICON } from "../constants/Application";
import MediaDisplay from "./admin/MediaDisplay";
import { CollaboratorDisplay } from "./admin/ProjectsOveriew";

// React Router
import { Link, withRouter } from "react-router-dom";

// Semantic UI
import { Label, Icon, Button, Sidebar, List, Input } from "semantic-ui-react";

const AnnotateButton = withRouter((props) => {
  const { projectMediaId, history } = props;
  return (
    <Button
      labelPosition="right"
      compact
      onClick={() => history.push(`mediate/${projectMediaId}`)}
      size="tiny"
      icon
      color="blue"
      className="mediate-project-option-edit mediate-project-option-button"
    >
      <Icon name="pencil" />
      Annotate
    </Button>
  );
});
export const GroupMemberIcon = (props) => {
  const { user } = props.groupMember;
  return (
    <Label
      className="collaborator-icon"
      color="teal"
      as="span"
      data-title={user.username}
    >
      {user.firstName && user.lastName
        ? user.firstName.charAt(0) + user.lastName.charAt(0)
        : user.username.charAt(0)}
    </Label>
  );
};

GroupMemberIcon.propTypes = {
  groupMember: PropTypes.object.isRequired,
};


export const ResearchGroupWrapper = (props) => {
  const [showMenu, toggleMenu] = useState(false);
  const handleToggle = useCallback(() => {
    toggleMenu(!showMenu);
  }, [showMenu]);
  const [filterVal, setFilterVal] = useState("");
  const handleFilterInputChange = useCallback((evt, { value }) => {
    setFilterVal(value);
  }, []);

  const filteredProjects =
    filterVal && filterVal !== ""
      ? props.researchGroups.filter((rg) =>
          rg.name.toLowerCase().includes(filterVal.toLowerCase())
        )
      : props.researchGroups;
  const refs = filteredProjects.map(() => createRef(null));
  const scrollRef = useRef(null);
  const scrollToProject = useCallback((idx) => {
    const ref = refs[idx];
    if (ref && ref.current) {
      scrollRef.current.scrollTop = ref.current.offsetTop - scrollRef.current.offsetTop;
    }
  });
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.style.scrollBehavior = "smooth";
    }
  }, []);
  
  let researchGroupDisplay = filteredProjects.map((group, index) => (
    <div ref={refs[index]} className="group-wrapper" key={index}>
      <div className="page-header-container">
        <h1>{group.name}</h1>
        <h6>{group.description}</h6>
        <div className="group-collaborators">
          <CollaboratorDisplay
            collaborators={group.researchGroupUsers}
            owner={group.owner}
            user={props.user}
          />
        </div>
      </div>
      <div className="dash-video-list-container">
        {group.researchGroupFilms.map((groupFilm) => (
          <MediaDisplay
            inverted
            markerCount={groupFilm.researchGroupFilmMarkerCount}
            {...groupFilm.film}
            customButtons={[<AnnotateButton projectMediaId={groupFilm.id} />]}
          />
        ))}
      </div>
    </div>
  ));
  return (
    <div className="mediate-research-group-container">
      <Icon
        size="big"
        className="mediate-project-menu-toggle"
        name="content"
        onClick={handleToggle}
      />
      <Sidebar
        visible={showMenu}
        animation="push"
        className="mediate-projects-menu"
      >
        <span className="mediate-filter-projects-container">
          <Icon name="filter" className="mediate-filter-projects-icon" />
          <Input
            size="huge"
            onChange={handleFilterInputChange}
            value={filterVal}
            transparent
            placeholder="filter projects ..."
          />
        </span>
        <List
          size="big"
          animated
          inverted
          className="mediate-projects-menu-list-container"
        >
          {filteredProjects.map((rg, idx) => (
            <List.Item
              onClick={() => scrollToProject(idx)}
              className="mediate-projects-menu-list-item"
            >
              <List.Content>
                <List.Header>{rg.name}</List.Header>
                <List.Description>{rg.description}</List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Sidebar>
      <Sidebar.Pusher className="mediate-dashboard-main-content">
        <SimpleBar
          scrollableNodeProps={{ ref: scrollRef }}
          className="mediate-dashboard-scroll-container"
          style={{ overflowX: "hidden", scrollBehavior: "smooth" }}
        >
          {researchGroupDisplay}
        </SimpleBar>
      </Sidebar.Pusher>
    </div>
  );
};

ResearchGroupWrapper.propTypes = {
  researchGroups: PropTypes.array.isRequired,
};

export const ResearchGroupFilmDisplay = (props) => {
  console.log(props);
  let group;
  const groupFilm = props.groupFilm;
  let className = "dash-video ";
  if (groupFilm.thumbnail === null) {
    className += "no-thumb";
  }
  const filmDisplay = (
    <div className={className}>
      <Link className="mediate-link" to={`/mediate/${groupFilm.id}/`}>
        <img src={groupFilm.film.overviewImage} className="dash-video-image" />
        <div className="dash-video-title">{groupFilm.title}</div>
      </Link>
      <div className="dash-video-collab-container">
        {groupFilm.activeUserIds ? (
          <CollaboratorDisplay
            collaborators={group.researchGroupUsers.filter((rgu) =>
              groupFilm.activeUserIds.includes(rgu.user.id)
            )}
            owner={group.owner}
            user={props.user}
          />
        ) : (
          <h4> No Active Users </h4>
        )}
      </div>
      {groupFilm.hasCaptions === true ? (
        <div className="captions-label">
          <Icon name="closed captioning" inverted />
        </div>
      ) : (
        ""
      )}
      <div className="ui label">
        <Icon name="tags" inverted />
        {groupFilm.markerCount}
      </div>
    </div>
  );
  return filmDisplay;
};
ResearchGroupFilmDisplay.propTypes = {
  groupFilm: PropTypes.object.isRequired,
};
