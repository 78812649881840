import React, { useState, useRef, useCallback, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { useDrag } from "react-dnd";
export const SCROLL_DIRECTIONS = {
  HORIZONTAL: "HORIZONTAL",
  VERTICAL: "VERTICAL",
};


export default function ScrollDrag(props) {
  const { direction, disableDrag, className, children } = props;
  // Refs
  const ref = useRef(null);
  // State
  const [scrolling, setScrolling] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [scrollPos, setScrollPos] = useState(0);
  const prevDragDisabled = useRef(false);
  // Callbacks
  const handleMouseDown = useCallback((event) => {
    setScrolling(true);
    setClientX(event.clientX);
    setClientY(event.clientY);
    setScrollPos(
      direction === SCROLL_DIRECTIONS.HORIZONTAL
        ? ref.current.scrollLeft
        : ref.current.scrollTop
    );
  }, []);
  const handleMouseUp = useCallback(() => setScrolling(false), []);
  const handleMouseMove = useCallback(
    (event) => {
      let pos;
      if (direction === SCROLL_DIRECTIONS.HORIZONTAL) {
        pos = scrollPos - (event.clientX - clientX);
        setClientX(event.clientX);
      } else {
        pos = scrollPos - (event.clientY - clientY);
        setClientY(event.clientY);
      }
      setScrollPos(pos);
    },
    [clientX, clientY, scrollPos]
  );
  // if a child component is using the Drag and Drop API all mousevents are disabled
  // using this hook will make sure scrolling === false when drag ends
  useEffect(() => {
    if (!disableDrag && prevDragDisabled.current) {
      setScrolling(false);
    }
    prevDragDisabled.current = disableDrag;
  }, [disableDrag]);

  useEffect(() => {
    if (ref.current && scrolling) {
      if (direction === SCROLL_DIRECTIONS.HORIZONTAL) {
        ref.current.scrollLeft = scrollPos;
      } else {
        ref.current.scrollTop = scrollPos;
      }
    }
  }, [scrollPos]);
  // handle nested dragging bug if children use Drag n Drop API

  return (
    <SimpleBar
      style={{ cursor: scrolling ? "grabbing" : "inherit", height: "100%" }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      scrollableNodeProps={{ ref: ref }}
      className={`draggable-scroll-container ${
        className !== undefined ? className : ""
      }`}
    >
      {children}
    </SimpleBar>
  );
}
