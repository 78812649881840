/* @flow */

// React
import React, { Component } from "react";

// Constants
import { MARKER_SELECT } from "../constants/ItemTypes";
import { SHORTCUT_COUNT } from "../constants/Application";

// PropTypes
import PropTypes from "prop-types";

// DnD
import { DropTarget } from "react-dnd";

// Semantic UI
import { Label, Button } from "semantic-ui-react";

// immutability helper
import update from "immutability-helper";

// Components
import MediateMarkerLabel from "./MediateMarkerLabel";

class MediateMarkerShortcutSlot extends Component {
  constructor(props: Object) {
    super(props);
    (this: any).removeCurrentMarker = this.removeCurrentMarker.bind(this);
  }

  removeCurrentMarker() {
    const { index, removeCurrentMarker } = this.props;
    removeCurrentMarker(index);
  }

  render() {
    const { connectDropTarget, key, marker } = this.props;
    if (marker !== null) {
      return (
        <div className="mediate-marker-modal-shortcut-slot">
          <span className="mediate-marker-modal-shortcut-slot-label">
            {this.props.label}
          </span>
          <MediateMarkerLabel
            className="mediate-marker-shortcut-label"
            marker={marker}
            clamp={true}
          />
          <Button
            onClick={this.removeCurrentMarker}
            className="mediate-marker-shortcut-remove"
            color="gray"
            icon="window close"
          />
        </div>
      );
    } else {
      return connectDropTarget(
        <div className="mediate-marker-modal-shortcut-slot">
          <span className="mediate-marker-modal-shortcut-slot-label">
            {this.props.label}
          </span>
        </div>
      );
    }
  }
}
const spec = {
  drop(props, monitor, component) {
    const { index, setCurrentMarker } = props;
    const { marker } = monitor.getItem();
    setCurrentMarker(index, marker);
  }
};
function shortcutSlotCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    itemType: monitor.getItemType()
  };
}
const DroppableMediateMarkerShortcutSlot = DropTarget(
  MARKER_SELECT,
  spec,
  shortcutSlotCollect
)(MediateMarkerShortcutSlot);
export default DroppableMediateMarkerShortcutSlot;
