// Fetch Wrapper
import { doPost, doGet } from "./FetchWrapper";

export function fetchCreateUser(username, email, password) {
  const body = new FormData();
  body.set("username", username);
  body.set("email", email);
  body.set("password1", password);
  body.set("password2", password);
  return doPost("/rest-auth/registration/", body);
}

export function fetchVerifyUser(key) {
  const body = new FormData();
  body.set("key", key);
  return doPost("/rest-auth/registration/verify-email/", body);
}

// loginUser now returns userData response
export function loginUser(userInfo) {
  const body =
    "username=" + userInfo.username + "&password=" + userInfo.password;
  return doPost("/annotate/login/", body, function (json) {
    return json;
  });
}

export function getUserSession() {
  return doGet("/annotate/session/", function (json) {
    return json;
  });
}

export function fetchDashboardData() {
  return doGet("/annotate/dashboard/", function (json) {
    return json;
  });
}

export function authenticate() {
  return doGet("/annotate/authenticate/", function (json) {
    return json;
  });
}

export function logoutUser() {
  return doPost("/annotate/logout/", null);
}

export function getUsers() {
  doGet("/annotate/api/users/", function (json) {
    console.log(json);
  });
}
