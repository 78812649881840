/* @flow */
// React
import React from "react";

const MAX_LABEL_CHARS = 12;

export function clampLabel(label: string): string {
  if (label.length > MAX_LABEL_CHARS) {
    return label.substring(0, MAX_LABEL_CHARS) + " ...";
  }
  return label;
}

const MediateMarkerLabel = (props: Object) => {
  const { color, name, description } = props.marker;
  const { className, clamp } = props;
  let baseClass = "mediate-marker-label ";
  baseClass += className;
  let style = {};
  style["border-left"] = "3px solid " + color;
  return (
    <span
      className={baseClass}
      alt={name}
      title={clamp === true ? name : description}
      style={style}
    >
      {clamp === true ? clampLabel(name) : name}
    </span>
  );
};

export default MediateMarkerLabel;
