import React, { useCallback, useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";

const KEY_TO_MOUSETRAP_MAPPING = {
  control: "ctrl",
  " ": "space",
  arrowright: "right",
  arrowup: "up",
  arrowleft: "left",
  arrowdown: "down",
};

function keyToMousetrap(key) {
  const k = key.toLowerCase();
  return KEY_TO_MOUSETRAP_MAPPING[k] || k;
}

export default function CreateShortcut(props) {
  const { reservedShortcuts, onSave, onClear, currentShortcut } = props;
  // TODO warn on reserved shortcuts?
  const [keystrokes, setKeystrokes] = useState(null);
  const [warning, setWarning] = useState(null);
  const handleCaptureInput = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setWarning(null);
      const updated = keystrokes ? [...keystrokes] : [];
      const key = event.key.toLowerCase();
      if (key === "+") {
        setWarning("Can't use the + key in a shortcut");
        return;
      }
      const keystroke = keyToMousetrap(key);
      if (keystroke && !updated.includes(keystroke)) {
        updated.push(keystroke);
        const toStr = updated.join("+");
        if (reservedShortcuts && reservedShortcuts.includes(toStr)) {
          setWarning(`${toStr} is a reserved shortcut`);
          return;
        }
        setKeystrokes(updated);
      }
    },
    [keystrokes]
  );
  const clearKeystrokes = useCallback(() => {
    setWarning(null);
    setKeystrokes(null);
    if (onClear && keystrokes) {
      onClear(keystrokes.join("+"));
    }
  }, []);
  const saveKeystrokes = useCallback(() => {
    if (onSave) {
      onSave(keystrokes && keystrokes.length > 0 ? keystrokes.join("+") : null);
    }
  }, [keystrokes]);
  useEffect(() => {
    if (currentShortcut) {
      setKeystrokes(currentShortcut.split("+"));
    }
  }, [currentShortcut]);
  return (
    <div className="mediate-create-shortcut-container">
      <span className="mediate-create-shortcut-title">Shortcut</span>
      <div
        tabIndex={0}
        className="mediate-create-shortcut-input"
        onKeyDown={handleCaptureInput}
      >
        {warning
          ? warning
          : keystrokes
          ? keystrokes.join("+")
          : "Click here and enter keystrokes"}
      </div>
      <div className="mediate-create-shortcut-actions">
        <Icon
          className="mediate-create-shortcut-action"
          name="save"
          title="save shortcut"
          onClick={saveKeystrokes}
        />
        <Icon
          className="mediate-create-shortcut-action"
          name="trash"
          title="discard shortcut"
          onClick={clearKeystrokes}
        />
      </div>
    </div>
  );
}
