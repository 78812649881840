import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Redirect } from 'react-router';

// Loader
import PageLoader from "../componets/Loading";

// Actions
import { logoutUser, resetLoginErrorMessage } from "../actions/AnnotateActions";

class Logout extends Component {
  componentDidMount() {
    this.props.logoutUser();
    this.props.resetLoginErrorMessage();
  }
  render() {
    const { loggedIn } = this.props.user;
    if (loggedIn === false) {
      return(
        <Redirect to='/login' />
      );
    } else {
      return(
        <PageLoader text="logging out" />
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  { logoutUser, resetLoginErrorMessage }
)(Logout);
