import React, { Component, createRef } from "react";
import {
  Form,
  Button,
  Divider,
  Accordion,
  Icon,
  Segment,
  Tab,
  Menu,
  Sidebar,
} from "semantic-ui-react";
import { ACCEPTED_MEDIA_TYPES } from "../../constants/Application";
import SearchMedia from "./AdminSearchMedia";
import { BasicMediaDisplay } from "./MediaDisplay";
import FileField from "./FileField";
import ToggleIcon from "../ToggleIcon";
import { createMergeObj } from "../../utils/Application";

// TODO when we switch to typescript make an interface called IMediateAdminForm
export default class AddMediaOverlay extends Component {
  state = {
    film: {
      title: "",
      id: null,
    },
    media: {
      file: null,
      id: null,
    },
    captions: [], // [{file, language, id}]
    activeIndex: 0,
  };
  FILE_TYPES = {
    MEDIA: "MEDIA",
    CAPTIONS: "CAPTIONS",
  };
  TEXT_TYPES = {
    URL: "URL",
    TITLE: "TITLE",
  };
  mediaInput = createRef();
  captionsInput = createRef();

  constructor(props) {
    super(props);
    this.handleAddFile = this.handleAddFile.bind(this);
    this.handleSelectMedia = this.handleSelectMedia.bind(this);
    this.handleSelectCaptions = this.handleSelectCaptions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePropChange = this.handlePropChange.bind(this);
    this.renderUploadForm = this.renderUploadForm.bind(this);
    this.renderUserMediaForm = this.renderUserMediaForm.bind(this);
    this.handleAccordionSelect = this.handleAccordionSelect.bind(this);
  }

  componentDidMount() {
    const { currentMedia } = this.props;
    if (currentMedia) {
      const mediaUpload = currentMedia.mediaUpload[0];
      this.setState({
        film: {
          title: currentMedia.title,
          id: currentMedia.id,
          public: currentMedia.public,
        },
        media: {
          id: mediaUpload.id,
        },
      });
    }
  }

  handleAddFile(files, fileType) {
    switch (fileType) {
      case this.FILE_TYPES.MEDIA:
        this.setState({
          media: {
            ...this.state.media,
            file: files,
          },
        });
        break;

      case this.FILE_TYPES.CAPTIONS:
        this.setState({
          captions: files.map((f) => ({ file: f })),
        });
        break;

      default:
        break;
    }
  }

  handleSelectMedia() {
    this.mediaInput.current.click();
  }

  handleSelectCaptions() {
    this.captionsInput.current.click();
  }

  handleSubmit(id = null) {
    const { researchGroup } = this.props;
    const { film, media, captions } = this.state;
    this.props.onSelectMedia(film, media, captions, researchGroup, id);
  }

  handlePropChange(key, val) {
    const { toMerge, root } = createMergeObj(key, val);
    this.setState({
      [root]: { ...this.state[root], ...toMerge },
    });
  }

  handleAccordionSelect(event, { index }) {
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  }

  renderUserMediaForm() {
    return (
      <Form
        inverted
        as={Segment}
        size="big"
        className="mediate-admin-project-user-asset-display"
      >
        <BasicMediaDisplay
          inverted
          columns={3}
          media={this.props.userMedia}
          onSelect={this.handleSubmit}
        />
      </Form>
    );
  }

  renderUploadForm() {
    const { media, film } = this.state;
    const { currentMedia, onDeleteCaptions } = this.props;
    let mediaLabel;
    if (media.file) {
      mediaLabel = media.file.name;
    } else {
      if (currentMedia) {
        mediaLabel = currentMedia.mediaUpload[0].originalFileBasename;
      } else {
        mediaLabel = "Video or Audio";
      }
    }
    const isPublic = currentMedia ? currentMedia.public : film.public;
    return (
      <Form inverted as={Segment} className="mediate-tab-form" size="big">
        <div className="mediate-tab-form-toggle-public">
          <ToggleIcon
            size="large"
            onIcon="eye"
            offIcon="eye slash"
            onColor="green"
            offColor="red"
            onLabel="Public"
            offLabel="Private"
            initialVal={isPublic}
            onClick={(evt, val) => this.handlePropChange("film.public", val)}
          />
          <h4 className="mediate-tab-form-toggle-public-text">
            {film.public
              ? "Anyone Can Add This Media to Their Projects"
              : "This Media Can Only be Added to Your Projects"}
          </h4>
        </div>
        <Form.Group widths="equal">
          <Form.Field required>
            <label className="mediate-form-label">Media Title</label>
            <Form.Input
              type="text"
              onChange={(event, { value }) =>
                this.handlePropChange("film.title", value)
              }
              value={film.title}
            />
          </Form.Field>
          <FileField
            label="Media File"
            mediaTypes={ACCEPTED_MEDIA_TYPES.MEDIA}
            readOnly={true}
            multiple={false}
            currentFiles={currentMedia ? currentMedia.mediaUpload : null}
            onChange={(files) =>
              this.handleAddFile(files, this.FILE_TYPES.MEDIA)
            }
            defaultDisplay="Audio or Video File"
          />
          <FileField
            label="Captions File"
            multiple={true}
            mediaTypes={ACCEPTED_MEDIA_TYPES.CAPTIONS}
            onDeleteCurrent={onDeleteCaptions}
            currentFiles={
              currentMedia ? currentMedia.mediaUpload[0].captionsFiles : null
            }
            onChange={(files) =>
              this.handleAddFile(files, this.FILE_TYPES.CAPTIONS)
            }
            defaultDisplay="WebVTT or SRT File"
          />
        </Form.Group>
        {/*<Divider horizontal inverted section>
          OR
        </Divider>
        <Form.Field>
          <label className="mediate-form-label">
            URL (YouTube, Vimeo, IIIF Manifest)
          </label>
          <Form.Input
            type="text"
            onChange={(event, data) =>
              this.handleAddText(event, data, this.TEXT_TYPES.URL)
            }
          />
          </Form.Field>*/}
        <Form.Field className="mediate-form-add-button">
          <Button onClick={() => this.handleSubmit()} color="blue" size="big">
            {currentMedia ? "Update" : "Add"}
          </Button>
        </Form.Field>
      </Form>
    );
  }

  render() {
    const { activeIndex } = this.state;
    return (
      <div className="mediate-form-accordion-container">
        <h2 className="mediate-form-accordion-header-title">Add Media</h2>
        <Accordion className="mediate-form-accordion">
          <Accordion.Title
            className="mediate-form-accordion-title mediate-form-header"
            active={activeIndex === 0}
            index={0}
            as="h2"
            onClick={this.handleAccordionSelect}
          >
            <Icon
              className="mediate-form-accordion-icon"
              name="dropdown"
              size="huge"
            />
            Search Available Media
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            Search Goes Here
          </Accordion.Content>
          <Accordion.Title
            className="mediate-form-accordion-title mediate-form-header"
            active={activeIndex === 1}
            index={1}
            as="h2"
            onClick={this.handleAccordionSelect}
          >
            <Icon
              className="mediate-form-accordion-icon"
              name="dropdown"
              size="huge"
            />
            Upload Media
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            {this.renderUploadForm()}
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }
}

export class AddMediaOverlayTabs extends AddMediaOverlay {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      showOptions: false,
    };
  }
  searchPane = {
    menuItem: (
      <Menu.Item
        key="search"
        className="mediate-form-tabs-item"
      >
        <Button
          icon
          onClick={(evt) => this.toggleOptions(evt, false)}
          fluid
          className="mediate-form-tabs-item-button"
          labelPosition="right"
        >
          <Icon name="search" />
          Search
        </Button>
      </Menu.Item>
    ),
    render: () => (
      <Tab.Pane>
        <SearchMedia onSelectResult={this.handleSubmit} />
      </Tab.Pane>
    ),
  };
  myMediaPane = {
    menuItem: (
      <Menu.Item
        key="my-media"
        className="mediate-form-tabs-item"
      >
        <Button
          fluid
          onClick={(evt) => this.toggleOptions(evt, false)}
          icon
          className="mediate-form-tabs-item-button"
          labelPosition="right"
        >
          <Icon name="file alternate" />
          My Media
        </Button>
      </Menu.Item>
    ),
    render: () => <Tab.Pane>{this.renderUserMediaForm()}</Tab.Pane>,
  };
  uploadPane = {
    menuItem: (
      <Menu.Item
        key="upload"
        className="mediate-form-tabs-item"
      >
        <Button
          onClick={(evt) => this.toggleOptions(evt, false)}
          fluid
          icon
          className="mediate-form-tabs-item-button"
          labelPosition="right"
        >
          <Icon name="cloud upload" />
          Upload
        </Button>
      </Menu.Item>
    ),
    render: () => (
      <Tab.Pane className="mediate-form-add-media-pane">
        {this.renderUploadForm()}
      </Tab.Pane>
    ),
  };

  renderPanes = (...panes) => {
    const { activeIndex } = this.state;
    return [
      panes.map((pane, idx) =>
        React.cloneElement(pane.menuItem, {
          ...pane.menuItem.props,
          ...{
            onClick: () => this.setState({ activeIndex: idx }),
            active: activeIndex === idx,
            index: idx,
          },
        })
      ),
      () => panes[this.state.activeIndex].render(),
    ];
  };

  toggleOptions = (evt, val) => {
    this.setState({ showOptions: val });
  };

  render() {
    const { backButton, showTabs, currentMedia } = this.props;
    const { showOptions } = this.state;
    const [panes, renderPane] = this.renderPanes(
      this.myMediaPane,
      // this.uploadPane,
      this.searchPane
    );
    return (
      <Sidebar.Pushable as="div" className="mediate-form-tabs-container">
        <div className="mediate-form-tabs-header">
          {backButton}
          <h2 className="mediate-form-tabs-header-title">
            {currentMedia ? "Edit Media" : "Add Media"}
          </h2>
          {showTabs !== false ? (
            <ToggleIcon
              onIcon="options"
              offIcon="options"
              onColor="blue"
              offColor="grey"
              onLabel="Hide Options"
              offLabel="Show Options"
              val={showOptions}
              onClick={this.toggleOptions}
            />
          ) : null}
        </div>
        {showTabs !== false ? (
          <div className="mediate-form-tabs-menu-container">
            <Sidebar
              animation="overlay"
              visible={showOptions}
              as={Menu}
              className="mediate-form-tabs-menu"
              inverted={true}
              tabular={false}
              vertical={true}
              fluid={false}
              secondary={true}
            >
              {panes}
            </Sidebar>
            <Sidebar.Pusher
              dimmed={showOptions}
              className="mediate-form-tabs-active-tab"
            >
              <div
                onClick={(evt) => this.toggleOptions(evt, false)}
                className={`mediate-form-tabs-active-tab-dimmer ${
                  showOptions ? "visible" : "hidden"
                }`}
              ></div>
              {renderPane()}
            </Sidebar.Pusher>
          </div>
        ) : (
          <div className="mediate-form-tabs">{this.uploadPane.render()}</div>
        )}
      </Sidebar.Pushable>
    );
  }
}
