import React, { useCallback, useEffect, useState } from "react";
import { Fragment } from "react";
import { Portal, Icon } from "semantic-ui-react";
// Can't seem to override height of Sidebar so will have to roll our own basic one
export default function VerticalSidebar(props) {
  const { visible, onClose, children } = props;
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleKeyPress = useCallback(
    (evt) => {
      const { keyCode } = evt;
      if (keyCode === 27) {
        setSidebarVisible(false);
        setTimeout(() => {
          if (onClose) onClose();
        }, 1000);
      }
    },
    [onClose]
  );

  const handleClose = useCallback(() => {
    setSidebarVisible(false);
    setTimeout(() => {
      if (onClose) onClose();
    }, 1000);
  }, [onClose]);

  useEffect(() => {
    if (visible) {
      document.addEventListener("keydown", handleKeyPress);
      setTimeout(() => {
        setSidebarVisible(true);
      }, 100);
    }
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [visible]);

  return (
    <Fragment>
      <Portal open={visible}>
        <div className={`mediate-sidebar ${sidebarVisible ? "show" : "hide"}`}>
          <div onClick={handleClose} className="mediate-sidebar-close-button">
            <Icon size="large" color="grey" name="close" />
          </div>
          {children}
        </div>
      </Portal>
      <Portal open={visible}>
        <div
          onClick={handleClose}
          className={`mediate-sidebar-dimmer ${
            sidebarVisible ? "show" : "hide"
          }`}
        />
      </Portal>
    </Fragment>
  );
}
