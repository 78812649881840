// React
import React, { Component } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";

// Dashboard Component
import { ResearchGroupWrapper } from "../componets/ResearchGroupWrapper.js";

// Loader
import PageLoader from "../componets/Loading";

// Actions
import { loadResearchGroups } from "../actions/AdminActions";

import { Link } from "react-router-dom";

class DashboardPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { user } = this.props;
    if (user && user.isAuthenticated) {
      this.props.loadResearchGroups(user.userId);
    }
  }

  componentDidUpdate(prevProps) {
    // really only the case when logging in
    const { user, loadResearchGroups } = this.props;
    if (!prevProps.user && user) {
      if (user.isAuthenticated) {
        loadResearchGroups(user.userId);
      }
    }
  }

  render() {
    const { researchGroups, user } = this.props;

    if (researchGroups) {
      const sortedGroups = researchGroups.sort((rg1, rg2) => (new Date(rg2.createdDate) - new Date(rg1.createdDate)));
      return (
        <div className="mediate-dashboard-container">
          {sortedGroups.length > 0 ? (
            <ResearchGroupWrapper researchGroups={sortedGroups} user={user} />
          ) : (
            <h1 className="mediate-dashboard-project-prompt">
              <span>You don't have any projects</span>
              <Link className="mediate-project-prompt-admin-link" to="/admin">
                Create one here
              </Link>
            </h1>
          )}
        </div>
      );
    } else {
      const loaderText = "Loading Dashboard";
      return <PageLoader text={loaderText} />;
    }
  }
}

function mapStateToProps(state) {
  return {
    researchGroups: state.admin.researchGroups,
    user: state.user.session,
  };
}

DashboardPage.propTypes = {
  dashboard: PropTypes.object.isRequired,
  dashboardLoaded: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, { loadResearchGroups })(DashboardPage);
