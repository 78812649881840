import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import UserCard from "../UserCard";
import AccountSettings from "./AccountSettings";

export default class UserAccount extends Component {
  render() {
    const { user, onSaveProfile } = this.props;
    return (
      <div className="user-account-container">
        <Grid relaxed padded columns={2} className="user-account-grid">
          <h2 className="mediate-account-header mediate-project-title">
            My Account
          </h2>
          <Grid.Column className="mediate-account-section mediate-account-section-profile">
            <h3 className="mediate-account-section-header">My Profile</h3>
            <div className="mediate-account-section-profile-container">
              <UserCard
                user={user}
                readOnly={false}
                size="huge"
                onSave={onSaveProfile}
              />
            </div>
          </Grid.Column>
          <Grid.Column className="mediate-account-section">
            <h3 className="mediate-account-section-header">Settings</h3>
            <AccountSettings user={user} />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
