/* @flow */

// React
import React, { Component } from "react";

// Semantic UI
import { Button, Dimmer, Icon, Input } from "semantic-ui-react";

// Components
import MediateShortcutSlotContainer from "../containers/MediateShortcutSlotContainer";
import MediateMarkerLabel from "./MediateMarkerLabel";

// immutability helper
import update from "immutability-helper";

import DraggableMarkerSelect, {
  MediateMarkerSelectGrid
} from "./MediateMarkerSelect";

export default class MediateMarkerModal extends Component {
  state = {
    active: false,
    filteredMarkerTypes: [],
    selectedMarkers: []
  };

  constructor(props: Object) {
    super(props);
    (this: any).toggle = this.toggle.bind(this);
    (this: any).filterMarkers = this.filterMarkers.bind(this);
    (this: any).handleInputChange = this.handleInputChange.bind(this);
    (this: any).handleMarkerSelect = this.handleMarkerSelect.bind(this);
    (this: any).removeCurrentMarker = this.removeCurrentMarker.bind(this);
  }

  componentDidMount() {
    this.setState({
      filteredMarkerTypes: this.props.markerTypes
    });
  }

  componentDidUpdate(prevProps: Object, prevState: Object) {
    if (prevProps.markerTypeSetId !== this.props.markerTypeSetId) {
      this.setState({
        filteredMarkerTypes: this.props.markerTypes
      });
    }
  }

  toggle() {
    this.setState({
      active: !this.state.active
    });
  }

  filterMarkers(input: string): Array<Object> {
    if (input === "") return this.props.markerTypes;
    return this.props.markerTypes.filter(marker =>
      marker.name.toUpperCase().includes(input.toUpperCase())
    );
  }

  handleInputChange(event: SyntheticEvent, { value }: string): void {
    this.setState({
      filteredMarkerTypes: this.filterMarkers(value)
    });
  }

  handleMarkerSelect(marker: Object): void {
    const cloned = this.props.currentMarkerTypes.slice(0);
    cloned.push(marker);
    this.props.setCurrentMarkerTypes(cloned);
  }

  removeCurrentMarker(index: number): void {
    const cloned = this.props.currentMarkerTypes.slice(0);
    cloned.splice(index, 1);
    this.props.setCurrentMarkerTypes(cloned);
  }

  render() {
    const { currentMarkerTypes, setCurrentShortcuts } = this.props;
    const { active, filteredMarkerTypes, selectedMarkers } = this.state;
    return (
      <div className="mediate-marker-modal-select-container">
        <Button
          title="Select Markers: ctrl+s"
          className="mediate-marker-modal-select-open"
          onClick={this.toggle}
          icon="tags"
        />
        <Dimmer className="mediate-marker-modal" active={active} page>
          <div className="center">
            <Button
              onClick={this.toggle}
              className="mediate-marker-modal-select-close"
              color="blue"
              icon="window close"
            />
            <div className="mediate-marker-modal-select-filter-container mediate-ui-widget">
              <Input
                className="mediate-marker-modal-select-filter-input"
                onChange={this.handleInputChange}
                placeholder="Search ..."
              />
              <h3 className="mediate-ui-widget-label">Selected:</h3>
              <div className="mediate-marker-modal-select-current-markers-container mediate-ui-widget">
                <div className="mediate-marker-modal-select-current-markers">
                  {currentMarkerTypes.map((marker, index) => (
                    <div className="mediate-marker-modal-select-current-markers-wrapper">
                      <MediateMarkerLabel
                        className="mediate-marker-selected-label"
                        marker={marker}
                      />
                      <Button
                        onClick={() => this.removeCurrentMarker(index)}
                        className="mediate-marker-shortcut-remove"
                        color="gray"
                        icon="window close"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mediate-marker-modal-select-group">
              <div className="mediate-marker-modal-select-markers mediate-ui-widget">
                <h3 className="mediate-ui-widget-header">Marker Bin</h3>
                <MediateMarkerSelectGrid
                  markerTypes={filteredMarkerTypes}
                  columns={3}
                  onClickMarker={this.handleMarkerSelect}
                />
              </div>
              <MediateShortcutSlotContainer
                setCurrentShortcuts={setCurrentShortcuts}
              />
            </div>
          </div>
        </Dimmer>
      </div>
    );
  }
}
